import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButton";

const ShowMoreFilters = ({ showAll, handleShowMore }) => {
    const { t } = useTranslation()
    return (
      <CustomButton view="cancelModal" onClick={handleShowMore}>
        {!showAll ? t("More") : t("Less")}
      </CustomButton>
    );
};

export default ShowMoreFilters;
