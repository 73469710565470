import React from "react"
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import CustomTableCell from "../CustomTableCell";
import { withStyles } from "@material-ui/core/styles";

export const StyledTableHead = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(TableHead);

export default function CustomTableHeader({ labels }) {
    const isString = (label) => typeof (label) === "string";
    return (
      <StyledTableHead>
        <TableRow>
          {labels && labels?.map(({ label, ...rest }) => (label ? (
            <CustomTableCell title={isString(label) && label} {...rest} verticalAlign={rest.verticalAlign ?? "bottom"}>
              {!isString(label) && label}
            </CustomTableCell>
                ) : (
                  <CustomTableCell />
                )))}
        </TableRow>
      </StyledTableHead>
    )
}
