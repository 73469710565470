import { formtNumberByLanguage, getDefaultFormat, round } from "util";
import get from "lodash/get";

export const handleSplitClick = (form, key = "sharedData") => {
  const currentSharedData = form.watch(key);
  const newSharedData = [];
  const splitedWeight = Number(round(100 / currentSharedData.length, 3));
  const restPercent = Number(
    round(100 - splitedWeight * currentSharedData.length, 3)
  );
  currentSharedData.forEach((el, index) => {
    newSharedData.push({
      ...el,
      weight: formtNumberByLanguage(splitedWeight),
      allocatedAmount: formtNumberByLanguage(
        (splitedWeight / 100) * getDefaultFormat(form.watch("allocatedAmount"))
      ),
    });
    form.clearErrors([
      `${key}.${index}.allocatedAmount`,
      `${key}.${index}.weight`,
    ]);
  });
  if (restPercent !== 0) {
    const lastItem = newSharedData.pop();
    newSharedData.push({
      ...lastItem,
      weight: formtNumberByLanguage(splitedWeight + restPercent),
      allocatedAmount: formtNumberByLanguage(
        ((splitedWeight + restPercent) / 100)
          * getDefaultFormat(form.watch("allocatedAmount"))
      ),
    });
  }
  form.setValue(key, newSharedData);
};

export const totalProcessWeightValue = (form, key = "sharedData") => (Array.isArray(get(form.watch(), key))
    ? get(form.watch(), key).reduce(
        (acc, val) => Number(acc) + Number(val?.weight),
        0
      )
    : 0);

export const handlePercentChange = (form, field, target, isMultipled) => {
  const allocatedValue = (form.watch(field) / getDefaultFormat(form.watch("allocatedAmount"))) * 100;
  form.setValue(
    target,
    isMultipled
      ? allocatedValue.toFixed(`${allocatedValue}`.includes(".") ? 2 : 0)
      : formtNumberByLanguage(
          (
            (form.watch(field) / 100)
            * getDefaultFormat(form.watch("allocatedAmount"))
          ).toFixed(2)
        )
  );
};

export const handleSharedProcessChange = (form, target, processId) => {
  form.setValue(target, processId);
};
