export const COLLECTING_STATUS_COLOR = "#F99417";
export const FINISHED_STATUS_COLOR = "#1F8A70";
export const EXPORTING_STATUS_COLOR = "#BFDB38";
export const DEFAULT_STATUS_COLOR = "#DC3535";
export const RPA_PALETTE = {
    factors: {
        simple: {
            bgColor: "#8EFFA8",
            textColor: "#13a535",
        },
        medium: {
            bgColor: "#eef161",
            textColor: "#556409",
        },
        complex: {
            bgColor: "#FFC8C8",
            textColor: "#961212",
        },
    },
    status: {
        accepted: "#13a535",
        rejected: "#F65353",
        submitted: "#536ff6",
        roadMap: "#536ff6",
        pending: "#97979a",
        draft: "#97979a",
        notSubmitted: "#97979a",
    },
    actions: {
        edit: "#536ff6",
        accept: "#13a535",
        reject: "#F65353",
        delete: "#F65353",
        submit: "#536ff6",
    },
};
