import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({
  data, optionsChart, width, height,
}) => (
  <Bar height={height} width={width} data={data} options={optionsChart} />
);

export default BarChart;
