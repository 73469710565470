import React from "react";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";

import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import { Box, SvgIcon, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

export default function StatusButton({
  status, label, isProcess, customClass, disabled = false
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const statusStyle = {
    PENDING: {
      processStyle: classes.pending,
      processExecutionStyle: classes.pending,
    },
    PENDING_SR: {
      processStyle: classes.pending,
      processExecutionStyle: classes.pending,
    },
    MISSED_P: {
      processStyle: classes.pending,
      processExecutionStyle: classes.pending,
    },
    MISSED: {
      processStyle: classes.unknown,
      processExecutionStyle: classes.unknown,
    },
    RUNNING: {
      processStyle: classes.running,
      processExecutionStyle: classes.running,
    },
    UNKNOWN: {
      processStyle: classes.unknown,
      processExecutionStyle: classes.unknown,
    },
    EXCEPTION: {
      processStyle: classes.idle,
      processExecutionStyle: classes.unknown,
    },
    STOPPED: {
      processStyle: classes.idle,
      processExecutionStyle: classes.stopped,
    },
    DELETED: {
      processStyle: classes.done,
      processExecutionStyle: classes.archived,
    },
    DEBUGGING: {
      processStyle: classes.idle,
      processExecutionStyle: classes.running,
    },
    ARCHIVED: {
      processStyle: classes.idle,
      processExecutionStyle: classes.archived,
    },
    WARNING: {
      processStyle: classes.idle,
      processExecutionStyle: classes.warning,
    },
    STOPPING: {
      processStyle: classes.running,
      processExecutionStyle: classes.stopping,
    },
    COMPLETED: {
      processStyle: classes.idle,
      processExecutionStyle: classes.done,
    },
  };

  const handleStatusLabel = (label) => {
    if (label === "WARNING") {
      return t("RUNNING")
    }
    const status = (t(label) || "").split(" ");
    if (status.length > 1) {
      return status[0]?.length > 5 ? `${status[0]}...` : `${status[0]} ${status[1]}${status.length < 3 ? "" : "..."}`
    }
    return status[0]
  };

  const handleTooltipLabel = (label) => {
    if (label === "WARNING") {
      return t("status.running.with.warning");
    }
    return t(label);
  };

  return (
    <Tooltip title={handleTooltipLabel(label)} placement="top">
      <Chip
        size="small"
        className={clsx(
          classes.status,
          disabled && classes.statusDisabled,
          label === "WARNING" ? classes.customWarningStatus : classes.status,
          customClass,
          isProcess
            ? statusStyle[status]?.processStyle || classes.idle
            : statusStyle[status]?.processExecutionStyle || classes.done,
        )}
        label={
          <Box className={classes.warningLabel}>
            <span>{handleStatusLabel(label)}</span>
            {label === "WARNING" && (<SvgIcon className={classes.warningIcon}><WarningIcon /></SvgIcon>)}
          </Box>
        }
      />
    </Tooltip>
  );
}
