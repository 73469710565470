import React, { useEffect } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";

import SubPagePanel from "../../components/SubPagePanel";
import SubPageContainer from "../../components/SubPageContainer";
import {
  isSubModulePermitted,
} from "../../components/HasPermission";
import DefaultAlerts from "./DefaultAlerts";
import CustomtAlerts from "./CustomAlerts";

export const reasons = {
  CREATE: "create-option",
  REMOVE: "remove-option",
  CLEAR: "clear",
};

export default function AlertsManagement() {
  const classes = useStyles();
  const routes = [
    "/alerts/defaultAlerts",
    "/alerts/customAlerts",
  ];

  const history = useHistory();

  const value = routes.indexOf(history.location.pathname);

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  useEffect(() => {
    if (history.location.state && history.location.state.dialogMsg) {
      history.replace({ ...history.location });
    }
  }, [history]);
  return (
    <SubPageContainer>
      <div className={classes.root}>
        <Grid className={classes.firstGrid}>
          {isSubModulePermitted(currentUser, "Default Alerts") && (
          <SubPagePanel value={value} index={0}>
            {/* <HasPermission name="View default alerts"> */}
            <DefaultAlerts />
            {/* </HasPermission> */}
          </SubPagePanel>
            )}
          {isSubModulePermitted(currentUser, "Custom Alerts") && (
          <SubPagePanel value={value} index={1}>
            {/* <HasPermission name="View custom alerts"> */}
            <CustomtAlerts />
            {/* </HasPermission> */}
          </SubPagePanel>
            )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
