import React from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import {
  bePeriods,
  currencyList,
  isFleetAdministrator,
  REGEX,
} from "util";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomSelectField from "components/FormFields/CustomSelectField";
import useStyles from "../style.js";

const ClientParameters = () => {
  const classes = useStyles();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
    register,
    getValues,
  } = useFormContext();
  const { idFleet, mode = "add" } = useParams();

  const enableEdit = mode !== "view" && (mode === "add"
      || (isFleetAdministrator(currentUser) && (Number.parseInt(idFleet) === currentUser?.fleet?.id
      || currentUser?.fleet?.instanceOwner))
  );

  return (
    <Grid container item direction="column" spacing={4}>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={5} className={classes.textInputFormatter}>
          {t("fleet.management.formLabel.manDayValue")}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            {...register("hoursPerManDay", {
              required: t("fleet.management.formControl.required"),
              pattern: {
                value: REGEX.AMOUNT,
                message: t("fleet.management.formControl.number"),
              },
            })}
            id="hoursPerManDay"
            label={`${t("hours")} *`}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("hoursPerManDay"),
            }}
            error={!!errors.hoursPerManDay?.message}
            valued={!!enableEdit}
            InputProps={{ inputProps: { min: 0, readOnly: !enableEdit } }}
            helperText={errors.hoursPerManDay && errors.hoursPerManDay?.message}
          />
        </Grid>
        <Grid item xs={3} className={classes.textInputFormatter}>
          {t("hours")}
          {" *"}
        </Grid>
      </Grid>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={5} className={classes.textInputFormatter}>
          {t("fleet.management.formLabel.fullTime")}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            {...register("daysPerYear", {
              required: t("fleet.management.formControl.required"),
              pattern: {
                value: REGEX.AMOUNT,
                message: t("fleet.management.formControl.number"),
              },
            })}
            id="daysPerYear"
            label={`${t("days")} *`}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("daysPerYear"),
            }}
            error={!!errors.daysPerYear?.message}
            valued={!!enableEdit}
            InputProps={{
              readOnly: !enableEdit,
            }}
            helperText={errors.daysPerYear && errors.daysPerYear?.message}
          />
        </Grid>
        <Grid item className={classes.textInputFormatter} xs={3}>
          {t("days")}
          {" *"}
        </Grid>
      </Grid>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={5} className={classes.textInputFormatter}>
          {t("fleet.management.formLabel.bePeriod")}
        </Grid>
        <Grid item xs={4}>
          <CustomSelectField
            options={bePeriods}
            optionLabel="label"
            optionValue="days"
            {...register("beCalculationPeriod", {
              required: t("fleet.management.formControl.required"),
            })}
            value={watch("beCalculationPeriod")}
            readOnly={!enableEdit}
            isViewMode={!enableEdit}
            isCustom
          />
          <p className={classes.validation_error}>
            {errors.beCalculationPeriod?.message}
          </p>
        </Grid>
      </Grid>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={5} className={classes.textInputFormatter}>
          {t("fleet.management.formLabel.recommendedOccupancy")}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            {...register("recommendedOccupancy", {
              required: t("fleet.management.formControl.required"),
              pattern: {
                value: REGEX.POSITIVE_INTEGER,
                message: t("restriction.form.numberInteger.error"),
              },
              min: {
                value: 0,
                message: t("fleet.management.formControl.occupancyLowerThan"),
              },
              max: {
                value: 24,
                message: t("fleet.management.formControl.occupancyHigherThan"),
                }
            })}
            id="recommendedOccupancy"
            label={`${t("hours")} *`}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("recommendedOccupancy"),
            }}
            error={!!errors.recommendedOccupancy?.message}
            valued={!!enableEdit}
            InputProps={{
              inputProps: { min: 0, max: 24, readOnly: !enableEdit },
              endAdornment: (
                <InputAdornment position="start">
                  <Typography>
                    {(100 * (getValues("recommendedOccupancy") / 24)) ? `(${((100 * (getValues("recommendedOccupancy") / 24)).toFixed(0))}%)` : " "}
                  </Typography>
                </InputAdornment>)
            }}
            placeholder={`${t("hours")} *`}
            helperText={errors.recommendedOccupancy && errors.recommendedOccupancy?.message}
          />
        </Grid>
        <Grid item xs={3} className={classes.textInputFormatter}>
          {t("fleet.management.hoursPerDay")}
          {" *"}
        </Grid>
      </Grid>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={5} className={classes.textInputFormatter}>
          {t("fleet.management.formLabel.currency")}
        </Grid>
        <Grid item xs={4}>
          <CustomSelectField
            options={currencyList?.sort((a, b) => (a.label > b.label ? 1 : -1))}
            optionLabel="label"
            optionValue="code"
            {...register("currency", {
              required: t("fleet.management.formControl.required"),
            })}
            value={watch("currency")}
            readOnly={!enableEdit}
            isViewMode={!enableEdit}
            isCustom
          />
          <p className={classes.validation_error}>{errors.currency?.message}</p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientParameters;
