import React from "react";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "../style";
import { formatImagePath, getProcessAbbreviation } from "util";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tooltip from "@material-ui/core/Tooltip";

function RobotsCard({ resources }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.containerTitle}>
          {t("queue.management.section.resources")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {resources?.length > 0 ? (
                      resources.map((resource) => (
                        <Box
                              component={Grid}
                              mb="10px !important"
                              container
                              directiom="row"
                              alignItems="center"
                          >

                          <Box item mr="20px !important">
                            {resource?.icon ? (
                              <img
                                          src={formatImagePath(resource.icon)}
                                          alt="Service"
                                          className={classes.image}
                                      />
                                  ) : (
                                    <Avatar
                                          className={`${classes.avatar} ${classes.avatarColor}`}
                                          alt="Resource icon"
                                      >
                                      {getProcessAbbreviation(resource?.resourceDisplayName)}
                                    </Avatar>
                                  )}
                          </Box>
                          <Grid item xs={9}>
                            <Tooltip title={resource?.resourceDisplayName} placement="right">
                              <ButtonBase>
                                <Typography variant="subtitle1" className={classes.content}>
                                  {resource?.resourceDisplayName}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          </Grid>
                        </Box>
                      ))
                  ) : (
                    <Alert severity="warning" className={classes.alert}>
                      <Typography variant="subtitle1">
                        {t("administration.orchestrator.resource.list.not.available")}
                      </Typography>
                    </Alert>
              )}
      </Grid>
    </Grid>
  );
}

export default RobotsCard;
