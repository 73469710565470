import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Paper,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  ScatterPlotOutlined as ResultIcon,
  AssignmentIndOutlined as ProfileIcon,
  HorizontalSplitOutlined as FeasibilityIcon,
  AvTimerOutlined as TimeSpentIcon,
  SentimentSatisfiedAltOutlined as ImpactIcon,
  RateReviewOutlined as ITReviewIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import Result from "../../../components/Result";
import ProcessAssessmentByCriteria from "../ProcessAssessmentByCriteria";
import { LOAD_PROCESS, LOAD_STEPS_DATA } from "redux/constants";
import { useQuery } from "@redux-requests/react";
import { loadSteps } from "redux/actions/services/opportunities";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsContainer: {
    borderRadius: 0,
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    /* width: 100%; */
    borderBottom: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700]
    }`,
  },
  comment: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[400]
        : theme.palette.grey[500],
  },
}));

const getStepIcon = (i) => {
  switch (i) {
    case 0:
      return <ProfileIcon />;
    case 1:
      return <FeasibilityIcon />;
    case 2:
      return <TimeSpentIcon />;
    case 3:
      return <ImpactIcon />;
    case 4:
      return <ITReviewIcon />;
    default:
      return <ProfileIcon />;
  }
};

const ViewProcessTabs = ({ loadProcess }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  const { data: resLoadProcess, loading: isProcessLoading } = useQuery({ type: LOAD_PROCESS })
  const processStore = resLoadProcess || []

  const { data: resLoadStes, loading: isStepsLoading } = useQuery({ type: LOAD_STEPS_DATA })
  const steps = resLoadStes || []

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSteps());
  }, [dispatch]);

  if (isProcessLoading || !processStore || isStepsLoading || !steps) return <CircularLoader />

  return (
    <Box className={classes.root}>
      {(isProcessLoading || isStepsLoading) && <CircularLoader />}
      <Paper className={classes.tabsContainer}>
        {!isStepsLoading && processStore && (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {steps.map((step, i) => (
              <Tab
                key={step.id}
                label={t(step.name)}
                icon={getStepIcon(i)}
                {...a11yProps(0)}
              />
            ))}
            <Tab
              label={t("Result")}
              icon={<ResultIcon />}
              {...a11yProps(5)}
            />
          </Tabs>
        )}
      </Paper>
      {!isStepsLoading
        && !isProcessLoading
        && steps.map((step, i) => (
          <TabPanel
            key={step.id}
            value={value}
            index={i}
            className={classes.marginBotton}
          >
            <Box padding={2}>
              {step.criterias
                && step.criterias
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((criteria) => (
                    <Box key={criteria.id} marginBottom={4}>
                      <Grid item container spacing={4}>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h3">
                            {t(criteria.name)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="body1" component="h4">
                              <ProcessAssessmentByCriteria criteria={criteria} processStore={processStore} />
                            </Typography>
                            <Typography
                              className={classes.comment}
                              variant="body1"
                              component="h4"
                            >
                              {
                                processStore.qualifications.find(
                                  (q) => q.criteria.id === criteria.id,
                                )?.comment
                              }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box marginTop={3}>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
            </Box>
          </TabPanel>
        ))}
      {!isStepsLoading && !isProcessLoading && (
        <TabPanel value={value} index={steps.length}>
          {!isProcessLoading && (
            <Result result={processStore} loadProcess={loadProcess} />
          )}
        </TabPanel>
      )}
    </Box>
  );
};

export default ViewProcessTabs;
