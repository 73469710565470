import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateFilter } from "../../redux/slices/auditFilterSlice";
import AuditFilter from "./AuditFilter";
import CustomTableContainer from "../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../components/TableComponents/CustomTable";
import CustomTableHeader from "../../components/TableComponents/CustomTableHeader";
import CircularLoader from "../../components/Loaders/CircularLoader";
import CustomPagination from "../Services/components/CustomPagination";
import Audit from "./Audit";
import AuditService from "../../redux/actions/services/audit";
import UserNotFound from "../../assets/No_Users.svg";
import DataNotFound from "../../components/DataNotFound";
import { exportLogsData, fetchExportedFiles } from "redux/actions/services";
import useStyles from "./style";

export default function Audits() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [logsData, setLogsData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auditFilters = useSelector(({ auditFilters }) => auditFilters);
  const headerCellProps = {
    paddingBottom: 0,
  };

  const handleSortChange = (name, dir) => {
      dispatch(
        updateFilter({
          ...auditFilters,
          sortBy: [{ name, dir }],
        })
      );
  }
  const headerCells = [
    { label: t("audit.module"), ...headerCellProps },
    { label: t("audit.action"), ...headerCellProps },
    { label: t("audit.object"), ...headerCellProps },
    { label: t("audit.user"), ...headerCellProps },
    {
      label: t("audit.times.stamp"),
      name: "timestamp",
      sortable: true,
      onSort: handleSortChange,
      sortDir: auditFilters.sortBy.find((s) => s.name === "timestamp")?.dir,
      ...headerCellProps
    },
  ];
  const handlePageChange = (e, pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo,
      })
    );
  };
  const handleRowPerPageChange = (e) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: 0,
        pageSize: e.target.value,
      })
    );
  };

  const handleNextPerPage = (pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: pageNo + 1,
      })
    );
  };

  const handlePreviousPage = (pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: pageNo - 1,
      })
    );
  };

  const handleExportCsv = () => {
    setExportLoading(true);
    setAnchorEl(null);
    dispatch(
      exportLogsData(auditFilters),
    ).then(() => {
      setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(AuditService.fetchAuditLogs(
      auditFilters,
      (res) => {
        if (res.status === 200) {
          setLogsData(res.data);
        } else {
          toast.error(t("Unexpected Error"));
        }
        setIsLoading(false);
      },
      (err) => {
        toast.error(t(err.response?.data?.title || err.message));
        setIsLoading(false);
      }
    ));
  }, [dispatch, auditFilters]);

  return (
    <Grid container spacing={3} className={classes.rootGlobal}>
      <AuditFilter
        handleExportCsv={handleExportCsv}
        exportLoading={exportLoading}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
      />
      { isLoading && <CircularLoader height={300} /> }
      { !isLoading && Object.is(logsData.empty, false)
        && (
        <>
          <CustomTableContainer className={classes.tableContainer}>
            <CustomTable>
              <CustomTableHeader labels={headerCells} />
              <TableBody>
                {logsData.content
                    .map((row, i) => (<Audit row={row} key={`audit-row-${i}`} />))}
              </TableBody>
            </CustomTable>
          </CustomTableContainer>
          <CustomPagination
            count={logsData.totalElements}
            rowsPerPage={auditFilters.pageSize}
            page={auditFilters.pageNo}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowPerPageChange}
            onNext={handleNextPerPage}
            onPrevious={handlePreviousPage}
            rowsPerPageOptions={[10, 15, 20]}
        />
        </>)}
      { !isLoading && !Object.is(logsData.empty, false)
        && (
        <DataNotFound
          message={t("no.logs.message")}
          icon={UserNotFound}
        />)}
    </Grid>
  );
}
