/* eslint-disable no-nested-ternary */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import { TabPanel } from "pages/Services/components/SharedService";
import CustomDialog from "../../CustomDialog";
import CustomTab, {
  useIndicatorStyle,
} from "pages/Services/components/CustomTab";
import useStyles from "./style";

export default function DialogWithTabs(props) {
  const {
    disableFields,
    entityBaseUrl,
    tabs,
    tabsContents,
    activeStep,
    handleChange,
    open,
    idItem,
    moduleName,
    editCondition,
    customIcons,
    width,
    height,
    styledContent = true,
  } = props;
  const classes = useStyles({
 disableFields, width, TabPanelPaddingTop: disableFields ? 5 : 20, height
})();
  const { t } = useTranslation();
  const tabsStyle = useIndicatorStyle();
  const history = useHistory();

  const handleClose = () => {
    history.push(entityBaseUrl);
  };
  function a11yProps(index) {
    return {
      "aria-controls": `${moduleName}-tabpanel-${index}`,
    };
  }

  const renderEditButton = () => disableFields
    && editCondition && (
      <Grid container justify="flex-end" alignItems="center">
        {customIcons && customIcons?.map(({ icon, title }) => (
          <Tooltip title={title}>
            {icon}
          </Tooltip>
        ))}
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              history.push(`${entityBaseUrl}/edit/${idItem}`);
            }}
            className={classes.dialogTitleEditIcon}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    );

  return (
    <CustomDialog
      open={open}
      onBackdropClick={() => disableFields && handleClose()}
      onClose={handleClose}
      closeOnEscape={disableFields}
      titlePaddingBottom={5}
      title={
        <Grid container>
          <Grid item xs={11}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="switch tabs"
              value={activeStep}
              TabIndicatorProps={{
                style: tabsStyle,
              }}
          >
              {tabs?.filter(({ hidden }) => !hidden)?.map((tab, index) => (
                <CustomTab
                    id={tab?.id}
                    label={t(tab?.label)}
                    {...a11yProps(index)}
                />
            ))}
            </Tabs>
          </Grid>
          <Grid container item direction="row" xs={1} justify="flex-end">
            <CustomCloseButton
              aria-label="close"
              onClick={() => handleClose()}
          />
          </Grid>
        </Grid>
      }
      contentText={renderEditButton()}
      className={classes.dialog}
    >
      {tabsContents?.filter(({ hidden }) => !hidden)?.map((tabContent, index) => (
        <TabPanel value={activeStep} index={index} className={classes.tabPanelRoot}>
          <DialogContent className={styledContent ? (tabContent?.notUseBorder ? classes.borderlessDialogContent : classes.dialogContent) : classes.scrolledDialogContent}>
            <Box className={classes.dialogContentInner}>
              {tabContent?.content}
            </Box>
          </DialogContent>
          {(!disableFields || tabContent?.fixedActions) && (
          <DialogActions className={classes.dialogActions}>
            {tabContent?.actions}
            {tabContent?.fixedActions}
          </DialogActions>)}
        </TabPanel>))}
    </CustomDialog>
  );
}
