import { withStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  root: {
    "&$selected": {
      color: theme.palette.info.main,
    },
  },
  selected: {},
});

const CustomTab = withStyles(styles)(Tab);

export default CustomTab;
export const useIndicatorStyle = () => {
    const theme = useTheme();
   return {
     backgroundColor: theme.palette.info.main,
      height: 4,
    }
  };
