import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { FETCH_CURRENT_USER } from "../../redux/constants";
import { useQuery } from "@redux-requests/react";
import ImageProfileCmp from "../Administration/ImageProfile";
import PasswordReset from "./PasswordReset";
import UserInformation from "./UserInformation";
import useStyles from "./style";

function Profile() {
  const classes = useStyles();
  const [logo, setLogo] = useState(null);
  const [logoPath, setLogoPath] = useState();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  useEffect(() => {
    if (currentUser) {
      setLogoPath(currentUser?.logo);
    }
  }, [currentUser]);
  return (
    <Box display="grid" gridTemplateColumns="1FR 2FR" gridColumnGap="35px" className={classes.profileContainer} mt="70px">
      <Box display="grid" gridTemplateColumns="1FR" gridTemplateRows="100px 1FR" gridRowGap="35px">
        <Box className={classes.pictureSectionContainer}>
          <ImageProfileCmp
                  src={logoPath}
                  changeValue={setLogo}
                  maxMbSize={2}
                  allowedExtensions="image/jpg, image/jpeg,image/png, image/gif, image/bmp"
                  size={60}
              />
        </Box>
        <Box className={classes.profileSectionContainer}>
          <PasswordReset />
        </Box>
      </Box>
      <UserInformation logo={logo} />
    </Box>
  )
}

export default Profile;
