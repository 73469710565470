import React, { useState } from "react";
import { useFormContext, Controller, useFormState } from "react-hook-form";
import { useQuery } from "@redux-requests/react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { Delete as DeleteIcon } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextFieldForm from "../../../components/ReactHookField/TextFieldForm";
import { TOTAL_PROCESS_WEIGHT } from "util/constants/financial";
import {
  formatInputOnBlur,
  formatInputOnFocus,
  getCurrencySymbol,
  handleInputChanges,
} from "util";
import {
  handleSplitClick,
  handleSharedProcessChange,
  totalProcessWeightValue,
  handlePercentChange
} from "util/helpers/financial.helper";
import { FETCH_CURRENT_USER } from "redux/constants";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import useStyles from "../style";

function CostShared({
  showCostShared,
  processAllocations,
  processesData,
  isReadOnly,
  handleAddClick,
  handleDeleteClick,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useFormContext()
  const { errors } = useFormState()
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [openProcessesMenu, setOpenProcessesMenu] = useState({ state: false, id: null })
  const alreadyTakenProcess = (processId) => {
      const sharedData = form.watch("sharedData") ?? [];
      return sharedData?.map(({ processId }) => processId)?.includes(processId);
  }

  const handleProcessList = (state, id = null) => setOpenProcessesMenu({
    state,
    id
  })

  return (
    <Box className={classes.sharedCostWrapper}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <Typography
            className={!showCostShared ? classes.disabled : classes.enabled}
            variant="subtitle1"
          >
            {t("Cost Allocation")}
          </Typography>
        </Grid>
        <Grid container item xs={6} justify="flex-end" className={isReadOnly ? classes.hide : classes.show}>
          <CustomButton
            className={classes.addButtonText}
            variant="text"
            startIcon={
              <CallSplitIcon className={classes.addButtonIcon} />
            }
            disabled={!showCostShared}
            onClick={() => handleSplitClick(form, "sharedData")}
          >
            {t("Split equally")}
          </CustomButton>
          <CustomButton
            className={classes.addButtonText}
            variant="text"
            startIcon={
              <Add className={classes.addButtonIcon} />
            }
            disabled={!showCostShared || processesData?.length === form.watch("sharedData")?.length}
            onClick={handleAddClick}
          >
            {t("add.process")}
          </CustomButton>
        </Grid>
      </Grid>
      <div className={classes.sharedCostContainer}>
        {showCostShared && processAllocations && (
            processAllocations?.map((processAllocation, index) => (
              <Grid
                container
                alignItems="flex-start"
                justify="space-between"
                key={index}
                mt={2.5}
                spacing={3}
              >
                <Grid item xs={5} className={classes.sharedGrid}>
                  <Controller
                      name={`sharedData.${index}.processId`}
                      control={form.control}
                      rules={{
                        required: t("ERRORS_EMPTY_FIELD")
                      }}
                      render={() => (
                        <CustomAutoComplete
                    fullWidth
                    multiple={false}
                    options={processesData}
                    getOptionLabel={(option) => option?.processDisplayName}
                    value={processesData?.find((process) => process?.id === form.watch(`sharedData.${index}.processId`))}
                    disabled={isReadOnly}
                    disableClearable
                    onChange={(newVal) => {
                      handleProcessList(false)
                      handleSharedProcessChange(form, `sharedData.${index}.processId`, newVal.id)
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        label={t("Process")}
                        fieldName={`sharedData.${index}.processId`}
                        {...params}
                        variant="standard"
                        error={!!errors.sharedData?.[index]?.processId}
                        helperText={errors.sharedData?.[index]?.processId && errors.sharedData?.[index]?.processId.message}
                        inputRef={{ ...params }}
                        value={processesData?.find((process) => process?.id === form.watch(`sharedData.${index}.processId`))}
                        />
                    )}
                    renderOption={(process) => (
                      <Typography variant="subtitle2">
                        {process?.processDisplayName}
                      </Typography>
                    )}
                    getOptionDisabled={(option) => alreadyTakenProcess(option.id)}
                    open={openProcessesMenu.state && openProcessesMenu.id === `sharedData.${index}.processId`}
                    onOpen={() => handleProcessList(true, `sharedData.${index}.processId`)}
                    onClose={() => handleProcessList(false)}
                  />
                  )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextFieldForm
                    fieldName={`sharedData.${index}.weight`}
                    disabled={
                      !form.watch("allocatedAmount")
                      || +form.watch("allocatedAmount") === 0
                      || isReadOnly
                    }
                    rules={{
                      required: t("ERRORS_EMPTY_FIELD"),
                      min: {
                        value: totalProcessWeightValue(form, "sharedData") < TOTAL_PROCESS_WEIGHT ? 100 : 0,
                        message: t("WEIGHTS_SUM_MUST_BE_100"),
                      },
                      max: {
                        value:
                            totalProcessWeightValue(form, "sharedData") <= TOTAL_PROCESS_WEIGHT
                          ? 100
                            - (totalProcessWeightValue(form, "sharedData") - form.watch(`sharedData.${index}.weight`))
                          : 0,
                        message: t("WEIGHTS_SUM_MUST_BE_100"),
                      },
                    }}
                    min={0}
                    max={TOTAL_PROCESS_WEIGHT}
                    onChange={() => {
                      handlePercentChange(
                          form,
                        `sharedData.${index}.weight`,
                        `sharedData.${index}.allocatedAmount`,
                        false
                      );
                    }}
                    label={t("Weight")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    isCustom
                    onInput={(e) => handleInputChanges(e, 9)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.sharedGridAllocatedAmount}>
                  <TextFieldForm
                    fieldName={`sharedData.${index}.allocatedAmount`}
                    min={0}
                    rules={{
                      required: t("ERRORS_EMPTY_FIELD"),
                    }}
                    classes={classes.costTextField}
                    disabled={
                      !form.watch("allocatedAmount")
                      || +form.watch("allocatedAmount") === 0
                      || isReadOnly
                    }
                    onChange={() => {
                      handlePercentChange(
                          form,
                        `sharedData.${index}.allocatedAmount`,
                        `sharedData.${index}.weight`,
                        true
                      );
                    }}
                    label={t("Allocation Amount")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{getCurrencySymbol(currentUser?.fleet?.currency)}</InputAdornment>
                      ),
                    }}
                    isCustom
                    onInput={(e) => handleInputChanges(e, 9)}
                    onBlur={(field) => formatInputOnBlur(field, form)}
                    onFocus={(field) => formatInputOnFocus(field, form)}
                  />
                </Grid>
                <Grid item xs={1} className={`${classes.sharedDeleteGrid} ${isReadOnly || index === 0 ? classes.hide : classes.show}`}>
                  <DeleteIcon
                    className={classes.deleteIcon}
                    color={
                      processAllocation === processAllocations[0]
                        ? "disabled"
                        : "error"
                    }
                    disabled={processAllocation === processAllocations[0]}
                    onClick={() => handleDeleteClick(index)}
                  />
                </Grid>
              </Grid>
            ))
        )}
      </div>
    </Box>
  );
}

export default CostShared;
