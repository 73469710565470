import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
    // margin: -theme.spacing(3),
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.custom.color.chartColor1,
  },
  buttonContainer: {
    padding: theme.spacing(1, 0),
  },
  select: {
    width: "100%",
  },
  cardActions: {
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  validation_error: {
    color: "red",
    margin: "0",
  },
  formRow: {
    paddingBottom: theme.spacing(2),
  },
  titleProsListByOrchestrator: {
    marginBottom: "30px",
  },
  testConnectionBox: {
    padding: theme.spacing(1, 0),
  },
  informationIcon: {
    position: "absolute",
    top: 0,
    right: "14px",
  },
  inputsContainer: {
    height: "534px",
  },
  switchPrivate: {
  marginTop: -4,
  marginRight: 4,
  fontSize: "1.6rem",
},
  processesContainer: {
    border: `1px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(3),
  },
  statusButton: {
    color: "white",
    backgroundColor: "#7BB0FF",
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    marginRight: 10,
    "&:hover": {
      backgroundColor: fade("#7BB0FF", 0.8),
    }
  },
  testConnectionButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "0.3125rem"
  }
}));
