import { createSlice } from "@reduxjs/toolkit";
import { Duration } from "util/constants";

const initialState = {
    selectedDurationValue: Duration.DAY,
    process: [],
    tags: []
};

const dashboardFilters = createSlice({
    initialState,
    name: "filtersDashboard",
    reducers: {
        initFilter: () => ({
            ...initialState,
        }),
        updateProcessFilter: (state, action) => ({
            ...state,
            process: action.payload,
        }),
        updateDateFilter: (state, action) => ({
            ...state,
            selectedDurationValue: action.payload,
        }),
        updateTagsFilter: (state, action) => ({
            ...state,
            tags: action.payload,
        }),
    },
});

export const {
    initFilter, updateProcessFilter, updateDateFilter, clearProcessFilter, updateTagsFilter, updateFleetsFilter, updateMonitoringMode
} = dashboardFilters.actions;
export default dashboardFilters.reducer;
