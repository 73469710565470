import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
  FormControl,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ClearFilter from "../../../../components/ClearFilter";
import { initFilter } from "../../../../redux/slices/alertFilterSlice";
import { fetchFleetsForFilter } from "../../../../redux/actions/services";
import { useQuery } from "@redux-requests/react";
import { FETCH_ALL_FLEETS_FOR_FILTER, FETCH_CURRENT_USER } from "../../../../redux/constants";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import { isFleetAdministrator } from "util";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import AddIcon from "@material-ui/icons/Add";
import CustomButton from "../../../../components/CustomButton";
import { HasPermission } from "../../../../components/HasPermission";
import { SearchFilter } from "components/Filter";
import useStyles from "../style";

export default function AlertsTableFilter({
  statusList,
  typeList,
  handleFilterValueChange,
  handleChangeStatus,
  handleChangeTypes,
  handleChangeFleets,
  entityBaseUrl,
  handleAlertDialogOpen,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = useSelector(({ filterListAlert }) => filterListAlert);
  const dispatch = useDispatch();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const userFLeets = useQuery({ type: FETCH_ALL_FLEETS_FOR_FILTER })?.data;
  const handleClear = () => {
    dispatch(initFilter());
  };

    useEffect(() => {
        if (currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser)) {
          dispatch(fetchFleetsForFilter());
        }
    }, [])
    const getStatusColor = (status) => {
      if (status.code === "DISABLED") return "OFFLINE";
      return "ACTIVE";
    };

  return (
    <Grid container item>
      <Grid container item spacing={2} alignItems="flex-end">
        <Grid item xs={2}>
          <SearchFilter
            value={filter[filter.currModule]?.searchCriteria}
            callback={handleFilterValueChange}
          />
        </Grid>
        {currentUser?.fleet?.instanceOwner
          && isFleetAdministrator(currentUser) && (
            <Grid container item xs={2}>
              <FormControl fullWidth>
                <CustomAutoComplete
                      multiple
                      options={userFLeets || []}
                      optionLabel="companyName"
                      value={
                        userFLeets
                            ?.filter(({ id }) => filter[filter.currModule]?.fleets.includes(id))
                      }
                      noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                      onChange={handleChangeFleets}
                      label={t("groups")}
                />
              </FormControl>
            </Grid>
          )}
        <Grid container item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={statusList || []}
              optionLabel="label"
              optionUuid="code"
              value={statusList.filter(({ code }) => filter[filter.currModule]?.status?.includes(code))}
              onChange={handleChangeStatus}
              label={t("status")}
              isStatusFilter
              getStatusColor={getStatusColor}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={typeList}
              optionLabel="label"
              optionUuid="code"
              value={typeList.filter((item) => filter[filter.currModule]?.types.includes(item.code))}
              noOptionsNode={<NoDataMenu message={t("no.type.message")} />}
              onChange={handleChangeTypes}
              label={t("alert.type")}
          />
          </FormControl>
        </Grid>
        <Grid
            container
            item
            xs={1}
            justify="flex-start"
            alignItems="flex-end"
            className={classes.clearFilterBtn}
        >
          <ClearFilter
              clearFilter={handleClear}
          />
        </Grid>
        <HasPermission name={entityBaseUrl.includes("customAlerts") ? "Add custom alert" : "Add default alert"}>
          <Grid
          container
          item
          xs={3}
          justify="flex-end"
          alignItems="flex-end"
          className={classes.actionButtons}
        >
            <CustomButton
              view="primary"
              type="submit"
              onClick={() => {
                handleAlertDialogOpen(null, "add");
              }}
          >
              <AddIcon />
              {t("alerts.default.addButton")}
            </CustomButton>
          </Grid>
        </HasPermission>
      </Grid>
    </Grid>
  );
}
