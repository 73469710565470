import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { timeSlots } from "../../utils";
import InformationIcon from "components/InformationIcon";
import DataNotFound from "../../../../components/DataNotFound";
import OccurrencesNotFound from "../../../../assets/Process_Overview.svg"
import CustomDialog from "../../../../components/CustomDialog";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import useStyles from "./style";

function RiskManagement({
  openStart,
  handleClose,
  dispatch,
  t,
  reloadPlannerData,
  updateProcessRiskTime,
  processes,
}) {
  const classes = useStyles();
  const {
    handleSubmit, setValue, watch,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const newData = data.riskManagement.map((e) => ({
      ...e,
      riskTime: e.riskTime
        ? e.riskTime
        : 0,
    }));
    dispatch(updateProcessRiskTime(newData)).then((result) => {
      if (result?.status === 200) {
        toast.success(t("calendar.formControl.saveRiskSuccess"));
        reloadPlannerData();
        handleClose();
      }
      else
      {
        toast.error(t("Unexpected Error"));
      }
    });
  };
  useEffect(() => {
    if (processes) {
      processes.map((row, index) => (setValue(
        `riskManagement[${index}].riskTime`,
        row.riskTime,
      )));
    }
  }, [processes]);
  const getFormattedProcessName = (processName) => {
    if (processName?.length > 35) {
      return `${processName.substring(0, 35)}...`;
    }
    return processName;
  };

  return (
    <CustomDialog
      title={<Typography className={classes.dialogTitle}>{t("Risk management")}</Typography>}
      open={openStart}
      disableBackdropClick
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
      actionElements={[
          {
             label: t("Cancel"),
             onClick: handleClose,
             color: "primary",
          },
          {
            label: t("apply"),
            onClick: handleSubmit(onSubmit),
            disabled: !processes?.length,
            className: classes.cardActions,
            view: "primary",
            type: "submit",
          }
      ]}
    >
      <Grid container item xs={12}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          {processes?.length ? (
            <div className={classes.contentContainer}>
              <Grid container xs={12} className={classes.grid}>
                <Grid item alignItems="center" xs={9}>
                  <Typography className={clsx(classes.tableTitle, classes.processNameTitle)}>
                    {t("Schedule Name")}
                  </Typography>
                </Grid>
                <Grid item container xs={3}>
                  <Typography className={clsx(classes.tableTitle, classes.riskMarginTitle)}>
                    {t("Risk management")}
                  </Typography>
                </Grid>
              </Grid>
              {processes
                && processes.map((row, index) => (
                  <Grid container xs={12} className={classes.processContainer}>
                    <Grid item xs={9}>
                      <InformationIcon
                            titleContent={row?.title ?? ""}
                            originalContent={(
                              <TextField
                                    value={getFormattedProcessName(row.scheduleName)}
                                    style={{ width: "23rem" }}
                                    onChange={setValue(
                                        `riskManagement[${index}].scheduleId`,
                                        row.id,
                                    )}
                                    InputProps={{
                                      readOnly: true,
                                      disableUnderline: true,
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                      className: classes.typography
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item container xs={3}>
                      <CustomAutoComplete
                        options={timeSlots}
                        optionLabel={(item) => item?.label}
                        onChange={(e) => {
                          setValue(
                            `riskManagement[${index}].riskTime`,
                            e.value,
                          );
                        }}
                        value={timeSlots.find((ts) => ts.value === watch(`riskManagement[${index}].riskTime`))}
                        />
                    </Grid>
                  </Grid>
              ))}
            </div>
          ) : (
            <DataNotFound
              message={t("ERRORS_NO_DATA")}
              icon={OccurrencesNotFound}
              boxShadow="none"
            />
          )}
        </form>
      </Grid>
    </CustomDialog>
  );
}
export default RiskManagement;
