import React, { useEffect } from "react"
import { useTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import CustomDialog from "../../CustomDialog";
import useStyles from "../style"

function ExportConfirmation({
  onExportConfirm, setExportLoading, exportConfirmationOpen, setExportConfirmationOpen, setSeparateDetails, selectedProcess, fetchProcess
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [radioGrouped, setRadioGrouped] = React.useState(true);
    const [radioColumn, setRadioColumn] = React.useState(false);
    const [radioColumnCase, setRadioColumnCase] = React.useState({});

    React.useEffect(() => {
      setRadioColumnCase({
        color: (selectedProcess !== 1 && fetchProcess !== 1) ? "#E9E8E8" : "",
        enabled: selectedProcess === 1 || fetchProcess === 1,
      })
    }, [selectedProcess, fetchProcess])

    const handleClickOpen = () => {
        onExportConfirm();
        setExportLoading(true);
        setExportConfirmationOpen(false)
    };
    const handleRadioGrouped = (e) => {
        setRadioGrouped(e.target.checked);
        setRadioColumn(false);
        setSeparateDetails(false);
    }
    const handleRadioColumn = (e) => {
        setRadioColumn(e.target.checked);
        setRadioGrouped(false);
        if (selectedProcess === 1) {
          setSeparateDetails(true);
        }
    }

    useEffect(() => {
      if (!exportConfirmationOpen) {
        setRadioGrouped(true);
        setSeparateDetails(false);
        setRadioColumn(false);
      }
    }, [exportConfirmationOpen])

    return (
      <CustomDialog
          title={<Typography className={classes.dialogTitle}>{t("filter.export")}</Typography>}
          open={exportConfirmationOpen}
          onClose={() => setExportConfirmationOpen(false)}
          aria-labelledby="alert-dialog-export-confirmation"
          aria-describedby="alert-dialog-description"
          actionElements={[
              {
                  label: t("user.button.cancel"),
                  onClick: () => setExportConfirmationOpen(false),
                  color: "primary",
              },
              {
                  label: t("filter.export"),
                  onClick: handleClickOpen,
                  view: "primary",
                  disabled: !radioColumn && !radioGrouped,
              }
          ]}
          classes={{
            paper: classes.dialogPaper,
          }}
        >
        <Box display="flex" alignItems="center" marginBottom="-0.5rem">
          <Radio
                  checked={radioGrouped && exportConfirmationOpen}
                  onChange={(e) => handleRadioGrouped(e)}
                  name="radio-buttons"
                  id="grouped"
                  className={classes.radio}
                />
          <Typography className={classes.dialogDefaultText}>{t("export.confirmation.radio.grouped")}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Radio
                  checked={radioColumn}
                  onChange={(e) => handleRadioColumn(e)}
                  name="column"
                  disabled={!radioColumnCase?.enabled}
                  style={{ color: radioColumnCase?.color }}
                  className={classes.radio}
                />
          <Typography className={classes.dialogDefaultText} style={{ color: radioColumnCase?.color }}>{t("export.confirmation.radio.columns")}</Typography>
        </Box>
        {(selectedProcess !== 1 || fetchProcess === 1) && (
          <Alert severity="info" className={classes.wrongFilterAlert} icon={false}>
            {t("export.confiramtion.warnning")}
          </Alert>
              )}
      </CustomDialog>
    );
}

export default ExportConfirmation
