import truncate from "lodash/truncate";

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
};
export const minimizeString = (word, length = 20) => truncate(word, {
    length,
    omission: "..."
});
export const stringToValidObject = (data) => {
    const keysValue = data?.split(",")
    const result = {}
    keysValue?.forEach((pair) => {
        const [key, value] = pair.split(":").map((item) => item.trim());
        result[key] = value;
    })
    return result
}
