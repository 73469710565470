import theme from "../../style/orchestratorTheme";

export const colors = [
    theme.palette.info.main,
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.custom.color.chartColor1,
    theme.custom.color.chartColor4,
    "#7d4fff",
    "#FF8CC4",
    "#0047B2",
    "#B2C0FF",
    "#9bedff",
    "#ffd78a",
    "#2E8B57",
    "#9932CC",
    "#FF8C00",
    "#4B0082",
    "#20B2AA",
    "#FF1493",
    "#00BFFF",
    "#1E90FF",
    "#6A5ACD",
    "#FF4500",
    "#FFD700",
    "#ADFF2F",
    "#00FA9A",
    "#CD5C5C",
    "#9370DB",
    "#48D1CC",
    "#FF69B4",
    "#4682B4",
    "#2F4F4F",
    "#FA8072",
    "#FFE4E1",
    "#00CED1",
    "#FFA500",
    "#7B68EE",
    "#6B8E23",
    "#CD853F",
    "#008B8B",
    "#DC143C",
    "#9400D3",
    "#00FF7F",
    "#556B2F",
    "#E9967A",
    "#7FFFD4",
    "#8B008B",
    "#FF6347",
    "#40E0D0",
    "#D2691E",
    "#3CB371",
    "#6B8E23",
];
export const getRandomColor = (i) => (colors.length - 1 >= i ? colors[i] : colors[i % (colors.length - 1)])
