import {
    CREATE_ANALYTICS_PRESET,
    DELETE_ANALYTICS_PRESET,
    FETCH_ANALYTICS_PRESETS,
    DELETE_CUSTOM_KPIs,
    CREATE_UPDATE_CUSTOM_KPIs,
    FETCH_CUSTOM_KPIs
} from "redux/constants";
import { URL_ANALYTICS } from "redux/constants/endpoints";
import { customKpi, customKpis, presets } from "util/configs/backendEntities";

export const deleteAnalyticsPreset = (id, onSuccess, onError) => ({
    type: DELETE_ANALYTICS_PRESET,
    request: {
        url: `${URL_ANALYTICS}${presets}/${id}/delete`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
export const createAnalyticsPreset = (presetData, onSuccess, onError) => ({
    type: CREATE_ANALYTICS_PRESET,
    request: {
        url: `${URL_ANALYTICS}${presets}/create`,
        data: presetData,
        method: "post",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
export const fetchAnalyticsPresets = (onSuccess, onError) => ({
    type: FETCH_ANALYTICS_PRESETS,
    request: {
        url: `${URL_ANALYTICS}${presets}`,
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const fetchCustomKpis = (onSuccess, onError) => ({
    type: FETCH_CUSTOM_KPIs,
    request: {
        url: `${URL_ANALYTICS}${customKpis}`,
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const createOrUpdateCustomKpi = (kpiData, onSuccess, onError) => ({
    type: CREATE_UPDATE_CUSTOM_KPIs,
    request: {
        url: `${URL_ANALYTICS}${customKpi}`,
        data: kpiData,
        method: "post",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const deleteCustomKpi = (id, onSuccess, onError) => ({
    type: DELETE_CUSTOM_KPIs,
    request: {
        url: `${URL_ANALYTICS}${customKpi}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
