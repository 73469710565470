import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { bulkRetryItemException, checkPendingRetry, retryItemException } from "../../../../redux/actions/services";
import { isActionPemitted } from "../../../../components/HasPermission";
import { FETCH_CURRENT_USER } from "../../../../redux/constants";
import CustomDialog from "../../../../components/CustomDialog";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import TableContainer from "@material-ui/core/TableContainer";
import useStyles from "./style";

function RetryDialog({
  open, onClose, items = [], fetchData, resetSelected
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pendingRetryCheck, setPendingRetryCheck] = React.useState(null);
  const [dataType, setDataType] = React.useState("same");
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingMarkAsDone, setLoadingMarkAsDone] = React.useState(false);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const isRetryableWithData = items[0]?.processDto?.isRetryable && items[0]?.processDto?.isRetryableWithData;
  React.useEffect(() => {
    if (items.length === 1) {
      setData(items[0].parsedData);
      dispatch(checkPendingRetry(items[0].originalItemId)).then((res) => {
        if (res.status === 200) {
          // res.data can either be true or false
          setPendingRetryCheck(false);
          if (res.data) {
            handleItemHasPendingRetry();
          }
        } else {
          onClose();
        }
      });
    }
  }, [items]);

  const CustomToast = ({ title, content }) => (
    <div>
      <strong>{title}</strong>
      <br />
      {content}
    </div>
  );

  const handleItemHasPendingRetry = () => {
    onClose();
    const errorMessage = items.length === 1 ? t("retry-dialog.pending-alert") : t("retry-dialog.pending-alert-multiple");
    toast.error(<CustomToast title={t("error")} content={errorMessage} />, {
      icon: false,
      style: {
        background: "#ff0688",
        fontSize: "0.9rem",
        fontWeight: "600",
      },
      })
  };

  const retry = (markAsDone = false) => {
    changeLoadingState(markAsDone, true)
    if (items.length === 1) {
      dispatch(retryItemException(items[0].id, dataType === "changed", data, markAsDone, handleRetrySuccess, handleRetryError))
    } else if (items.length > 1) {
      dispatch(bulkRetryItemException(items.map((i) => i.id), markAsDone, handleRetrySuccess, handleRetryError))
    }
  };
  const changeLoadingState = (markAsDone, isLoading) => {
    (markAsDone) ? setLoadingMarkAsDone(isLoading) : setLoading(isLoading);
  }

  const handleRetrySuccess = (response, markAsDone, isBulk) => {
    if (response.data) {
      const successMessage = isBulk
        ? (`${t("retry-dialog.message.success.multiple-items", { count: response.data })}${response.data < items.length ? ` ${t("retry-dialog.message.warning.pending")}` : ""}`)
        : t("retry-dialog.message.success.one-item");
      toast.success(successMessage)
      if (markAsDone) {
        fetchData();
      }
      resetSelected();
      onClose();
    }
    else if (isBulk) {
      toast.error(t("retry-dialog.pending-alert-multiple"))
    }
    else {
      handleItemHasPendingRetry();
    }
    changeLoadingState(markAsDone, false);
  }

  const handleRetryError = (markAsDone, isBulk) => {
    const errorMessage = isBulk ? t("retry-dialog.message.error.multiple-items") : t("retry-dialog.message.error.one-item");
    toast.error(errorMessage)
    changeLoadingState(markAsDone, false);
  }

  const handleChange = (e, row) => {
    const idx = data.findIndex((o) => o.name === row.name);
    const newData = cloneDeep(data);
    newData[idx].value = e.target.value;
    setData(newData);
  };

  const getOneItemContent = () => {
    // pending check request in progress
    if (pendingRetryCheck === null) {
      return (
        <Box px={10} py={1.5} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    return (
      <>
        <RadioGroup
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        >
          <FormControlLabel
            value="same"
            control={<Radio className={classes.radio} />}
            label={(<Typography className={classes.dialogDefaultText}>{t("retry-dialog.radio.same-data")}</Typography>)}
          />
          {isActionPemitted(
            currentUser,
            "Process Item",
            "Retry item exceptions with changed data"
          )
            && isRetryableWithData && (
              <FormControlLabel
                value="changed"
                control={<Radio className={classes.radio} />}
                label={(<Typography className={classes.dialogDefaultText}>{t("retry-dialog.radio.changed-data")}</Typography>)}
              />
            )}
        </RadioGroup>
        <Collapse in={dataType === "changed"}>
          <TableContainer className={classes.scrollTable}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell>
                    <Typography className={classes.tableTitle}>{t("retry-dialog.table.name")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableTitle}>{t("retry-dialog.table.old-value")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableTitle}>{t("retry-dialog.table.new-value")}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items[0].parsedData
                  .filter((row) => row.editForRetry)
                  .map((row, idx) => (
                    <TableRow key={idx} className={classes.fieldContainer}>
                      <TableCell className={classes.nameCell}>
                        <Typography className={classes.dialogDefaultText}>{row.name}</Typography>
                      </TableCell>
                      <TableCell className={classes.oldValueCell}>
                        <Typography className={classes.dialogDefaultText}>{row.value}</Typography>
                      </TableCell>
                      <TableCell className={classes.newValueCell}>
                        <CustomTextField
                          fullWidth
                          defaultValue={row.value}
                          onChange={(e) => handleChange(e, row)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </>
    );
  };

  const getContentText = () => {
    const itemsRetry = items.filter((item) => item?.processDto?.orchestrator?.orchestratorType);
    if (items.length === 1) return t("retry-dialog.text.one-item")
    return t("retry-dialog.text.multiple-items", { count: itemsRetry.length })
  }

  const getContentTitle = () => {
    const itemsRetry = items.filter((item) => item?.processDto?.orchestrator?.orchestratorType);
    if (items.length === 1) return t("retry-dialog.title.one-item")
    return t("retry-dialog.title.multiple-items", { count: itemsRetry.length })
  }

    return (
      <CustomDialog
          title={getContentTitle()}
          contentText={!pendingRetryCheck ? getContentText() : null}
          open={open}
          disableBackdropClick
          PaperProps={{
            style: {
              maxWidth: 600,
            },
          }}
          actionElements={
             [{
               label: t("Cancel"),
               onClick: onClose,
               color: "primary",
               disabled: loading,
             },
               {
                 label: t("retry"),
                 onClick: () => retry(),
                 view: "primary",
                 disabled: loading,
                 hidden: !(!pendingRetryCheck || (items.length > 1)),
                 startIcon: loading && <CircularProgress size={20} />
               }, {
                 label: t("retry.mark.done"),
                 onClick: () => retry(true),
                 view: "primary",
                 disabled: loadingMarkAsDone,
                 hidden: !(!pendingRetryCheck || (items.length > 1)),
                 startIcon: loadingMarkAsDone && <CircularProgress size={20} />
               },
             ]
          }
        >
        {items.length === 1 && getOneItemContent()}
      </CustomDialog>
    )
}

export default RetryDialog;
