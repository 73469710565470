 export function displayDeletedItems(deletedObjects, attribute, objectTypeSingle, objectTypePlural, t) {
     const attributeValues = deletedObjects.map((obj) => obj[attribute]);
     let message;
     switch (deletedObjects.length) {
         case 1:
             message = t("deletedSuccessfullySingle", {
                 objectNature: objectTypeSingle,
                 ObjectName: attributeValues[0],
             });
             break;
         case 2:
             message = t("deletedSuccessfullyDouble", {
                 objectNature: objectTypePlural,
                 firstObjectName: attributeValues[0],
                 secondObjectName: attributeValues[1],
             });
             break;
         default:
             const firstTwoValues = attributeValues.slice(0, 2).join(", ");
             const remainingCount = deletedObjects.length - 2;
             message = t("deletedSuccessfullyMultiple", {
                 twoFirstObjectName: firstTwoValues,
                 remainingCount,
                 objectNatureRemaining: remainingCount === 1 ? objectTypeSingle : objectTypePlural,
             });
             break;
     }

     return message;
 }

 export const isOnlyCurrentUserSelected = (selected, currentUser) => selected.length === 1 && selected[0]?.id === Number(currentUser.id)
