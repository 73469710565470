import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../style/orchestratorTheme";

export default makeStyles((theme) => ({
    rootBox: {
        height: "80vh",
        width: "100%",
    },
    gridContainer: {
        maxWidth: 500,
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.info.light,
        borderRadius: 9,
        padding: "0 0 60px 0",
        boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
        flex: 1,
    },
    goBackBtn: {
        textTransform: "none !important",
    },
    centred: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    header: {
        fontSize: "1.9rem",
        marginTop: "16px",
        textAlign: "center"
    },
    localeRoot: {
        position: "absolute",
        width: "200px",
        top: "20px",
        right: "20px",
        textAlign: "right",
        "& ul": {
            display: "none",
            listStyle: "none",
            position: "absolute",
            backgroundColor: "#fff",
            right: 0,
            top: "20px",
            minWidth: "100px",
            padding: "2px 0",
            border: "solid 1px #bbb",
        },
        "&:hover ul": {
            display: "block",
            margin: 0
        },
        "& ul li": {
            display: "block",
            padding: "5px 14px",
            color: "#000",
            textDecoration: "none",
            lineHeight: "20px",
            textAlign: "left",
            cursor: "pointer",
        },
        "& ul li:hover": {
            backgroundColor: "#d4edfa",
        },
        currentLocale: {
            display: "block",
            padding: "0 5px",
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.2)"
            }
        }
    },
    container: {
        display: "flex",
        justifyContent: "center"
    },
    licenseInfo: {
        fontFamily
    },
    alertBody: {
        width: "100%",
    },
    alertInfo: {
        marginBottom: 5
    }
}));
