import React, { useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { ArrowRightAltRounded } from "@material-ui/icons";
import clsx from "clsx";
import { minimizeString } from "util";
import useStyles from "./style"

export const StyledTableCell = withStyles(() => ({
    root: {
        borderWidth: 0,
        verticalAlign: ({ verticalAlign }) => verticalAlign || "middle",
        width: ({ width }) => width,
        paddingTop: ({ paddingTop }) => paddingTop || 5,
        paddingBottom: ({ paddingBottom }) => paddingBottom || 5,
        height: ({ height = 50 }) => height,
    }
}))(TableCell);

export default function CustomTableCell(props) {
    const {
        children,
        title,
        text,
        sortable,
        name,
        onSort,
        sortDir,
        titleInfo
    } = props
    const classes = useStyles()
    const tableCellRef = useRef()

    const renderSortDir = {
        desc: <ArrowRightAltRounded className={classes.sortedDownIcon} onClick={() => onSort(name, "asc")} />,
        asc: <ArrowRightAltRounded className={classes.sortedUpIcon} onClick={() => onSort(name, "desc")} />
    }

    useEffect(() => {
        if (tableCellRef?.current) tableCellRef?.current?.removeAttribute("title")
    }, []);

    return (
      <StyledTableCell ref={tableCellRef} {...props}>
        <Tooltip title={titleInfo ?? ""} interactive placement="bottom-start">
          <Box display="flex" alignItems="center">
            {title && (typeof title !== "string" ? title
          : (
            <Typography
                  variant="subtitle2"
                  align={props?.align ?? "left"}
                  color="primary"
                  className={classes.title}
              >
              {title}
            </Typography>))}
            {sortable && (sortDir ? renderSortDir?.[sortDir]
          : (
            <Box
              component="span"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => onSort(name, "desc")}
            >
              <ArrowRightAltRounded className={clsx(classes.sortedDownIcon, classes.sortedDownIcon)} />
              <ArrowRightAltRounded className={clsx(classes.sortedUpIcon, classes.unsortedUpIcon)} />
            </Box>
            )
          )}
          </Box>
        </Tooltip>
        { typeof text !== "string" ? (
          <Typography
                variant="body2"
                align={props?.align ?? "left"}
                color="primary"
                className={classes.text}
            >
            {text}
          </Typography>
        ) : (
          <Tooltip title={text} interactive placement="bottom-start">
            <Typography
                    variant="body2"
                    align={props?.align ?? "left"}
                    color="primary"
                    className={classes.text}
                >
              {minimizeString(text, 300)}
            </Typography>
          </Tooltip>
        )}
        {children}
      </StyledTableCell>
    )
}
