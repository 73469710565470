import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import { deleteFleets } from "../../../../../redux/actions/services";
import { isFleetAdministrator, isOnlyCurrentUserSelected, displayDeletedItems } from "util";
import { QUEUE_DELETED } from "util/constants";
import { updateQueueAction } from "../../../../../redux/slices/executionManagerSlice";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import useStyles from "../../../../../components/TableComponents/style";

export default function FleetTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(
    ({ executionManager }) => executionManager,
  );
  const onlyCurrentUserSelected = isOnlyCurrentUserSelected(selected, currentUser);

  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteFleets(
        newSelected?.map((group) => group?.id),
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(displayDeletedItems(selected, "companyName", t("groupSingle"), t("groupPlural"), t));
        },
        (res) => {
          handleCloseConfirmationPopup();
          res?.response?.data?.title ? toast.error(t(`fleet.${res?.response?.data?.title}`)) : toast.error(t("something went wrong"));
        },
      ),
    );
  };
  useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };
  const headerCells = [
    {
      id: "status",
      label: "",
    },
    {
      id: "companyName",
      label: t("Name"),
    },
    {
      id: "clientSince",
      label: t("Created since"),
    },
    {
      id: "email",
      label: t("fleet.management.email"),
    },
    {
      id: "countUsers",
      label: t("user.management.menu.title"),
      titleInfo: t("The number of users belonging to this group"),
    },
    {
      id: "countAutomations",
      label: t("Automations"),
      titleInfo: t("The number of automations assigned to this group"),
    },
    {
      label:
          isFleetAdministrator(currentUser) && currentUser?.fleet?.instanceOwner && data?.totalElements > 0 && (
          <Grid container item justify="flex-end" alignItems="center">
            <Grid item>
              <CustomDeleteIcon
                  className={classes.rowActionButtons}
                  aria-label="delete"
                  disabled={
                      isEmpty(selected)
                      || onlyCurrentUserSelected
                      || onlyCurrentUserInList
                  }
                  onClick={() => {
                    setOpenDeletePopup(true);
                  }}
              />
              <ConfirmMessage
                  message={t("fleet.management.deleteFleetsActionMessage")}
                  openStart={openDeletePopup}
                  onCancel={handleCloseConfirmationPopup}
                  onConfirm={onClick}
                  buttonConfirm={t("Delete")}
                  buttonCancel={t("Cancel")}
                  isLoading={isLoading}
              />
            </Grid>
            <Grid item>
              <Checkbox
                  checked={checkedAll && !onlyCurrentUserInList}
                  className={classes.rowActionButtons}
                  indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                  onChange={(e) => {
                    handleSelectedAll(e.target.checked);
                  }}
                  disabled={onlyCurrentUserInList}
              />
            </Grid>
          </Grid>
          )
    }
  ];

  return (
    <CustomTableHeader labels={headerCells} />
  );
}
