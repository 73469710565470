import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import SecurityIcon from "@material-ui/icons/Security";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  deleteFleet,
  enableOrDisableFleet,
} from "../../../../../redux/actions/services";
import {
  formatDateByLanguage,
  isFleetAdministrator,
} from "util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import useStyles from "../../../../../components/TableComponents/style";

const displayFields = [
  {
    id: "status",
    valuePath: "status",
    customContent: ({ valuePath }) => <StatusBadge level={valuePath} />,
    width: 50
  },
  {
    id: "companyName",
    valuePath: "companyName",
  },
  {
    id: "clientSince",
    valuePath: "clientSince",
    defaultValue: "--:--:--",
    translate: false,
    format: (v) => formatDateByLanguage(v, false),
  },
  {
    id: "email",
    valuePath: "email",
  },
  {
    id: "countUsers",
    valuePath: "countUsers",
  },
  {
    id: "countAutomations",
    valuePath: "countAutomations",
  },
];

export default function FleetTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableFleet(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
        setOpenConfirmationPopup(false);
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteFleet(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        toast.success(t("fleet deleted successfully"));
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.title) toast.error(t(`fleet.${res?.response?.data?.title}`));
      else toast.error(t("Unexpected Error"));
      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (field?.customContent ? (
          <CustomTableCell width={field?.width}>
            {field.customContent({
                valuePath: getFieldData(field?.valuePath),
                indexRow: getFieldData("id"),
                indexField: get(field, "id"),
                valueAvatarPath: getFieldData(field?.avatarPath),
              })}
          </CustomTableCell>
          ) : (
            <CustomTableCell
              key={i}
              text={
                field.format
                  ? field.format(getFieldData(field.valuePath))
                  : getFieldData(field?.valuePath)
              }
            />
          )),)}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {getFieldData("isInstanceOwner") && (
              <Grid item>
                <Tooltip title={t("instance.owner")}>
                  <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${entityBaseUrl}/licence`);
                      }}
                      className={classes.rowActionButtons}
                  >
                    <SecurityIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && currentUser?.fleet?.instanceOwner && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                >
                  <CustomSwitch
                    id="fleet-admin-enable-disable-switch"
                    checked={active}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    handleChange={() => {
                      setOpenConfirmationPopup(true);
                    }}
                    disabled={getFieldData("isInstanceOwner")}
                    value={active}
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    size="small"
                    labelClassName={classes.switchLabel}
                  />
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && ((getFieldData("id") === currentUser?.fleet?.id) || currentUser?.fleet.instanceOwner) && (
              <Grid item>
                <CustomEditIcon
                    id="fleet-admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && currentUser?.fleet?.instanceOwner && (
              <Grid item>
                <CustomDeleteIcon
                    id="fleet-admin-delete-btn"
                    disabled={getFieldData("isInstanceOwner")}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  />
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && currentUser?.fleet?.instanceOwner && (
              <Grid item>
                <Checkbox
                  id="fleet-admin-select-checkbox"
                  className={classes.rowActionButtons}
                  checked={
                      !getFieldData("isInstanceOwner") && selected?.map((item) => item?.id)?.includes(parseInt(row.id, 10))
                  }
                  disabled={getFieldData("isInstanceOwner")}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(row)
                      : list.splice(
                            list.findIndex((item) => item.id === parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("fleet.management.desaDes.confirmMsg")
            : t("fleet.management.enaDes.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("fleet.management.desaDes.button.confirm")
            : t("fleet.management.enaDes.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />
      <ConfirmMessage
        title={t("title.confirm.message.delete")}
        message={t("fleet.management.delete.confirmMsg")}
        alertMsg={t("fleet.management.delete.warning")}
        alertType="error"
        viewConfirmBtn="tertiary"
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
