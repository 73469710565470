import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.custom.color.staticInput,
    padding: "0 5px",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 12,
    marginInline: "8px",
    borderRadius: 6
  },
}));
