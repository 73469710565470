import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  editButtonContainer: {
    position: "relative",
  },
  editButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));
