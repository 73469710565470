import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  avatarIcon: {
    width: 100,
    height: 100,
  },
  editIcon: {
    padding: 6,
    backgroundColor: fade(theme.palette.secondary.main, 0.9),
    fontSize: 10,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    }

  },
  large: {
    width: ({ size }) => size ?? 90,
    height: ({ size }) => size ?? 90,
  },
  small: {
    width: 20,
    height: 20,
  },
  avatarColor: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
