import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cellTitle: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  cellTitleQueueName: {
    fontSize: "0.8rem",
    fontWeight: 800,
    color: theme.palette.info.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    background: "transparent",
},
paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
},
tableHeaderContainer: {
    margin: theme.spacing(3, 0, 1),
    paddingRight: theme.spacing(2),
},
rowActionButtons: {
    color: theme.palette.primary.dark,
    padding: theme.spacing(0.75)
},
deleteIcon: {
    color: theme.palette.secondary.main,
},
switchLabel: {
    marginRight: 0,
    marginLeft: theme.spacing(0.5)
},
tagChip: {
  fontSize: 12,
  color: theme.palette.info.main,
  fontWeight: 700,
  backgroundColor: "rgba(123, 176, 255, 0.13)",
  height: 28,
  minWidth: 60,
  margin: 4
},
}));
