import {
    BULK_RETRY_ITEM_EXCEPTION,
    CHECK_PENDING_RETRY,
    DELETE_WORK_QUEUE_ITEM,
    FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS,
    FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON,
    FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND,
    FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE,
    FETCH_WORK_QUEUE_ITEM,
    FETCH_WORK_QUEUE_ITEM_ASSINERS,
    FETCH_WORK_QUEUE_ITEM_COUNT,
    FETCH_WORK_QUEUE_ITEM_EXCEPTION_REASON,
    FETCH_WORK_QUEUE_ITEM_EXCEPTION_TYPE,
    FETCH_WORK_QUEUE_ITEM_EXECPTIONS,
    FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT,
    FETCH_WORK_QUEUE_ITEM_WORK_FLOW_STATUS,
    RETRY_ITEM_EXCEPTION
} from "../../constants";
import { URL_WORK_QUEUE_ITEM } from "../../constants/endpoints";
import { formatDateTimeIsoParam } from "util";
import { exceptionsEntity } from "util/configs/backendEntities";

export const bulkRetryItemException = (itemsIds, markAsDone = false, onSuccess, onError) => {
    const params = { itemsIds: itemsIds.join(","), markAsDone };
    return {
        type: BULK_RETRY_ITEM_EXCEPTION,
        request: {
            url: `${URL_WORK_QUEUE_ITEM}retryException/bulk`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
        meta: {
          onSuccess: (response) => {
              if (onSuccess) onSuccess(response, markAsDone, true);
              return response;
          },
          onError: (err) => {
              if (onError) onError(true);
              throw err;
          },
        },
    };
};
export const checkPendingRetry = (originalItemId) => ({
    type: CHECK_PENDING_RETRY,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}checkPendingRetry?originalItemId=${originalItemId}`,
        method: "get",
    },
});
export const retryItemException = (
    itemId,
    retryWithChangedData,
    itemParsedData,
    markAsDone = false,
    onSuccess,
    onError
) => ({
    type: RETRY_ITEM_EXCEPTION,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}retryException`,
        method: "post",
        data: {
            itemId,
            retryWithChangedData,
            itemParsedData,
        },
        params: {
            markAsDone
        },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response, markAsDone, false);
          return response;
      },
      onError: (err) => {
          if (onError) onError(markAsDone, false);
          throw err;
      },
    },
});

export const fetchItemExceptionsAnalyticsKpiByProcess = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    processExecutionIds,
    showHidden,
    maxProcesses,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}exceptions/analytics/group-by-process?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}&processExecutionIds=${processExecutionIds}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: `${fleetIds?.join()}`,
            tagsIds,
            maxProcesses
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const fetchItemExceptionsAnalyticsKpiByReason = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    processExecutionIds,
    showHidden,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}${exceptionsEntity}/analytics/group-by-reason?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}&processExecutionIds=${processExecutionIds}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: `${fleetIds?.join()}`,
            tagsIds
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const fetchItemExceptionsAnalyticsKpiByType = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    processExecutionIds,
    showHidden,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}${exceptionsEntity}/analytics/group-by-type?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}&processExecutionIds=${processExecutionIds}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: `${fleetIds?.join()}`,
            tagsIds
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const fetchItemExceptionsAnalyticsKpiByTrend = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    processExecutionIds,
    showHidden,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}${exceptionsEntity}/analytics/group-by-trend?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}&processExecutionIds=${processExecutionIds}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: `${fleetIds?.join()}`,
            tagsIds
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const fetchExceptions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    exceptions = [],
    exceptionsType = [],
    fleetIds,
    loadedDatetime,
    completedDatetime,
    showHidden = false,
    searchText,
    processExecutionIds = [],
    workFlowStatus = [],
    assignersIds = [],
    originalItemId = null,
    tags = [],
    accountForBusinessExceptionsAsCompleted = false
) => ({
    type: FETCH_WORK_QUEUE_ITEM_EXECPTIONS,
    request: {
        url: encodeURI(`${URL_WORK_QUEUE_ITEM}${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            page,
            size,
            sortField,
            sortOrder,
            processes: `${processes}`,
            hidden: showHidden,
            fleetIds: fleetIds?.join(),
            exceptions: `${exceptions?.map((element) => element?.id).join()}`,
            exceptionsType: exceptionsType.join(),
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            processExecutionIds: processExecutionIds.join(),
            workFlowStatus: workFlowStatus.join(),
            assignersIds: assignersIds.join(),
            originalItemId,
            tagsIds: tags.join(),
            accountForBusinessExceptionsAsCompleted
        },
    },
});
export const fetchExceptionsCount = (
    processes,
    exceptions = [],
    exceptionsType = [],
    fleetIds,
    loadedDatetime,
    completedDatetime,
    showHidden = false,
    searchText,
    processExecutionIds = [],
    workFlowStatus = [],
    assignersIds = [],
    originalItemId = null,
    tags = [],
    accountForBusinessExceptionsAsCompleted = false
    ) => ({
        type: FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT,
        request: {
            url: encodeURI(`${URL_WORK_QUEUE_ITEM}count${searchText ? `?searchText=${searchText}` : ""}`),
            params: {
                processes: `${processes}`,
                exceptions: `${exceptions?.map((element) => element?.id).join()}`,
                hidden: showHidden,
                fleetIds: `${fleetIds?.join()}`,
                exceptionsType: exceptionsType.join(),
                loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
                completedDatetime: formatDateTimeIsoParam(completedDatetime),
                processExecutionIds: processExecutionIds.join(),
                workFlowStatus: workFlowStatus.join(),
                assignersIds: assignersIds.join(),
                originalItemId,
                tagsIds: tags.join(),
                accountForBusinessExceptionsAsCompleted
            },
        },
});

export const fetchWorkItems = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    searchCriteria,
    processExecutionIds = [],
    tags = [],
    originalItemId
) => ({
    type: FETCH_WORK_QUEUE_ITEM,
    request: {
        url: encodeURI(`${URL_WORK_QUEUE_ITEM}items`),
        params: {
            page,
            processesIds: processes.join(),
            size,
            sort: `${sortField},${sortOrder}`,
            fleetIds: fleetIds?.join(),
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            searchCriteria,
            processExecutionIds: processExecutionIds.join(),
            tagsIds: tags?.join(),
            originalItemId
        },
    },
});
export const fetchWorkItemsCount = (
    processes,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    searchCriteria,
    processExecutionIds = [],
    tags = [],
    originalItemId
) => ({
    type: FETCH_WORK_QUEUE_ITEM_COUNT,
    request: {
        url: encodeURI(`${URL_WORK_QUEUE_ITEM}items/count`),
        params: {
            processesIds: processes.join(),
            fleetIds: fleetIds?.join(),
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            searchCriteria,
            processExecutionIds: processExecutionIds.join(),
            tagsIds: tags?.join(),
            originalItemId
        },
    },
});
export const fetchPendingItems = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    fleetIds,
    filterStartDate,
    filterEndDate,
    searchText,
    tagsIds
) => ({
    type: FETCH_WORK_QUEUE_ITEM,
    request: {
        url: encodeURI(`${URL_WORK_QUEUE_ITEM}pendingItems${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            page,
            size,
            sortField,
            sortOrder,
            processes: processes.join(),
            filterStartDate: formatDateTimeIsoParam(filterStartDate),
            filterEndDate: formatDateTimeIsoParam(filterEndDate),
            fleetIds: fleetIds?.join(","),
            tagsIds
        },
    },
});
export const fetchExceptionsReason = (
    exceptionTypes = [],
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
    tagsIds = [],
) => ({
    type: FETCH_WORK_QUEUE_ITEM_EXCEPTION_REASON,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}exceptionsReason?types=${exceptionTypes}&processIds=${processIds.join()}&tagsIds=${tagsIds?.join()}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: fleetIds.join(","),
        },
    },
});
export const fetchExceptionWorkflowStatus = (
    exceptionTypes = [],
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
) => ({
    type: FETCH_WORK_QUEUE_ITEM_WORK_FLOW_STATUS,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}exceptionsWorkflowStatus?types=${exceptionTypes}&processIds=${processIds.join()}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: fleetIds.join(","),
        },
    },
});
export const fetchExceptionByAssigner = (
    exceptionTypes = [],
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
) => ({
    type: FETCH_WORK_QUEUE_ITEM_ASSINERS,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}getAssigners?types=${exceptionTypes}&processIds=${processIds.join()}`,
        params: {
            loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
            completedDatetime: formatDateTimeIsoParam(completedDatetime),
            fleetIds: fleetIds.join(","),
        },
    },
});
export const fetchExceptionsType = (
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
    workflowStatus,
    tagsIds = []
) => {
    const ids = Array.isArray(fleetIds) ? fleetIds.join() : fleetIds;
    return {
        type: FETCH_WORK_QUEUE_ITEM_EXCEPTION_TYPE,
        request: {
            url: `${URL_WORK_QUEUE_ITEM}exceptionsType?processIds=${processIds?.join()}`,
            params: {
                loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
                completedDatetime: formatDateTimeIsoParam(completedDatetime),
                fleetIds: ids,
                workflowStatus: workflowStatus?.join(),
                tagsIds: tagsIds.join()
            },
        },
    };
};
export const deleteException = (id) => ({
    type: DELETE_WORK_QUEUE_ITEM,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const deleteExceptions = (exceptions) => ({
    type: DELETE_WORK_QUEUE_ITEM,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}hide/?exceptions=${exceptions}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const showExceptions = (exceptions) => ({
    type: DELETE_WORK_QUEUE_ITEM,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}show/?exceptions=${exceptions}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const fetchProcessItemData = (processId, onSuccess) => ({
    type: DELETE_WORK_QUEUE_ITEM,
    request: {
        url: `${URL_WORK_QUEUE_ITEM}item-data/${processId}`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
    },
});
