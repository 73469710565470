import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  customDate: {
    display: "flex",
    alignItems: "start",
    paddingLeft: theme.spacing(1),
  },
  input: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "400",
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.info.main,
      borderWidth: 3,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.info.main,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: theme.palette.info.main,
      borderWidth: 3,
    },
  },
  dateInput: {
    marginTop: 1
  },
  dateTimePicker: {
    "& .MuiPickersModal-dialogRootWider": {
      minWidth: "333px",
    },
  },
  popupIcon: {
    color: theme.palette.info.main
  },
  customDateInput: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2)
}

}));
