/* eslint-disable eqeqeq */
import React from "react";
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DoneIcon from "@material-ui/icons/Done";
import PersonIcon from "@material-ui/icons/Person";
import FlagIcon from "@material-ui/icons/Flag";
import SettingsIcon from "@material-ui/icons/Settings";
import { SrWorkflowIcon } from "components/CustomIcons";
import useStyles from "../style";
import { formatImagePath, getProcessAbbreviation, formatDateByLanguage } from "util";
import AttemptChip from "../../../../../components/AttemptChip";
import WorkflowChip from "../WorkflowChip";
import {
  getWorkflowStatusColor,
  getWorkflowStatusFromAction,
  workflowActions as actions,
  workflowStatus as status,
} from "../workflowUtil";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import { isPermitted } from "../../../../../components/HasPermission";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import { toast } from "react-toastify";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CustomButton from "../../../../../components/CustomButton";
import RulesList from "../../../AutoExceptionAssignment/RulesList";

function WorkflowDrawer({
  open, onClose, item, setItem, data, fetchData, handleShowExceptionDetails, updateAssigners, apiDispatch, isAutomation, itemData
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    message: null,
    onConfirm: null,
  });
  const [actionLoading, setActionLoading] = React.useState(false);
  const [openRuleDialog, setOpenRuleDialog] = React.useState(false);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const users = useSelector(({ requests }) => get(requests, "queries.FETCH_FLEET_USERS.data"));
  const historyLoading = useSelector(({ requests }) => get(requests, isAutomation ? "queries.FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY.pending" : "queries.FETCH_EXCEPTION_WORKFLOW_HISTORY.pending") === 1);
  const historyData = useSelector(({ requests }) => get(requests, isAutomation ? "queries.FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY.data" : "queries.FETCH_EXCEPTION_WORKFLOW_HISTORY.data"));

  React.useEffect(() => {
    if (item) {
      dispatch(apiDispatch.fetchHistory(item.id));
    }
  }, [item]);

  React.useEffect(() => {
    if (item && data) {
      setItem(data.find((i) => i.id === item.id));
    }
  }, [data]);

  React.useEffect(() => {
    if (!actionLoading) {
      setConfirmDialog({
        open: false,
        message: null,
        onConfirm: null,
      });
    }
  }, [actionLoading]);

  const isActionAllowed = (action) => {
    if (!item || !currentUser) return false;
    // == is used to check user id equality because they have different datatypes.
    // TODO use parseint
    switch (action) {
      case actions.TAKE:
        return item.workflowStatus === status.READY || item?.userInCharge?.id != currentUser.id;
      case actions.ASSIGN:
        return (
          item.workflowStatus === status.READY
          || (item.workflowStatus === status.TAKEN
            && item?.userInCharge?.id == currentUser.id)
        );
      case actions.MARK_AS_DONE:
        return (
          item.workflowStatus === status.TAKEN
          && item?.userInCharge?.id == currentUser.id
        );
      case actions.RELEASE:
        return (
          item.workflowStatus === status.TAKEN
          && item?.userInCharge?.id == currentUser.id
        );
      case actions.REOPEN:
        return (
          item.workflowStatus === status.DONE
        );
      default:
        return false;
    }
  };

  const isNoActionAllowed = () => !item || item.workflowStatus === status.DONE;

  const makeResponseSideEffects = (res, actionMessage) => {
    if (res.status === 200) {
      fetchData();
      toast.success(actionMessage)
    } else {
      toast.error(t("exception-workflow.snackbar.error"))
    }
  };

  const take = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.take");
    dispatch(apiDispatch.take(item.id)).then((res) => {
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      updateAssigners();
    });
  };

  const assign = () => {
    if (selectedUser) {
      setActionLoading(true);
      const actionMessage = t("exception-workflow.snackbar.success.assign");
      dispatch(apiDispatch.assign(item.id, selectedUser.id)).then((res) => {
        setActionLoading(false);
        makeResponseSideEffects(res, actionMessage);
        updateAssigners();
      });
      assignExceptionNotify();
    }
  };

  const assignExceptionNotify = () => {
    if (selectedUser) {
      const actionMessage = t("exception-workflow.snackbar.success.assign.notify");
      dispatch(apiDispatch.assignNotify(selectedUser.id, 1)).then((res) => {
        makeResponseSideEffects(res, actionMessage);
      });
    }
  };

  const release = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.release");
    dispatch(apiDispatch.release(item.id)).then((res) => {
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      updateAssigners();
    });
  };

  const markAsDone = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.markAsDone");
    dispatch(apiDispatch.markAsDone(item.id)).then((res) => {
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
    });
  };

  const reopen = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.reopen");
    dispatch(apiDispatch.reopen(item.id)).then((res) => {
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
    });
  };

  // eslint-disable-next-line consistent-return
  const getStatusIcon = (s) => {
    switch (s) {
      case status.READY:
        return <FlagIcon fontSize="small" />;
      case status.TAKEN:
        return <PersonIcon fontSize="small" />;
      case status.DONE:
        return <DoneIcon fontSize="small" />;
      default:
        break;
    }
  };

  // eslint-disable-next-line consistent-return
  const getLogMessage = (log) => {
    switch (log.action) {
      case actions.TAKE:
        return t("exception-workflow.log.take", { user: log.user });
      case actions.ASSIGN:
        return t("exception-workflow.log.assign", {
          user: log.user,
          targetUser: log.targetUser,
        });
      case actions.MARK_AS_DONE:
        return t("exception-workflow.log.markAsDone", { user: log.user });
      case actions.RELEASE:
        return t("exception-workflow.log.release", { user: log.user });
      case actions.REOPEN:
        return t("exception-workflow.log.reopen", { user: log.user });
      default:
        break;
    }
  };

  if (!item) return null;

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box
          py={2}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <SrWorkflowIcon />
            <Typography variant="h6" display="inline">
              {t("exception-workflow.open")}
            </Typography>
          </Box>
          {(isPermitted(currentUser, "Setup Automatic Assignment") && isAutomation) && (
            <Box>
              <CustomButton
                  view="cancelModal"
                  fullWidth
                  onClick={() => {
                    onClose()
                    setOpenRuleDialog(true)
                  }}
                  startIcon={<SettingsIcon />}
                  className={classes.assigmentButton}
              >
                {t("Assignement Rules")}
              </CustomButton>
            </Box>
          )}
          <CustomCloseButton onClick={onClose} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Box display="flex" alignItems="center">
            {item.processDto.icon ? (
              <img
                    src={formatImagePath(item.processDto.icon)}
                    alt="Service"
                    width="35px"
                />
            ) : (
              <Avatar alt="avatar">
                {getProcessAbbreviation(
                      item.processDto.processDescription?.processDisplayName,
                  )}
              </Avatar>
            )}
            &nbsp;
            <Typography variant="subtitle2" align="left">
              <Box fontWeight="fontWeightBold">
                {item.processDto.processDescription?.processDisplayName ?? "---"}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">{t("Creation date")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{formatDateByLanguage(item.startDateTime)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">{t("Exception date")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{formatDateByLanguage(item.endDateTime)}</Typography>
          </Grid>
          { !isAutomation && (
            <>
              <Grid item xs={4}>
                <Typography color="textSecondary">{t("attempt")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <AttemptChip attempt={item.attempt} />
              </Grid>
            </>
            )}
          <Grid item xs={4}>
            <Typography color="textSecondary">{t("status")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <WorkflowChip item={item} inDrawer />
          </Grid>
          { !isAutomation && (
          <>
            <Grid item xs={4}>
              <Typography color="textSecondary">{t("Execution detail")}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2" align="left">
                <Tooltip
                  title={t("Details")}
                  style={{ position: "relative" }}
                >
                  <IconButton
                    style={{ padding: 0, width: 24, height: 24 }}
                    edge="end"
                    aria-label="view"
                    onClick={() => {
                      handleShowExceptionDetails(item);
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
          </>
          )}
        </Grid>

        <Grid container className={classes.workflowActions}>
          <Grid container item spacing={2}>
            {!isNoActionAllowed() && (
              <>
                {isActionAllowed(actions.TAKE) && (
                <Grid item xs={6}>
                  <CustomButton
                    view="primary"
                    fullWidth
                    disableElevation
                    onClick={() => {
                      take();
                    }}
                  >
                    {t("exception-workflow.action.take")}
                  </CustomButton>
                </Grid>
                )}
                {isActionAllowed(actions.MARK_AS_DONE) && (
                <Grid item xs={6}>
                  <CustomButton
                    view="primary"
                    fullWidth
                    disableElevation
                    onClick={() => setConfirmDialog({
                      open: true,
                      message: t("exception-workflow.confirm.markAsDone"),
                      onConfirm: markAsDone,
                    })}
                  >
                    {t("exception-workflow.action.markAsDone")}
                  </CustomButton>
                </Grid>
                )}
                {isActionAllowed(actions.RELEASE) && (
                <Grid item xs={6}>
                  <CustomButton
                    view="primary"
                    fullWidth
                    disableElevation
                    onClick={() => {
                      release();
                    }}
                  >
                    {t("exception-workflow.action.release")}
                  </CustomButton>
                </Grid>
                )}
                {isActionAllowed(actions.ASSIGN) && (
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <Autocomplete
                      options={users?.filter(
                        (u) => isPermitted(u, "Workflow")
                          && u.id != currentUser.id,
                      )}
                      getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                      renderInput={(params) => (
                        <Box ref={params.InputProps.ref} height="36.5px">
                          <OutlinedInput
                            {...params.inputProps}
                            placeholder={t("exception-workflow.choose-user")}
                            style={{
                              height: "100%",
                              width: "100%",
                              backgroundColor: "white",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            startAdornment={(
                              <AccountCircleIcon
                                style={{ marginRight: "10px" }}
                              />
                            )}
                          />
                        </Box>
                      )}
                      onChange={(e, val) => setSelectedUser(val)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      view="primary"
                      fullWidth
                      disableElevation
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      disabled={!selectedUser}
                      onClick={() => setConfirmDialog({
                        open: true,
                        message: t("exception-workflow.confirm.assign", {
                          selectedUser,
                        }),
                        onConfirm: assign,
                      })}
                    >
                      {t("exception-workflow.action.assign")}
                    </CustomButton>
                  </Grid>
                </Grid>
                )}
              </>
            )}
            {isActionAllowed(actions.REOPEN) && (
            <Grid item xs={6}>
              <CustomButton
                view="primary"
                fullWidth
                disableElevation
                onClick={() => setConfirmDialog({
                  open: true,
                  message: t("exception-workflow.confirm.reopen"),
                  onConfirm: reopen,
                })}
              >
                {t("exception-workflow.action.reopen")}
              </CustomButton>
            </Grid>
            )}
          </Grid>
        </Grid>

        <Timeline align="left" className={classes.timeline}>
          {!historyLoading && historyData?.length >= 0 ? (
            historyData
              ?.sort((a, b) => b.id - a.id)
              .map((log, idx) => {
                const s = getWorkflowStatusFromAction(log.action);
                return (
                  <TimelineItem key={idx}>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{ color: getWorkflowStatusColor(s) }}
                        variant="outlined"
                      >
                        {getStatusIcon(s)}
                      </TimelineDot>
                      {idx !== historyData.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="caption">
                        {formatDateByLanguage(log.performedAt)}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: getLogMessage(log),
                        }}
                      />
                    </TimelineContent>
                    <TimelineOppositeContent
                      className={classes.timelineOppositeContent}
                    />
                  </TimelineItem>
                );
              })
          ) : (
            <CircularLoader />
          )}
        </Timeline>
      </Drawer>

      {confirmDialog?.open && <ConfirmMessage
        message={confirmDialog.message}
        openStart={confirmDialog.open}
        onCancel={() => setConfirmDialog({
          open: false,
          message: null,
          onConfirm: null,
        })}
        onConfirm={confirmDialog.onConfirm}
        buttonConfirm={t("Confirm")}
        buttonCancel={t("Cancel")}
        isLoading={actionLoading}
      />}
      { (isAutomation && openRuleDialog) && (
      <RulesList open={openRuleDialog} handleDialogClose={() => setOpenRuleDialog(false)} itemData={itemData} />
      )}
    </>
  );
}

export default WorkflowDrawer;
