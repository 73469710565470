/* eslint-disable no-nested-ternary */
import Grid from "@material-ui/core/Grid";
import { useLocation, useHistory } from "react-router-dom";
import { format } from "date-fns-tz";
import get from "lodash/get";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  deleteException,
  deleteExceptions,
  exportOccurrencesXLSX,
  fetchExportedFiles,
  fetchProcessesNameException,
  fetchWorkItems,
  fetchWorkItemsCount,
} from "../../../../redux/actions/services";
import { useQuery } from "../../../../components/Filter";
import { connect, useDispatch, useSelector } from "react-redux";
import { getQuery } from "@redux-requests/core";
import {
  initialState,
  updateFilter,
  initFilter,
} from "../../../../redux/slices/workqueueitemSlice";
import WorkqueueitemRow from "../components/WorkqueueitemRow";
import { maxItemDetailsCount } from "util";
import ExportConfirmation from "components/Filter/ExportConfirmation/ExportConfirmation";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTable from "../../../../components/TableComponents/CustomTable";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import Details from "../../../../components/DataDetail";
import DataNotFound from "../../../../components/DataNotFound";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import Filter from "../Filter";
import CompletedItemsNotFound from "../../../../assets/Process_Overview.svg";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";

const dateFormat = "yyyy/MM/dd HH:mm";
function WorkItemsPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const processExecIdsParam = Number(
    history?.location?.state?.processExecutionId
      || query.get("processExecution"),
  );

  const processExecIds = processExecIdsParam ? [processExecIdsParam] : [];
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [exceptions, setExceptions] = React.useState([]);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [messagConfirm, setMessagConfirm] = React.useState("");
  const [exceptionId, setExceptionId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMsgDetail, setOpenMsgDetail] = React.useState(false);
  const [logDetail, setLogDetail] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const [validate, setValidate] = React.useState(false);
  const [loadedDatetime, setLoadedDatetime] = React.useState(null);
  const [completedDatetime, setCompletedDatetime] = React.useState(null);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [processExecutionIds, setProcessExecutionIds] = React.useState([]);
  const [exportConfirmationOpen, setExportConfirmationOpen] = React.useState(false);
  const [separateDetails, setSeparateDetails] = React.useState(false);

  const workqueueitems = useSelector(({ requests }) => get(requests, "queries.FETCH_WORK_QUEUE_ITEM_BY_PROCESS.data"));
  const processFilter = useSelector((state) => getQuery(state, { type: "FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION" }).data);
  const workqueueitemsCount = useSelector(({ requests }) => get(requests, "queries.FETCH_WORK_QUEUE_ITEM_COUNT.data"));
  const labels = [
    { label: "" },
    { label: t("Item Data") },
    { label: t("Creation date") },
    { label: t("End date") },
    { label: t("Execution Time") },
  ];

  useEffect(() => {
    setData(workqueueitems?.list);
    setIsLoading(false);
  }, [workqueueitems]);

  useEffect(() => {
    setCount(workqueueitemsCount);
    setIsLoading(false);
  }, [workqueueitemsCount]);

  const workqueueitemFilter = useSelector(({ workqueueitem }) => workqueueitem);
  const fdash = query.get("fdash");

  const handleFiltersChange = (values, target) => {
    if (processExecIds?.length) {
      history.replace({
        pathname: location.pathname,
        search: `?processExecution=${[]}`,
      });
    }
    if (fdash) dispatch(updateFilter({ ...initialState, pageNo: 0, [target]: values }));
    else {
      dispatch(
        updateFilter({ ...workqueueitemFilter, pageNo: 0, [target]: values }),
      );
    }
  };

  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setLoadedDatetime(from);
    setCompletedDatetime(to);
    dispatch(
      updateFilter({
        ...workqueueitemFilter,
        pageNo: 0,
        completedItemsSelectedDurationValue: value,
        completedItemsFromDate: from,
        completedItemsToDate: to,
      }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        ...workqueueitemFilter,
        order: property,
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...workqueueitemFilter,
        pageNo: newPage,
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...workqueueitemFilter,
        pageNo: page + 1,
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...workqueueitemFilter,
          pageNo: page - 1,
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...workqueueitemFilter,
        pageNo: 0,
        pageSize: event.target.value,
      }),
    );
  };

  const getWorkItems = React.useCallback(
    (
      page,
      size,
      sortField,
      sortOrder,
      processes,
      fleetIds,
      loadedDatetime,
      completedDatetime,
      searchText,
      processExecutionIds,
      tags,
      originalItemId
    ) => {
      setIsLoading(true);
      const processID = processes ? processes.map((e) => e?.id || e) : [];
      const tagsIds = tags ? tags.map((e) => e?.id || e) : [];
      props
        .fetchWorkItems(
          page,
          size,
          sortField,
          sortOrder,
          processID,
          fleetIds,
          loadedDatetime,
          completedDatetime,
          searchText,
          processExecutionIds,
          tagsIds,
          originalItemId
        )
        .then((result) => {
          setData(result?.data);
          setIsLoading(false);
        });
      props
        .fetchWorkItemsCount(
            processID,
            fleetIds,
            loadedDatetime,
            completedDatetime,
            searchText,
            processExecutionIds,
            tagsIds,
            originalItemId
        ).then((result) => {
          setCount(result?.data);
          setIsLoading(false);
        });
    },
    [],
  );
  const handleIsLoading = () => {
    setIsLoading(true);
  };
  const createWqObj = () => {
    let wqObj = {};
    wqObj = {
      pageNo: workqueueitemFilter.pageNo,
      pageSize: workqueueitemFilter.pageSize,
      orderId: workqueueitemFilter.order.id,
      orderOrder: workqueueitemFilter.order.order,
      processes: workqueueitemFilter.process,
      fleets: workqueueitemFilter.fleet,
      from: workqueueitemFilter.completedItemsFromDate,
      to: workqueueitemFilter.completedItemsToDate,
      search: workqueueitemFilter.searchText,
      processExecIds: processExecIds || workqueueitemFilter.processExecutions,
      tags: workqueueitemFilter.tags,
      originalItemId: workqueueitemFilter.originalItemId
    };
    return wqObj;
  };

  const fetchData = () => {
    const wqObj = createWqObj();
    getWorkItems(
      wqObj.pageNo,
      wqObj.pageSize,
      wqObj.orderId,
      wqObj.orderOrder,
      wqObj.processes,
      wqObj.fleets,
      wqObj.from,
      wqObj.to,
      wqObj.search,
      wqObj.processExecIds,
      wqObj.tags,
      wqObj.originalItemId
    );
  };

  useEffect(() => {
      fetchData();
  }, [workqueueitemFilter]);

  useEffect(() => {
    if (!processExecutionIds.length && processExecIds.length) {
      setProcessExecutionIds(processExecIds);
      history.replace({
        pathname: location.pathname,
        search: `?processExecution=${processExecIds}`,
      });
    }
  }, []);

  const deleteExceptions = () => {
    setOpenMsgConfirm(true);
    setMessagConfirm(
      `${t("Are you sure you want to delete")
      } ${
        selected.length
      } ${
        t("exceptions")
      } ?`,
    );
  };
  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setExceptionId(null);
  };
  const closeDialog = () => {
    setOpenMsgDetail(false);
  };
  const confirmDelete = () => {
    if (exceptionId) {
      props.deleteException(exceptionId).then(() => {
        setExceptionId(null);
        toast.success(t("Exception Deleted Successfully"))
        setOpenMsgConfirm(false);
        setSelected([]);
        props.fetchProcessesNameException().then(() => {
          // setProcesses(result.data);
          props.fetchExceptionsReason().then((resultE) => {
            setExceptions(resultE.data);
            getWorkItems(
              workqueueitemFilter.pageNo,
              workqueueitemFilter.pageSize,
              workqueueitemFilter.order.id,
              workqueueitemFilter.order.order,
              workqueueitemFilter.process,
              workqueueitemFilter.fleet,
              loadedDatetime,
              completedDatetime,
              workqueueitemFilter.searchText,
              processExecutionIds,
              workqueueitemFilter.tags,
              workqueueitemFilter.originalItemId
            );
          });
        });
      });
    } else {
      props.deleteExceptions(selected).then(() => {
        setExceptionId(null);
        toast.success(`${selected.length} ${t("exceptions successfully deleted")}`)
        setOpenMsgConfirm(false);
        setSelected([]);
        getWorkItems(
          workqueueitemFilter.pageNo,
          workqueueitemFilter.pageSize,
          workqueueitemFilter.order.order,
          workqueueitemFilter.order.order,
          workqueueitemFilter.process,
          workqueueitemFilter.fleet,
          loadedDatetime,
          completedDatetime,
          workqueueitemFilter.searchText,
          processExecutionIds,
          workqueueitemFilter.tags,
          workqueueitemFilter.originalItemId
        );
      });
    }
  };
  const handleOpenOccurenceDetails = (row) => {
    setOpenMsgDetail(true);
    setDetail(row?.parsedData);
    setValidate(row?.logDetail && row?.logSummary);
    setLogDetail(row?.logDetail);
  };

  const onClickRow = (row) => {
    handleOpenOccurenceDetails(row);
  };
  const handleExport = () => {
    setExportConfirmationOpen(true)
  };

  const onExportConfirm = () => {
    setExportLoading(true);
    setExportConfirmationOpen(false)
    const peId = processExecIds || workqueueitemFilter.processExecutions;
    const ids = workqueueitemFilter.process?.map(({ id }) => id);
    const tagsID = workqueueitemFilter.tags?.map((e) => e?.id ?? e);
    dispatch(
      exportOccurrencesXLSX(
        ids,
        workqueueitemFilter.fleet.map((c) => c.id),
        workqueueitemFilter.completedItemsFromDate,
        workqueueitemFilter.completedItemsToDate,
        workqueueitemFilter.searchText,
        false,
        peId,
        separateDetails,
        workqueueitemFilter.order.id,
        workqueueitemFilter.order.order,
        tagsID?.join(),
        workqueueitemsCount
      ),
    ).then(() => {
      setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  }

  const handleChangeSearchText = (value, currFilter) => {
    dispatch(
      updateFilter({
        ...currFilter,
        pageNo: 0,
        searchText: value,
      }),
    );
  };
  const handleFilterChangeBatch = (filters) =>
  {
    let obj = { ...initialState }
    filters.forEach((fi) => {
      const key = Object.keys(fi)[0]
      obj = { ...obj, [key]: fi[key] }
    })

    dispatch(updateFilter({ ...obj, pageNo: 0 }));
  }

  return (
    <Grid container>
      <Filter
        exceptions={exceptions}
        handleFiltersChange={handleFiltersChange}
        handleExport={handleExport}
        handleChangeDates={handleChangeDates}
        handleRequestSort={handleRequestSort}
        onDelete={deleteExceptions}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        rowCount={data?.length}
        exportLoading={exportLoading}
        handleChangeSearchText={handleChangeSearchText}
        fetchData={fetchData}
        workqueueitemFilter={workqueueitemFilter}
        handleIsLoading={handleIsLoading}
        initFilter={initFilter}
        isFetchLoading={isLoading}
        handleFilterChangeBatch={handleFilterChangeBatch}
        totalCount={workqueueitemsCount}
        pageTitle="Process CompletedItems"
        filteredItemsCount={workqueueitemsCount}
      />
      <CustomTableContainer>
        <CustomTable>
          {data?.length > 0 && <CustomTableHeader labels={labels} />}
          {!isLoading && data ? (
              data.length > 0 ? (
                  data.map((row, index) => (
                    <CustomTableRow key={index} onClick={() => onClickRow(row)}>
                      <WorkqueueitemRow
                            row={row}
                            handleOpenOccurenceDetails={handleOpenOccurenceDetails}
                            handleFiltersChange={handleFiltersChange}
                            maxDetailsCount={maxItemDetailsCount(data)}
                        />
                    </CustomTableRow>
                  ))
              ) : !isLoading && data.length === 0 ? (
                <DataNotFound
                      message={t("no.completedItems.message")}
                      icon={CompletedItemsNotFound}
                  />
              ) : isLoading ? (
                <CircularLoader />
              ) : (
                  null
              )
          ) : (
            <CircularLoader />
          )}
        </CustomTable>
      </CustomTableContainer>

      <Grid container xs={12} direction="row" justify="flex-end">
        {data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={workqueueitemFilter?.pageSize}
          page={workqueueitemFilter?.pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          logDetail={logDetail}
          data={detail}
          validate={validate}
          openStart={openMsgDetail}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      {openMsgConfirm && (
        <ConfirmMessage
          message={messagConfirm}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      <ExportConfirmation
        setExportLoading={setExportLoading}
        exportConfirmationOpen={exportConfirmationOpen}
        setExportConfirmationOpen={setExportConfirmationOpen}
        setSeparateDetails={setSeparateDetails}
        onExportConfirm={onExportConfirm}
        selectedProcess={workqueueitemFilter.process.length}
        fetchedProcess={processFilter?.length} />
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchWorkItems,
  fetchProcessesNameException,
  deleteException,
  deleteExceptions,
  fetchWorkItemsCount
};
export default connect(null, mapDispatchToProps)(WorkItemsPage);
