import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { abbreviateNumber, numberFloatFormat } from "util";
import TimeUp from "../../../../assets/DashboardStatesIcons/timeup.svg";
import TimeDown from "../../../../assets/DashboardStatesIcons/timedown.svg";
import useStyles from "../../style";
import { useSelector } from "react-redux";

const format = (num) => numberFloatFormat(num && num / 60);
export default function HandlingTimeItem({ item }) {
  const selectedDurationValue = useSelector(
    (state) => state.filterDashboard.selectedDurationValue
  );
  const [isAllTime, setIsAllTime] = useState(false);
  useEffect(() => {
    setIsAllTime(selectedDurationValue === "ALL_TIME")
  }, [selectedDurationValue])
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      justify="space-between"
      className={classes.handlingTimeItem}
    >
      <Grid xs={7}>
        <Typography className={classes.subItem}>
          {item?.avg?.entityName}
        </Typography>
        <Typography variant="caption" className={classes.subTime}>
          {format(item?.avg?.values[0])}
          {" "}
          {t("mins")}
        </Typography>
      </Grid>
      {!isAllTime && (
        <Box
          component={Grid}
          xs={5}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography component="span" className={classes.percentage}>
            {`${abbreviateNumber(item?.timeChangePercent)} %`}
          </Typography>
          {+item.timeChangePercent >= 0 ? (
            <img src={TimeUp} alt="time up" className={classes.percentIcon} />
          ) : (
            <img
              src={TimeDown}
              alt="time down"
              className={`${classes.percentIcon} ${classes.percentIconUp}`}
            />
          )}
        </Box>
      )}
    </Grid>
  );
}
