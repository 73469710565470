import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "appState",
  initialState: {
    menuExpanded: true,
    isConnected: false,
    processes: [],
    rpaQual: {
      includeArchive: false,
      opportunitiesStatus: [],
    },
  },
  reducers: {
    toogleMenu: (state) => ({ ...state, menuExpanded: !state.menuExpanded }),
    SetConnectedStatut: (state) => ({
      ...state,
      isConnected: !state.isConnected,
    }),
    setIncludeArchive: (state) => ({
      ...state,
      rpaQual: {
        ...state.rpaQual,
        includeArchive: !state.rpaQual.includeArchive,
      },
    }),
    setOpportunitiesStatus: (state, action) => ({
      ...state,
      rpaQual: {
        ...state.rpaQual,
        opportunitiesStatus: action.payload,
      },
    }),
  },
});

export const {
  toogleMenu,
  SetConnectedStatut,
  setIncludeArchive,
  setOpportunitiesStatus,
} = appSlice.actions;

export default appSlice.reducer;
