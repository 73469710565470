/**
 * @module Alerts : DefaultAlert / CustomAlerts
 */

/**
 *
 * @type {{error: {severity: string}, info: {severity: string}}}
 */
export const frequencyScheduleAlerts = {
    info: {
        severity: "info",
    },
    error: {
        severity: "error",
    },
}

export default frequencyScheduleAlerts
