import {
    CREATE_PROCESS_COST,
    CREATE_SHARED_ALL,
    DELETE_COST_ITEM,
    DELETE_COST_SHARED,
    FETCH_ALLOCATIONS,
    FETCH_SHARED,
    UPDATE_COST_ALLOCATION,
    UPDATE_LIST_SHARED,
    UPDATE_SHARED
} from "redux/constants";
import {
  URL_COST_ALLOCATIONS, URL_COST_SHARED, URL_COST_SHARED_ALL, URL_COST_SHARED_LIST
} from "redux/constants/endpoints";

    export const fetchAllocations = (processId) => ({
    type: FETCH_ALLOCATIONS,
    request: {
        url: `${URL_COST_ALLOCATIONS}${processId}`,
      },
    });
    export const fetchShared = () => ({
    type: FETCH_SHARED,
      request: {
        url: `${URL_COST_SHARED}`,
      },
    });
    export const deleteSharedByList = (shared, onSuccess, onError) => ({
      type: DELETE_COST_SHARED,
      request: {
          url: `${URL_COST_SHARED_LIST}`,
          method: "delete",
          data: shared,
      },
      meta: {
          onSuccess: (response) => {
              if (onSuccess) onSuccess(response);
              return response; },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }

    });

    export const deleteCosts = (id, onSuccess, onError) => ({
    type: DELETE_COST_ITEM,
      request: {
      url: `${URL_COST_ALLOCATIONS}${id}`,
      method: "delete",
    },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }
    });

    export const updateShared = (costAllocation, onSuccess, onError) => ({
      type: UPDATE_SHARED,
      request: {
        url: `${URL_COST_SHARED}${costAllocation?.id}`,
        method: "put",
      data: costAllocation,
      },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }
    });
export const updateListShared = (costAllocations, onSuccess, onError) => ({
    type: UPDATE_LIST_SHARED,
    request: {
        url: `${URL_COST_SHARED}`,
        method: "put",
        data: costAllocations,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    }
});
    export const updateCostAllocation = (costAllocation, onSuccess, onError) => ({
      type: UPDATE_COST_ALLOCATION,
      request: {
        url: `${URL_COST_ALLOCATIONS}${costAllocation?.id}`,
        method: "put",
      data: costAllocation,
      },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }
      });

    export const saveCost = (cost, onSuccess, onError) => ({
      type: CREATE_PROCESS_COST,
      request: {
        url: `${URL_COST_ALLOCATIONS}`,
        method: "post",
        data: cost,
      },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }
    });
    export const saveShardAll = (costAllocation, id, onSuccess, onError) => ({
      type: CREATE_SHARED_ALL,
      request: {
        url: `${URL_COST_SHARED_ALL}${id}`,
        method: "post",
        data: costAllocation,
      },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        }
    });
