import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import useToolbarStyles from "./style"

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected, title, onDelete, filterWidth
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container direction="column">
        {(numSelected || title || onDelete) && (
        <Grid item xs={12}>
          {numSelected > 0 ? (

            <Typography
                      className={classes.title}
                      color="inherit"
                      variant="h5"
                      component="div"
                  >
              {numSelected}
              {" "}
              selected
            </Typography>

              ) : (
                <Typography
                      className={classes.title}
                      variant="h5"
                      id="tableTitle"
                      component="div"
                  >
                  {title}
                </Typography>
              )}
          {numSelected > 0
                  && (
                  <IconButton
                          className={classes.deleteIcon}
                          edge="end"
                          aria-label="view"
                          onClick={onDelete}
                      >
                    <DeleteIcon />
                  </IconButton>
                  )}
        </Grid>
        )}

        <Grid container xs={12} justify="space-between" alignItems="center">
          <Grid item xs={filterWidth || 2}>{props.filterInput}</Grid>
          <Grid item>{props.button}</Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
