import { FETCH_LINKS, UPDATE_PROCESS_LINKS } from "../../constants";
import { URL_LINKS } from "../../constants/endpoints";

export const fetchLinksByProcess = (
    id,
    page,
    size,
    sort,
    searchCriteria
) => ({
        type: FETCH_LINKS,
        request: {
            url: `${URL_LINKS}/${id}`,
            params: {
                page,
                size,
                sort,
                searchCriteria
            }
        },
    });

export const saveLink = (link) => ({
    type: UPDATE_PROCESS_LINKS,
    request: {
        url: `${URL_LINKS}`,
        method: "post",
        data: link,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const deleteLink = (id) => ({
    type: UPDATE_PROCESS_LINKS,
    request: {
        url: `${URL_LINKS}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const updateLink = (id, link) => ({
    type: UPDATE_PROCESS_LINKS,
    request: {
        url: `${URL_LINKS}/${id}`,
        method: "put",
        data: link,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
