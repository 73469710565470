import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "none",
    padding: 0,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  infoTableContainer: {
    height: 400,
    marginTop: 15,
  },
  dialogTitle: {
    fontSize: "1.35rem",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  tableCellText: {
    fontWeight: "700",
    paddingLeft: theme.spacing(0.6),
  },
}));
