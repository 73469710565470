import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import {
  FETCH_ALL_FLEETS_FOR_FILTER,
  FETCH_PENDING_AUTOMATIONS_TAGS,
  FETCH_PENDING_AUTOMATIONS_USERS,
} from "redux/constants";
import { fetchFleetsForFilter } from "redux/actions/services";
import {
  fetchPendingAutomationsTags,
  fetchPendingAutomationsUsers,
} from "redux/actions/services/pendingAutomation";
import { useDispatch } from "react-redux";
import useStyles from "pages/AutomationsPending/style";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import InformationIcon from "components/InformationIcon";

export default function AutomationAssignment() {
  const { t } = useTranslation();
  const form = useFormContext();
  const classes = useStyles()();
  const dispatch = useDispatch();
  const tags = useQuery({ type: FETCH_PENDING_AUTOMATIONS_TAGS })?.data;
  const groups = useQuery({ type: FETCH_ALL_FLEETS_FOR_FILTER }).data;
  const users = useQuery({ type: FETCH_PENDING_AUTOMATIONS_USERS }).data;
  const selectedGroups = form.watch("fleetsIds");
  useEffect(() => {
    if (selectedGroups?.[0]) {
      dispatch(fetchPendingAutomationsTags(selectedGroups));
      dispatch(fetchPendingAutomationsUsers(selectedGroups));
    }
  }, [selectedGroups]);
  useEffect(() => {
    dispatch(fetchFleetsForFilter());
  }, []);

  return (
    <Box>
      <FormControl fullWidth className={classes.textField}>
        <Controller
          control={form.control}
          name="fleetsIds"
          rules={{
            required: t("field.is.required", {
              field: t("groups"),
            }),
          }}
          render={(field) => (
            <CustomAutoComplete
              {...field}
              multiple
              label={
                <InformationIcon
                  titleContent={t("groups")}
                  originalContent={t("groups")}
                  placement="right"
                />
              }
              optionLabel="companyName"
              options={groups ?? []}
              value={groups?.filter(({ id }) => form.getValues("fleetsIds")?.includes(id))}
              onChange={(value) => form.setValue(
                  "fleetsIds",
                  value?.map(({ id }) => id)
                )}
              error={!!form.formState.errors.fleetsIds?.message}
              helperText={
                !!form.formState.errors.fleetsIds
                && form.formState.errors.fleetsIds?.message
              }
              disableClearable
              simpleChipStyle
            />
          )}
        />
      </FormControl>
      <FormControl
        fullWidth
        className={classes.textField}
        key={`${selectedGroups?.length}-tags`}
      >
        <Controller
          control={form.control}
          name="tagsDto"
          rules={{
            required: t("field.is.required", {
              field: t("Tags"),
            }),
          }}
          render={(field) => (
            <CustomAutoComplete
              {...field}
              multiple
              label={
                <InformationIcon
                  titleContent={t("Tags")}
                  originalContent={t("Tags")}
                  placement="right"
                />
              }
              optionLabel="name"
              options={tags ?? []}
              value={tags?.filter(({ id }) => form.getValues("tagsDto")?.includes(id))}
              onChange={(value) => form.setValue(
                  "tagsDto",
                  value?.map(({ id }) => id)
                )}
              error={!!form.formState.errors.tagsDto?.message}
              helperText={
                !!form.formState.errors.tagsDto
                && form.formState.errors.tagsDto?.message
              }
              disabled={!selectedGroups?.length}
              disableClearable
            />
          )}
        />
      </FormControl>
      <FormControl
        fullWidth
        className={classes.textField}
        key={`${selectedGroups?.length}-user`}
      >
        <Controller
          control={form.control}
          name="usersIds"
          rules={{
            required: t("field.is.required", {
              field: t("user.management.menu.title"),
            }),
          }}
          render={(field) => (
            <CustomAutoComplete
              {...field}
              multiple
              label={
                <InformationIcon
                  titleContent={t("user.management.menu.title")}
                  originalContent={t("user.management.menu.title")}
                  placement="right"
                />
              }
              optionLabel={(user) => `${user.firstName} ${user.lastName}`}
              options={users ?? []}
              value={users?.filter(({ id }) => form.getValues("usersIds")?.includes(id))}
              onChange={(value) => form.setValue(
                  "usersIds",
                  value?.map(({ id }) => id)
                )}
              error={!!form.formState.errors.usersIds?.message}
              helperText={
                !!form.formState.errors.usersIds
                && form.formState.errors.usersIds?.message
              }
              disabled={!selectedGroups?.length}
              disableClearable
            />
          )}
        />
      </FormControl>
    </Box>
  );
}
