import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    customButton: {
        transition: "0.3s",
        color: theme.palette.background.default,
        fontWeight: 700,
        fontSize: "0.8rem",
        paddingInline: "20px",
        padding: "5px 13px",
        "&:hover": {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.06)"
        }
    },
    button: {
        fontWeight: 700,
        fontSize: "0.8rem"
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        "&:hover": {
            backgroundColor: fade(theme.palette.primary.main, 0.9),
        }
    },
    secondary: {
        backgroundColor: theme.palette.info.dark,
        "&:hover": {
            backgroundColor: fade(theme.palette.info.dark, 0.9),
        }
    },
    tertiary: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: fade(theme.palette.secondary.main, 0.9),
        }
    },
    cancel: {
        backgroundColor: theme.custom.color.chartColor4,
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: fade(theme.custom.color.chartColor4, 0.9),
        }
    },
    disabled: {
        backgroundColor: theme.custom.color.chartColor4,
        color: fade(theme.palette.primary.main, 0.5),
    },
    cancelModal: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: fade(theme.palette.background.default, 0.9),
        },
        fontWeight: 700
    },
    cancelOutlined: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "0.3125rem",
        fontWeight: 700,
        "&:hover": {
            backgroundColor: fade(theme.palette.background.default, 0.9),
        }
    },
    outlinedPrimary: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
            backgroundColor: fade(theme.palette.background.default, 0.9),
        },
        fontWeight: 700,
        boxShadow: theme.shadows[23]
    },
    outlinedSecondary: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
        "&:hover": {
            backgroundColor: fade(theme.palette.background.default, 0.9),
        },
        fontWeight: 700,
        boxShadow: theme.shadows[23]
    },
    secondaryText: {
        color: theme.palette.info.main,
        "&:hover": {
            backgroundColor: theme.custom.color.transparent,
            boxShadow: "none",
        },
        padding: 0
    },
    secondaryVariant: {
        backgroundColor: theme.palette.info.main,
        "&:hover": {
            backgroundColor: fade(theme.palette.info.main, 0.8),
            boxShadow: "none",
        },
        padding: 0
    },
    iconBtn: {
        padding: theme.spacing(1),
        minWidth: 0,
        height: 35,
        width: 35
    }
}));
