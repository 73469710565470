import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";

export default function SubscribersInput({
  sameFleetUsers,
  disableFields,
  selectedSubscribers,
  handleSubscribersChange,
  control,
  errors,
}) {
  const { t } = useTranslation();
  return (
    <Grid container item alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Controller
          {...control?.register("sub")}
          name="sub"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <CustomAutoComplete
              fullWidth
              limitTags={5}
              multiple
              options={sameFleetUsers ?? []}
              label={`${t("alerts.management.subscribers")} *`}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              optionLabel={(item) => `${item?.firstName} ${item?.lastName}`}
              translateLabel={false}
              value={selectedSubscribers}
              onChange={(value) => {
                handleSubscribersChange(value)
              }}
              disabled={disableFields}
              error={errors?.sub}
              helperText={errors?.sub && errors?.sub?.message}
              inputRef={field?.ref}
              noOptionsNode={<NoDataMenu message={t("no.users.message")} marginX={8} />}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
