import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  DEFAULT_FROM_TIME, DEFAULT_TO_TIME, getDateAndTime, parseTimeString
} from "util/helpers/date.helpers";
import useStyles from "../style";

const DayHoursSelector = (props) => {
  const {
    title,
    titleStyle,
    setError,
    clearErrors,
    setToTime,
    setFromTime,
    errors,
    fromTime,
    toTime,
    pattern,
    disableFields
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [checkedHours, setCheckedHours] = useState(false);

  const handleChangeHours = () => {
      clearErrors("to");
      setFromTime(DEFAULT_FROM_TIME);
      setToTime(DEFAULT_TO_TIME);
  };

  useEffect(() => {
    toTime <= fromTime ? setError("to", { type: "custom", message: t("restriction.form.to.error") + fromTime, }) : clearErrors("to");
    setCheckedHours(DEFAULT_FROM_TIME.startsWith(fromTime) && DEFAULT_TO_TIME.startsWith(toTime));
    }, [fromTime, toTime]);

  return (
    <Box className={classes.container}>
      <Typography className={titleStyle}>{t(title)}</Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="fit-content"
          gridColumnGap={25}
        >
          <KeyboardTimePicker
            label={t("restriction.form.from")}
            keyboardIcon={<AccessTimeIcon className={classes.chipIcon} />}
            onChange={(value) => setFromTime(getDateAndTime(value, pattern).timePart)}
            minutesStep={5}
            className={classes.input}
            value={parseTimeString(fromTime)}
            ampm={false}
            onKeyDown={(e) => e.preventDefault()}
            disabled={disableFields}
          />
          <KeyboardTimePicker
            label={t("restriction.form.to")}
            onChange={(value) => setToTime(getDateAndTime(value, pattern).timePart)}
            className={classes.input}
            minutesStep={5}
            value={parseTimeString(toTime)}
            ampm={false}
            keyboardIcon={<AccessTimeIcon className={classes.chipIcon} />}
            error={!!errors.to}
            helperText={errors.to ? errors.to.message : ""}
            onKeyDown={(e) => e.preventDefault()}
            disabled={disableFields}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <FormControlLabel
        classes={{
          label: classes.label,
          root: classes.checkboxContainer
        }}
        disabled={disableFields}
        control={
          <Checkbox
            checked={checkedHours}
            onChange={handleChangeHours}
            classes={{
              root: classes.checkboxRoot,
              checked: classes.checkboxChecked,
            }}
          />
        }
        label={`24 ${t("hours")}`}
      />
    </Box>
  );
};
export default DayHoursSelector;
