import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageHeading: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(2),
    // [theme.breakpoints.down('md')]: {
    //     padding: theme.spacing(2)
    // },
  },
  acceptBtn: {
    backgroundColor: theme.custom.palette.status.accepted,
    "&:hover": {
      backgroundColor: theme.custom.palette.status.accepted,
    },
  },
  rejecttBtn: {
    backgroundColor: theme.custom.palette.status.rejected,
    "&:hover": {
      backgroundColor: theme.custom.palette.status.rejected,
    },
  },
  deleteOutlinedBtn: {
    color: theme.custom.palette.status.rejected,
    borderColor: theme.custom.palette.status.rejected,
    "&:hover": {
      color: theme.custom.palette.status.rejected,
      borderColor: theme.custom.palette.status.rejected,
    },
  },
  deleteBtn: {
    color: "white",
    backgroundColor: theme.custom.palette.status.rejected,
    borderColor: theme.custom.palette.status.rejected,
    "&:hover": {
      backgroundColor: theme.custom.palette.status.rejected,
      borderColor: theme.custom.palette.status.rejected,
    },
  },
  status: {
    textOverflow: "nowrap",
    fontSize: "0.8rem",
    padding: theme.spacing(0.8, 2.3),
    borderRadius: theme.spacing(4),
    color: theme.palette.common.white,
  },
  actionsContainer: {
    marginBottom: theme.spacing(3),
  },
  pageHeaderContainer: {
    padding: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  pageHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));
