import React, { useEffect, useState } from "react";
import CustomDialog from "components/CustomDialog";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import {
  createResourceCredential, fetchResourceCredentialById,
  updateResourceCredential, fetchFleetUsers,
} from "redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "../style";
import { get } from "lodash";

export default function CredentialForm({ open, handleClose, currentCredential }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fleetUsers, setFleetUsers] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const {
    register,
    unregister,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    clearErrors,
        trigger
  } = useForm({
    defaultValues: {},
  });

  const CloseCleanUp = () => {
    unregister();
    reset();
    clearErrors();
    setIsSubmitLoading(false);
    setIsNameError(false);
  };
  const onSuccess = () => {
    setIsSubmitLoading(false);
    toast.success(t("changes.saved.successfully"));
    CloseCleanUp();
    handleClose();
  };
  const onError = (data) => {
    if (data?.response?.data?.title === "unique.name.constraint") {
      toast.error(t("A credential with the same already exists"))
      setIsNameError(true);
      trigger("name");
    } else {
      toast.error(t("Unexpected Error"));
    }
    setIsSubmitLoading(false);
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (!result) {
      return;
    }
    const tempData = {
      ...getValues(),
    };
    if (!currentCredential) {
      dispatch(createResourceCredential(tempData, onSuccess, onError));
    } else {
      dispatch(updateResourceCredential(tempData, onSuccess, onError));
    }
  };

  const onFetchCurrentCredentialSuccess = (res) => {
    const tempCredential = res?.data;
    setValue("id", tempCredential?.id);
    setValue("name", tempCredential?.name);
    setValue("description", tempCredential?.description);
    setValue("username", tempCredential?.username);
    setValue("password", tempCredential?.password);
    setValue("userIds", tempCredential?.userIds)
    setIsLoading(false);
  }

  const onFetchFixedCredential = () => {
    setValue("id", currentCredential?.id);
    setValue("name", currentCredential?.name);
    setValue("description", currentCredential?.description);
    setValue("username", currentCredential?.username);
    setValue("password", currentCredential?.password);
    setValue("userIds", currentCredential?.userIds)
  }

  const onFetchCurrentCredentialError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
    handleClose();
  };

  useEffect(() => {
    dispatch(fetchFleetUsers(currentUser?.fleet?.id)).then((res) => {
      setFleetUsers(res.data);
    });
    return () => {
      CloseCleanUp();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentCredential && fleetUsers) {
      if (currentCredential?.isFixed) onFetchFixedCredential()
      else {
        setIsLoading(true);
        dispatch(
          fetchResourceCredentialById(
                currentCredential?.id,
                onFetchCurrentCredentialSuccess,
                onFetchCurrentCredentialError
            )
        );
      }
    }
  }, [currentCredential, fleetUsers]);

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxHeight="99%"
      width={660}
      fullWidth
      open={open}
      disableBackdropClick
      PaperProps={{
        square: true,
      }}
      title={t("Credential Configuration")}
      actionElements={[
        {
          label: t("Cancel"),
          onClick: handleClose,
          color: "cancelModal",
          disabled: isSubmitLoading,
        },
        {
          label: currentCredential ? t("Update Credential") : t("Create Credential"),
          view: "primary",
          type: "submit",
          isLoading: isSubmitLoading,
          disabled: isSubmitLoading || isLoading,
          onClick: onSubmit,
          hidden: currentCredential?.isFixed,
        },
      ]}
      borderRadius={6}
    >
      {isLoading ? (
        <CircularLoader height={400} />
      ) : (
        <Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("name", {
                    required: t("field.is.required", {
                      field: t("Credential Name"),
                    }),
                    validate: !isNameError
                  })}
                  valued={getValues("name")}
                  label={`${t("Credential Name")} *`}
                  error={!!errors.name?.message}
                  helperText={errors.name && errors.name?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("name"),
                  }}
              />
          </Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("description", {
                    required: t("field.is.required", {
                      field: t("Description"),
                    }),
                  })}
                  valued={getValues("description")}
                  label={`${t("Description")} *`}
                  error={!!errors.description?.message}
                  helperText={errors.description && errors.description?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("description"),
                  }}
                  className={classes.field}
              />
          </Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("username", {
                    required: t("field.is.required", {
                      field: t("username"),
                    }),
                    validate: !isNameError
                  })}
                  valued={getValues("username")}
                  label={`${t("username")} *`}
                  error={!!errors.username?.message}
                  helperText={errors.username && errors.username?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("username"),
                  }}
              />
          </Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("password", {
                    required: t("field.is.required", {
                      field: t("password"),
                    }),
                  })}
                  valued={getValues("description")}
                  label={`${t("password")} *`}
                  error={!!errors.password?.message}
                  helperText={errors.password && errors.password?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("password"),
                  }}
                  className={classes.field}
                  type="password"
              />
          </Box>
          <FormControl fullWidth margin="none">
            <Controller
                    control={control}
                    name="userIds"
                    rules={{
                      required: t("queue.management.form.validation.required"),
                    }}
                    render={(field) => (
                      <CustomAutoComplete
                            {...field}
                            multiple
                            label={t("Assign to these users")}
                            options={fleetUsers ?? []}
                            value={fleetUsers?.filter((user) => watch("userIds")?.includes(user?.id))}
                            onChange={(value) => {
                              setValue("userIds", value?.map((user) => user.id));
                            }}
                            error={!!errors.userIds?.message}
                            helperText={
                              !!errors.userIds && errors.userIds?.message
                            }
                            optionLabel={(item) => `${item?.firstName} ${item?.lastName}`}
                        />
                    )}
                />
          </FormControl>
        </Box>
      )}

    </CustomDialog>
  );
}
