import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  boxSection: {
    position: "relative",
    padding: theme.spacing(5, 2, 5, 2),
    marginTop: 20,
    border: `1px solid ${theme.palette.primary.light}`,
    width: "100%",
    margin: theme.spacing(2, 5, 2, 5),
  },
  boxSectionTitle: {
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: -10,
    left: 25,
    color: theme.palette.primary.dark,
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0 10px"
  },
  fieldsGrid: {
    display: "flex",
    padding: theme.spacing(0.5, 0),
  },
  switchText: {
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: 600,
  },
  switchLabel: {
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    fontWeight: 500,
  },
  switchBox: {
    marginTop: 15,
  },
  slaDialogPaper: {
    width: "45%",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    borderRadius: "1%",
  },
  executionTextSla: {
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  actionsGrid: {
    marginLeft: theme.spacing(-3),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    width: theme.spacing(25),
    marginRight: 20
  },
  buttonRight: {
    textAlign: "right",
  },
  deleteIcon: {
    color: theme.palette.secondary.main,
  },
  hourly: {
    position: "relative",
    bottom: theme.spacing(3),
  },
  daily: {
    position: "relative",
    top: theme.spacing(2),
  },
  weekly: {
    position: "relative",
    top: theme.spacing(8),
  },
  monthly: {
    position: "relative",
    top: theme.spacing(14),
  },
  yearly: {
    position: "relative",
    top: theme.spacing(20),
  },
  tableCell: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: "2px 10px",
    fontWeight: "700",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`
  },
  autoComplete: {
    width: "220px",
    marginLeft: 0,
    marginBottom: 10,
  },
  pagination: {
    fontSize: theme.spacing(0.875),
  },
  paper: {
    backgroundColor: "unset",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  scheduleTable: {
    height: "370px",
  },
  scheduleContainer: {
    padding: "35px 35px",
    backgroundColor: theme.palette.info.light,
  },
  editIcon: {
    justifyContent: "left"
  },
  weeklySchedule: {
    textTransform: "capitalize"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    paddingBottom: 0,
    marginRight: 5
  },
  input: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "400",
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.info.main,
      borderWidth: 3,
    },
    "& .MuiInput-underline:not(.Mui-error):after": {
      borderBottomColor: theme.palette.info.main,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: theme.palette.info.main,
      borderWidth: 3,
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
      borderBottomColor: theme.custom.color.chartColor4,
    },
    "& .MuiInput-underline.Mui-error:before": {
      borderWidth: 3,
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontSize: 15,
      fontWeight: 600
    }
  },
  inputDisabled: {
    "& .MuiInputLabel-root": {
      color: theme.custom.color.chartColor4,
    }
  },
  popupIcon: {
      color: theme.palette.primary.main
  },
  popupIconDisabled: {
      color: theme.custom.color.chartColor4
  },
  dateLabel: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 600,
    minWidth: 90
  },
  dayOfMonthLabel: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 600,
    marginRight: 10
  },
  dayOfMonthSelect: {
    width: theme.spacing(11),
    marginRight: 10
  },
  frequencyLabel: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 600,
    minWidth: 90
  },
  periodFormControl: {
    width: theme.spacing(28),
  },
  periodFormControlLabel: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700
  },
  tasksContainer: {
    padding: "30px 0 0 30px",
  },
  tasksGrid: {
    height: 380,
    overflowY: "auto",
    paddingRight: 20,
    paddingLeft: 10
  },
  periodError: {
    fontSize: 13
  },
  daysContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 15,
    marginTop: 10,
  },
  slaSectionContainer: {
    padding: 20,
    marginTop: 25,
  },
  inputField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2.5),
  },
  chipIcon: {
    fontSize: 16,
    margin: 0
  },
  chip: {
    display: "flex",
    flexDirection: "column",
    height: 51,
    width: 31,
    gap: "5px",
    padding: 0,
    alignItems: "center",
    borderRadius: 4,
    fontSize: 10,
    fontWeight: 500
  },
  label: {
    fontSize: 14,
  },
  checkboxRoot: {
    color: theme.custom.color.chartColor1,
  },
  checkboxChecked: {
    color: `${theme.palette.info.main} !important`,
  },
  container: {
    display: "grid",
    rowGap: "10px",
  },
  checkboxContainer: {
    width: "fit-content"
  },
  scheduleDescription: {
    color: theme.palette.primary.dark,
    fontSize: 15,
    fontWeight: 600,
    minWidth: 90
  },
  formField: {
    margin: theme.spacing(2),
  },
  dayDoesNotExist: {
    width: theme.spacing(14),
  },
  dayDoesNotExistLabel: {
    color: theme.palette.secondary.main,
    fontSize: 15,
    fontWeight: 600,
  },
}));
