import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import { isSubModulePermitted } from "components/HasPermission";
import SubPagePanel from "components/SubPagePanel";
import WorkItemsPage from "./WorkItemsPage/CompletedItems";
import PendingItems from "./WorkItemsPage/PendingItems";
import SubPageContainer from "components/SubPageContainer";
import useStyles from "./style";

export default function ProcessItemPage(props) {
  const history = useHistory();
  const routes = ["/items/completed", "/items/pending"];
  const classes = useStyles();

  const value = (!props.currentUserItems) && routes.indexOf(history.location.pathname) !== -1 ? routes.indexOf(history.location.pathname) : 0;
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });

  return (
    <SubPageContainer>
      <div className={classes.rootTabs}>
        <Grid className={classes.firstGrid}>
          {isSubModulePermitted(currentUser, "Process Item") && (
            <SubPagePanel value={value} index={0}>
              <WorkItemsPage />
            </SubPagePanel>
          )}
          {isSubModulePermitted(currentUser, "Process Item") && (
            <SubPagePanel value={value} index={1}>
              <PendingItems />
            </SubPagePanel>
          )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
