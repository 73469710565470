import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import SignupForm from "./SignupForm";
import useStyles from "./style";
import smartRobyBg from "./smartRoby-bg.png";

function SignUp() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Hidden mdDown>
        <Grid item lg={6}>
          <img src={smartRobyBg} alt="SmartRoby background" width="100%" style={{ maxHeight: "100vh" }} />
        </Grid>
      </Hidden>
      <Grid container item xs={12} lg={6} justify="center">
        <Grid item xs={10}>
          <SignupForm />
        </Grid>
      </Grid>
    </Grid>

  );
}

export default SignUp;
