import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import {
  chartPeriodLabels,
  LINE_CHART_DATA_LABELS,
  LINE_CHART_DATASET_OPTION,
  LINE_CHART_TOOLTIP_OPTIONS,
  LINE_CHART_X_AXES, MONITORING_TIME, MONITORING_TIME_Params,
} from "pages/newDashboard/dashboardUtils";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardWeeklySuccessRate } from "redux/actions/services/dashboard";
import ChartSectionHeader from "pages/newDashboard/WeeklyView/ChartSectionHeader";
import MixedChart from "pages/newDashboard/MixedChart";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "../../../../style/orchestratorTheme";
import useStyles from "pages/newDashboard/style";
import { numberFloatFormat } from "util/index";

const formattedData = (data) => ({
  labels: data?.chartPeriodLabels,
  datasets: [
    {
      yAxisID: "rate",
      data: data?.successRates,
      ...LINE_CHART_DATASET_OPTION,
      fill: true,
    },
  ],
});

const options = (chartStep) => ({
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: LINE_CHART_X_AXES(),
    yAxes: [
      {
        id: "rate",
        display: false,
        position: "left",
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          stepSize: chartStep,
          beginAtZero: true,
          display: false,
        },
        scaleLabel: {
          display: true,
          fontColor: theme.palette.primary.main,
        },
      },
    ],
  },
  plugins: {
    datalabels: LINE_CHART_DATA_LABELS(),
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => `${data.datasets[0].data[tooltipItem.index]}%`,
    },
    ...LINE_CHART_TOOLTIP_OPTIONS,
  },
});

export default function WeeklySuccessRate() {
  const classes = useStyles()();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [containerHeight, setContainerHeight] = useState(null);
  const containerRef = useRef();
    const dispatch = useDispatch();
    const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);

    const [successRateData, setSuccessRateData] = useState(null);
    const fetchWeeklySuccessRate = () => {
        setIsLoading(true);
        dispatch(
            fetchDashboardWeeklySuccessRate({
                ...filterDashboard
            })
        ).then((res) => {
            setSuccessRateData(res?.data);
            setIsLoading(false);
        });
    }

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchWeeklySuccessRate, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

    useEffect(() => {
        fetchWeeklySuccessRate()
    }, [filterDashboard]);

  const chartData = formattedData({
    ...successRateData,
    chartPeriodLabels: chartPeriodLabels(t, filterDashboard.selectedDurationValue),
  });
  const chartOption = options(10);
  const headerDataList = [
    {
      label: t("average.success.rate"),
      value: `${numberFloatFormat(successRateData?.averageSuccessRate)}%`,
    },
  ];

  useEffect(() => {
    if (containerRef?.current) setContainerHeight(containerRef.current.clientHeight - 75);
  }, [containerRef]);

  return (
    <Box className={classes.sectionContainer} ref={containerRef}>
      <ChartSectionHeader
        title={t("success.rate")}
        dataList={headerDataList}
        isLoading={isLoading}
      />
      {isLoading ? (
        <CircularLoader height={300} size={23} />
      ) : (
        successRateData?.successRates?.length > 0 && (
          <MixedChart
            key={`SuccessRateChart-${containerHeight}`}
            data={chartData}
            options={chartOption}
            width={null}
            height={containerHeight}
          />
        )
      )}
      {!isLoading
        && (!successRateData?.successRates
          || successRateData?.successRates?.length === 0) && (
          <DataNotFound
            backgroundColor="transparent"
            boxShadow="none"
            iconHeight={100}
            iconWidth={100}
            height={300}
          />
        )}
    </Box>
  );
}
