import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MinimumRoiRate } from "util/constants";
import { formtNumberByLanguage, numberFloatFormat, numberRoiFloatFormat } from "util";
import { handleRequestMonitoringMode } from "../dashboardUtils";
import {
  fetchBreakEven,
  fetchRoi,
  fetchUnhandledExceptions
} from "../../../redux/actions/services/dashboard";
import ProfitabilityRatio from "../../../assets/DashboardStatesIcons/profitabilityratio.svg"
import BreakEven from "../../../assets/DashboardStatesIcons/breakevent.svg"
import Exceptions from "../../../assets/DashboardStatesIcons/exceptioncount.svg"
import StateItem from "./StateItem";
import useStyles from "../style";

export default function DashboardStates({ xs }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [roi, setRoi] = React.useState({ loading: false });
  const [breakEven, setBreakEven] = React.useState({ loading: false });
  const [unhandledExceptions, setUnhandledExceptions] = React.useState({ loading: false });

  const breakEvenContent = (
    { breakEvenUnit, breakEvenValue },
    roiPercentage
  ) => {
    if (!breakEvenUnit || roiPercentage < MinimumRoiRate) {
      return "--";
    }

    return breakEvenValue > 0
      ? `${t("ago", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`
      : `${t("point.amortissage.negative", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`;
  };

  const getRate = (data) => (data ? `${numberFloatFormat(data)}%` : "---");

  const roiContent = (roiPercentage) => {
    if (isNaN(roiPercentage) || roiPercentage < MinimumRoiRate) return "--";
    return getRate(roiPercentage);
  };

  const states = [
    {
      label: "Roi",
      tooltipTitle: t("kpi.header.roi.tooltip"),
      state: (roi.data && roiContent(roi.data.roiPercentage)) || "--",
      icon: ProfitabilityRatio,
      loading: roi.loading,
      xs: 4,
    },
    {
      label: "Break-even",
      tooltipTitle: t("kpi.header.breakEven.tooltip"),
      state:
        (breakEven.data && roi.data && breakEvenContent(breakEven.data, roi.data.roiPercentage)) || "--",
      icon: BreakEven,
      loading: breakEven.loading || roi.loading,
      xs: 4,
    },
    {
      label: "header.kpi.exceptions",
      tooltipTitle: t("header.kpi.exceptions"),
      state:
        (unhandledExceptions.data && formtNumberByLanguage(unhandledExceptions.data.todayItemExceptions)) || "--",
      icon: Exceptions,
      loading: unhandledExceptions.loading,
      xs: 4,
      pushTo: "/items/exception"
    },
  ];

  const fetchDashboardStates = () => {
    setRoi({ loading: true });
    dispatch(fetchRoi(filterDashboard)).then((res) => {
      setRoi({
        loading: false,
        data: res.data
      });
    });
    setBreakEven({ loading: true });
    dispatch(fetchBreakEven(filterDashboard)).then((res) => {
      setBreakEven({
        loading: false,
        data: res.data
      });
    });
    setUnhandledExceptions({ loading: true });
    dispatch(fetchUnhandledExceptions(filterDashboard)).then((res) => {
      setUnhandledExceptions({
        loading: false,
        data: res.data
      });
    });
  }

  useEffect(() => {
    fetchDashboardStates()
  }, [filterDashboard]);

  useEffect(() => {
    handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchDashboardStates)
  }, [refreshTime])

  return (
    <Box
      component={Grid}
      item
      container
      xs={xs}
      className={`${classes.container} ${classes.statesContainer}`}
      minHeight={90}
    >
      {states.map((state, index) => (
        <StateItem state={state} isLoading={state.loading} key={index} isLast={states.length === index + 1} />
      ))}
    </Box>
  );
}
