import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    margin: "12px",
    height: "100%",
  },
  cardContent: {
    padding: 0,
    height: "100%",
  },
  card: {
    height: "100%",
    minHeight: 314,
  },
  chart: {
    marginBottom: "-10px",
  },
  alignChartsMiddle: {
    padding: 0,
    height: "100%",
    marginTop: "25%",
  },
  reasonsChartAlign: {
    padding: 0,
    height: "100%",
    marginTop: "10%",
  },
  graphContainer: {
    width: "80%",
  },
  chartLegend: {
    listStyleType: "none",
    maxWidth: "100%",
  },
  chartItemLegendItem: {
    fontWeight: 600,
    fontSize: "0.75rem",
    overflow: "hidden",
  },
  legendContainer: {
    marginTop: "0",
  },
  noPadding: {
    padding: 0,
    pisition: "relative",
  },
}));
