import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { useTranslation } from "react-i18next";
import useStyles from "../styles"

export default function CustomViewIcon({
 title, placement, size, ...rest
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <Tooltip placement={placement ?? "top"} title={title ?? t("tooltip.action.view")}>
        <IconButton
                {...rest}
                size={size}
            >
          <VisibilityRoundedIcon fontSize={size} className={classes.rowActionButtons} />
        </IconButton>
      </Tooltip>
    )
}
