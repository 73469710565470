import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { formatDateByLanguage, formtNumberByLanguage, round } from "util";
import logoImg from "../../../assets/logo.png";

const PdfTemplate = ({ data }) => {
  const { t } = useTranslation();
  const profile = data.steps[0];
  let impactAndTime = "";
  if (data.steps[2]?.criteria) {
    impactAndTime = data.steps[2].criteria
      && data.steps[3].criteria && [...data.steps[2].criteria, ...data.steps[3].criteria];
  }
  const Feasibility = data.steps[1];

  const ITReview = data.steps[4];

  if (ITReview?.criteria) {
    let temp = [];
    // sort ITReview : [..., ...yes, ...no]
    temp = [
      ...ITReview.criteria.filter(
        (criteria) => !["Yes", "No"].includes(criteria.type),
      ),
    ];
    temp = [
      ...temp,
      ...ITReview.criteria.filter((criteria) => criteria.type === "Yes"),
    ];
    temp = [
      ...temp,
      ...ITReview.criteria.filter((criteria) => criteria.type === "No"),
    ];
    ITReview.criteria = temp;
  }

  const results = data.result;

  // graph points (x,y):
  const graph = {
    x: 0,
    y: 0,
  };
  if (results?.values?.graph) {
    graph.x = (results.values.graph.x * 100) / 5;
    graph.y = 100 - (results.values.graph.y * 100) / 5;
  }

  return (
    <div id="pdfResult" className="pdfResult">
      <div className="header" id="top">
        {/* <div className="logo">
                    <img src={require('./images/DocumentStore.png')}  alt=""/>
                </div> */}
        <div className="title uppercase">
          {t("rpa.export.rpa")}
          <span className="smaller">{t("rpa.export.qualification")}</span>
        </div>
        <div className="logo">
          <img src={logoImg} alt="" />
        </div>
      </div>
      {profile?.criteria && (
      <div className="card">
        <div className="title orange">
          <div className="icon">
            <i className="bi bi-person-fill" />
          </div>
          <span className="text">{t(profile.name)}</span>
        </div>
        <div className="content two-columns">
          <div className="content-elements">
            {profile?.criteria
              .filter((criteria) => criteria.name !== "Description")
              .map((criteria) => (
                <div className="do-not-break" key={criteria.name}>
                  <span className="key">
                    {t(criteria.name)}
                    :
                    {" "}
                  </span>
                  <span className="value">
                    {criteria.name === "Date"
                      ? formatDateByLanguage(criteria.type)
                      : t(criteria.type)}
                  </span>
                </div>
              ))}
          </div>
          {profile?.criteria
            .filter((criteria) => criteria.name === "Description")
            .map((criteria) => (
              <div className="comment description" key={criteria.name}>
                <div className="text-dark">{t("Description")}</div>
                <div>{t(criteria.type)}</div>
              </div>
            ))}
        </div>
      </div>
      )}

      {impactAndTime && (
      <div className="card">
        <div className="title green do-not-break">
          <div className="icon">
            <div className="center">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.5"
                  width="12"
                  height="12"
                  rx="2"
                  fill="#00BA34"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.85123 3.83389C6.85123 3.74534 6.89111 3.66041 6.96209 3.59779C7.03308 3.53518 7.12935 3.5 7.22974 3.5H10.2579C10.3582 3.5 10.4545 3.53518 10.5255 3.59779C10.5965 3.66041 10.6364 3.74534 10.6364 3.83389V6.50504C10.6364 6.59359 10.5965 6.67852 10.5255 6.74113C10.4545 6.80375 10.3582 6.83893 10.2579 6.83893C10.1575 6.83893 10.0612 6.80375 9.9902 6.74113C9.91922 6.67852 9.87934 6.59359 9.87934 6.50504V4.76879L7.1442 7.7184C7.11071 7.75445 7.06905 7.78392 7.02188 7.80491C6.9747 7.8259 6.92307 7.83795 6.8703 7.84027C6.81754 7.8426 6.7648 7.83516 6.71549 7.81842C6.66618 7.80169 6.62139 7.77604 6.584 7.74311L4.62556 6.01555L1.85787 9.37251C1.79732 9.44042 1.70929 9.48499 1.61228 9.49684C1.51526 9.5087 1.41681 9.48692 1.33762 9.43608C1.25842 9.38524 1.20465 9.30931 1.1876 9.22423C1.17055 9.13915 1.19155 9.05156 1.24619 8.97985L4.2743 5.30703C4.30644 5.26797 4.3478 5.23553 4.39553 5.21193C4.44325 5.18833 4.49621 5.17413 4.55075 5.17033C4.60529 5.16652 4.66012 5.17318 4.71146 5.18987C4.7628 5.20655 4.80942 5.23285 4.84813 5.26696L6.82322 7.00988L9.45919 4.16779H7.22974C7.12935 4.16779 7.03308 4.13261 6.96209 4.06999C6.89111 4.00737 6.85123 3.92245 6.85123 3.83389Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <span className="text">
            {t("Time Spent")}
            {" "}
            /
            {t("Impact")}
          </span>
        </div>
        <div className="content">
          {impactAndTime.map((criteria) => (
            <div className="do-not-break" key={criteria.name}>
              <span className="key">
                {t(criteria.name)}
                :
                {" "}
              </span>
              <span className="value">
                {isNaN(criteria.type)
                  ? t(criteria.type)
                  : formtNumberByLanguage(round(Number(criteria.type), 2))}
              </span>
            </div>
          ))}
        </div>
      </div>
      )}

      {Feasibility?.criteria && (
      <div className="card">
        <div className="title blue do-not-break">
          <div className="icon">
            <i className="bi bi-sliders2" />
          </div>
          <span className="text">{t(Feasibility.name)}</span>
        </div>
        <div className="content">
          {Feasibility.criteria.map((criteria) => (
            <div className="dotted do-not-break" key={criteria.name}>
              <div className="text">
                <span className="key">
                  {t(criteria.name)}
                  :
                  {" "}
                </span>
                <span className="value">{t(criteria.type)}</span>
                {criteria.comment && (
                <div className="comment">{t(criteria.comment)}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
      {ITReview?.criteria && (
      <div className="card">
        <div className="title red do-not-break">
          <div className="icon">
            <i className="bi bi-pencil-square" />
          </div>
          <span className="text">{t(ITReview.name)}</span>
        </div>
        <div className="content">
          {ITReview.criteria.map((criteria) => (["Yes", "No"].includes(criteria.type) ? (
            <div
              className={
                      criteria.type === "Yes"
                        ? "on do-not-break"
                        : "off do-not-break"
                    }
              key={criteria.name}
            >
              <div className="text text-dark">
                <span>{t(criteria.name)}</span>
                {criteria.comment && (
                <div className="comment">{t(criteria.comment)}</div>
                )}
              </div>
            </div>
          ) : (
            <div className="dotted do-not-break" key={criteria.name}>
              <div className="text">
                <span className="key">
                  {t(criteria.name)}
                  :
                  {" "}
                </span>
                <span className="value">{t(criteria.type)}</span>
                {criteria.comment && (
                <div className="comment">{t(criteria.comment)}</div>
                )}
              </div>
            </div>
          )))}
        </div>
      </div>
      )}

      {results && (
      <div id="result-card" className="card result">
        <div className="title purple">
          <div className="icon">
            <i className="bi bi-clipboard-check-fill" />
          </div>
          <span className="text">{t(results.name)}</span>
        </div>
        <div className="content results">
          <div className="graph-container">
            <div className="title">{t("Impact/Feasibility Matrix")}</div>
            <div className="graph-dot-title">
              {t("Feasibility")}
              {" "}
              /
              {t("Impact")}
            </div>
            <div className="right-numbers-title">{t("Impact")}</div>
            <div className="graph-frame">
              <div className="right-numbers">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
              </div>
              <div className="graph-inner">
                <div className="colored-areas">
                  <div className="overflow-hidden">
                    <div className="bar-container orange">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="bar-container green">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="bar-container red">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="bar-container orange">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                </div>
                <div className="dividers" />
                <div
                  className="dot"
                  style={{ top: `${graph.y}%`, left: `${graph.x}%` }}
                />
              </div>
              <div className="bottom-numbers">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
              </div>
            </div>
            <div className="left-numbers-title">{t("Feasibility")}</div>
            <div className="notes-container">
              <div className="note">
                <div className="area">
                  <div className="overflow-hidden w-100 h-100">
                    <div className="bar-container red">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                </div>
                <div className="text">
                  {t("Impact and feasibility are limited")}
                  <br />
                  {t("The process is likely to be reprioritized")}
                </div>
              </div>
              <div className="note">
                <div className="area">
                  <div className="overflow-hidden w-100 h-100">
                    <div className="bar-container orange">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                </div>
                <div className="text">
                  {t(
                    "Here either the impact or the feasibility is relevant",
                  )}
                  <br />
                  {t(
                    "Depending on the case and arbitration, the process could be deprioritized",
                  )}
                </div>
              </div>
              <div className="note">
                <div className="area">
                  <div className="overflow-hidden w-100 h-100">
                    <div className="bar-container green">
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                      <span className="bar" />
                    </div>
                  </div>
                </div>
                <div className="text">
                  {t("Both impact and feasibility show some great signs")}
                  <br />
                  {t("The process is likely to be prioritized")}
                </div>
              </div>
            </div>
          </div>
          <div className="boxes">
            <div className="box">
              <div className="title">
                <div className="fixBorder">
                  <div className="icon green">
                    <i className="bi bi-coin" />
                  </div>
                </div>
                <div className="text">
                  {formatStr(t("Saving (per year)"))}
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("FTE Gains")}
                  <span className="value">{formtNumberByLanguage(results.values.fteGains)}</span>
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("FTE Costs")}
                  <span className="value">{formtNumberByLanguage(results.values.fteCosts)}</span>
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("Total in man-days")}
                  <span className="value">
                    {formtNumberByLanguage(results.values.totalSaving)}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="title">
                <div className="fixBorder">
                  <div className="icon blue">
                    <i className="bi bi-person-workspace" />
                  </div>
                </div>
                <div className="text">
                  {formatStr(t("Developement costs"))}
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("Developer")}
                  <span className="value">
                    {formtNumberByLanguage(results.values.developerCosts)}
                    {" "}
                  </span>
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("Business analyst")}
                  <span className="value">
                    {formtNumberByLanguage(results.values.baCosts)}
                    {" "}
                  </span>
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("Total in man-days")}
                  <span className="value">
                    {formtNumberByLanguage(results.values.totalDevelopementCosts)}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="title">
                <div className="fixBorder">
                  <div className="icon orange">
                    <i className="bi bi-gear-fill" />
                  </div>
                </div>
                <div className="text">
                  {formatStr(t("Running costs (per year)"))}
                </div>
              </div>
              <div className="text">
                <div className="key">
                  {t("Running costs in man-days")}
                  <span className="value">
                    {formtNumberByLanguage(results.values.runningCosts)}
                  </span>
                </div>
              </div>
              <div className="text" id="bottom">
                <div className="key">
                  {t("Payback period (year)")}
                  <span className="value">{formtNumberByLanguage(results.values.payback)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};
export default PdfTemplate;

const formatStr = (str) => {
  let formatedStr = "";

  if (str.includes("(") && str.includes(")")) {
    const smallStr = str.slice(str.indexOf("(") + 1, str.lastIndexOf(")"));
    const longStr = str.split("(")[0];
    formatedStr = (
      <>
        {longStr}
        <span className="smaller">
          (
          {smallStr}
          )
        </span>
      </>
    );
  }
  return formatedStr;
};
