import { isSRQueuesDisabled } from "util/constants/PermitedFeature";

const permissionsSubMenus = {
    srQueues: "SR Queues",
    tags: "Tags Module",
    userAdministration: "User Administration",
}
export const defaultPermissions = [
    {
        index: 0,
        name: "Smart Services",
        enabled: false,
        modules: [
            {
                index: 1,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
        ],
    },
    {
        index: 1,
        name: "Smart Robot",
        enabled: true,
        modules: [
            {
                index: 0,
                name: "Dashboard",
                enabled: true,
                actions: [],
            },
            {
                index: 14,
                name: "Monitoring Dashboard",
                enabled: true,
                actions: [
                    { index: 1, name: "Business exceptions", enabled: false },
                    { index: 2, name: "System exceptions", enabled: false },
                    { index: 3, name: "All time stats", enabled: false },
                    { index: 4, name: "FTEs saved", enabled: false },
                ],
            },
            {
                index: 1,
                name: "My Processes",
                enabled: true,
                actions: [
                    { index: 1, name: "Start process", enabled: false },
                    { index: 2, name: "Schedule process", enabled: false },
                    { index: 3, name: "KPI parameters", enabled: false },
                    { index: 5, name: "Edit process details", enabled: false },
                    { index: 6, name: "Enable/Disable process", enabled: false },
                    { index: 7, name: "SLA", enabled: false },
                    { index: 8, name: "Process data mapping", enabled: false },
                    { index: 9, name: "View external documentation", enabled: false },
                    { index: 10, name: "Manage external documentation", enabled: false },
                ],
            },
            {
                index: 2,
                name: "Executions",
                label: "Executions",
                subMenu: "Executions",
                enabled: true,
                actions: [
                    {
                        index: 0,
                        name: "Delete process exceptions",
                        enabled: false,
                    },
                ],
            },
            {
                index: 3,
                name: "Process Item",
                label: "Process Item",
                subMenu: "Process Item",
                enabled: true,
                actions: [
                    {
                        index: 1,
                        name: "Retry item exceptions",
                        enabled: false,
                        parentOf: "Retry item exceptions with changed data",
                    },
                    { index: 2, name: "Workflow", enabled: true },
                    {
                        index: 3,
                        name: "Retry item exceptions with changed data",
                        enabled: false,
                        dependsOn: "Retry item exceptions",
                    },
                    {
                        index: 4,
                        name: "Setup Automatic Assignment",
                        enabled: false,
                    },
                ],
            },
            {
                index: 4,
                name: "Analytics",
                enabled: true,
                actions: [],
            },
            {
                index: 5,
                name: "Resource Planner",
                enabled: false,
                actions: [{ index: 0, name: "Risk management", enabled: false }],
            },
            {
                index: 6,
                name: "Alerts Management",
                label: "Default Alerts",
                subMenu: "Default Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add default alert", enabled: true },
                    { index: 1, name: "Edit default alerts", enabled: true },
                    { index: 2, name: "Delete default alert", enabled: false },
                ],
            },
            {
                index: 7,
                name: "Alerts Management",
                label: "Custom Alerts",
                subMenu: "Custom Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add custom alert", enabled: true },
                    { index: 1, name: "Edit custom alerts", enabled: true },
                    { index: 2, name: "Delete custom alert", enabled: false },
                ],
            },
            {
                index: 8,
                name: "Process Assessment",
                enabled: true,
                actions: [
                    { index: 1, name: "Add opportunity", enabled: true },
                    { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
                    { index: 3, name: "Archive / Backlog", enabled: false },
                    { index: 4, name: "Delete opportunity", enabled: false },
                ],
            },
            {
                index: 9,
                name: "Financial Parameters",
                label: "Financial Parameters",
                subMenu: "Financial Parameters",
                enabled: false,
                actions: [
                    { index: 0, name: "Add cost", enabled: false },
                    { index: 1, name: "Edit cost", enabled: false },
                    { index: 2, name: "Delete cost", enabled: false },
                ],
            },
            {
                index: 11,
                name: "Tags Module",
                label: "Tags Module",
                subMenu: permissionsSubMenus.tags,
                enabled: true,
                actions: [
                    { index: 0, name: "Add tag", enabled: true },
                    { index: 1, name: "Edit tag", enabled: false },
                    { index: 2, name: "Delete tag", enabled: false },
                    { index: 3, name: "Archive", enabled: false },
                ],
            },
            ...!isSRQueuesDisabled ? [
                {
                    index: 13,
                    name: "SR Queues",
                    label: "SR Queues",
                    subMenu: permissionsSubMenus.srQueues,
                    enabled: false,
                    actions: [
                        { index: 0, name: "Add SR Queue", enabled: false },
                        { index: 1, name: "Edit SR Queue", enabled: false },
                        { index: 2, name: "Delete SR Queue", enabled: false },
                    ],
                },
            ] : [],
            {
                index: 15,
                name: "Orchestrators",
                label: "Orchestrators",
                enabled: false,
                actions: [
                    { index: 0, name: "Add Orchestrator", enabled: false },
                    { index: 1, name: "Edit Orchestrator", enabled: false },
                ],
            },
            {
                index: 16,
                name: "Resources",
                label: "Resources",
                enabled: false,
                actions: [
                    { index: 0, name: "Edit Resources", enabled: false },
                ],
            },
        ],
    },
];
export const defaultCOEPermissions = [
    {
        index: 0,
        name: "Smart Services",
        enabled: false,
        modules: [
            {
                index: 1,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
        ],
    },
    {
        index: 1,
        name: "Smart Robot",
        enabled: true,
        modules: [
            {
                index: 0,
                name: "Dashboard",
                enabled: true,
                actions: [],
            },
            {
                index: 14,
                name: "Monitoring Dashboard",
                enabled: true,
                actions: [
                    { index: 1, name: "Business exceptions", enabled: true },
                    { index: 2, name: "System exceptions", enabled: true },
                    { index: 3, name: "All time stats", enabled: true },
                    { index: 4, name: "FTEs saved", enabled: true },
                ],
            },
            {
                index: 1,
                name: "My Processes",
                enabled: true,
                actions: [
                    { index: 1, name: "Start process", enabled: true },
                    { index: 2, name: "Schedule process", enabled: true },
                    { index: 3, name: "KPI parameters", enabled: true },
                    { index: 5, name: "Edit process details", enabled: true },
                    { index: 6, name: "Enable/Disable process", enabled: true },
                    { index: 7, name: "SLA", enabled: true },
                    { index: 8, name: "Process data mapping", enabled: true },
                ],
            },
            {
                index: 2,
                name: "Executions",
                label: "Executions",
                subMenu: "Executions",
                enabled: true,
                actions: [
                    {
                        index: 0,
                        name: "Delete process exceptions",
                        enabled: true,
                    },
                ],
            },
            {
                index: 3,
                name: "Process Item",
                label: "Process Item",
                subMenu: "Process Item",
                enabled: true,
                actions: [
                    {
                        index: 1,
                        name: "Retry item exceptions",
                        enabled: true,
                        parentOf: "Retry item exceptions with changed data",
                    },
                    { index: 2, name: "Workflow", enabled: true },
                    {
                        index: 3,
                        name: "Retry item exceptions with changed data",
                        enabled: false,
                        dependsOn: "Retry item exceptions",
                    },
                ],
            },
            {
                index: 4,
                name: "Analytics",
                enabled: true,
                actions: [],
            },
            {
                index: 5,
                name: "Resource Planner",
                enabled: true,
                actions: [{ index: 0, name: "Risk management", enabled: true }],
            },
            {
                index: 6,
                name: "Alerts Management",
                label: "Default Alerts",
                subMenu: "Default Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add default alert", enabled: true },
                    { index: 1, name: "Edit default alerts", enabled: true },
                    { index: 2, name: "Delete default alert", enabled: true },
                ],
            },
            {
                index: 7,
                name: "Alerts Management",
                label: "Custom Alerts",
                subMenu: "Custom Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add custom alert", enabled: true },
                    { index: 1, name: "Edit custom alerts", enabled: true },
                    { index: 2, name: "Delete custom alert", enabled: true },
                ],
            },
            {
                index: 8,
                name: "Process Assessment",
                enabled: true,
                actions: [
                    { index: 1, name: "Add opportunity", enabled: true },
                    { index: 2, name: "Accept / Reject / Reconsider", enabled: true },
                    { index: 3, name: "Archive / Backlog", enabled: true },
                    { index: 4, name: "Delete opportunity", enabled: true },
                ],
            },
            {
                index: 9,
                name: "Financial Parameters",
                label: "Financial Parameters",
                subMenu: "Financial Parameters",
                enabled: true,
                actions: [
                    { index: 0, name: "Add cost", enabled: true },
                    { index: 1, name: "Edit cost", enabled: true },
                    { index: 2, name: "Delete cost", enabled: true },
                ],
            },
            {
                index: 11,
                name: "Tags Module",
                label: "Tags Module",
                subMenu: permissionsSubMenus.tags,
                enabled: true,
                actions: [
                    { index: 0, name: "Add tag", enabled: true },
                    { index: 1, name: "Edit tag", enabled: true },
                    { index: 2, name: "Delete tag", enabled: true },
                    { index: 3, name: "Archive", enabled: true },
                ],
            },
            ...!isSRQueuesDisabled ? [
                {
                    index: 13,
                    name: "SR Queues",
                    label: "SR Queues",
                    subMenu: permissionsSubMenus.srQueues,
                    enabled: true,
                    actions: [
                        { index: 0, name: "Add SR Queue", enabled: true },
                        { index: 1, name: "Edit SR Queue", enabled: true },
                        { index: 2, name: "Delete SR Queue", enabled: true },
                    ],
                },
            ] : [],
            {
                index: 15,
                name: "Orchestrators",
                label: "Orchestrators",
                enabled: true,
                actions: [
                    { index: 0, name: "Add Orchestrator", enabled: false },
                    { index: 1, name: "Edit Orchestrator", enabled: false },
                ],
            },
            {
                index: 16,
                name: "Resources",
                label: "Resources",
                enabled: true,
                actions: [
                    { index: 0, name: "Edit Resources", enabled: false },
                ],
            },
        ],
    },
];
export const defaultSMEPermissions = [
    {
        index: 0,
        name: "Smart Services",
        enabled: false,
        modules: [
            {
                index: 1,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
        ],
    },
    {
        index: 1,
        name: "Smart Robot",
        enabled: true,
        modules: [
            {
                index: 0,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
            {
                index: 14,
                name: "Monitoring Dashboard",
                enabled: true,
                actions: [
                    { index: 1, name: "Business exceptions", enabled: true },
                    { index: 2, name: "System exceptions", enabled: false },
                    { index: 3, name: "All time stats", enabled: false },
                    { index: 4, name: "FTEs saved", enabled: false },
                ],
            },
            {
                index: 1,
                name: "My Processes",
                enabled: false,
                actions: [
                    { index: 1, name: "Start process", enabled: false },
                    { index: 2, name: "Schedule process", enabled: false },
                    { index: 3, name: "KPI parameters", enabled: false },
                    { index: 5, name: "Edit process details", enabled: false },
                    { index: 6, name: "Enable/Disable process", enabled: false },
                    { index: 7, name: "SLA", enabled: false },
                    { index: 8, name: "Process data mapping", enabled: false },
                ],
            },
            {
                index: 2,
                name: "Executions",
                label: "Executions",
                subMenu: "Executions",
                enabled: true,
                actions: [
                    {
                        index: 0,
                        name: "Delete process exceptions",
                        enabled: true,
                    },
                ],
            },
            {
                index: 3,
                name: "Process Item",
                label: "Process Item",
                subMenu: "Process Item",
                enabled: true,
                actions: [
                    {
                        index: 1,
                        name: "Retry item exceptions",
                        enabled: true,
                        parentOf: "Retry item exceptions with changed data",
                    },
                    { index: 2, name: "Workflow", enabled: true },
                    {
                        index: 3,
                        name: "Retry item exceptions with changed data",
                        enabled: false,
                        dependsOn: "Retry item exceptions",
                    },
                ],
            },
            {
                index: 4,
                name: "Analytics",
                enabled: true,
                actions: [],
            },
            {
                index: 5,
                name: "Resource Planner",
                enabled: false,
                actions: [{ index: 0, name: "Risk management", enabled: false }],
            },
            {
                index: 6,
                name: "Alerts Management",
                label: "Default Alerts",
                subMenu: "Default Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add default alert", enabled: true },
                    { index: 1, name: "Edit default alerts", enabled: true },
                    { index: 2, name: "Delete default alert", enabled: false },
                ],
            },
            {
                index: 7,
                name: "Alerts Management",
                label: "Custom Alerts",
                subMenu: "Custom Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add custom alert", enabled: true },
                    { index: 1, name: "Edit custom alerts", enabled: true },
                    { index: 2, name: "Delete custom alert", enabled: false },
                ],
            },
            {
                index: 8,
                name: "Process Assessment",
                enabled: true,
                actions: [
                    { index: 1, name: "Add opportunity", enabled: true },
                    { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
                    { index: 3, name: "Archive / Backlog", enabled: false },
                    { index: 4, name: "Delete opportunity", enabled: false },
                ],
            },
            {
                index: 9,
                name: "Financial Parameters",
                label: "Financial Parameters",
                subMenu: "Financial Parameters",
                enabled: false,
                actions: [
                    { index: 0, name: "Add cost", enabled: false },
                    { index: 1, name: "Edit cost", enabled: false },
                    { index: 2, name: "Delete cost", enabled: false },
                ],
            },
            {
                index: 11,
                name: "Tags Module",
                label: "Tags Module",
                subMenu: permissionsSubMenus.tags,
                enabled: true,
                actions: [
                    { index: 0, name: "Add tag", enabled: true },
                    { index: 1, name: "Edit tag", enabled: true },
                    { index: 2, name: "Delete tag", enabled: false },
                    { index: 3, name: "Archive", enabled: false },
                ],
            },
            ...!isSRQueuesDisabled ? [
                {
                    index: 13,
                    name: "SR Queues",
                    label: "SR Queues",
                    subMenu: permissionsSubMenus.srQueues,
                    enabled: false,
                    actions: [
                        { index: 0, name: "Add SR Queue", enabled: false },
                        { index: 1, name: "Edit SR Queue", enabled: false },
                        { index: 2, name: "Delete SR Queue", enabled: false },
                    ],
                },
            ] : [],
            {
                    index: 15,
                    name: "Orchestrators",
                    label: "Orchestrators",
                    enabled: false,
                    actions: [
                        { index: 0, name: "Add Orchestrator", enabled: false },
                        { index: 1, name: "Edit Orchestrator", enabled: false },
                    ],
                },
            {
                    index: 16,
                    name: "Resources",
                    label: "Resources",
                    enabled: false,
                    actions: [
                        { index: 0, name: "Edit Resources", enabled: false },
                    ],
                },
        ],
    },
];
export const defaultMMGTPermissions = [
    {
        index: 0,
        name: "Smart Services",
        enabled: false,
        modules: [
            {
                index: 1,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
        ],
    },
    {
        index: 1,
        name: "Smart Robot",
        enabled: true,
        modules: [
            {
                index: 0,
                name: "Dashboard",
                enabled: false,
                actions: [],
            },
            {
                index: 14,
                name: "Monitoring Dashboard",
                enabled: true,
                actions: [
                    { index: 1, name: "Business exceptions", enabled: false },
                    { index: 2, name: "System exceptions", enabled: false },
                    { index: 3, name: "All time stats", enabled: true },
                    { index: 4, name: "FTEs saved", enabled: true },
                ],
            },
            {
                index: 1,
                name: "My Processes",
                enabled: false,
                actions: [
                    { index: 1, name: "Start process", enabled: false },
                    { index: 2, name: "Schedule process", enabled: false },
                    { index: 3, name: "KPI parameters", enabled: false },
                    { index: 5, name: "Edit process details", enabled: false },
                    { index: 6, name: "Enable/Disable process", enabled: false },
                    { index: 7, name: "SLA", enabled: false },
                    { index: 8, name: "Process data mapping", enabled: false },
                ],
            },
            {
                index: 2,
                name: "Executions",
                label: "Executions",
                subMenu: "Executions",
                enabled: true,
                actions: [
                    {
                        index: 0,
                        name: "Delete process exceptions",
                        enabled: false,
                    },
                ],
            },
            {
                index: 3,
                name: "Process Item",
                label: "Process Item",
                subMenu: "Process Item",
                enabled: true,
                actions: [
                    {
                        index: 1,
                        name: "Retry item exceptions",
                        enabled: false,
                        parentOf: "Retry item exceptions with changed data",
                    },
                    { index: 2, name: "Workflow", enabled: false },
                    {
                        index: 3,
                        name: "Retry item exceptions with changed data",
                        enabled: false,
                        dependsOn: "Retry item exceptions",
                    },
                ],
            },
            {
                index: 4,
                name: "Analytics",
                enabled: true,
                actions: [],
            },
            {
                index: 5,
                name: "Resource Planner",
                enabled: false,
                actions: [{ index: 0, name: "Risk management", enabled: false }],
            },
            {
                index: 6,
                name: "Alerts Management",
                label: "Default Alerts",
                subMenu: "Default Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add default alert", enabled: true },
                    { index: 1, name: "Edit default alerts", enabled: true },
                    { index: 2, name: "Delete default alert", enabled: false },
                ],
            },
            {
                index: 7,
                name: "Alerts Management",
                label: "Custom Alerts",
                subMenu: "Custom Alerts",
                enabled: true,
                actions: [
                    { index: 0, name: "Add custom alert", enabled: true },
                    { index: 1, name: "Edit custom alerts", enabled: true },
                    { index: 2, name: "Delete custom alert", enabled: false },
                ],
            },
            {
                index: 8,
                name: "Process Assessment",
                enabled: true,
                actions: [
                    { index: 1, name: "Add opportunity", enabled: true },
                    { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
                    { index: 3, name: "Archive / Backlog", enabled: false },
                    { index: 4, name: "Delete opportunity", enabled: false },
                ],
            },
            {
                index: 9,
                name: "Financial Parameters",
                label: "Financial Parameters",
                subMenu: "Financial Parameters",
                enabled: true,
                actions: [
                    { index: 0, name: "Add cost", enabled: false },
                    { index: 1, name: "Edit cost", enabled: false },
                    { index: 2, name: "Delete cost", enabled: false },
                ],
            },
            {
                index: 11,
                name: "Tags Module",
                label: "Tags Module",
                subMenu: permissionsSubMenus.tags,
                enabled: true,
                actions: [
                    { index: 0, name: "Add tag", enabled: true },
                    { index: 1, name: "Edit tag", enabled: false },
                    { index: 2, name: "Delete tag", enabled: false },
                    { index: 3, name: "Archive", enabled: false },
                ],
            },
            ...!isSRQueuesDisabled ? [
                {
                    index: 13,
                    name: "SR Queues",
                    label: "SR Queues",
                    subMenu: permissionsSubMenus.srQueues,
                    enabled: false,
                    actions: [
                        { index: 0, name: "Add SR Queue", enabled: false },
                        { index: 1, name: "Edit SR Queue", enabled: false },
                        { index: 2, name: "Delete SR Queue", enabled: false },
                    ],
                },
            ] : [],
            {
                index: 15,
                name: "Orchestrators",
                label: "Orchestrators",
                enabled: false,
                actions: [
                    { index: 0, name: "Add Orchestrator", enabled: false },
                    { index: 1, name: "Edit Orchestrator", enabled: false },
                ],
            },
            {
                index: 16,
                name: "Resources",
                label: "Resources",
                enabled: false,
                actions: [
                    { index: 0, name: "Edit Resources", enabled: false },
                ],
            },
        ],
    },
];
export const resolveAdminPermissions = (permissions) => {
    const permissionsCopy = [...permissions];
    const { modules } = permissionsCopy[1];
    const targetModules = [permissionsSubMenus.srQueues, permissionsSubMenus.tags, permissionsSubMenus.userAdministration];
    targetModules.forEach((target) => {
        const moduleIndex = modules.findIndex((item) => item.subMenu === target);
        if (moduleIndex > -1)
        {
            modules[moduleIndex].enabled = true;
            modules[moduleIndex].actions = modules[moduleIndex].actions.map((obj) => ({ ...obj, enabled: true }));
        }
    })
    return permissionsCopy;
}

export const defaultRoles = (t) => [
    {
 id: -3, name: `COE (${t("user.permissions.default")})`, permissions: JSON.stringify(defaultCOEPermissions), isFixed: true
},
    {
 id: -4, name: `SME (${t("user.permissions.default")})`, permissions: JSON.stringify(defaultSMEPermissions), isFixed: true
},
    {
 id: -5, name: `MMGT (${t("user.permissions.default")})`, permissions: JSON.stringify(defaultMMGTPermissions), isFixed: true
},
]
