import React from "react";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import IconButton from "@material-ui/core/IconButton";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatDatePickerByLanguage } from "util";
import CustomTableRow from "components/TableComponents/CustomTableRow";
import CustomTableCell from "components/TableComponents/CustomTableCell";
import ProcessIconName from "components/TableComponents/ProcessIconName";
import useStyles from "../style";

export default function AutomationRow({ row, handleConfigClick }) {
  const { t } = useTranslation();
  const classes = useStyles()();
  return (
    <CustomTableRow>
      <CustomTableCell>
        <ProcessIconName imgSrc={null} processName={row?.displayName} />
      </CustomTableCell>
      <CustomTableCell text={t(row?.orchestrator?.name)} />
      <CustomTableCell
        text={format(
          parseISO(row?.createdAt),
          `${formatDatePickerByLanguage(false)} HH:mm`
        )}
      />
      <CustomTableCell
        text={
          <IconButton size="small" onClick={() => handleConfigClick(row)}>
            <SettingsRoundedIcon className={classes.settingsIcon} />
          </IconButton>
        }
        width={10}
      />
    </CustomTableRow>
  );
}
