import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CustomTooltip from "../CustomTooltip";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const AdditionalInfoTooltip = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <CustomTooltip
            title={
              <Grid container direction="column" spacing={1}>
                <Grid item className={classes.moreInfoTooltipTitle}>
                  {t("Additional information")}
                </Grid>
                {data?.map((info, i) => (
                  <Grid item key={`item-${i}`}>
                    <Typography variant="span" className={classes.itemDataName}>
                      {info?.name}
                      {" "}
                    </Typography>
                    <Typography variant="span" className={classes.itemDataValue}>
                      {": "}
                      {info?.value}
                    </Typography>
                  </Grid>
                    ))}
              </Grid>
            }
            customBgColor
      >
        <Box display="flex" alignItems="center">
          <InfoOutlinedIcon className={classes.infoIcon} />
        </Box>
      </CustomTooltip>
    )
}
export default AdditionalInfoTooltip;
