import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    errorLink: {
        fontStyle: "italic",
        fontSize: "large",
        marginTop: 10
    },
    errorText: {
        fontSize: "4.5rem",
        fontWeight: 600,
        transform: "translate(-100%, 20%)"
    },
    errorImage: {
        width: 650
    }
}));
