import React from "react";
import { SvgIcon } from "@material-ui/core";

// Custom icons should start with Sr to differentiate them from Material UI icons

export const SrWorkflowIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M8.9 21.15q-1.75 0-2.988-1.237-1.237-1.238-1.237-3.013V8.925q-.875-.35-1.437-1.138Q2.675 7 2.675 6q0-1.3.913-2.225.912-.925 2.237-.925 1.3 0 2.213.925Q8.95 4.7 8.95 6q0 1-.562 1.787-.563.788-1.438 1.138V16.9q0 .825.575 1.388.575.562 1.375.562.825 0 1.388-.562.562-.563.562-1.388V7.1q0-1.775 1.238-3.013Q13.325 2.85 15.1 2.85q1.75 0 2.988 1.237 1.237 1.238 1.237 3.013v7.975q.875.35 1.438 1.137.562.788.562 1.788 0 1.3-.912 2.225-.913.925-2.213.925-1.325 0-2.237-.925Q15.05 19.3 15.05 18q0-1 .563-1.788.562-.787 1.437-1.137V7.1q0-.825-.575-1.388Q15.9 5.15 15.1 5.15q-.825 0-1.387.562-.563.563-.563 1.363V16.9q0 1.775-1.237 3.013Q10.675 21.15 8.9 21.15ZM5.825 6.95q.4 0 .675-.275T6.775 6q0-.4-.275-.675t-.675-.275q-.425 0-.7.275Q4.85 5.6 4.85 6t.275.675q.275.275.7.275Zm12.375 12q.4 0 .675-.275T19.15 18q0-.4-.275-.675t-.675-.275q-.425 0-.7.275-.275.275-.275.675t.275.675q.275.275.7.275ZM5.825 6Zm12.35 12Z"
    />
  </SvgIcon>
);

export const SrLicensesIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M5.9 21.2q-1.725 0-2.962-1.225Q1.7 18.75 1.7 17t1.238-2.975Q4.175 12.8 5.9 12.8q1.75 0 2.975 1.225T10.1 17q0 1.75-1.225 2.975T5.9 21.2Zm12.2 0q-1.75 0-2.975-1.225T13.9 17q0-1.75 1.225-2.975T18.1 12.8q1.725 0 2.962 1.225Q22.3 15.25 22.3 17t-1.238 2.975Q19.825 21.2 18.1 21.2ZM5.9 18.925q.8 0 1.363-.562.562-.563.562-1.363t-.562-1.363q-.563-.562-1.363-.562-.775 0-1.35.562-.575.563-.575 1.363t.575 1.363q.575.562 1.35.562Zm12.2 0q.775 0 1.35-.562.575-.563.575-1.363t-.575-1.363q-.575-.562-1.35-.562-.8 0-1.362.562-.563.563-.563 1.363t.563 1.363q.562.562 1.362.562Zm-6.1-7.9q-1.75 0-2.975-1.238Q7.8 8.55 7.8 6.825q0-1.75 1.225-2.975T12 2.625q1.75 0 2.975 1.225T16.2 6.825q0 1.725-1.225 2.962Q13.75 11.025 12 11.025Zm0-2.275q.8 0 1.363-.575.562-.575.562-1.35 0-.8-.562-1.363Q12.8 4.9 12 4.9t-1.362.562q-.563.563-.563 1.363 0 .775.563 1.35.562.575 1.362.575Zm0-1.925ZM18.1 17ZM5.9 17Z"
    />
  </SvgIcon>
);
