import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { HasPermission } from "../../../../../components/HasPermission";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { isEqual } from "lodash";
import CustomAutoComplete, { CustomTextField } from "../../../../../components/FormFields/CustomAutoComplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import CustomButton from "../../../../../components/CustomButton";
import NoDataMenu from "../../../../../components/NoData/NoDataMenu";
import useStyles from "../../ModalService/style";

function Field({
 content, field, label, onSubmit, setProcess, setIsDataEdited, fieldList, isArray = false, isEditable = true, rules
}) {
    const classes = useStyles();
    const [showInput, setShowInput] = useState(false);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        control
    } = useForm({
        defaultValues: {
            [field]: content,
        },
    });
    const submit = (data) => {
        setIsLoading(true)
        onSubmit(data).then((res) => {
            setIsLoading(false);
            handleEdit();
            setProcess(res.data);
        })
    }

    const handleEdit = () => {
        setIsDataEdited(false);
        setValue(field, content)
        setShowInput((prevState) => !prevState);
    }

    const getFieldContent = () => {
        let result = "---";
        if (content && content !== "") {
          if (isArray) {
            if (content.length > 0) result = content?.map(({ name }) => name)?.join(", ");
          } else result = content;
        }
        return result
    }

    useEffect(() => {
        if (content) setValue(field, content)
        return () => {
            reset()
        }
    }, [content])

    useEffect(() => {
        if (isEditable) setIsDataEdited(!isEqual(content, watch(field)));
    }, [watch(field)]);

    return (
      <Grid className={classes.field} container item>
        <Grid item xs={3}>
          <Typography color="primary" variant="subtitle1">{t(label)}</Typography>
        </Grid>
        {!showInput && (
        <Grid item xs={9}>
          <Grid container>
            <Grid item className={isEditable && classes.fieldContainer}>
              <Typography className={classes.fieldContent} component="span" color="primary" variant="subtitle1">
                {getFieldContent()}
              </Typography>
              {isEditable && (
                <HasPermission name="Edit process details">
                  <Tooltip title={t("process.modify")}>
                    <ButtonBase id="editTooltip" className={classes.editFieldIcon}>
                      <IconButton className={classes.pencil} onClick={handleEdit} size="small">
                        <EditIcon className={classes.pencilSize} fontSize="small" />
                      </IconButton>
                    </ButtonBase>
                  </Tooltip>
                </HasPermission>
                )}
            </Grid>
            <Grid item />
          </Grid>
        </Grid>
            )}
        {showInput && (
        <Grid item xs={9}>
          <form
              onSubmit={handleSubmit(submit)}
              className={classes.root}
              noValidate
              autoComplete="off"
          >
            <Grid container>
              <Grid item xs={12}>
                { isArray ? (
                  <Controller
                          name={field}
                          control={control}
                          rules={rules}
                          render={({ field: target }) => (
                            <CustomAutoComplete
                          multiple
                          options={fieldList || []}
                          optionLabel="name"
                          value={watch(field)}
                          noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                          onChange={target.onChange}
                      />
                      )}
                      />
                  ) : (
                    <CustomTextField
                          multiline
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          className={classes.editTexAreaField}
                          {...register(field)}
                          id={field}
                          disabled={isLoading}
                      />
                  )}
              </Grid>

              <Grid item className={classes.cardActions}>
                <CustomButton
                    onClick={handleEdit}
                    size="small"
                    className={classes.cancelButton}
                    startIcon={<CancelIcon />}
                    disabled={isLoading}
                    variant="outlined"
                >
                  {t("Cancel")}
                </CustomButton>
                <CustomButton
                    view="primary"
                    size="small"
                    type="submit"
                    className={classes.saveButton}
                    startIcon={<SaveIcon />}
                    disabled={isLoading}
                >
                  {t("Save")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
            )}
      </Grid>
    )
}
export default Field;
