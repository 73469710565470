import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../../../components/CustomDialog";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import useStyles from "../../../style.js";

function CreatePresetDialog({ open, onClose, onSubmit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [presetName, setPresetName] = React.useState("");
  const [errorHelperText, setErrorHelperText] = React.useState("");

  const handleSubmit = () => {
    if (presetName.length === 0) {
      setErrorHelperText(t("custom.kpi.formControl.required"));
      return
    }
    onSubmit(presetName);
    setPresetName("");
  }

  const onTextChange = (event) => {
    setPresetName(event.currentTarget.value)
    if (event.currentTarget.value.length !== 0) setErrorHelperText("")
  }

  const onCancel = () => {
    setErrorHelperText("")
    onClose()
  }

  return (
    <CustomDialog
        title={t("analytics.preset.dialog.label")}
        open={open}
        onClose={onCancel}
        disableBackdropClick
        fullWidth
        actionElements={[
            {
              label: t("Cancel"),
              onClick: onCancel,
              color: "primary",
            },
            {
              label: t("analytics.preset.dialog.field.submit"),
              onClick: handleSubmit,
              view: "primary",
            }
            ]}
        classes={{
          paper: classes.createPresetDialog,
        }}
    >
      <CustomTextField
        error={errorHelperText.length !== 0}
        label={t("analytics.preset.dialog.field.label")}
        fullWidth
        onChange={(event) => onTextChange(event)}
        required
        helperText={errorHelperText}
      />
    </CustomDialog>
  );
}

export default (CreatePresetDialog);
