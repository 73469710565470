import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "pages/newDashboard/style";

export default function ChartSectionHeader({ title, dataList, isLoading }) {
  const classes = useStyles()();
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography component="div" className={classes.sectionTitle}>
        {title}
      </Typography>
      <Box>
        {dataList?.map(({
 label, value, isSecondary, hidden
}, index) => (
          hidden ? null : (
            <Box
                  display="grid"
                  gridTemplateColumns="1FR auto"
                  gridColumnGap="10px"
                  className={
                      index % 2 === 0 && !isSecondary
                          ? classes.sectionFirstItem
                          : classes.sectionSecondItem
                  }
              >
              <Typography component="div">{`${label}:`}</Typography>
              <Typography component="div">
                {isLoading ? (
                  <CircularLoader
                              justifyContent="flex-end"
                              height={15}
                              size={8}
                          />
                      ) : (
                          value || "-"
                      )}
              </Typography>
            </Box>
          )
        ))}
      </Box>
    </Box>
  );
}
