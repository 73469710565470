import { get } from "lodash";
import { truncateString } from "util";
import { ORCHESTRATOR_TYPE } from "../constants";

// eslint-disable-next-line import/prefer-default-export
export const renderValue = (selected, group, key) => truncateString(selected
    .map((id) => get(group?.find((e) => e.id === id), key))?.join(","), 30);

export const isDataMappingRetryDisabled = (orchestratorType) => orchestratorType?.toLowerCase() === ORCHESTRATOR_TYPE.UIPATH.toLowerCase();
export const isSchedulableDisabled = (orchestratorType) => orchestratorType?.toLowerCase() === ORCHESTRATOR_TYPE.UIPATH.toLowerCase();
export const canBeStartedManually = (orchestratorType) => orchestratorType?.toLowerCase() === ORCHESTRATOR_TYPE.UIPATH.toLowerCase();
export const cannotBeAssignToQueue = (orchestratorType) => orchestratorType?.toLowerCase() === ORCHESTRATOR_TYPE.UIPATH.toLowerCase();
export const cannotBeStopped = (orchestratorType) => orchestratorType?.toLowerCase() === ORCHESTRATOR_TYPE.UIPATH.toLowerCase();
