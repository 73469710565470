import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/core";

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul li button": {
    backgroundColor: theme.palette.info.main,
    border: "none",
  },
  "& .MuiPagination-ul li .MuiSvgIcon-root": {
    color: theme.palette.background.default,
  },
  "& .MuiPagination-ul li:not(:first-child):not(:last-child)": {
    display: "none",
  },
}));

export default function PrevNext({
  count,
  onNavigate,
  position,
}) {
  const handleNavigation = (_, p) => onNavigate(p);

  return (
    <StyledPagination
      page={position}
      count={count}
      onChange={handleNavigation}
      variant="outlined"
      shape="rounded"
    />
  );
}
