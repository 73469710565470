import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
    cursor: "pointer",
    background: "white !important",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  rootGlobal: {
    paddingTop: theme.spacing(1),
  },
  tableCellHead: {
    paddingTop: 0,
    paddingBottom: 0,
    color: "gray",
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: "Robot, sans-serif",
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "13px",
    fontFamily: "Robot, Helvetica, Arial, sans-serif",
  },
}));
