import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@redux-requests/react";
import { useTranslation } from "react-i18next";
import { Duration } from "util/constants";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns-tz";
import {
     fetchProcessesForFilters,
    fetchTagsForFilters,
} from "../../../redux/actions/services";
import {
    FETCH_PROCESSES_FOR_FILTERS,
    FETCH_TAG_FOR_FILTERS
} from "../../../redux/constants";
import {
    updateDateFilter,
    updateProcessFilter,
    updateTagsFilter,
} from "../../../redux/slices/dashboardFilterSlice";
import { getDate, formatDatePickerByLanguage } from "util";
import DateFilter from "../../Services/components/DateFilter";
import CustomAutoComplete, {
} from "../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../components/NoData/NoDataMenu";

const onChangeDateFormat = "yyyy/MM/dd HH:mm";

export default function DashboardFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [defaultFromDate, setDefaultFromDate] = useState(null)
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const userProcesses = useQuery({ type: FETCH_PROCESSES_FOR_FILTERS })?.data;
  const tags = useQuery({ type: FETCH_TAG_FOR_FILTERS })?.data;
  const handleChangeProcessesFilter = (value) => {
    dispatch(updateProcessFilter(value?.map(({ id }) => id)));
  };

  const handleChangeTagsFilter = (values) => {
    const tagsIds = values.map(({ id }) => id);
    dispatch(updateTagsFilter(tagsIds));
  };
  useEffect(() => {
    const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1)
  setDefaultFromDate(fromDate)
  }, [])

  const onFromDateChange = (date) => {
    if (format(date, onChangeDateFormat) > filterDashboard.toDate) {
      setInvalidStartDate(true)
    } else {
      setDefaultFromDate(date)
      setInvalidStartDate(false)
      setInvalidEndDate(false)
      dispatch(
        updateDateFilter({
          selectedDurationValue: filterDashboard.selectedDurationValue,
          fromDate: date,
          toDate: filterDashboard.toDate,
        })
      );
    }
  };

  const onToDateChange = (date) => {
    if (format(date, onChangeDateFormat) < format(defaultFromDate, onChangeDateFormat)) {
      setInvalidEndDate(true)
    } else {
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      dispatch(
        updateDateFilter({
          selectedDurationValue: filterDashboard.selectedDurationValue,
          fromDate: defaultFromDate,
          toDate: format(date, onChangeDateFormat),
        })
      );
    }
  };

  const onSelectDurationValue = (value) => {
    const date = value === Duration.CUSTOM
        ? getDate(new Date())
        : null;
    dispatch(
      updateDateFilter({
        selectedDurationValue: value,
        fromDate: date,
        toDate: date,
      })
    );
  };
    useEffect(() => {
        dispatch(fetchTagsForFilters([], filterDashboard.process));
    }, [filterDashboard.process]);
    useEffect(() => {
        dispatch(fetchProcessesForFilters(filterDashboard.tags));
    }, [filterDashboard.tags]);
  useEffect(() => {
    dispatch(fetchProcessesForFilters());
    dispatch(fetchTagsForFilters());
  }, []);
  return (
    <>
      <DateFilter
        onFromDateChange={onFromDateChange}
        fromDate={defaultFromDate}
        onToDateChange={onToDateChange}
        toDate={filterDashboard.toDate}
        selectedDuration={filterDashboard.selectedDurationValue}
        onSelectDurationValue={onSelectDurationValue}
        showCustomDate={
          filterDashboard.selectedDurationValue === Duration.CUSTOM
        }
        dateFormat={formatDatePickerByLanguage()}
        invalidEndDate={invalidEndDate}
        invalidStartDate={invalidStartDate}
        isCustom
        DurationList={Duration.list.slice(2, -1)}
      />
      <Grid item xs={3}>
        <CustomAutoComplete
          multiple
          options={tags || []}
          optionLabel="name"
          value={tags?.filter(({ id }) => filterDashboard.tags?.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
          onChange={handleChangeTagsFilter}
          label={t("Tags")}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomAutoComplete
          multiple
          options={userProcesses || []}
          optionLabel="processName"
          value={userProcesses?.filter(({ id }) => filterDashboard.process?.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
          onChange={handleChangeProcessesFilter}
          label={t("processes")}
        />
      </Grid>
    </>
  );
}
