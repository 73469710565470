import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 10;

export const initialState = {
  process: [],
  searchText: "",
  fleet: [],
  tags: [],
  // Duration Completed Items
  completedItemsSelectedDurationValue: "ALL_TIME",
  completedItemsFromDate: null,
  completedItemsToDate: null,
  // Duration Exceptions
  exceptionsSelectedDurationValue: "ALL_TIME",
  exceptionsFromDate: null,
  exceptionsToDate: null,
  // Duration Dashboard
  selectedDurationValue: "ALL_TIME",
  fromDate: null,
  toDate: null,
  processExecutions: [],
  pageNo: 0,
  pageSize: defaultPageSize,
  order: {
    id: "completedDatetime",
    label: "End date (Descending)",
    order: "desc",
  },
  exceptionType: [],
  exception: [],
  originalItemId: null,
};

const counterSlice = createSlice({
  name: "workqueueitemFilter",
  initialState,
  reducers: {
    initFilter: () => initialState,
    updateFilter: (state, action) => action.payload,
  },
});

export const { initFilter, updateFilter } = counterSlice.actions;
export default counterSlice.reducer;
