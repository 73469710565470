import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import { configureStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

import orchestratorTheme from "./style/orchestratorTheme";
import "./i18n";
import "./style/utilities.css";
import "./index.css";
import "./style/flag-icon.min.css"
import keycloakConf from "./conf/keycloakConf";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import CircularLoader from "components/Loaders/CircularLoader";

function Init() {
    const { initialized } = useKeycloak();
    if (!initialized) {
        return <CircularLoader height="95vh" />;
    }
    return (
      <Provider store={store}>
        <ThemeProvider theme={orchestratorTheme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    );
}
function Main() {
  return (
    <ReactKeycloakProvider authClient={keycloakConf} initOptions={{ onLoad: "login-required", checkLoginIframe: false }}>
      <Init />
    </ReactKeycloakProvider>
  );
}
const store = configureStore();
ReactDOM.render(
  <Main />,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
