import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import Alert from "@material-ui/lab/Alert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import { Grid, ListItemIcon, Typography } from "@material-ui/core";
import { getStatusBGColor, resourceStatus } from "util";
import ClearFilter from "../../../../components/ClearFilter";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import CustomDialog from "../../../../components/CustomDialog";
import useStyles from "./style";

function RobotDialog({
  openStart,
  message,
  onConfirm,
  isLoading,
  buttonConfirm,
  buttonCancel,
  onCancel,
  _resources,
  setSelectedResourceName,
  selectedResourceName,
  isResourceRequired = true,
}) {
  const classes = useStyles();

  const handleOnClickItem = (r, index) => {
    setSelectedResourceName(r?.resourceName);
    setSelectedResourceIndex(index);
  };

  const { t } = useTranslation();

  const [selectedResourceIndex, setSelectedResourceIndex] = useState(null);

  const [resourceData, setResourceData] = useState(null);

  useEffect(() => {
    const newResources = _resources?.filter((r) => r.isActive === resourceStatus.ACTIVE);
    setResourceData(newResources);
  }, [_resources]);

  const handleClear = () => {
    setSelectedResourceName(null);
    setSelectedResourceIndex(null);
  }

  return (
    <CustomDialog
      open={openStart}
      title={<Typography className={classes.dialogTitle}>{message}</Typography>}
      contentText={(
        <Grid container xs={12}>
          {!isResourceRequired
                && (
                <Grid container xs={2}>
                  <ClearFilter clearFilter={handleClear} />
                </Grid>)}
        </Grid>
      )}
      actionElements={[
        {
          label: buttonCancel,
          onClick: onCancel,
          color: "primary",
          disabled: isLoading,
          isLoading
        },
        {
          label: buttonConfirm,
          onClick: onConfirm,
          view: "primary",
          disabled: isLoading || (isResourceRequired && !selectedResourceName),
        }
      ]}
      aria-labelledby="confirm_message-dialog-title"
      maxWidth="xs"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <List key={_resources}>
        {
            !resourceData && <CircularLoader color="inherit" />
          }
        {resourceData?.map((r, index) => (
          <ListItem
              button
              selected={selectedResourceIndex === index}
              className={classes.listItem}
              onClick={() => handleOnClickItem(r, index)}
            >
            <ListItemIcon>
              <span
                  style={{
                    backgroundColor:
                      getStatusBGColor(r?.displayStatus),
                  }}
                  className={classes.icon}
                />
            </ListItemIcon>
            <ListItemText primary={<Typography className={classes.resourceName}>{r.resourceDisplayName}</Typography>} />
          </ListItem>
          ))}
        {resourceData?.length === 0 && (
          <Alert className={classes.noResourceAlert} icon={false}>
            <Typography className={classes.alertText}>{t("start.process.no.resource.assigned")}</Typography>
          </Alert>
        )}
      </List>
    </CustomDialog>
  );
}

export default RobotDialog;
