import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { getWorkflowStatusColor, workflowStatus as status } from "../workflowUtil";
import { ImageProfile } from "../../../../Administration/ImageProfile";

function WorkflowChip({ item, inDrawer }) {
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const getWorkflowStatusLabel = () => {
    switch (item.workflowStatus) {
      case status.READY:
        return (
          <Box display="flex" alignItems="center">
            {t("exception-workflow.status.ready")}
          </Box>
        );
      case status.TAKEN:
        return (
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item noWrap>
              {t("exception-workflow.status.taken")}
            </Grid>
            <Tooltip title={`${item?.userInCharge?.firstname} ${item?.userInCharge?.lastname}`}>
              <Grid item>
                <ImageProfile
                  src={item?.userInCharge?.profilePicture}
                  disabled
                  smallIcon
                />
              </Grid>
            </Tooltip>
          </Grid>
        );
      case status.DONE:
        return (
          <Box display="flex" alignItems="center">
            {t("exception-workflow.status.done")}
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Box style={{
      ...(!inDrawer) && {
        top: 55,
        right: 6,
        marginLeft: "5px",
      },
      ...(inDrawer) && {
        borderRadius: "4px",
      },
      padding: "3px 6px",
      color: "white",
      fontSize: "0.7rem",
      fontWeight: 600,
      backgroundColor: getWorkflowStatusColor(item.workflowStatus),
    }}
    >
      {getWorkflowStatusLabel()}
    </Box>
  );
}

export default WorkflowChip;
