import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedOrchestrator: null,
  isFullScreen: false,
  selectedResource: null,
  selectedProcesses: null,
  fleets: [],
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    setOrchestrator: (state, action) => {
      state.selectedOrchestrator = action.payload;
    },

    setFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setResource: (state, action) => {
      state.selectedResource = action.payload;
    },
    setProcesses: (state, action) => {
      state.selectedProcesses = action.payload;
    },
    setFleets: (state, action) => {
      state.fleets = action.payload;
    },
  },
});

// Planner reducer
export default plannerSlice.reducer;

// Actions
export const {
  setOrchestrator, setResource, setProcesses, setFullScreen, setFleets
} = plannerSlice.actions;
