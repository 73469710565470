import i18n from "../../i18n";

export const formtNumberByLanguage = (numberValue) => {
    if (numberValue === null) return null;
    if (!Number(numberValue)) return numberValue;

    const { language } = i18n;
    let formattingValue = new Intl.NumberFormat("fr-FR").format(numberValue);
    if (language === "en") formattingValue = new Intl.NumberFormat("en-EN").format(numberValue);
    return formattingValue;
};
export const getDefaultFormat = (value) => {
    const { language } = i18n;
    if (typeof value === "string") {
        let newValue = String(value).replaceAll(",", language === "en" ? "" : ".");
        newValue = newValue.split("").filter((i) => i === "0" || !!Number(i) || i === ".").join("");
        return language === "en" ? newValue : Number(newValue);
    }
    return Number(value);
};
export const formatInputOnBlur = (field, form) => {
    const value = getDefaultFormat(form.getValues(field) || "");
    form.setValue(field, formtNumberByLanguage(value));
};
export const formatInputOnFocus = (field, form) => {
    const value = getDefaultFormat(form.getValues(field) || "");
    form.setValue(field, value);
};
export const formatGraphNumbers = (tooltipItem, data) => (
    `${data.datasets[tooltipItem.datasetIndex].label
    }: ${formtNumberByLanguage(
        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
    )}`
);
export const numberFloatFormat = (num) => {
    if (num === null || num === undefined) return "-";
    return parseFloat(num).toFixed(2) !== "0.00"
        ? formtNumberByLanguage((num && parseFloat(num).toFixed(2)) || 0)
        : "0";
};

export const rateFloatFormat = (num) => {
    if (num === null || num === undefined || isNaN(num)) return "--";
    return num
        ? formtNumberByLanguage((num && parseFloat(num).toFixed(2)) || 0)
        : "0";
};
export const numberRoiFloatFormat = (num) => {
    if (num === null || num === undefined || Number.isNaN(+num)) return "0";
    return num
        ? formtNumberByLanguage(
            (num && parseFloat(num > 0 ? num : -num).toFixed(2)) || 0,
        )
        : "0";
};
export const round = (number, decimals) => {
    // return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
    decimals = number === 0 ? 0 : decimals;
    return number?.toFixed(decimals);
};
export const accumulate = (arr) => arr?.map(
    (
        // eslint-disable-next-line no-return-assign
        (sum) => (value) => (sum += value)
    )(0),
);
export const abbreviateNumber = (number) => {
    if (number >= 1000000) {
        return `${formtNumberByLanguage((number / 1000000).toFixed(1).replace(/\.0$/, ""))}M`;
    } if (number >= 1000) {
        return `${formtNumberByLanguage((number / 1000).toFixed(1).replace(/\.0$/, ""))}k`;
    }
    return formtNumberByLanguage(Number.isInteger(+number) ? number?.toString() : number.toFixed(2));
};
const ordinals = {
    en: ["st", "nd", "rd", "th"],
    fr: ["er", "e", "e", "e"],
    pl: [".", ".", ".", "."],
    de: [".", ".", ".", "."],
    es: ["º", "º", "º", "º"],
    ar: [".", ".", ".", "."],
};
export const getOrdinalSuffix = (attempt, language) => {
    const suffix = ordinals?.[language]?.[+attempt - 1]
    return `${attempt}${suffix}`;
}
