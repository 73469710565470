import React from "react";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import renderValue, {
    DOUGHNUT_CHART_DATASET_DATA,
    DOUGHNUT_CHART_DATASET_OPTION,
    renderContent
} from "pages/newDashboard/dashboardUtils";
import Needle from "assets/NewDashboardIcons/needle.svg";
import useStyles from "../../style"

const SuccessRatePercentage = ({ successRate, isLoading }) => {
  const { t } = useTranslation();
  const rotation = -22.5 + (successRate * 2.25);
  const classes = useStyles({ rotation })();

  const Component = () => (
    <Grid container item alignItems="center" direction="column">
      <Grid item className={classes.chartContainer}>
        <Doughnut data={DOUGHNUT_CHART_DATASET_DATA(successRate)} options={DOUGHNUT_CHART_DATASET_OPTION} />
        <img src={Needle} alt="needle" className={classes.needleImage} />
      </Grid>
      <Grid item>
        <Typography variant="h6" className={classes.textBold}>
          {renderValue({ value: successRate, suffix: " %" })}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={t("successRate.header.label")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent>
        {renderContent(isLoading, [successRate], <Component />)}
      </CardContent>
    </Box>
  );
};

export default SuccessRatePercentage;
