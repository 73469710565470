import React from "react";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const NoDataMenu = ({ message, marginX, marginY }) => (
  <Alert style={{ margin: `${marginY || 0}px ${marginX || 0}px` }} severity="warning" icon=" ">
    <Typography variant="subtitle1">
      {message}
    </Typography>
  </Alert>
);

export default NoDataMenu;
