import { createMuiTheme } from "@material-ui/core/styles";
import { RPA_PALETTE } from "util/constants/colors";

export const fontFamily = "Nunito"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#303167",
      dark: "#8181A4",
      light: "#DEE4FF",
    },
    secondary: {
      main: "#FF067E",
    },
    info: {
      main: "#7BB0FF",
      dark: "#B2C0FF",
      light: "#F9FAFF"
    },
    error: {
      main: "#e00914",
      light: "#fce6e5",
    },
    warning: {
      main: "#ff9600",
      dark: "#f4970b",
      light: "#f7f1e4",
    },
    success: {
      main: "#63b335",
      dark: "#BFDB38",
      light: "#f1f6e5",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily,
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
  },
  custom: {
    variables: {
      menuLeftWidth: 225,
      menuLeftCollapsedWidth: 80,
      headerHeight: 130,
    },
    color: {
      transparent: "transparent",
      pendingBackground: "#FFFEF4",
      pending: "#FFC224",
      hoverColor: "#F3F5FF",
      staticInput: "#EFF2FF",
      runningBackground: "#e7effa",
      containerBackgroundHover: "#E6EAFF",
      chartColor4: "#E0E0E0",
      stoppedBackground: "#dcd5e0",
      chartColor1: "#D0D8FF",
      archivedBackground: "#cfcfcf",
      dividerColor: "#AAA",
      stopped: "#733999",
      running: "#3d6db4",
      archived: "#0e0414",
    },
    shadow: "0 2px 5px rgba(101, 101, 101, 0.18)",
    palette: {
      ...RPA_PALETTE
    },
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiCheckbox: {
    colorSecondary: {
      "&$checked": {
        color: theme.palette.info.main,
      },
    },
  },
  MuiTableHead: {
    root: {
      backgroundColor: theme.custom.color.hoverColor,
    },
  },
  MuiTableCell: {
    root: {
      fontSize: "0.8rem",
      padding: `10px ${theme.spacing(2)}px`,
    },
    head: {
      color: "#3c4d6f",
    },
  },
  MuiCard: {
    root: {
      borderRadius: "0px",
    },
  },
  MuiCardHeader: {
    title: {
      fontSize: "18px",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: "10px",
    },
  },
  MuiTablePagination: {
    caption: {
      fontSize: "0.875rem",
      fontFamily,
      fontWeight: "300",
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "400",
    },
    listbox: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "400",
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "600",
    },
  },
  MuiTextField: {
    border: "1px solid #eaeaea",
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        color: "white",
      },
    },
  },
  MuiButton: {
    label: {
      fontFamily,
    }
  },
  MuiChip: {
    label: {
      fontFamily
    }
  }
};
theme.shadows[23] = "0 5px 10px rgba(222,228,255,0.8)";
theme.shadows[25] = "0 1px 15px 1px rgba(69,65,78,.08)";

export default theme;
