import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  process: [],
  selectedDurationValue: "WEEK",
  fromDate: null,
  toDate: null,
  fleets: [],
  tags: [],
  monitoringMode: null
};

const filterDashboard = createSlice({
  initialState,
  name: "dashboardFilter",
  reducers: {
    initFilter: () => ({
      ...initialState,
    }),
    updateProcessFilter: (state, action) => ({
      ...state,
      process: action.payload,
    }),
    updateFleetsFilter: (state, action) => ({
      ...state,
      fleets: action.payload,
    }),
    updateTagsFilter: (state, action) => ({
      ...state,
      tags: action.payload,
    }),
    updateDateFilter: (state, action) => ({
      ...state,
      selectedDurationValue: action.payload.selectedDurationValue,
      fromDate: action.payload.fromDate,
      toDate: action.payload.toDate,
    }),
    updateMonitoringMode: (state, action) => ({
      ...state,
      monitoringMode: action.payload,
    }),
  },
});

export const {
  initFilter, updateProcessFilter, updateDateFilter, clearProcessFilter, updateTagsFilter, updateFleetsFilter, updateMonitoringMode
} = filterDashboard.actions;
export default filterDashboard.reducer;
