import {
  FETCH_AUTOMATION_GROUPS,
  FETCH_AUTOMATION_USERS,
  FETCH_PENDING_AUTOMATIONS,
  FETCH_PENDING_AUTOMATIONS_TAGS,
  FETCH_PENDING_AUTOMATIONS_USERS,
} from "redux/constants";
import { URL_PROCESSES, URL_TAGS, URL_USER } from "redux/constants/endpoints";

export const fetchPendingAutomations = (filter = {}, onSuccess) => {
  const { page, size } = filter;
  return {
    type: FETCH_PENDING_AUTOMATIONS,
    request: {
      url: `${URL_PROCESSES}pending`,
      params: {
        page,
        size,
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
    },
  };
};
export const fetchPendingAutomationsTags = (fleetsIds) => ({
  type: FETCH_PENDING_AUTOMATIONS_TAGS,
  request: {
    url: `${URL_TAGS}/pending-automations`,
    params: {
      fleetsIds: fleetsIds.join(),
    },
  },
});
export const fetchPendingAutomationsUsers = (fleetsIds) => ({
  type: FETCH_PENDING_AUTOMATIONS_USERS,
  request: {
    url: `${URL_USER}/pending-automations`,
    params: {
      fleetsIds: fleetsIds.join(),
    },
  },
});
export const fetchProcessGroups = (id) => ({
  type: FETCH_AUTOMATION_GROUPS,
  request: {
    url: `${URL_PROCESSES}${id}/fleets`,
  },
});
export const fetchProcessUsers = (id) => ({
  type: FETCH_AUTOMATION_USERS,
  request: {
    url: `${URL_PROCESSES}${id}/users`,
  },
});
export const fetchPendingAutomationsCount = (onSuccess) => ({
  type: FETCH_PENDING_AUTOMATIONS,
  request: {
    url: `${URL_PROCESSES}pending-count`,
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
  },
});
export const updatePendingAutomation = (data, onSuccess, onError) => ({
  type: FETCH_PENDING_AUTOMATIONS,
  request: {
    url: `${URL_PROCESSES}pending/configs`,
    method: "post",
    data: {
      ...data,
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError(error);
      throw error;
    },
  },
});
