import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import get from "lodash/get";
import {
  updateProcessKpiParameters,
  fetchProcesses,
  processHasSchedules,
} from "redux/actions/services";
import {
  isSchedulableDisabled,
  canBeStartedManually,
  cannotBeStopped
} from "util";
import ConfirmMessage from "components/ConfirmMessage";
import InformationIcon from "components/InformationIcon";
import CustomSwitch from "../CustomSwitch";
import CustomButton from "components/CustomButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Radio } from "@material-ui/core";

const SrPropertiesForm = ({
 classes, _process, setProcess
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const processId = get(_process, "id");
  const canBeScheduledOrStartedManually = !isSchedulableDisabled(_process?.orchestrator?.orchestratorType) || !canBeStartedManually(_process?.orchestrator?.orchestratorType);
  const isSchedulable = {
    value: _process.isSchedulable,
    changed: false,
  };
  const [confirmLoading, setConfirmLoading] = useState(false);
  const stopMethods = ["STOPREQUEST", "ITEMSDEFERRAL"];
  const [confirmMsgObj, setConfirmMsgObj] = useState({
    deleteMsg: (
      <div>
        <Alert severity="error">{t("kpiParam.schedule.warning")}</Alert>
      </div>
    ),
    open: false,
    onCancel: () => setConfirmMsgObj({ ...confirmMsgObj, open: false, isLoading: false }),
    onConfirm: () => {
      setConfirmMsgObj({ ...confirmMsgObj, isLoading: true });
      handleSubmit(saveData)();
    },
    isLoading: false,
  });
  const {
    handleSubmit,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      executionsExceptionAccountingRate:
        get(_process, "executionsExceptionAccountingRate") || 0,
      exceptionAccountingRate: get(_process, "exceptionAccountingRate") || 0,
      beCalculationPeriod: _process.beCalculationPeriod,
      isSchedulable: _process.isSchedulable,
      isManualAllowed: _process.isManualAllowed,
      isRetryable: _process.isRetryable,
      isRetryableWithData: _process.isRetryableWithData,
      isStoppable: _process.isStoppable,
      stopMethod: _process.stopMethod,
      businessExceptionsAsCompleted: _process.businessExceptionsAsCompleted,
      exceptionAccountingRateSwitch: !!get(_process, "exceptionAccountingRate"),
      executionsExceptionAccountingRateSwicth: !!get(
        _process,
        "executionsExceptionAccountingRate"
      ),
      processBeSince: get(_process, "processBeSince"),
    },
    mode: "onChange",
  });

  const createToSaveObj = (data) => ({
    id: processId,
    ...data,
  });
  const saveData = (data) => {
    const toSave = createToSaveObj(data)
    dispatch(updateProcessKpiParameters(toSave)).then(
      (response) => {
        setConfirmMsgObj({ ...confirmMsgObj, open: false, isLoading: false });
        setConfirmLoading(false);
        if (response.status === 200) {
          setProcess((prevState) => ({ ...prevState, ...toSave }))
          toast.success(t("kpiParam.update.success"));
        } else toast.error(t("kpiParam.update.success"));
        dispatch(
          fetchProcesses(
            filterProcess.search,
            filterProcess.fleet,
            filterProcess.tags,
            filterProcess.pageNo,
            filterProcess.pageSize,
            filterProcess.sortBy
          ),
        );
      }
    );
  };

  const handleApply = (data) => {
    if (isSchedulable.changed && !isSchedulable.value) {
      dispatch(processHasSchedules(processId)).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setConfirmMsgObj({ ...confirmMsgObj, open: true });
          } else {
            setConfirmLoading(true);
            saveData(createToSaveObj(data));
          }
        }
      });
    } else {
      setConfirmLoading(true);
      saveData(createToSaveObj(data));
    }
  };

  const handleRadioColumn = (index) => {
    const newValue = watch("stopMethod") === stopMethods[index] ? "" : stopMethods[index];
    setValue("stopMethod", newValue);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleApply)}>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridColumnGap={30} className={classes.parametersLeftContainer}>
          {canBeScheduledOrStartedManually && (
          <Box className={classes.slaSectionContainer}>
            {!isSchedulableDisabled(_process?.orchestrator?.orchestratorType) && (
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Controller
                    name="isSchedulable"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.allowScheduling.tooltip"
                      )}
                      originalContent={t("kpi.parameter.allowScheduling")}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!canBeStartedManually(_process?.orchestrator?.orchestratorType) && (
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Controller
                    name="isManualAllowed"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.allowManual.tooltip"
                      )}
                      originalContent={t("kpi.parameter.allowManual")}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!cannotBeStopped(_process?.orchestrator?.orchestratorType) && (
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Controller
                    name="isStoppable"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.allowStop.tooltip"
                      )}
                      originalContent={t("kpi.parameter.allowStop")}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(!cannotBeStopped(_process?.orchestrator?.orchestratorType) && watch("isStoppable")) && (
              <Grid
                className={classes.radioContainer}
                item
                container
                direction="column"
                alignItems="left"
              >
                <Box display="flex" alignItems="center" marginBottom="-0.5rem">
                  <Radio
                    checked={stopMethods[0] === watch("stopMethod")}
                    onChange={() => handleRadioColumn(0)}
                    name="radio-buttons"
                    id="grouped"
                    className={classes.radio}
                  />
                  <Typography className={classes.radioText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.allowStop.stoprequest.tooltip"
                      )}
                      originalContent={t("kpi.parameter.allowStop.stoprequest")}
                    />
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={stopMethods[1] === watch("stopMethod")}
                    onChange={() => handleRadioColumn(1)}
                    name="column"
                    className={classes.radio}
                  />
                  <Typography className={classes.radioText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.allowStop.stoprequest.itemdeferral.tooltip"
                      )}
                      originalContent={t("kpi.parameter.allowStop.itemdeferral")}
                    />
                  </Typography>
                </Box>
              </Grid>
            )}
            <Box className={classes.slaSectionContainer}>
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Controller
                    name="isRetryable"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.parameter.retryable.tooltip"
                      )}
                      originalContent={t("kpi.parameter.retryable")}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Controller
                    name="isRetryableWithData"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value && watch("isRetryable")}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                        disabled={!watch("isRetryable")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                    <InformationIcon
                      titleContent={t(
                        "kpi.parameter.human.in.the.loop.activated.tooltip"
                      )}
                      originalContent={t("Human-in-the-loop activated")}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              component={Grid}
              container
              justify="flex-end"
              xs={12}
              className={classes.applyBtnContainer}
              marginTop="140px"
              pl={3}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="flex-end"
                justify="flex-end"
              >
                <Grid item>
                  <CustomButton
                    view="primary"
                    type="submit"
                    startIcon={
                      confirmLoading && <CircularProgress color="white" size={20} />
                    }
                    disabled={confirmLoading}
                  >
                    {t("apply")}
                  </CustomButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
            )}
        </Box>

      </form>
      <ConfirmMessage
        message={confirmMsgObj.deleteMsg}
        openStart={confirmMsgObj.open}
        onCancel={confirmMsgObj.onCancel}
        onConfirm={confirmMsgObj.onConfirm}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={confirmMsgObj.isLoading}
      />
    </>
  );
};

export default SrPropertiesForm;
