/* eslint-disable no-nested-ternary */
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import React, { useEffect } from "react";
import { format } from "date-fns-tz";
import { useTranslation } from "react-i18next";
import {
  fetchProcessesNameException,
  fetchExecutionRequestsList
} from "../../../../redux/actions/services";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  initialState,
  updateFilter,
  initFilter,
} from "../../../../redux/slices/executionRequestsSlice";
import ExecutionRequestRow from "../components/ExecutionRequestRow";
import { maxItemDetailsCount } from "util";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTable from "../../../../components/TableComponents/CustomTable";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import DataNotFound from "../../../../components/DataNotFound";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import Filter from "../Filter";
import CompletedItemsNotFound from "../../../../assets/Process_Overview.svg";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";

const dateFormat = "yyyy/MM/dd HH:mm";
function ExecutionRequestsList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const executionRequests = useSelector(({ requests }) => get(requests, "queries.FETCH_ALL_EXECUTION_REQUESTS_SUCCESS.data.content"));
  const processFilter = useSelector(({ requests }) => get(requests, "queries.FETCH_PROCESSES_FOR_FILTERS.data"));
  const executionRequestsCount = useSelector(({ requests }) => get(requests, "queries.FETCH_ALL_EXECUTION_REQUESTS_SUCCESS.data.totalElements"));
  const labels = [
    { label: "" },
    { label: t("resource.management.resourceName") },
    { label: t("request.date") },
    { label: t("estimated.duration") },
    { label: t("max.wait.time") },
    { label: t("Trigger") },
    { label: t("Trigger details") },
  ];

  useEffect(() => {
    setData(executionRequests?.list);
    setIsLoading(false);
  }, [executionRequests]);

  useEffect(() => {
    setCount(executionRequestsCount);
    setIsLoading(false);
  }, [executionRequestsCount]);

  const executionRequestsFilter = useSelector(({ executionRequest }) => executionRequest);

  const handleFiltersChange = (values, target) => {
    const key = (target === "division") ? "divisions" : target
      dispatch(
        updateFilter({ ...executionRequestsFilter, pageNo: 0, [key]: values }),
      );
  };

  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    dispatch(
      updateFilter({
        ...executionRequestsFilter,
        pageNo: 0,
        selectedDurationValue: value,
        fromDate: from,
        toDate: to,
      }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        ...executionRequestsFilter,
        order: property,
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...executionRequestsFilter,
        pageNo: newPage,
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...executionRequestsFilter,
        pageNo: page + 1,
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...executionRequestsFilter,
          pageNo: page - 1,
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...executionRequestsFilter,
        pageNo: 0,
        pageSize: event.target.value,
      }),
    );
  };

  const getExecutionRequests = React.useCallback(
    (
      page,
      size,
      sortField,
      sortOrder,
      processes,
      divisions,
      fleetIds,
      fromDate,
      toDate,
      searchText,
      tags,
    ) => {
      setIsLoading(true);
      const processID = processes ? processes.map((e) => e?.id || e) : [];
      const tagsIds = tags ? tags.map((e) => e?.id || e) : [];
      props
        .fetchExecutionRequestsList(
          page,
          size,
          sortField,
          sortOrder,
          processID,
          fleetIds,
          fromDate,
          toDate,
          searchText,
          tagsIds,
        )
        .then((result) => {
          setData(result?.data?.content);
          setCount(result?.data?.totalElements);
          setIsLoading(false);
        });
    },
    [],
  );
  const handleIsLoading = () => {
    setIsLoading(true);
  };
  const createWqObj = () => {
    let wqObj = {};
    wqObj = {
      pageNo: executionRequestsFilter.pageNo,
      pageSize: executionRequestsFilter.pageSize,
      orderId: executionRequestsFilter.order.id,
      orderOrder: executionRequestsFilter.order.order,
      processes: executionRequestsFilter.process,
      division: executionRequestsFilter.divisions,
      fleets: executionRequestsFilter.fleet,
      from: executionRequestsFilter.fromDate,
      to: executionRequestsFilter.toDate,
      search: executionRequestsFilter.searchText,
      tags: executionRequestsFilter.tags,
    };
    return wqObj;
  };

  const fetchData = () => {
    const wqObj = createWqObj();
    getExecutionRequests(
      wqObj.pageNo,
      wqObj.pageSize,
      wqObj.orderId,
      wqObj.orderOrder,
      wqObj.processes,
      wqObj.division,
      wqObj.fleets,
      wqObj.from,
      wqObj.to,
      wqObj.search,
      wqObj.tags,
      wqObj.originalItemId
    );
  };

  useEffect(() => {
      fetchData();
  }, [executionRequestsFilter]);

  const handleChangeSearchText = (value, currFilter) => {
    dispatch(
      updateFilter({
        ...currFilter,
        pageNo: 0,
        searchText: value,
      }),
    );
  };
  const handleFilterChangeBatch = (filters) =>
  {
    let obj = { ...initialState }
    filters.forEach((fi) => {
      const key = Object.keys(fi)[0]
      obj = { ...obj, [key]: fi[key] }
    })

    dispatch(updateFilter({ ...obj, pageNo: 0 }));
  }

  return (
    <Grid container>
      <Filter
        handleFiltersChange={handleFiltersChange}
        handleChangeDates={handleChangeDates}
        handleRequestSort={handleRequestSort}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        rowCount={data?.length}
        handleChangeSearchText={handleChangeSearchText}
        fetchData={fetchData}
        executionRequestsFilter={executionRequestsFilter}
        handleIsLoading={handleIsLoading}
        initFilter={initFilter}
        isFetchLoading={isLoading}
        handleFilterChangeBatch={handleFilterChangeBatch}
        totalCount={executionRequestsCount}
        pageTitle="Execution Requests"
        filteredItemsCount={executionRequestsCount}
      />
      <CustomTableContainer>
        <CustomTable>
          {data?.length > 0 && <CustomTableHeader labels={labels} />}
          {!isLoading && data ? (
              data.length > 0 ? (
                  data.map((row, index) => (
                    <CustomTableRow key={index}>
                      <ExecutionRequestRow
                            row={row}
                            handleFiltersChange={handleFiltersChange}
                            maxDetailsCount={maxItemDetailsCount(data)}
                            processName={processFilter?.find((p) => p.id === row.processId)?.processName}
                            processIcon={processFilter?.find((p) => p.id === row.processId)?.icon}
                        />
                    </CustomTableRow>
                  ))
              ) : !isLoading && data.length === 0 ? (
                <DataNotFound
                      message={t("no.execution.requests.message")}
                      icon={CompletedItemsNotFound}
                  />
              ) : isLoading ? (
                <CircularLoader />
              ) : (
                  null
              )
          ) : (
            <CircularLoader />
          )}
        </CustomTable>
      </CustomTableContainer>

      <Grid container xs={12} direction="row" justify="flex-end">
        {data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={executionRequestsFilter?.pageSize}
          page={executionRequestsFilter?.pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExecutionRequestsList,
  fetchProcessesNameException,
};
export default connect(null, mapDispatchToProps)(ExecutionRequestsList);
