import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  tableHeadLabel: {
    fontSize: theme.typography.pxToRem(14),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
    marginRight: 0,
    color: theme.palette.primary.main,
    fontWeight: ({ cellFontWeight }) => cellFontWeight ?? 700,
  },
  tableHeadLabelTransparent: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700
  },
  tableCellHead: {
    borderBottom: "none",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: ({ paddingLeft }) => paddingLeft ?? theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.custom.color.staticInput,
    height: ({ height }) => height ?? 50,
  },
  tableCellHeadTransparent: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    fontSize: 30
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  card: { background: "transparent" },

  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
