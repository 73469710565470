import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { useQuery } from "@redux-requests/react";
import CustomDialog from "components/CustomDialog";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import { Controller, useForm } from "react-hook-form";
import {
  formatInputOnBlur,
  formatInputOnFocus,
  getDefaultFormat,
  handleInputChanges,
} from "util";
import { useDispatch } from "react-redux";
import {
  FETCH_CURRENT_USER,
  FETCH_PROCESSES,
} from "redux/constants";
import {
  addExceptionAssigmentRule,
  fetchFleetsForFilter,
  fetchExceptionAssigmentRule,
  fetchExceptionsReason,
  fetchExceptionsType,
  fetchFleetUsers,
  fetchProcesses,
  updateExceptionAssigmentRule,
  fetchProcessItemData,
} from "redux/actions/services";
import InformationIcon from "components/InformationIcon";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import ConfirmMessage from "components/ConfirmMessage";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "./style";

const GREATER = "GREATER";
const SMALLER = "SMALLER";
const numberConditionArray = [GREATER, SMALLER];
export default function RuleForm({
 currentRule, open, handleClose
}) {
  const { t } = useTranslation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userProcesses = useQuery({ type: FETCH_PROCESSES })?.data?.list;
  const [exceptionsType, setExceptionsType] = React.useState([]);
  const [exceptionReason, setExceptionReason] = useState([]);
  const [userFleet, setUserFleet] = useState();
  const [sameFleetUsers, setSameFleetUsers] = useState();
  const [availableLogicalConnectors, setAvailableLogicalConnectors] = useState(logicalConnectors);
  const [isLoading, setIsLoading] = useState(false);
  const [isSumbitLoading, setIsSubmitLoading] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemDataLoading, setItemDataLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    message: null,
  });
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      logicalConnector: logicalConnectors?.find(
        (item) => item?.key === "ITEM_DATA_BASED"
      ),
      keepOld: null,
      assignedTo: "user",
      alertOnAssignment: false,
    },
  });

  const isExceptionBased = watch("logicalConnector")?.key === "EXCEPTION_BASED";
  const isAssignedToUser = watch("assignedTo") === "user";
  const [isMultipleProcesses, setIsMultipleProcesses] = useState(false)

  const getUsersFleet = (fleetId) => {
    dispatch(fetchFleetUsers(fleetId)).then((result) => {
      setSameFleetUsers(result.data);
    });
  };

  const fetchExceptionsTypes = () => {
    dispatch(fetchExceptionsType()).then((res) => {
      setExceptionsType(res?.data);
    });
  };

  const fetchExceptionsReasons = () => {
    dispatch(fetchExceptionsReason()).then((res) => {
      setExceptionReason(res?.data?.map(({ exceptionReason }) => exceptionReason));
    });
  };

  const CloseCleanUp = () => {
    unregister();
    reset();
    clearErrors();
    setIsLoading(false);
    setIsSubmitLoading(false);
    setExceptionReason([]);
    setExceptionsType([]);
    setConfirmDialog({
      open: false,
      message: null,
    });
    setValue("assignedTo", "user");
    setValue("keepOld", null);
  };

  const onSuccess = () => {
    setIsSubmitLoading(false);
    toast.success(t("changes.saved.successfully"));
    CloseCleanUp();
    handleClose();
  };
  const onError = (data) => {
    if (data?.response?.data?.title === "rules.already.exists.with.processes") {
      setConfirmDialog({
        open: true,
        message: t(
          "Rule already exists with the same processes. Do you want to delete the old rule?"
        ),
      });
    } else {
      toast.error(t("Unexpected Error"));
    }
    setIsSubmitLoading(false);
  };

  const onSubmit = (data) => {
    setIsSubmitLoading(true);
    const tempData = {
      ...data,
      condition: data?.condition?.key,
      itemDetail: data?.itemDetail,
      logicalConnector: data?.logicalConnector?.key,
      processes: data?.processes?.map(({ id, processDisplayName }) => ({
        id,
        name: processDisplayName,
      })),
      value: numberConditionArray.includes(watch("condition")?.key) ? getDefaultFormat(data?.value) : data?.value,
    };
    if (!currentRule) {
      dispatch(addExceptionAssigmentRule(tempData, onSuccess, onError));
    } else {
      dispatch(updateExceptionAssigmentRule(tempData, onSuccess, onError));
    }
  };

  const getFieldValue = (options, field, key = "id", multiple = false) => (multiple
      ? options?.filter((item) => getValues(field)
            ?.map((item) => item?.[key])
            ?.includes(item?.[key]))
      : options?.find((item) => getValues(field)?.[key] === item?.[key]));

  const onFetchCurrentRuleSuccess = (res) => {
    const tempRule = res?.data;
    setValue("id", tempRule?.id);
    setValue("name", tempRule?.name);
    setValue("processes", tempRule?.processes);
    setValue(
      "logicalConnector",
      logicalConnectors?.find(
        (item) => item?.key === tempRule?.logicalConnector
      )
    );
    setValue("itemDetail", tempRule?.itemDetail);
    setValue(
      "condition",
      conditions?.find((item) => item?.key === tempRule?.condition)
    );
    setValue("value", tempRule?.value);
    setValue(
      "exceptionType",
      exceptionsType?.find((item) => item === tempRule?.exceptionType)
    );
    setValue(
      "exceptionRaison",
      exceptionReason?.find((item) => item === tempRule?.exceptionRaison)
    );
    setValue("alertOnAssignment", tempRule?.alertOnAssignment);
    if (tempRule?.user) {
      setValue(
        "user",
        sameFleetUsers?.find((item) => item?.id === tempRule?.user?.id)
      );
    }
    if (tempRule?.group) {
      setValue(
        "fleet",
        userFleet?.find((item) => item?.id === tempRule?.group?.id)
      );
    }
    setValue("assignedTo", tempRule?.user ? "user" : "fleet");
    if (
      userProcesses
      && userFleet
      && sameFleetUsers
      && exceptionsType
      && exceptionReason
      && itemData
    ) setIsLoading(false);
  };
  const onFetchCurrentRuleError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
    handleClose();
  };

  useEffect(() => {
    if (currentRule) {
      setIsLoading(true);
      dispatch(
        fetchExceptionAssigmentRule(
          currentRule?.id,
          onFetchCurrentRuleSuccess,
          onFetchCurrentRuleError
        )
      );
    }
  }, [
    exceptionReason
  ]);

  useEffect(() => {
    dispatch(fetchProcesses());
    fetchExceptionsTypes();
    fetchExceptionsReasons();
    getUsersFleet(currentUser?.fleet?.id);
    dispatch(fetchFleetsForFilter()).then((res) => {
      setUserFleet(res.data);
    });
    return () => {
      CloseCleanUp();
    };
  }, []);

  useEffect(() => {
    setValue(!isAssignedToUser ? "user" : "fleet", null);
    unregister(isAssignedToUser ? "user" : "fleet");
  }, [isAssignedToUser]);
  useEffect(() => {
    unregister(
      isExceptionBased
        ? ["conditions", "value"]
        : ["exceptionType", "exceptionRaison"]
    );
  }, [isExceptionBased]);
  useEffect(() => {
    if (isMultipleProcesses) {
      unregister([
        "itemDetail",
        "logicalConnector",
        "conditions",
        "value",
        "exceptionType",
        "exceptionRaison",
      ]);
      setAvailableLogicalConnectors((prevState) => prevState.filter((item) => item?.key !== "ITEM_DATA_BASED"));
    } else {
      setAvailableLogicalConnectors(logicalConnectors);
    }
  }, [isMultipleProcesses]);

  useEffect(() => {
    const currentProcesses = getValues("processes")
    setIsMultipleProcesses(currentProcesses?.length > 1)
    setItemData([])
    if (currentProcesses?.length === 1) {
      setItemDataLoading(true)
      dispatch(fetchProcessItemData(currentProcesses?.[0]?.id, (res) => {
        setItemData(res?.data)
        setItemDataLoading(false)
      }));
    }
  }, [watch("processes")])

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxHeight="99%"
        width={550}
        fullWidth
        open={open}
        disableBackdropClick
        PaperProps={{
          square: true,
        }}
        title={t("Rule Configuration")}
        actionElements={[
          {
            label: t("Cancel"),
            onClick: handleClose,
            color: "cancelModal",
            disabled: isSumbitLoading,
          },
          {
            label: t("save"),
            view: "primary",
            type: "submit",
            isLoading: isSumbitLoading,
            disabled: isSumbitLoading,
            onClick: handleSubmit(onSubmit)
          },
        ]}
        formProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        borderRadius={6}
      >
        {isLoading ? (
          <CircularLoader height={80} />
        ) : (
          <Box key={`rule-${getValues("id")}`}>
            <Box>
              <CustomTextField
                {...register("name", {
                  required: t("field.is.required", {
                    field: t("Rule Name"),
                  }),
                })}
                valued={getValues("name")}
                label={`${t("Rule Name")} *`}
                error={!!errors.name?.message}
                helperText={errors.name && errors.name?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch("name"),
                }}
              />
            </Box>
            <Box className={classes.sectionContainer}>
              <Box className={classes.sectionTitle}>
                <InformationIcon
                  titleContent={t("When an exception occurs for details")}
                  originalContent={(
                    <Typography className={classes.sectionTitleText}>
                      {t("When an exception occurs for")}
                    </Typography>
                  )}
                />
              </Box>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="processes"
                  rules={{
                    required: t("field.is.required", {
                      field: t("Process"),
                    }),
                  }}
                  render={(field) => (
                    <CustomAutoComplete
                      {...field}
                      multiple
                      label={`${t("Process")} *`}
                      optionLabel="processDisplayName"
                      options={userProcesses ?? []}
                      value={getFieldValue(
                        userProcesses,
                        "processes",
                        "id",
                        true
                      )}
                      onChange={(value) => field.field.onChange(value)}
                      error={!!errors.processes?.message}
                      helperText={
                        !!errors.processes && errors.processes?.message
                      }
                      disableClearable
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="logicalConnector"
                  rules={{
                    required: t("field.is.required", {
                      field: t("logical Connector"),
                    }),
                  }}
                  render={(field) => (
                    <CustomAutoComplete
                      {...field}
                      label={`${t("logical Connector")} *`}
                      optionLabel="name"
                      optionUuid="key"
                      options={availableLogicalConnectors ?? []}
                      value={getFieldValue(
                        availableLogicalConnectors,
                        "logicalConnector",
                        "key"
                      )}
                      onChange={(value) => field.field.onChange(value)}
                      error={!!errors.logicalConnector?.message}
                      helperText={
                        !!errors.logicalConnector
                        && errors.logicalConnector?.message
                      }
                      disableCloseOnSelect={false}
                      disableClearable
                    />
                  )}
                />
              </FormControl>
              {!isMultipleProcesses && !isExceptionBased && (
                <>
                  <FormControl fullWidth key={`${itemData}`}>
                    <Controller
                      control={control}
                      name="itemDetail"
                      rules={{
                        required: t("field.is.required", {
                          field: t("Item Data"),
                        }),
                      }}
                      render={(field) => (
                        <CustomAutoComplete
                          {...field}
                          label={`${t("Item Data")} *`}
                          options={itemData ?? []}
                          value={getValues("itemDetail")}
                          onChange={(value) => field.field.onChange(value)}
                          error={!!errors.itemDetail?.message}
                          helperText={
                            !!errors.itemDetail && errors.itemDetail?.message
                          }
                          disableCloseOnSelect={false}
                          disableClearable
                          disabled={!getValues("processes")}
                          noOptionsNode={itemDataLoading && (
                          <CircularLoader height="100%" size={10} />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    gridColumnGap="20px"
                    key={`${isExceptionBased}-field`}
                  >
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="condition"
                        rules={{
                          required: t("field.is.required", {
                            field: t("Condition"),
                          }),
                        }}
                        render={(field) => (
                          <CustomAutoComplete
                            {...field}
                            label={`${t("Condition")} *`}
                            optionLabel="name"
                            optionUuid="key"
                            options={conditions ?? []}
                            value={getFieldValue(
                              conditions,
                              "condition",
                              "key"
                            )}
                            onChange={(value) => field.field.onChange(value)}
                            error={!!errors.condition?.message}
                            helperText={
                              !!errors.condition && errors.condition?.message
                            }
                            disableCloseOnSelect={false}
                            disableClearable
                          />
                        )}
                      />
                    </FormControl>
                    <CustomTextField
                      {...register("value", {
                        required: t("field.is.required", {
                          field: t("Value"),
                        }),
                      })}
                      valued={getValues("value")}
                      label={`${t("Value")} *`}
                      error={!!errors.value?.message}
                      helperText={errors.value && errors.value?.message}
                      onBlur={() => numberConditionArray.includes(watch("condition")?.key) && formatInputOnBlur("value", { getValues, setValue })}
                      onFocus={() => numberConditionArray.includes(watch("condition")?.key) && formatInputOnFocus("value", { getValues, setValue })}
                      onInput={(e) => numberConditionArray.includes(watch("condition")?.key) && handleInputChanges(e, 9)}
                      isCustom
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("value"),
                      }}
                    />
                  </Box>
                </>
              )}
              {isExceptionBased && (
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridColumnGap="20px"
                  key={`${isExceptionBased}-field`}
                >
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="exceptionType"
                      rules={{
                        required: t("field.is.required", {
                          field: t("exception.type"),
                        }),
                      }}
                      render={(field) => (
                        <CustomAutoComplete
                          {...field}
                          label={`${t("exception.type")} *`}
                          options={exceptionsType ?? []}
                          value={getValues("exceptionType")}
                          valued={getValues("exceptionType")}
                          onChange={(value) => field.field.onChange(value)}
                          error={!!errors.exceptionType?.message}
                          helperText={
                            !!errors.exceptionType
                            && errors.exceptionType?.message
                          }
                          disableCloseOnSelect={false}
                          disableClearable
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="exceptionRaison"
                      rules={{
                        required: t("field.is.required", {
                          field: t("Exception Reason"),
                        }),
                      }}
                      render={(field) => (
                        <CustomAutoComplete
                          {...field}
                          label={`${t("Exception Reason")} *`}
                          options={exceptionReason ?? []}
                          value={getValues("exceptionRaison")}
                          valued={getValues("exceptionRaison")}
                          onChange={(value) => field.field.onChange(value)}
                          error={!!errors.exceptionRaison?.message}
                          helperText={
                            !!errors.exceptionRaison
                            && errors.exceptionRaison?.message
                          }
                          disableCloseOnSelect={false}
                          disableClearable
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
            <Box className={classes.sectionContainer}>
              <Box className={classes.sectionTitle}>
                <InformationIcon
                    titleContent={t("Assign to details")}
                    originalContent={(
                      <Typography className={classes.sectionTitleText}>
                        {t("Assign to")}
                      </Typography>
                    )}
                />
              </Box>
              <FormControl component="fieldset">
                <RadioGroup
                  name="assignedTo"
                  {...register("assignedTo")}
                  onChange={(e) => {
                    setValue("assignedTo", e.target.value);
                  }}
                  defaultValue="user"
                  row
                >
                  <FormControlLabel
                    value="user"
                    control={<Radio className={classes.radio} />}
                    label={
                      <Typography className={classes.dialogDefaultText}>
                        {t("userSingle")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="fleet"
                    control={<Radio className={classes.radio} />}
                    label={
                      <Typography className={classes.dialogDefaultText}>
                        {t("groupSingle")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth key={`${isAssignedToUser}-field`}>
                <Controller
                  control={control}
                  name={isAssignedToUser ? "user" : "fleet"}
                  rules={{
                    required: t("field.is.required", {
                      field: t(isAssignedToUser ? "userSingle" : "groupSingle"),
                    }),
                  }}
                  render={(field) => (
                    <CustomAutoComplete
                      {...field}
                      label={`${t(
                        isAssignedToUser ? "User List" : "Group List"
                      )} *`}
                      optionLabel={(item) => (isAssignedToUser
                          ? `${item?.firstName} ${item?.lastName}`
                          : item?.companyName)}
                      options={
                        (isAssignedToUser ? sameFleetUsers : userFleet) ?? []
                      }
                      value={getFieldValue(
                        sameFleetUsers,
                        isAssignedToUser ? "user" : "fleet"
                      )}
                      onChange={(value) => {
                        field.field.onChange(value)
                        setValue(
                          "assignedTo",
                          isAssignedToUser ? "user" : "fleet"
                        );
                      }}
                      error={!!errors.user?.message}
                      helperText={!!errors.user && errors.user?.message}
                      disableCloseOnSelect={false}
                      disableClearable
                    />
                  )}
                />
              </FormControl>
              <CustomSwitch
                  {...register("alertOnAssignment")}
                  checked={watch("alertOnAssignment")}
                  label={t("Alert on assignment")}
                  handleChange={(e) => setValue("alertOnAssignment", e.target.checked)}
              />
            </Box>
          </Box>
        )}
      </CustomDialog>
      <ConfirmMessage
        message={confirmDialog.message}
        openStart={confirmDialog.open}
        onCancel={() => {
          setValue("keepOld", null);
          setConfirmDialog({
            open: false,
            message: null,
          });
        }}
        onConfirm={() => {
          setValue("keepOld", false);
          onSubmit(watch());
        }}
        buttonConfirm={t("Confirm")}
        buttonCancel={t("Cancel")}
        isLoading={isSumbitLoading}
      />
    </>
  );
}

const logicalConnectors = [
  { name: "List of item Data for this process", key: "ITEM_DATA_BASED" },
  { name: "Exception Type/Reason", key: "EXCEPTION_BASED" },
];

const conditions = [
  { name: "GREATER", key: GREATER },
  { name: "SMALLER", key: SMALLER },
  { name: "IS.EQUAL", key: "EQUAL" },
  { name: "CONTAINS", key: "CONTAINS" },
];
