import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";

const useStyles = makeStyles({
  dot: {
    backgroundColor: ({ statusBGColor }) => statusBGColor,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  }
});

export function StatusCircle({ customSpace, statusBGColor }) {
  const classes = useStyles({ statusBGColor });

  return (
    <Badge
      className={customSpace}
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
      classes={{ dot: classes.dot }}
    />
  );
}

export default StatusCircle;
