import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { styled } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import {
  checkAwsUserExists,
  createUserAWS,
} from "../../../redux/actions/services";

import logoImg from "../../../assets/logo.png";
import LanguageHeader from "../../../components/Language";
import CustomButton from "../../../components/CustomButton";
import useStyles from "./style";

const AWS_TOKEN_KEY = "x-amzn-marketplace-token";

function SignupForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [awstoken, setAwsToken] = useState(null);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const [open, setOpen] = useState(false);
  const [openWithError, setOpenWithError] = useState(false);
  const [openWithSuccess, setOpenWithSuccess] = useState(false);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return
    setOpen(false)
    history.push("/")
  };
  const handleCloseWithError = (event, reason) => {
    if (reason && reason === "backdropClick") return
    setOpenWithError(false)
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  useEffect(() => {
    const results = decodeURIComponent(document.location.search).split(
      `${AWS_TOKEN_KEY}=`,
    );
    const awstokenvalue = results === null ? null : results[1];
    setAwsToken(awstokenvalue);

    dispatch(checkAwsUserExists({ awstoken: awstokenvalue }))
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res.status === 200) {
          if (res.data === true) {
            return (
              <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  fullWidth
                >
                <BootstrapDialogTitle>
                  {t("Information")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    {t("You are already signed up")}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <CustomButton autoFocus onClick={handleClose}>
                    {t("Go to SmartRoby")}
                  </CustomButton>
                </DialogActions>
              </BootstrapDialog>
            )
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    setError("");

    if (!awstoken) {
      setError(`${t("Aws registration token missing")} !`);
      return;
    }

    if (data.email !== data.email_confirmation) {
      setError(`${t("Email not matching")} !`);
      return;
    }

    setSaving(true);
    dispatch(
      createUserAWS({ ...data, awstoken }, (res) => {
        if (res.status === 200) {
          setOpenWithSuccess(true)
          setTimeout(() => {
            setOpenWithSuccess(false)
            history.push("/")
          }, 2500)
          return (
            <BootstrapDialog
                aria-labelledby="dialog-success"
                open={openWithSuccess}
                fullWidth
              >
              <BootstrapDialogTitle style={{ color: "green" }}>
                {t("Success")}
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  {t("Registration was successfull, you will be redirected")}
                </Typography>
              </DialogContent>
              <LinearProgress color="success" />
            </BootstrapDialog>
          )
        }

        return (
          <BootstrapDialog
              aria-labelledby="dialog-error"
              open={openWithError}
              fullWidth
            >
            <BootstrapDialogTitle style={{ color: "red" }}>
              {t("Error")}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                {t("Registration failed")}
              </Typography>
            </DialogContent>

            <DialogActions>
              <CustomButton autoFocus onClick={handleCloseWithError}>
                {t("Close")}
              </CustomButton>
            </DialogActions>
          </BootstrapDialog>
        )
      }),
    ).finally(() => {
      setLoading(false);
      setSaving(false);
    });
  };
  if (loading) return <CircularLoader />;

  return (
    <Grid>
      <div className={classes.localeRoot}>
        <LanguageHeader />
      </div>

      <Card className={classes.card}>
        <DialogContent className={classes.DialogContentRoot}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
            autoComplete="off"
          >
            <Grid container justify="center" spacing={3}>
              <Grid item xs={10}>
                <Box mb={3} textAlign="center">
                  <img
                    src={logoImg}
                    width={320}
                    alt="SmartRoby logo"
                  />
                  <Typography
                    color="primary"
                    style={{ fontSize: "2.1rem", marginTop: "16px" }}
                  >
                    {t("Sign up to SmartRoby")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="companyName"
                  id="companyName"
                  label={t("companyName")}
                  autoComplete="companyName"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  size="small"
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="firstName"
                  id="username-text"
                  label={t("firstName")}
                  autoComplete="firstName"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  size="small"
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="lastName"
                  id="username-text"
                  label={t("lastName")}
                  autoComplete="lastName"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  size="small"
                  className={classes.inputField}
                />
              </Grid>

              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="email"
                  id="email"
                  label={t("Enter your mail")}
                  autoComplete="email"
                  type="email"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  size="small"
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="email_confirmation"
                  id="email_confirmation"
                  label={t("Confirm your mail")}
                  type="email"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  size="small"
                  className={classes.inputField}
                />
              </Grid>

              <Grid item xs={10}>
                {error && (
                  <Typography className={classes.error} variant="body2">
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid container item xs={10} justify="center">
                <Grid item>
                  <CustomButton
                    view="primary"
                    type="submit"
                    className={classes.button}
                    disabled={!isValid || saving}
                  >
                    {saving ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      t("Register")
                    )}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogContent className={classes.DialogContentRoot}>
          <Typography
            className={classes.footer}
            variant="body2"
            color="textSecondary"
          >
            {t("Copyright © Smart-Roby - All rights reserved.")}
          </Typography>
        </DialogContent>
      </Card>
    </Grid>
  );
}

export default SignupForm;
