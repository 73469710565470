import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "../style";
import { TextField, FormControl } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { formatDatePickerByLanguage, getLocale } from "util";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";

function DateInputs({ isReadOnly, isEdit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, watch, setValue } = useFormContext(); // Obtenir le contexte du formulaire

  const watchStartDate = watch("startDate");
  const watchEndDate = watch("endDate");

  // Validation personnalisée pour comparer les dates
  const validateDate = () => {
    if (watchStartDate && watchEndDate) {
      const startDate = new Date(watchStartDate);
      const endDate = new Date(watchEndDate);
      if (startDate > endDate) {
        return t("Start date must be less than the end date");
      }
    }
    return true;
  };
  useEffect(() => {
    if (!isEdit && !isReadOnly) {
      const currentDate = new Date();
      changeDate(currentDate, "startDate");
      changeDate(currentDate.setFullYear(currentDate.getFullYear() + 1), "endDate");
    }
  }, []);

  const changeDate = (date, formValue) => {
    setValue(formValue, date);
    validateDate();
  };
  return (
    <>
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Box
            component={Grid}
            className={classes.date}
            display="flex"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1" display="inline">
                {t("Start Date")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl
                  className={classes.flexEnd}
                  margin="normal"
                  fullWidth
                >
                  <DatePicker
                    InputProps={{
                      endAdornment: <CalendarToday fontSize="small" className={classes.inputDateIcon} />,
                      className: classes.inputDate,
                    }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("startDate")}
                    clearable
                    value={watchStartDate}
                    disablePast={false}
                    onChange={(date) => changeDate(date, "startDate")}
                    format={formatDatePickerByLanguage(false)}
                    placeholder={t("Start Date")}
                    helperText={false}
                    error={false}
                    disabled={isReadOnly}
                    maxDate={watchEndDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Box
            component={Grid}
            className={classes.endDate}
            display="flex"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1" display="inline">
                {t("End Date")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl
                  className={classes.flexEnd}
                  margin="normal"
                  fullWidth
                >
                  <DatePicker
                    InputProps={{
                      endAdornment: <CalendarToday fontSize="small" className={classes.inputDateIcon} />,
                      className: classes.inputDate,
                    }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("endDate")}
                    clearable
                    value={watchEndDate}
                    disablePast={false}
                    onChange={(date) => changeDate(date, "endDate")}
                    format={formatDatePickerByLanguage(false)}
                    placeholder={t("End Date")}
                    helperText={false}
                    disabled={isReadOnly}
                    error={false}
                    minDate={
                      watchStartDate || new Date().getTime() + 24 * 3600 * 1000
                    }
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default DateInputs;
