import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@redux-requests/react";
import Grid from "@material-ui/core/Grid";
import { FETCH_CURRENT_USER } from "redux/constants";
import { isSubModulePermitted } from "components/HasPermission";
import Executions from "./Executions";
import ExceptionsPage from "pages/ExceptionsPage";
import SubPageContainer from "components/SubPageContainer";
import SubPagePanel from "components/SubPagePanel";
import { exceptionCurrentModuleRoutes } from "util/index";
import useStyles from "./style";

export default function ProcessExecutionPage(props) {
  const history = useHistory();
  const routes = ["/executions/history", "/exceptions/exception", `/exceptions/${exceptionCurrentModuleRoutes.PROCESSES.route}`];
  const classes = useStyles();
  const value = (!props.currentUserItems) && routes.indexOf(history.location.pathname) !== -1 ? routes.indexOf(history.location.pathname) : 0;
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });

  return (
    <SubPageContainer>
      <div className={classes.rootTabs}>
        <Grid className={classes.firstGrid}>
          {isSubModulePermitted(currentUser, "Executions") && (
            <SubPagePanel value={value} index={0}>
              <Executions />
            </SubPagePanel>
          )}
          {isSubModulePermitted(currentUser, "Process Item") && (
          <SubPagePanel value={value} index={1}>
            <ExceptionsPage page="Process Item" currentUserItems={props.currentUserItems} />
          </SubPagePanel>
          )}
          {isSubModulePermitted(currentUser, "Executions") && (
            <SubPagePanel value={value} index={2}>
              <ExceptionsPage page="Executions" />
            </SubPagePanel>
          )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
