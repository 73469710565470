import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    topMargin: {
        marginTop: theme.spacing(3),
    },
    boxSectionTitle: {
        backgroundColor: theme.palette.background.default,
        position: "absolute",
        top: -10,
        left: 25,
        color: theme.palette.primary.dark,
        fontSize: "0.8rem",
        fontWeight: 600,
        padding: theme.spacing(0, 1)
    },
    boxSection: {
        position: "relative",
        paddingTop: theme.spacing(2),
        border: `1px solid ${theme.palette.primary.light}`,
    }
}));
