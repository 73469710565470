import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    fontSize: "0.725rem",
    marginLeft: theme.spacing(0.3),
    marginBottom: ({ alignItems, marginBottom }) => theme.spacing(alignItems ? marginBottom : -0.1),
    color: theme.palette.secondary.main
  },
}));

const InformationIcon = ({
 titleContent, originalContent, justifyContent, alignItems, marginBottom, showTitle = true, placement = "top"
}) => {
  const classes = useStyles({ alignItems, marginBottom });
  const [showIcon, setShowIcon] = useState(false);
  const [showTooltip, setShwoTooltip] = useState(false);
  return (
    <Box onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} display="flex" alignItems={alignItems ?? "center"} justifyContent={justifyContent}>
      {originalContent}
      {((showIcon && showTitle) || !originalContent) && (titleContent || titleContent === 0) && titleContent !== "" && (
        <Tooltip open={showTooltip} placement={placement} title={titleContent}>
          <InfoOutlinedIcon
              className={classes.infoIcon}
              id="info_icon"
              onMouseEnter={() => setShwoTooltip(true)}
              onMouseLeave={() => setShwoTooltip(false)}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default InformationIcon;
