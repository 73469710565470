import React from "react";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../../../../components/CustomButton";

function Transition(props) {
  return <Slide direction="up" {...props} />; // eslint-disable-line react/jsx-props-no-spreading
}

export default function AlertMessage({
  handleClose,
  handleSubmit,
  isOpen,
  hasTwoButtons = false,
  submitButtonText,
  title,
  text,
}) {
  return (
    <CustomDialog
      open={isOpen}
      transition={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {hasTwoButtons ? (
          <CustomButton onClick={handleClose} variant="raised" color="primary">
            CANCEL
          </CustomButton>
        ) : null}
        <CustomButton onClick={handleSubmit} color="primary">
          {submitButtonText}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
}
