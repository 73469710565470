import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 0,
  size: 5,
};

const pendingAutomationFilters = createSlice({
  initialState,
  name: "pendingAutomation",
  reducers: {
    resetFilter: () => ({
      ...initialState,
    }),
    updateFilterPageNo: (state, action) => ({
      ...state,
      page: action.payload.page,
    }),
    updateFilterPageSize: (state, action) => ({
      ...state,
      size: action.payload.size,
    }),
  },
});

export const { updateFilterPageNo, updateFilterPageSize, resetFilter } = pendingAutomationFilters.actions;
export default pendingAutomationFilters.reducer;
