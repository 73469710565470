import React from "react";
import { useFormState, Controller } from "react-hook-form";
import { get } from "lodash";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "../style"

function SelectTextField({
  fieldName,
  label,
  options,
  rules,
  disabled,
  defaultValue,
  multiple,
  className,
  readOnly,
  fullWidth
}) {
  const { errors } = useFormState();
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          name={fieldName}
          rules={rules}
          render={({ field }) => (
            <FormControl
              fullWidth={fullWidth}
              className={classes.selectFormControl}
              margin="none"
            >
              {label && (
                <InputLabel id={`${fieldName}-label`}>{label}</InputLabel>
              )}
              <Select
                  key={field.value}
                disabled={disabled}
                labelId={label}
                id={fieldName}
                label={label}
                onChange={(e) => {
                  field.onChange(e);
                }}
                onBlur={field.onBlur}
                multiple={multiple}
                value={field.value}
                defaultValue={defaultValue}
                inputRef={field.ref}
                error={Boolean(errors && get(errors, `${fieldName}.message`))}
                className={className}
                inputProps={{ readOnly }}
              >
                {options}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {errors && get(errors, `${fieldName}.message`) && (
          <Typography variant="caption" color="error" component="h1">
            {get(errors, `${fieldName}.message`)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
export default SelectTextField;
