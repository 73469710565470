import { FETCH_SCHEDULE, UPDATE_PROCESS } from "../../constants";
import { URL_SCHEDULE, URL_SR_QUEUES } from "../../constants/endpoints";

export const fetchScheduleByProcess = (
    id,
    page,
    size,
    sortField,
    sortOrder,
    frequencies,
) => {
    if (!page && !size) {
        page = 0;
        size = 5;
    }
    return {
        type: `LIST_${FETCH_SCHEDULE}`,
        request: {
            url: `${URL_SCHEDULE}processes/${id}`,
            params: {
                page,
                size,
                sortField,
                sortOrder,
                frequencies: `${frequencies}`,
            }
        },
    };
};

export const saveSchedule = (schedule) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SCHEDULE}`,
        method: "post",
        data: schedule,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const processHasSchedules = (processId) => ({
    type: "PROCESS_HAS_SCHEDULES",
    request: {
        url: `${URL_SCHEDULE}process/${processId}`,
        method: "get",
    },
});
export const processCountSchedules = (processId) => ({
    type: "PROCESS_COUNT_SCHEDULES_AND_NO_ROBOTS_COUNT",
    request: {
        url: `${URL_SCHEDULE}process/${processId}/migration-check`,
        method: "get",
    },
});

export const deleteSchedule = (id, onSuccess, onError) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SCHEDULE}${id}`,
        method: "delete",
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess && response.status < 400) onSuccess();
        return response;
      },
      onError: (error) => {
          if (onError) onError();
          return error;
      },
    },
});

export const fetchSchedule = (id, onSuccess, onError) => ({
    type: FETCH_SCHEDULE,
    request: {
        url: `${URL_SCHEDULE}${id}`,
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        }
    },
});
export const updateSchedule = (schedule) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SCHEDULE}`,
        method: "put",
        data: schedule,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const saveScheduleInSRQueues = (schedule) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SR_QUEUES}/scheduler/`,
        method: "post",
        data: schedule,
    },
    meta: {
        onSuccess: (response) => response,
    },
});

export const updateScheduleInSRQueues = (schedule) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SR_QUEUES}/scheduler/`,
        method: "put",
        data: schedule,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const enableOrDisableSRQScheduler = (id, isDisabled, onSuccess, onError) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SR_QUEUES}/scheduler/status/${id}/${isDisabled ? "DISABLE" : "ENABLE"}`,
        method: "put",
        headers: { "Content-Type": "application/json" }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const enableOrDisableScheduler = (id, isDisabled, onSuccess, onError) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SCHEDULE}status/${id}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        params: { isDisabled }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
