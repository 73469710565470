import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useQuery } from "@redux-requests/react";
import { deleteOrchestrators } from "../../../../../../redux/actions/services";
import { isFleetAdministrator, displayDeletedItems, isOnlyCurrentUserSelected } from "util";
import ConfirmMessage from "../../../../../../components/ConfirmMessage";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import useStyles from "../../../../../../components/TableComponents/style";

export default function OrchestratorTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const onlyCurrentUserSelected = isOnlyCurrentUserSelected(selected, currentUser);

  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteOrchestrators(
        newSelected?.map((orchestrator) => orchestrator?.id),
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(displayDeletedItems(selected, "name", t("orchestratorSingle"), t("orchestratorPlural"), t));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };

  const headerCells = [
    { label: "" },
    { label: t("Name") },
    { label: t("Type") },
    { label: t("resource.management.menu.title"), titleInfo: t("The number of resources linked to this orchestrator") },
    { label: t("Automations"), titleInfo: t("The number of automations linked to this orchestrator") },
    { label: t("orchestrator.management.formControl.sessionNumber"), titleInfo: t("Number of licences allowed to use on this orchestrator") },
    { label: t("orchestrator.management.databaseServer") },
    { label: t("Last session synched"), titleInfo: t("The date of the latest session synched by SmartRoby") },
    {
      label:
          currentUser?.fleet?.instanceOwner
          && isFleetAdministrator(currentUser) && data?.totalElements > 0 && (
          <Grid container item justify="flex-end" alignItems="center">
            <Grid item>
              <CustomDeleteIcon
                      className={classes.rowActionButtons}
                      aria-label="delete"
                      disabled={
                          isEmpty(selected)
                          || onlyCurrentUserSelected
                          || onlyCurrentUserInList
                      }
                      onClick={() => {
                        setOpenDeletePopup(true);
                      }}
                  />
              <ConfirmMessage
                      message={t(
                          "orchestrator.management.deleteOrchestratorsActionMessage",
                      )}
                      openStart={openDeletePopup}
                      onCancel={handleCloseConfirmationPopup}
                      onConfirm={onClick}
                      buttonConfirm={t("Delete")}
                      buttonCancel={t("Cancel")}
                      isLoading={isLoading}
                  />
            </Grid>
            <Grid item>
              <Checkbox
                      checked={checkedAll && !onlyCurrentUserInList}
                      className={classes.rowActionButtons}
                      indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                      onChange={(e) => {
                        handleSelectedAll(e.target.checked);
                      }}
                      disabled={onlyCurrentUserInList}
                  />
            </Grid>
          </Grid>
          ),
        width: 280,
    }
  ]

  return (
    <CustomTableHeader labels={headerCells} />
  );
}
