import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import { deleteOrchestrations } from "../../../../redux/actions/services";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import useStyles from "../../../../components/TableComponents/style";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import { Tooltip } from "@material-ui/core";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";

export default function OrchestrationTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const onlyCurrentUserSelected = selected.length === 1 && selected[0] === Number(currentUser.id);
  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteOrchestrations(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("orchestration.deleted.success"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };

  const headerCells = [
    { label: "" },
    { label: t("") },
    { label: t("Description") },
    { label: t("Process") },
    { label: t("Trigger") },
    { label: t("Trigger details") },
    { label: t("Resource group") },
    {
      label:
          data?.totalElements > 0 && (
            <Grid container item xs={12} justify="flex-end" alignItems="center">
              <Grid item>
                <ConfirmMessage
                    message={t("orchestration.management.deleteSelected.confirmMsg")}
                    openStart={openDeletePopup}
                    onCancel={handleCloseConfirmationPopup}
                    onConfirm={onClick}
                    buttonConfirm={t("Accept")}
                    buttonCancel={t("Cancel")}
                    isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <CustomDeleteIcon
                    id="tag-admin-delete-btn"
                    disabled={selected.length === 0}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDeletePopup(true);
                    }}
                    className={classes.rowActionButtons}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Checkbox
                    checked={checkedAll && !onlyCurrentUserInList}
                    className={classes.rowActionButtons}
                    indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                    onChange={(e) => {
                      handleSelectedAll(e.target.checked);
                    }}
                    disabled={onlyCurrentUserInList}
                />
              </Grid>
            </Grid>
          ),
        width: 175
    }
  ]

  return (
    <CustomTableHeader labels={headerCells} />
  );
}
