import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useForm, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { saveLink, updateLink } from "redux/actions/services/links";
import ConfirmMessage from "components/ConfirmMessage";
import CustomButton from "components/CustomButton";
import CustomDialog from "pages/Services/components/CustomDialog";
import DocumentationRow from "pages/AutomationsPending/ConfigurationSteps/LinkedDocumentation/DocumentationRow";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import useStyles from "./style";

function checkHttpsUrl(string) {
  let givenURL;
  try {
    givenURL = new URL(string);
  } catch (error) {
    return false;
  }
  return givenURL.protocol === "https:";
}
function LinkedDocumentation(props) {
  const { process } = props;
  const processId = process ? process?.id : null;
  const { t } = useTranslation();
  const classes = useStyles();
  const [dataToSave, setDataToSave] = useState(null);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = useState(false);
  const [linkEdit, setLinkEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkId, setLinkId] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);
  const automationForm = useFormContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });

  const postLinkAction = (result, successMsg, failureMsg) => {
    if ([200, 201].includes(result.status)) toast.success(t(successMsg));
    else if (result?.error?.response?.status === 406) toast.error(t(result?.error?.response.data.title));
    else toast.error(t(failureMsg));
    setOpenMsgConfirm(false);
    editShowForm();
    setIsLoading(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    if (linkEdit) {
      props.updateLink(linkId, dataToSave).then((result) => {
        postLinkAction(result, "link.update.success", "link.update.failure");
        setDataChanged(true);
      });
    } else {
      props.saveLink(dataToSave).then((result) => {
        postLinkAction(result, "link.add.success", "link.add.failure");
        setDataChanged(true);
      });
    }
  };
  const confirmDiscard = () => {
    setOpenMsgDiscard(false);
    toast.success(t("Changes disregarded"));
    editShowForm();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };
  const onSubmit = (data) => {
    if (data) {
      const dataToSend = data;
      dataToSend.processId = processId;
      setDataToSave(dataToSend);
    }
    setOpenMsgConfirm(true);
  };

  const editShowForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      setLinkEdit(null);
      reset({
        url: "",
        description: "",
        name: "",
      });
    }
  };
  const onEdit = (row) => {
    setLinkId(row.id);
    setLinkEdit(row);
    setShowForm(!showForm);
    reset({
      url: row.url,
      description: row.description,
      name: row.name,
    });
  };

  return (
    <>
      <CustomDialog
        open={showForm}
        onClose={() => {
          setShowForm(false);
          setLinkEdit(null);
        }}
        paperStyle={{
          style: {
            margin: "auto",
            minWidth: "40%",
            minHeight: "35%",
            borderRadius: "5px",
          },
          component: "form",
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {t("add.link")}
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogBody}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                {...register("name", {
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
                id="name"
                fullWidth
                label={t("link.name")}
                type="text"
              />
              {errors.name && (
                <Typography color="error">{t("Required")}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="url"
                {...register("url", {
                  required: true,
                  validate: (value) => checkHttpsUrl(value),
                })}
                fullWidth
                label={t("link.hypertextUrl")}
                type="text"
              />
              {errors.url && (
                <Typography color="error">{t("link.validate.url")}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                {...register("description", {
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
                id="description"
                fullWidth
                label={t("link.description")}
                type="text"
              />
              {errors.description && (
                <Typography color="error">{t("Required")}</Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            view=""
            className={classes.popupBtnCancel}
            onClick={() => setOpenMsgDiscard(true)}
          >
            {t("Cancel")}
          </CustomButton>
          <CustomButton
            view="primary"
            className={classes.popupBtnSave}
            onClick={handleSubmit(onSubmit)}
          >
            {linkEdit ? t("Edit") : t("insert.link")}
          </CustomButton>
        </DialogActions>
      </CustomDialog>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            linkEdit
              ? t("Are you sure you want to save the changes?")
              : t("confirm.add.link")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={linkEdit ? t("Save changes") : t("create.link")}
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgDiscard && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
      <Grid container className={classes.linkContainer}>
        <Grid item xs={12} className={classes.linkContainer}>
          <DocumentationRow
            dataChanged={dataChanged}
            setDataChanged={setDataChanged}
            onClickEdit={onEdit}
            processId={automationForm.getValues("id")}
            classes={classes}
            button={
              <CustomButton
                view="primary"
                size="medium"
                onClick={editShowForm}
                startIcon={<AddIcon />}
              >
                {t("add.link")}
              </CustomButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
const mapDispatchToProps = {
  saveLink,
  updateLink,
};
export default connect(null, mapDispatchToProps)(LinkedDocumentation);
