export const ASK_DELETE_QUEUE = "ASK_DELETE_QUEUE"
export const CONFIRM_DELETE_QUEUE = "CONFIRM_DELETE_QUEUE"

export const ASK_ENABLE_QUEUE = "ASK_ENABLE_QUEUE"
export const CONFIRM_ENABLE_QUEUE = "CONFIRM_ENABLE_QUEUE"

export const ASK_DISABLE_QUEUE = "ASK_DISABLE_QUEUE"
export const CONFIRM_DISABLE_QUEUE = "CONFIRM_DISABLE_QUEUE"

export const QUEUE_PROCESS_ASSIGNEMENT_DIALOG_ACTION = "QUEUE_PROCESS_ASSIGNEMENT_DIALOG_ACTION"
export const QUEUE_ASSIGN_PROCESS_ACTION = "assign"
export const QUEUE_UNASSIGN_PROCESS_ACTION = "unassign"
export const CANCEL_QUEUE_ACTIONS = "cancel"
export const ASK_DELETE_ONE_QUEUE = "ASK_DELETE_ONE_QUEUE"
export const ASK_DELETE_QUEUE_FROM_ONE = "ASK_DELETE_QUEUE_FROM_ONE";
export const QUEUE_DELETED = "QUEUE_DELETED";
