import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import { CustomTextField } from "../../FormFields/CustomAutoComplete";
import useStyles from "../style";

export default function SearchField({
  searchTerm,
  setSearchTerm,
  placeholder,
  disabled,
  justify,
  xsSize = 6
}) {
  const classes = useStyles();

  return (
    <Grid item container xs={xsSize} justify={justify}>
      <CustomTextField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        variant="standard"
        fullWidth
        size="small"
        placeholder={placeholder}
        className={classes.searchField}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}
