import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { bePeriods } from "util";
import useStyles from "pages/AutomationsPending/style";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import InformationIcon from "components/InformationIcon";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";

export default function KpiSettings() {
  const form = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles()();
  const handleExceptionKpiChange = (event) => {
    if (!event.target.checked) {
      form.setValue("exceptionAccountingRate", 0);
    }
  };

  const handleExecutionsExceptionKpiChange = (event) => {
    if (!event.target.checked) {
      form.setValue("executionsExceptionAccountingRate", 0);
    }
  };
  return (
    <Box>
      <FormControl fullWidth className={classes.switchControl}>
        <CustomSwitch
          {...form.register("businessExceptionsAsCompleted")}
          defaultChecked={form.getValues("businessExceptionsAsCompleted")}
          label={
            <InformationIcon
              titleContent={t(
                "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip"
              )}
              originalContent={
                <Typography className={classes.controlLabel}>
                  {t(
                    "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems"
                  )}
                </Typography>
              }
              placement="right"
            />
          }
          fullWidth
        />
      </FormControl>
      <Box display="grid" gridTemplateColumns="1FR 200px">
        <FormControl fullWidth className={classes.switchControl}>
          <Controller
            name="exceptionAccountingRateSwitch"
            control={form.control}
            render={({ field }) => (
              <CustomSwitch
                checked={form.watch("exceptionAccountingRateSwitch")}
                handleChange={(value) => {
                  field.onChange(value);
                  handleExceptionKpiChange(value);
                }}
                name={field.name}
                label={
                  <InformationIcon
                    titleContent={t(
                      "kpi.parameter.includeExceptFinancial.tooltip"
                    )}
                    originalContent={
                      <Typography className={classes.controlLabel}>
                        {t("kpi.parameter.includeException")}
                      </Typography>
                    }
                    placement="right"
                  />
                }
              />
            )}
          />
        </FormControl>
        <CustomTextField
          fullWidth
          color="primary"
          id="exceptionAccountingRate-input"
          type="number"
          key={`exceptionAccountingRate-input-${form.watch(
            "exceptionAccountingRateSwitch"
          )}`}
          disabled={!form.watch("exceptionAccountingRateSwitch")}
          {...form.register("exceptionAccountingRate", {
            max: 100,
            min: 0,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.inputAdorn} position="end">
                %
              </InputAdornment>
            ),
          }}
          error={!!form.formState.errors?.exceptionAccountingRate}
          helperText={
            !!form.formState.errors?.exceptionAccountingRate
            && t("kpi.parameter.invalidRate")
          }
        />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1FR 200px"
        key={`executionsExceptionAccountingRate-${form.watch(
          "executionsExceptionAccountingRateSwicth"
        )}`}
      >
        <FormControl fullWidth className={classes.switchControl}>
          <Controller
            name="executionsExceptionAccountingRateSwicth"
            control={form.control}
            render={({ field }) => (
              <CustomSwitch
                checked={form.watch("executionsExceptionAccountingRateSwicth")}
                handleChange={(value) => {
                  field.onChange(value);
                  handleExecutionsExceptionKpiChange(value);
                }}
                name={field.name}
                label={
                  <InformationIcon
                    titleContent={t(
                      "kpi.parameter.includeExceptFinancialKPI.tooltip"
                    )}
                    originalContent={
                      <Typography className={classes.controlLabel}>
                        {t("kpi.parameter.executionsIncludeException")}
                      </Typography>
                    }
                    placement="right"
                  />
                }
              />
            )}
          />
        </FormControl>
        <CustomTextField
          fullWidth
          color="primary"
          id="executionsExceptionAccountingRate"
          type="number"
          disabled={!form.getValues("executionsExceptionAccountingRateSwicth")}
          {...form.register("executionsExceptionAccountingRate", {
            max: 100,
            min: 0,
          })}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={!!form.formState.errors?.executionsExceptionAccountingRate}
          helperText={
            !!form.formState.errors?.executionsExceptionAccountingRate
            && t("kpi.parameter.invalidRate")
          }
        />
      </Box>
      <Divider orientation="horizontal" className={classes.divider} />
      <FormControl fullWidth className={classes.textField}>
        <Controller
          control={form.control}
          name="beCalculationPeriod"
          rules={{
            required: t("field.is.required", {
              field: t("Break-even calculation period'"),
            }),
          }}
          render={(field) => (
            <CustomAutoComplete
              {...field}
              label={
                <InformationIcon
                  titleContent={t(
                    "kpi.parameter.breakEvenCalculationPeriod.tooltip"
                  )}
                  originalContent={t("fleet.management.formLabel.bePeriod")}
                  placement="right"
                />
              }
              optionLabel="label"
              optionUuid="days"
              options={bePeriods ?? []}
              value={bePeriods?.find(
                ({ days }) => form.getValues("beCalculationPeriod") === days
              )}
              onChange={(value) => form.setValue("beCalculationPeriod", value?.days)}
              error={!!form.formState.errors.beCalculationPeriod?.message}
              helperText={
                !!form.formState.errors.beCalculationPeriod
                && form.formState.errors.beCalculationPeriod?.message
              }
              disableClearable
            />
          )}
        />
      </FormControl>
    </Box>
  );
}
