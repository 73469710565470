import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: "list",
  copiedPermissions: false,
  menuLeftWidth: 280,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,

  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    toggleView: (state) => {
      state.view = state.view === "list" ? "card" : "list";
    },
    copyPermissions: (state, action) => {
      state.copiedPermissions = action.payload;
    },
    updateMenuLeftWidth: (state, action) => {
      state.menuLeftWidth = action.payload;
    },
  },
});

// Settings reducer
export default settingsSlice.reducer;

// Actions
export const {
 toggleTheme, toggleView, copyPermissions, updateMenuLeftWidth
} = settingsSlice.actions;
