import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // RPA Opportunities
  opportunities: [],
  isOpportunitiesLoading: false,
  opportunitiesLoaded: false,

  // my cases
  myCases: [],
  isMyCasesLoading: false,
  myCasesLoaded: false,

  // pagination
  pagination: {
    opportunitiesPage: 0,
    opportunitiesRowsPerPage: 6,
    opportunitiesTotalPages: 0,
    opportunitiesTotalElements: 0,

    myCasesPage: 0,
    myCasesRowsPerPage: 6,
    myCasesTotalPages: 0,
    myCasesTotalElements: 0,
  },

  // search
  searchKeyword: "",

  // status filters
  filters: {
    accepted: false,
    rejected: false,
    draft: false,
    notSubmitted: false,
    submitted: false,
  },

  isQualifying: false,
  isSubmitting: false,
  isDeleting: false,
};

const processesSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {
    // Opportunites
    startOpportunitiesLoading: (state) => {
      state.isOpportunitiesLoading = true;
    },
    getOpportunities: (state, action) => {
      state.opportunities = action.payload.data;
      state.pagination.opportunitiesTotalPages = action.payload.data.totalPages;
      state.pagination.opportunitiesTotalElements = action.payload.data.totalProcesses;
      state.opportunitiesLoaded = true;
      state.isOpportunitiesLoading = false;
    },

    // My cases
    startMyCasesLoading: (state) => {
      state.isMyCasesLoading = true;
    },
    getMyCases: (state, action) => {
      state.myCases = action.payload.data;
      state.pagination.myCasesTotalPages = action.payload.data.totalPages;
      state.pagination.myCasesTotalElements = action.payload.data.totalProcesses;
      state.myCasesLoaded = true;
      state.isMyCasesLoading = false;
    },

    // Pagination
    changeMyCasesPage: (state, action) => {
      if (state.pagination.myCasesPage !== action.payload.newPage) {
        state.myCasesLoaded = false;
        state.pagination.myCasesPage = action.payload.newPage;
      }
    },
    changeOpportunitiesPage: (state, action) => {
      if (state.pagination.opportunitiesPage !== action.payload.newPage) {
        state.pagination.opportunitiesPage = action.payload.newPage;
        state.opportunitiesLoaded = false;
      }
    },
    changeMyCasesRowsPerPage: (state, action) => {
      state.pagination.myCasesRowsPerPage = action.payload.rowsPerPage;
      state.myCasesLoaded = false;
    },
    changeOpportunitiesRowsPerPage: (state, action) => {
      state.pagination.opportunitiesRowsPerPage = action.payload.rowsPerPage;
      state.opportunitiesLoaded = false;
    },

    // search
    searchByName: (state, action) => {
      state.searchKeyword = action.payload.name;
      if (state.searchKeyword !== "" || action.payload.name !== "") {
        state.opportunitiesLoaded = false;
        state.myCasesLoaded = false;
      }
    },

    // Updating
    startQualifying: (state) => {
      state.isQualifying = true;
    },
    startSubmitting: (state) => {
      state.isSubmitting = true;
    },
    startDeleting: (state) => {
      state.isDeleting = true;
    },
    deleteProcess: (state) => {
      state.isDeleting = false;
    },
    qualifyProcess: (state) => {
      state.isQualifying = false;
    },
    submitProcess: (state) => {
      state.isSubmitting = false;
    },

    // filters
    setFilters: (state, action) => {
      state.filters = action.payload.filters;
      state.opportunitiesLoaded = false;
      state.myCasesLoaded = false;
      state.pagination.opportunitiesPage = 0;
      state.pagination.myCasesPage = 0;
    },

    // Resting
    resetProcessesState: (state) => {
      // opportunities
      state.opportunities = [];
      state.isOpportunitiesLoading = false;
      state.opportunitiesLoaded = false;

      // my cases
      state.myCases = [];
      state.isMyCasesLoading = false;
      state.myCasesLoaded = false;

      // updating
      state.isQualifying = false;
      state.isSubmitting = false;
      state.isDeleting = false;

      // search
      state.searchKeyword = "";

      // pagination
      state.pagination = {
        opportunitiesPage: 0,
        opportunitiesRowsPerPage: 6,
        opportunitiesTotalPages: 0,
        opportunitiesTotalElements: 0,

        myCasesPage: 0,
        myCasesRowsPerPage: 6,
        myCasesTotalPages: 0,
        myCasesTotalElements: 0,
      };

      // filters
      state.filters = {
        accepted: false,
        rejected: false,
        draft: false,
        notSubmitted: false,
        submitted: false,
      };
    },
  },
});

// Processes reducer
export default processesSlice.reducer;

// Actions
export const {
  startDeleting,
  deleteProcess,
  getMyCases,
  getOpportunities,
  startQualifying,
  startSubmitting,
  qualifyProcess,
  submitProcess,
  resetProcessesState,
  startMyCasesLoading,
  startOpportunitiesLoading,
  changeMyCasesPage,
  changeOpportunitiesPage,
  changeMyCasesRowsPerPage,
  changeOpportunitiesRowsPerPage,
  searchByName,
  setFilters,
} = processesSlice.actions;
