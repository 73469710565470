import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  handleRequestMonitoringMode,
  LINE_CHART_DATA_LABELS, LINE_CHART_DATASET_OPTION,
  LINE_CHART_LABELS,
  LINE_CHART_TOOLTIP_OPTIONS,
  LINE_CHART_X_AXES,
} from "../dashboardUtils";
import theme from "../../../style/orchestratorTheme";
import { useTranslation } from "react-i18next";
import { abbreviateNumber, formtNumberByLanguage, secondsToFTPTimeLabel } from "util";
import { fetchDashboardProcessesActivity } from "../../../redux/actions/services/dashboard";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import MixedChart from "../MixedChart";
import DataNotFound from "../../../components/DataNotFound";
import useStyles from "../style";

const data = (processesData = {}, translate) => {
  const combinedData = processesData.workTime.map((workTimeItem) => ({
    entityName: workTimeItem.entityName,
    workTime: workTimeItem.values[0],
    processedItems: processesData.processedItems.find((processData) => processData?.entityName === workTimeItem.entityName).values[0],
  }));

  combinedData.sort((a, b) => {
    if (a.entityName === "others") return 1;
    if (b.entityName === "others") return -1;
    return b.processedItems - a.processedItems;
  });

  return {
    labels: combinedData.map(({ entityName }) => entityName),
    datasets: [
      {
        type: "line",
        label: translate("Processed Items"),
        yAxisID: "items",
        data: combinedData.map(({ processedItems }) => processedItems),
        ...LINE_CHART_DATASET_OPTION,
        fill: true,
      },
      {
        type: "bar",
        label: translate("Working Time (HRS)"),
        yAxisID: "runtime",
        data: combinedData.map(({ workTime }) => workTime),
        backgroundColor: theme.custom.color.chartColor1,
        barThickness: 50,
        borderColor: theme.custom.color.chartColor1,
        borderWidth: 0,
      },
    ],
  };
};

const options = (translate, userFleet) => ({
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    position: "bottom",
    labels: LINE_CHART_LABELS(2),
  },
  scales: {
    xAxes: LINE_CHART_X_AXES,
    yAxes: [
      {
        id: "runtime",
        display: true,
        position: "left",
        gridLines: {
          lineWidth: 0.3,
        },
        ticks: {
          beginAtZero: true,
          fontColor: theme.palette.primary.main,
          callback: (value) => abbreviateNumber(value),
          maxTicksLimit: 5
        },
        scaleLabel: {
          display: true,
          labelString: translate("HRS Runtime"),
          fontColor: theme.palette.primary.main,
        },
      },
      {
        id: "items",
        display: true,
        position: "right",
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          maxTicksLimit: 5,
          beginAtZero: true,
          fontColor: theme.palette.primary.main,
          callback: (value) => abbreviateNumber(value),
        },
        scaleLabel: {
          display: true,
          labelString: translate("custom.kpi.item"),
          fontColor: theme.palette.primary.main,
        },
      },
    ],
  },
  plugins: {
    datalabels: LINE_CHART_DATA_LABELS,
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        if (data.datasets[tooltipItem.datasetIndex].yAxisID === "items") return formtNumberByLanguage(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(0))
        return secondsToFTPTimeLabel(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], userFleet?.hoursPerManDay, userFleet?.daysPerYear, translate)
      }
    },
    ...LINE_CHART_TOOLTIP_OPTIONS
  }
});

export default function ProcessActivity({ xs, userFleet }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [processesActivityData, setProcessesActivityData] = React.useState();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchTimeSavedData = () => {
    setIsLoading(true);
    dispatch(
      fetchDashboardProcessesActivity({
        ...filterDashboard,
        fleetIds: filterDashboard.fleets
      })
    ).then((res) => {
      setProcessesActivityData(data(res?.data, t));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTimeSavedData();
  }, [filterDashboard, t]);

  useEffect(() => {
    handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchTimeSavedData)
  }, [refreshTime])

  return (
    <Grid item xs={xs} className={classes.container}>
      <Typography className={classes.title}>{t("Process Activity")}</Typography>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading || !processesActivityData?.labels ? (
          <CircularLoader height={310} />
      ) : processesActivityData?.labels?.length === 0 ? (
        <DataNotFound
          backgroundColor="transparent"
          boxShadow="none"
          iconHeight={150}
          iconWidth={150}
        />
      ) : (
        <MixedChart data={processesActivityData} options={options(t, userFleet)} />
      )
}
    </Grid>
  );
}
