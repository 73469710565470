import React from "react";
import { Typography, Link, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import logo from "./logo.png";
import useStyles from "../../style";

const Copyright = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const VERSION = process.env.REACT_APP_VERSION;

  return (
    <Box
      className={classes.container}
      component={Grid}
      xs={12}
      py={2}
      alignItems="center"
      justifyContent="center"
    >

      <Box component={Typography} display="flex" alignItems="center" justifyContent="center" variant="body2" color="textSecondary" align="center">
        <img
          src={logo}
          alt="logo"
          className={classes.logo}
        />
        v
        {VERSION}
        {" "}
        ©
        {" "}
        {new Date().getFullYear()}
        &nbsp;
        <Link color="inherit" href="https://novelis.io" target="_blank">
          Novelis innovation
        </Link>
        {" - "}
        {t("All rights reserved")}
      </Box>
    </Box>
  );
};

export default Copyright;
