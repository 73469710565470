import React, { useState } from "react"
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./style"
import ButtonBase from "@material-ui/core/ButtonBase";

export default function SlaItemTitle({ title, info }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [showIcon, setShowIcon] = useState(false);
    return (
      <Box display="flex" gridColumnGap={4}>
        <Typography component="span" className={classes.title} onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
          {t(title)}
          {info && showIcon && (
            <Tooltip title={t(info)} placement="right">
              <ButtonBase>
                <ErrorOutlineRoundedIcon className={classes.infoIcon} />
              </ButtonBase>
            </Tooltip>)}
        </Typography>
      </Box>
    )
}
