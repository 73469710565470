import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "unset",
    height: "100%",
  },
  deleteIcon: {
    color: theme.custom.color.color6,
  },
  tableCell: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: "2px 10px",
    fontWeight: "700",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`,
    minHeight: 53,
  },
  costTableCell: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: "10px 10px",
    fontWeight: "700",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`,
  },
  paper: {
    backgroundColor: "unset",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  linkTable: {
    [theme.breakpoints.down(1600)]: {
      maxHeight: 450,
    },
    [theme.breakpoints.up(1600)]: {
      height: 650,
    },
  },
  costTable: {
    marginTop: 30,
    [theme.breakpoints.down(1600)]: {
      height: 180,
    },
    [theme.breakpoints.up(1600)]: {
      maxHeight: 360,
    },
  },
  searchContainer: {
    paddingTop: "17px !important",
    paddingBottom: "0px !important",
  },
  linkContainer: {
    height: "100%",
  },
  urlTableCell: {
    maxWidth: theme.spacing(38),
  },
  descriptionTableCell: {
    maxWidth: theme.spacing(15),
  },
  actionsTableCell: {
    maxWidth: theme.spacing(6),
  },
  urlText: {
    fontWeight: 600,
  },
  negativeLeftMargin: {
    marginLeft: theme.spacing(-2),
  },
  directLink: {
    fontSize: "0.9rem",
    color: theme.custom.color.chartColor2,
    textDecoration: "underline",
    cursor: "pointer",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
