import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import useStyles from "../styles"

export default function CustomEditIcon({
 title, placement, size, ...rest
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <Tooltip placement={placement ?? "top"} title={title ?? t("tooltip.action.edit")} size={size}>
        <IconButton
            {...rest}
            size={size}
          >
          <EditIcon fontSize={size} className={classes.rowActionButtons} />
        </IconButton>
      </Tooltip>
    )
}
