import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 10;

const initialState = {
  searchText: "",
  process: [],
  fleet: [],
  tags: [],
  pageNo: 0,
  pageSize: defaultPageSize,
  completedItemsSelectedDurationValue: "ALL_TIME",
  fromDate: null,
  toDate: null,
  order: {
    id: "loadedDatetime",
    label: "Creation date (Descending)",
    order: "desc",
  },
};

const pendingItemsFilter = createSlice({
  name: "pendingItemsFilter",
  initialState,
  reducers: {
    initFilter: () => initialState,
    updateFilter: (state, action) => ({ ...action.payload }),
  },
});

export const { initFilter, updateFilter } = pendingItemsFilter.actions;
export default pendingItemsFilter.reducer;
