import { fade, useTheme, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import {
  deleteResourceCredential,
  fetchResourceCredentials,
} from "redux/actions/services";
import CustomDialog from "components/CustomDialog";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import { EnhancedTableToolbar } from "components/DataTable/EnhancedTableToolbar";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomPagination from "pages/Services/components/CustomPagination";
import ConfirmMessage from "components/ConfirmMessage";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import { ReactComponent as SearchIcon } from "assets/common/searchicon.svg";
import useStyles from "../style";
import CredentialForm from "../CredentialForm";

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",
    paddingLeft: 20,
    color: theme.palette.primary.main,
    border: "none",
    width: ({ width }) => width ?? "",
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: fade(theme.custom.color.staticInput, 0.3),
    },
  },
}))(TableRow);

export default function ManageCredentials({ open, handleDialogClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openCredentialForm, setOpenCredentialForm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentCredential, setCurrentCredential] = useState();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [credentialsCount, setCredentialsCount] = useState(0);

  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    id: null,
  });

  const headCells = [
    { id: "credentialName", label: "Credential Name" },
    { id: "description", label: "Description" },
    { id: "username", label: "username" },
    { id: "actions", label: "Actions" },
  ];

  const debouncer = useCallback(
    debounce((newVale) => setSearchQuery(newVale), 500),
    [searchValue]
  );

  const handleClose = () => {
    setIsLoading(false);
    handleDialogClose();
  };

  const handleSearchQueryChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncer(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleNext = (page) => {
    setPage(page + 1);
  };
  const handlePrevious = (page) => {
    setPage(page - 1);
  };

  const handleCredentialFormOpen = (credential = null, isAdd = false) => {
    if (!isAdd) setCurrentCredential(credential);
    else setCurrentCredential(null);
    setOpenCredentialForm(true);
  };

  const handleCredentialFormClose = () => {
    setOpenCredentialForm(false);
    setCurrentCredential(null);
    fetchCredentialsList();
  };

  const onSuccess = (res) => {
    setData(res?.data?.content);
    setCredentialsCount(res?.data?.totalElements);
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
    handleClose();
  };

  const fetchCredentialsList = () => {
    setIsLoading(true);
    const params = {
      searchCriteria: searchQuery,
      page,
      size: rowsPerPage,
    };
    dispatch(fetchResourceCredentials(params, onSuccess, onError));
  };

  const onDeleteSuccess = () => {
    fetchCredentialsList();
    setConfirmDialog({
      open: false,
      id: null,
    });
    toast.success(t("changes.saved.successfully"));
  };
  const onDeleteError = () => {
    toast.error(t("Unexpected Error"));
  };

  const deleteCredential = (id) => {
    dispatch(deleteResourceCredential(id, onDeleteSuccess, onDeleteError));
  };

  useEffect(() => {
    if (open) {
      fetchCredentialsList();
    }
  }, [searchQuery, page, rowsPerPage, open]);

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        width={850}
        minHeight={275}
        fullWidth
        open={open}
        disableBackdropClick
        PaperProps={{
          square: true,
        }}
        title={t("Manage Credentials")}
        withDivider
        withCloseButton
        borderRadius={6}
        closeOnEscape={!openCredentialForm}
      >
        <Box className={classes.root}>
          <EnhancedTableToolbar
            filterInput={
              <CustomTextField
                color="primary"
                id="standard-adornment-search"
                type="text"
                value={searchValue}
                onChange={handleSearchQueryChange}
                label={t("Search")}
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      stroke={theme.palette.info.main}
                      height={15}
                    />
                  ),
                }}
              />
            }
            button={
              <CustomButton
                view="primary"
                aria-label="close"
                onClick={() => handleCredentialFormOpen(null, true)}
                size="small"
                startIcon={<AddIcon />}
              >
                {t("Create Credential")}
              </CustomButton>
            }
            filterWidth={3}
          />

          <TableContainer className={classes.infoTableContainer}>
            {isLoading ? (
              <CircularLoader height={250} />
            ) : (
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                width="100%"
              >
                {!data || data?.length === 0 ? (
                  <DataNotFound
                    backgroundColor="transparent"
                    boxShadow="none"
                    iconHeight="20%"
                    iconWidth="20%"
                    padding={50}
                  />
                ) : (
                  data?.length && (
                    <>
                      <EnhancedTableHead
                        numSelected={0}
                        headCells={headCells}
                        paddingLeft={25}
                        height={40}
                      />
                      <TableBody>
                        {data?.map((row, index) => (
                          <CustomTableRow key={`assignment-credentials-row-${index}`}>
                            <CustomTableCell align="left" width={200}>
                              <Typography
                                variant="caption"
                                className={classes.tableCellText}
                              >
                                {row?.name}
                              </Typography>
                            </CustomTableCell>
                            <CustomTableCell align="left">
                              <Typography
                                variant="caption"
                                className={classes.tableCellText}
                              >
                                {row?.description || "--"}
                              </Typography>
                            </CustomTableCell>
                            <CustomTableCell align="left">
                              <Typography
                                variant="caption"
                                className={classes.tableCellText}
                              >
                                {row?.username || "--"}
                              </Typography>
                            </CustomTableCell>
                            <CustomTableCell align="left" width={150}>
                              <CustomEditIcon
                                onClick={() => handleCredentialFormOpen(row)}
                                size="small"
                              />
                              <CustomDeleteIcon
                                size="small"
                                onClick={() => setConfirmDialog({
                                    open: true,
                                    id: row?.id,
                                  })}
                              />
                            </CustomTableCell>
                          </CustomTableRow>
                        ))}
                      </TableBody>
                    </>
                  )
                )}
              </Table>
            )}
          </TableContainer>
          {data?.length > 0 && (
            <CustomPagination
              count={credentialsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onNext={handleNext}
              onPrevious={handlePrevious}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Box>
      </CustomDialog>
      {openCredentialForm && (
        <CredentialForm
          open={openCredentialForm}
          currentCredential={currentCredential}
          handleClose={handleCredentialFormClose}
        />
      )}
      <ConfirmMessage
        message={t("confirm.delete.credential")}
        openStart={confirmDialog.open}
        onCancel={() => setConfirmDialog({
            open: false,
            id: null,
          })}
        onConfirm={() => {
          deleteCredential(confirmDialog?.id);
        }}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </>
  );
}
