import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";

import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import ConfirmMessage from "../../../../components/ConfirmMessage";
// eslint-disable-next-line import/no-cycle
import CustomAlertInfo from "./CustomAlertInfo";
import {
  createCustomAlert,
  fetchCustomAlert,
  updateCustomAlert,
  resetCustomAlertScheduler,
  fetchFleetUsers, fetchFleetsForFilter, fetchConnectedUserProcesses,
} from "../../../../redux/actions/services";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import { isFleetAdministrator } from "util";
import {
  DEFAULT_FROM_TIME, DEFAULT_TO_TIME, initialDays, joinDays
} from "util/helpers/date.helpers";
import useStyles from "./style";
import CircularLoader from "components/Loaders/CircularLoader";
import { Box, Grid, Typography } from "@material-ui/core";
import EditAlertButton from "pages/AlertsManagement/components/EditButton";
import CloseAlertButton from "pages/AlertsManagement/components/CloseButton";
import CustomDialog from "components/CustomDialog";

const menuItems = {
  whenMenuItem: [
    "Number of completed items",
    "Number of items with Exceptions",
    "Number of pending items",
    "Number of process executions completed successfully",
    "Number of process executions with exceptions",
    "Number of process executions",
    "Process execution duration",
    "Item execution duration",
    "Total time saved"
  ],
  operatorMenuItem: [
    "GREATER THAN",
    "LESS THAN",
    "GREATER OR EQUAL TO",
    "LESS OR EQUAL TO",
  ],
};

export const USE_CUMULATIVE = "USE_CUMULATIVE";

export const USE_INTERVALS = "USE_INTERVALS";

export default function CustomAlertDialog({
  open, handleClose, mode, idAlert, handleCustomAlertEdit
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const currentUserTimeZone = currentUser?.timeZone;

  const [customAlert, setCustomAlert] = React.useState({});

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [endDate, setEndDate] = React.useState(null);

  const [startDate, setStartDate] = React.useState(null);

  const [frequency, setFrequency] = useState();

  const [days, setDays] = useState(initialDays);

  const [fromTime, setFromTime] = useState(DEFAULT_FROM_TIME);

  const [toTime, setToTime] = useState(DEFAULT_TO_TIME);

  const [selectedTimeZone, setSelectedTimeZone] = useState(currentUserTimeZone);

  const [unitType, setUnitType] = useState(null);

  const [useInterval, setUseInterval] = useState(USE_INTERVALS);

  const [submittedScheduler, setSubmittedScheduler] = useState({});

  const [processMenuItem, setProcessMenuItem] = useState([]);

  const [tabValue, setTabValue] = React.useState(0);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [disableSchedulerInfo, setDisableSchedulerInfo] = React.useState(false);

  const [sameFleetUsers, setSameFleetUsers] = useState();

  const [subscribersIds, SetSubscribersIds] = useState();
  const [userFleet, setUserFleet] = useState([]);
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [schedulerError, setSchedulerError] = useState();
  const [
    selectedSubscribers,
    setSelectedSubscribers,
  ] = React.useState([]);
  const [defaultFleetSelected, setDefaultFleetSelected] = useState(null);
  const handleClickOpen = (data) => {
    if (data === true || data === false) setDisableSchedulerInfo(data);
    else setDisableSchedulerInfo(false)
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    unregister,
    watch,
    trigger,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      customRules: [{
        when: "", operator: "", condition: ""
      }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customRules",
  });

  const disableFields = !(mode === "add" || mode === "edit");

  const showDefaultData = mode !== "add";

  const headerMapping = {
    add: "alerts.addHeader",
    edit: "alerts.editHeader",
    view: "alerts.displayHeader",
    default: "alerts.displayHeader"
  };

  function setDateToTheStartOfDay(date) {
    const dt = new Date(date);
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    return dt;
  }

  const onSubmit = (data) => {
    if (!isEmpty(submittedScheduler))
    {
      setOpenMsgConfirm(true);

      let alertSubscribersIds = selectedSubscribers.map((e) => e.id);
      // eslint-disable-next-line no-nested-ternary
      alertSubscribersIds = alertSubscribersIds?.length > 1
          ? alertSubscribersIds?.join(",")
          : alertSubscribersIds?.length === 1
              ? alertSubscribersIds?.join("")
              : "";
      setCustomAlert({
        ...data,
        alertSubscribersIds,
        processId: data.process,
        schedulerUnitType: submittedScheduler.unitType,
        schedulerPeriod: submittedScheduler.frequency,
        schedulerEndDate:
            submittedScheduler.endDate
            && setDateToTheStartOfDay(submittedScheduler.endDate).toISOString(),
        schedulerStartDate:
            submittedScheduler.startDate
            && setDateToTheStartOfDay(submittedScheduler.startDate).toISOString(),
        schedulerUseInterval: submittedScheduler.useInterval,
        scheduledDays: typeof submittedScheduler.days === "string" ? submittedScheduler?.days : joinDays(submittedScheduler.days),
        timezone: submittedScheduler.selectedTimeZone ?? currentUserTimeZone,
        schedulerStartTime: submittedScheduler.fromTime,
        schedulerEndTime: submittedScheduler.toTime,
        fleetId: selectedFleet,
        type: "CUSTOM"
      });
      setSchedulerError(null);
    }
    else {
      setSchedulerError(t("scheduler.is.required"))
    }
  };

  const confirmSave = () => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createCustomAlert(customAlert, (res) => {
          if (res?.status === 200) {
            toast.success(t("alert.management.formControl.saveSuccess"))
            resetFields();
            handleClose(true);
            setOpenMsgConfirm(false);
            setIsLoading(false);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(t(res.response?.data?.message))
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        updateCustomAlert(idAlert, customAlert, (res) => {
          if (res?.status === 200) {
            toast.success(t("alert.management.formControl.updateSuccess"))
            resetFields();
            handleClose(true);
            setOpenMsgConfirm(false);
            setIsLoading(false);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(t(res.response?.data?.message))
          }
        })
        .catch(() => {});
    }
  };

  const resetAdvancedSettings = () => {
    setFromTime(DEFAULT_FROM_TIME);
    setToTime(DEFAULT_TO_TIME);
    setDays(initialDays);
    setSelectedTimeZone(currentUserTimeZone);
  };

  const resetScheduler = () => {
    setFrequency(0.5);
    setEndDate(null);
    setStartDate(null);
    setUnitType(1);
    setTabValue(0);
    setUseInterval(USE_INTERVALS);
    resetAdvancedSettings();

    unregister("alertScheduler.period");
    unregister("alertScheduler.endDate");
    unregister("alertScheduler.startDate");
    unregister("alertScheduler.useInterval");

    setSubmittedScheduler({});
  };

  const handleCancel = () => setOpenMsgCancel(true);

  const cancelConfirm = () => setOpenMsgConfirm(false);

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleAcceptCancelForm = () => {
    resetFields()
    setOpenMsgCancel(false);
    handleClose(false);
  };

  const handleChangeEndDate = (value) => {
    setEndDate(value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
  };

  const handleChangeFrequency = (value) => {
    setFrequency(value);
  };

  const handleSubscribersChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedSubscribers(value);
    setValue("sub", value);
  }

  const handleChangeUseInterval = (event) => {
    setUseInterval(event.target.value);
  };

  const handleDeleteScheduler = () => {
    setIsLoading(true);
    resetScheduler();
  };

  const confirmSaveScheduler = () => {
    setValue("alertScheduler.period", frequency || 0.5);
    setValue("alertScheduler.endDate", endDate);
    setValue("alertScheduler.startDate", startDate);
    setValue("alertScheduler.useInterval", useInterval);

    setSubmittedScheduler({
      frequency: frequency || 0.5,
      endDate,
      startDate,
      useInterval,
      unitType: unitType || 1,
      days,
      fromTime,
      toTime,
      selectedTimeZone
    });

    toast.success(showDefaultData ? t("Schedule modified successfully") : t("Schedule saved successfully"))
  };

  const discardSchedulerChanges = () => {
    setFrequency(
      submittedScheduler.frequency ? submittedScheduler.frequency : 0.5,
    );
    setEndDate(submittedScheduler.endDate);
    setStartDate(submittedScheduler.startDate);
    setDays((typeof submittedScheduler?.days === "string" ? initialDays?.filter(({ value }) => submittedScheduler?.days?.split(",")?.includes(value)) : submittedScheduler?.days) ?? initialDays);
    setSelectedTimeZone(submittedScheduler.selectedTimeZone ?? currentUserTimeZone);
    setFromTime(submittedScheduler.fromTime ?? DEFAULT_FROM_TIME);
    setToTime(submittedScheduler.toTime ?? DEFAULT_TO_TIME);
    setUseInterval(
      submittedScheduler.useInterval
        ? submittedScheduler.useInterval
        : USE_INTERVALS,
    );
    setUnitType(submittedScheduler.unitType ? submittedScheduler.unitType : 1);
    setTabValue(
      submittedScheduler.unitType - 1 ? submittedScheduler.unitType - 1 : 0,
    );
    toast.success(t("Changes disregarded"))
  };

  const handleChangeTab = (event) => {
    const value = event?.value;
    setTabValue(value);
    setUnitType(value + 1);
    if (value === 0) {
      setFrequency(0.5);
      setValue("alertScheduler.period", 0.5);
    } else {
      setFrequency(1);
      setValue("alertScheduler.period", 1);
    }
  };
  const getUserProcessByFleet = (fleetId) => {
    dispatch(fetchConnectedUserProcesses([fleetId])).then((res) => setProcessMenuItem(res.data?.list?.filter((item) => !item.isDisabled)));
  }
  useEffect(() => {
    getUserProcessByFleet(selectedFleet)
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  useEffect(() => {
    SetSubscribersIds([currentUser?.id]);
    setValue("sub", [currentUser?.id]);
  }, [open]);
  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleetsForFilter()).then((res) => {
        setUserFleet(res.data);
      });
    }
  }, [isInstanceOwner])
  const handleChangeFleet = (e) => {
    if (e) {
      setSelectedFleet(e?.target?.value?.id);
    }
  }
  const getUsersFleet = (fleetId) => {
    dispatch(fetchFleetUsers(fleetId)).then((result) => {
      setSameFleetUsers(result.data);
      const selectedUsers = result?.data?.filter((e) => subscribersIds?.includes(e?.id.toString()))
      setSelectedSubscribers(selectedUsers);
    });
  }
  useEffect(() => {
    getUsersFleet(currentUser?.fleet?.id);
  }, [subscribersIds])
  useEffect(() => {
    if (selectedFleet) {
      setValue("process", "");
      getUsersFleet(selectedFleet);
      getUserProcessByFleet(selectedFleet);
    }
  }, [selectedFleet]);

  useEffect(() => {
    if (mode !== "add") {
      setIsDataLoading(true);
      dispatch(fetchCustomAlert(idAlert)).then((res) => {
        const userIds = !(res?.data?.alertSubscribersIds?.split(",")) ? [] : (res?.data?.alertSubscribersIds?.split(","));
        SetSubscribersIds(userIds);
        setSelectedFleet(res?.data?.fleetId);
        setDefaultFleetSelected(res?.data?.tinyFleetDto);
        setValue("alertName", res?.data?.alertName);
        setValue("alertDescription", res?.data?.alertDescription);
        setValue("flag", res?.data?.flag);
        setValue("status", res?.data?.status);
        setValue("sub", userIds);
        const customRules = res?.data?.customRules;
        for (let i = 0; i < customRules.length - 1; i += 1) {
          append({
            when: "",
            operator: "",
            condition: "",
            exceptionType: "",
            exceptionReason: "",
          });
        }
        for (let i = 0; i < customRules.length; i += 1) {
          setValue(`customRules[${i}].when`, customRules[i]?.when);
          setValue(`customRules[${i}].operator`, customRules[i]?.operator);
          setValue(`customRules[${i}].condition`, customRules[i]?.condition);
          setValue(`customRules[${i}].exceptionType`, customRules[i]?.exceptionType);
          setValue(`customRules[${i}].exceptionReason`, customRules[i]?.exceptionReason);
        }
        setValue("process", customRules.length > 0 ? res?.data?.process : "");
        setUnitType(
          res?.data?.schedulerUnitType == null
            ? 1
            : res?.data?.schedulerUnitType,
        );
        setTabValue(
          res?.data?.schedulerUnitType == null
            ? 0
            // eslint-disable-next-line no-unsafe-optional-chaining
            : res?.data?.schedulerUnitType - 1,
        );
        res?.data?.schedulerPeriod
          && setValue("alertScheduler.period", res?.data?.schedulerPeriod);
        setFrequency(res?.data?.schedulerPeriod);
        res?.data?.schedulerEndDate
          && setValue("alertScheduler.endDate", res?.data?.schedulerEndDate);
        setEndDate(res?.data?.schedulerEndDate);
        res?.data?.schedulerStartDate
          && setValue(
            "alertScheduler.startDate",
            setDateToTheStartOfDay(res?.data?.schedulerStartDate),
          );
        setStartDate(setDateToTheStartOfDay(res?.data?.schedulerStartDate));
        res?.data?.schedulerUseInterval
          && setValue(
            "alertScheduler.useInterval",
            res?.data?.schedulerUseInterval,
          );
        setUseInterval(
          res?.data?.schedulerUseInterval
            ? res?.data?.schedulerUseInterval
            : USE_INTERVALS,
        );
        setDays(res?.data?.scheduledDays ? days?.map((day) => ({ ...day, checked: res?.data?.scheduledDays?.split(",").includes(day.value) })) : initialDays);
        setFromTime(res?.data?.schedulerStartTime ?? DEFAULT_FROM_TIME);
        setToTime(res?.data?.schedulerEndTime ?? DEFAULT_TO_TIME);
        setSelectedTimeZone(res?.data?.timezone)
        setSubmittedScheduler({
          unitType: res?.data?.schedulerUnitType,
          frequency: res?.data?.schedulerPeriod,
          endDate: res?.data?.schedulerEndDate,
          startDate: res?.data?.schedulerStartDate,
          days: res?.data?.scheduledDays,
          from: res?.data?.schedulerStartTime,
          to: res?.data?.schedulerEndTime,
          selectedTimeZone: res?.data?.timezone,
          useInterval: res?.data?.schedulerUseInterval,
        });
        setIsDataLoading(false);
      });
    }
  }, [idAlert]);

  const resetFields = () => {
    reset();
    setSelectedSubscribers([]);
    setSelectedFleet(null);
    setDefaultFleetSelected(null);
    SetSubscribersIds([currentUser?.id]);
    setSameFleetUsers([]);
    resetScheduler();
    setCustomAlert({});
  }

  return (
    <CustomDialog
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.dialogPaper,
          }}
          actionElements={!disableFields && [
            {
                label: t("Cancel"),
                onClick: () => handleCancel(false),
                variant: "contained",
                className: classes.resetButton,
                size: "medium"
            },
            {
                label: idAlert
                  ? t("alerts.management.button.update")
                  : t("alerts.management.button.save"),
                view: "primary",
                size: "medium",
                type: "submit",
                onClick: () => handleSubmit(onSubmit)(),
            }
        ]}
      >
      <Grid container justify="center">
        <Grid item xs={8} justify="flex-start">
          <Typography variant="h5" className={classes.title}>
            {t(headerMapping[mode] || headerMapping.default)}
          </Typography>
        </Grid>
        <Grid item xs={4} container justify="flex-end" spacing={4}>
          <Grid item>
            {mode === "view" && (
              <EditAlertButton
                mode={mode}
                currentUser={currentUser}
                onClick={handleCustomAlertEdit}
              />
            )}
          </Grid>
          <Grid item>
            {mode === "view" && (
              <CloseAlertButton
                onClick={() => {
                  handleClose(false);
                  resetFields();
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      { isDataLoading ? (
        <CircularLoader style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
      ) : (
        <Box className={classes.alertInfoContainer}>
          <form className={classes.fleetForm} onSubmit={handleSubmit(onSubmit)}>
            <CustomAlertInfo
              trigger={trigger}
              classes={classes}
              register={register}
              unregister={unregister}
              control={control}
              disableFields={disableFields}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              showDefaultData={showDefaultData}
              fields={fields}
              append={append}
              remove={remove}
              menuItems={menuItems}
              idAlert={idAlert}
              handleCancel={handleCancel}
              handleChangeEndDate={handleChangeEndDate}
              handleChangeStartDate={handleChangeStartDate}
              endDate={endDate}
              startDate={startDate}
              useInterval={useInterval}
              watch={watch}
              open={dialogOpen}
              handleClickOpen={handleClickOpen}
              handleCloseDialog={handleCloseDialog}
              handleChangeFrequency={handleChangeFrequency}
              handleChangeUseInterval={handleChangeUseInterval}
              discardSchedulerChanges={discardSchedulerChanges}
              frequency={frequency}
              mode={mode}
              handleChangeTab={handleChangeTab}
              value={tabValue}
              confirmSaveScheduler={confirmSaveScheduler}
              processMenuItem={processMenuItem}
              submittedScheduler={submittedScheduler}
              handleDeleteScheduler={handleDeleteScheduler}
              resetCustomAlertScheduler={resetCustomAlertScheduler}
              isLoading={isLoading}
              disableSchedulerInfo={disableSchedulerInfo}
              sameFleetUsers={sameFleetUsers}
              selectedSubscribers={selectedSubscribers}
              setSameFleetUsers={setSameFleetUsers}
              handleSubscribersChange={handleSubscribersChange}
              userFleet={userFleet}
              handleChangeFleet={handleChangeFleet}
              selectedFleet={selectedFleet}
              isInstanceOwner={isInstanceOwner}
              defaultFleetSelected={defaultFleetSelected}
              schedulerError={schedulerError}
              days={days}
              setDays={setDays}
              setError={setError}
              selectedTimeZone={selectedTimeZone}
              setSelectedTimeZone={setSelectedTimeZone}
              fromTime={fromTime}
              toTime={toTime}
              setFromTime={setFromTime}
              setToTime={setToTime}
              resetAdvancedSettings={resetAdvancedSettings}
              currentUserTimeZone={currentUserTimeZone}
            />
          </form>
          {openMsgConfirm && (
            <ConfirmMessage
              message={
                idAlert
                  ? t("alerts.management.update.confirmMsg")
                  : t("alerts.management.save.confirmMsg")
              }
              openStart={openMsgConfirm}
              onCancel={cancelConfirm}
              onConfirm={confirmSave}
              buttonConfirm={
                idAlert
                  ? t("alerts.management.button.update")
                  : t("alerts.management.button.save")
              }
              buttonCancel={t("alerts.management.button.cancel")}
              isLoading={isLoading}
            />
          )}
          {openMsgCancel && (
            <ConfirmMessage
              message={t("alerts.management.delete.discard")}
              openStart={openMsgCancel}
              onCancel={handleRejectCancelForm}
              onConfirm={handleAcceptCancelForm}
              buttonConfirm={t("alerts.management.button.discard")}
              buttonCancel={t("alerts.management.button.cancel")}
            />
          )}
        </Box>
      )}
    </CustomDialog>
  );
}
