import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "../styles"

export default function CustomDeleteIcon({
 title, placement, size, ...rest
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <Tooltip placement={placement ?? "top"} title={title ?? t("tooltip.action.delete")}>
        <IconButton
            {...rest}
            size={size}
            >
          <DeleteIcon fontSize={size} className={clsx(classes.deleteIcon, rest?.disabled && classes.disableDelete)} />
        </IconButton>
      </Tooltip>
    )
}
