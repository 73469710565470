import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function AlertSeverityInput({
  errors,
  control,
  disableFields,
  classes,
}) {
  const InfoRadio = ((props) => <Radio className={disableFields ? classes.disabledColor : classes.infoColor} {...props} />);

  const WarningRadio = ((props) => <Radio className={disableFields ? classes.disabledColor : classes.warningColor} {...props} />);

  const ErrorRadio = ((props) => <Radio className={disableFields ? classes.disabledColor : classes.errorColor} {...props} />);

  const { t } = useTranslation();

  const [value, setValue] = React.useState();

  const handleChangeFlag = (event) => {
    setValue(event.target.value);
  };

  return (
    <Grid container item alignContent="center" alignItems="left" direction="column">
      <Typography className={disableFields ? classes.flagLabelGrey : classes.flagLabel} style={{ paddingTop: "1rem" }}>
        {t("alerts.management.severity")}
      </Typography>
      <Grid container item xs={12} alignContent="center">
        <Controller
          name="flag"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          render={({ field }) => (
            <FormControl component="fieldset" disabled={disableFields}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                onChange={(event) => handleChangeFlag(event)}
                value={value}
                {...field}
                disabled={disableFields}
              >
                <FormControlLabel
                  value="INFO"
                  control={<InfoRadio />}
                  label={t("info")}
                  className={disableFields ? classes.disabledColor : classes.infoColor}
                  style={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="WARNING"
                  control={<WarningRadio />}
                  label={t("warning")}
                  className={disableFields ? classes.disabledColor : classes.warningColor}
                  style={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="ERROR"
                  control={<ErrorRadio />}
                  label={t("error")}
                  className={disableFields ? classes.disabledColor : classes.errorColor}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
        <p className={classes.validation_error}>
          {errors.flag && (
            <Typography color="error" variant="subtitle1" className={classes.optText}>
              {t(errors.flag.message)}
            </Typography>
          )}
        </p>
      </Grid>
    </Grid>
  );
}
