import React, { useEffect, useRef, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartStyles from "chartjs-plugin-style";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardWeeklyTransactions } from "redux/actions/services/dashboard";
import {
  chartPeriodLabels,
  LINE_CHART_DATA_LABELS,
  LINE_CHART_TOOLTIP_OPTIONS,
  LINE_CHART_X_AXES, MONITORING_TIME, MONITORING_TIME_Params,
} from "pages/newDashboard/dashboardUtils";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { abbreviateNumber } from "util/index";
import ChartSectionHeader from "pages/newDashboard/WeeklyView/ChartSectionHeader";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "../../../../style/orchestratorTheme";
import useStyles from "pages/newDashboard/style";

const formattedData = (data) => ({
  labels: data?.chartPeriodLabels,
  datasets: [
    {
      xAxisID: "fte",
      data: data?.transactionsCount,
      type: "horizontalBar",
      fill: true,
      backgroundColor: theme.palette.primary.main,
      barThickness: 12,
      borderRadius: 10,
      borderColor: theme.custom.color.chartColor1,
      borderWidth: 0,
    },
  ],
});

const options = () => ({
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    display: false,
  },
  indexAxis: "x",
  scales: {
    yAxes: LINE_CHART_X_AXES({ offset: true }),
    xAxes: [
      {
        id: "fte",
        gridLines: {
          drawOnChartArea: false,
          display: false,
        },
        ticks: {
          fontColor: theme.palette.primary.main,
          beginAtZero: true,
          stepSize: 50000,
        },
        offset: false,
      },
    ],
  },
  plugins: {
    datalabels: LINE_CHART_DATA_LABELS({
      anchor: "end",
      align: "right",
    }),
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => abbreviateNumber(data.datasets[0].data[tooltipItem.index]),
    },
    ...LINE_CHART_TOOLTIP_OPTIONS,
  },
});

export default function WeeklyTransactions() {
  const classes = useStyles()();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);
  const [transactionsData, setTransactionsData] = useState();
  const fetchWeeklyTransactions = (withLoading) => {
    setIsLoading(withLoading);
    dispatch(
        fetchDashboardWeeklyTransactions({
          ...filterDashboard
        })
    ).then((res) => {
      setTransactionsData(res?.data);
      setIsLoading(false);
    });
  }

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchWeeklyTransactions, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    fetchWeeklyTransactions()
  }, [filterDashboard]);

  const chartData = formattedData({
    ...transactionsData,
    chartPeriodLabels: chartPeriodLabels(t, filterDashboard.selectedDurationValue),
  });
  const chartOption = options(10);
  const headerDataList = [
    {
      label: t("total.transactions"),
      value: abbreviateNumber(transactionsData?.totalTransactionsCount ?? 0),
    },
    {
      label: t("average.transactions"),
      value: abbreviateNumber(transactionsData?.averageTransactionsCount ?? 0),
    }
  ];

  return (
    <Box className={classes.sectionContainer}>
      <ChartSectionHeader
        title={t("transactions")}
        dataList={headerDataList}
        isLoading={isLoading}
      />
      {isLoading ? (
        <CircularLoader height={300} size={23} />
      ) : (
        transactionsData?.transactionsCount?.length > 0 && (
          <HorizontalBar
            height={230}
            plugins={[ChartDataLabels, ChartStyles]}
            data={chartData}
            options={chartOption}
          />
        )
      )}
      {!isLoading
        && (!transactionsData?.transactionsCount
          || transactionsData?.transactionsCount?.length === 0) && (
          <DataNotFound
            backgroundColor="transparent"
            boxShadow="none"
            iconHeight={100}
            iconWidth={100}
            height={300}
          />
        )}
    </Box>
  );
}
