import { GENERATE_DATA } from "redux/constants";
import { URL_GENERATION } from "redux/constants/endpoints";

const generateJsonData = (text, onSuccess) => ({
    type: GENERATE_DATA,
    request: {
        url: `${URL_GENERATION}/generate`,
        method: "post",
        headers: {
            "Content-Type": "application/json", // Set the content type to JSON
        },
        data: text,
    },
    meta: {
        onSuccess: (result) => {
            if (onSuccess) onSuccess()
            return result
        }
    }
});

export default generateJsonData;
