import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CustomDialog from "pages/Services/components/CustomDialog";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ConfirmMessage from "components/ConfirmMessage";
import CustomButton from "components/CustomButton"
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete"
import { hourPeriods } from "util/constants";
import EstimationComputing from "./ExecutionTimeComputer";
import { responseTimeCalculationOptions } from "pages/IntelligentOrchestration/util";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SlaForm({
  classes,
  // handleChangeTab,
  open,
  handleClose,
  register,
  disableFields,
  handleCloseDialog,
  errors,
  trigger,
  getValues,
  setValues,
  setIsViewMode,
  isViewMode,
  sla,
  setSlas,
  selectedSlaIndex
}) {
  const { t } = useTranslation();
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = React.useState(false);
  const [openEstimatedTimeComputingPopUp, setOpenEstimatedTimeComputingPopUp] = React.useState(false);

  const showMsgDiscard = () => {
    setOpenMsgDiscard(true);
  };

  const confirmDiscard = () => {
    setOpenMsgDiscard(false);
    setIsViewMode(false);
    handleCloseDialog();
  };

  const cancelConfirm = () => {
    setValues("orchestrationSlas", sla);
    setIsViewMode(false);
    setOpenMsgConfirm(false);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };

  const confirmSave = () => {
    setSlas(getValues("orchestrationSlas"));
    setIsViewMode(false);
    handleCloseDialog();
  };

  const minVal = 0;
  let maxVal = 5;
  const estimatedExecutionField = (period, field) => {
    let maxValMessage = "";
    if (period === "seconds" || period === "minutes") {
      maxVal = 59;
      maxValMessage = "restriction.form.min.sec.maxValue.error";
    } else if (period === "hours") {
      maxVal = 23;
      maxValMessage = "restriction.form.hour.maxValue.error";
    }
    return (
      <Grid item xs={4}>
        <CustomTextField
          {...register(`orchestrationSlas.${selectedSlaIndex}.${field}.${period}`, {
            pattern: {
              value: /^[0-9]*$/,
              message: t("restriction.form.numberInteger.error"),
            },
            min: {
              value: minVal,
              message: t("restriction.form.minValue.error"),
            },
            max: {
              value: maxVal,
              message: t(maxValMessage),
            },
          })}
          key={errors?.orchestrationSlas?.[selectedSlaIndex]?.[field]?.[period].message}
          value={getValues(`orchestrationSlas.${selectedSlaIndex}.${field}.${period}`)}
          label={t(period.charAt(0).toUpperCase() + period.slice(1))}
          error={!!errors.orchestrationSlas?.[selectedSlaIndex]?.[field]?.[period]}
          helperText={
            errors?.orchestrationSlas?.[selectedSlaIndex]?.[field]?.[period]
            && errors?.orchestrationSlas?.[selectedSlaIndex]?.[field]?.[period]?.message
          }
          onKeyUp={() => trigger(`orchestrationSlas.${selectedSlaIndex}.${field}.${period}`)}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: !!getValues(`orchestrationSlas.${selectedSlaIndex}.${field}.${period}`) || getValues(`orchestrationSlas.${selectedSlaIndex}.${field}.${period}`) === 0,
          }}
          disabled={isViewMode || disableFields}
        />
      </Grid>
    );
  }

  return (
    <CustomDialog
      onClose={handleClose}
      fullWidth
      open={open}
      disableBackdropClick
      maxWidth="false"
      classes={{
        paper: classes.slaDialogPaper,
      }}
    >
      <Grid className={classes.SlarRoot}>
        <Grid container item xs={12} spacing={1}>
          <Box className={classes.boxSection}>
            <Typography className={classes.boxSectionTitle}>
              {t("fleet.formSection.fleetInfo")}
            </Typography>
            <Grid container item justify="space-between" className={classes.fieldsGrid}>
              <Grid item xs={5}>
                <CustomTextField
                      disabled={isViewMode || disableFields}
                      fullWidth
                      InputProps={{
                        readOnly: disableFields,
                      }}
                      id="name"
                      label={t("Name")}
                      autoFocus
                      {...register(`orchestrationSlas.${selectedSlaIndex}.name`, { required: true })}
                      onChange={(e) => setValues(`orchestrationSlas.${selectedSlaIndex}.name`, e.target.value)}
                      InputLabelProps={{
                        shrink: !!getValues(`orchestrationSlas.${selectedSlaIndex}.name`),
                      }}
                  />
              </Grid>
              <Grid item xs={5}>
                <CustomTextField
                      disabled={isViewMode || disableFields}
                      fullWidth
                      InputProps={{
                        readOnly: disableFields,
                      }}
                      id="description"
                      label={t("Description")}
                      autoFocus
                      {...register(`orchestrationSlas.${selectedSlaIndex}.description`, { required: true })}
                      onChange={(e) => setValues(`orchestrationSlas.${selectedSlaIndex}.description`, e.target.value)}
                      InputLabelProps={{
                        shrink: !!getValues(`orchestrationSlas.${selectedSlaIndex}.description`),
                      }}
                  />
              </Grid>
            </Grid>
            <Grid container item justify="space-between" className={classes.fieldsGrid}>
              <Grid item xs={5}>
                <CustomTextField
                      disabled={isViewMode || disableFields}
                      fullWidth
                      InputProps={{
                        readOnly: disableFields,
                      }}
                      id="tagFilter"
                      label={t("Tag Filter")}
                      {...register(`orchestrationSlas.${selectedSlaIndex}.tagFilter`, { required: true })}
                      onChange={(e) => setValues(`orchestrationSlas.${selectedSlaIndex}.tagFilter`, e.target.value)}
                      InputLabelProps={{
                        shrink: !!getValues(`orchestrationSlas.${selectedSlaIndex}.tagFilter`),
                      }}
                  />
              </Grid>
              <Grid item xs={5}>
                <CustomAutoComplete
                      disabled={isViewMode || disableFields}
                      fullWidth
                      InputProps={{
                        readOnly: disableFields,
                      }}
                      id="responseTimeCalc"
                      label={t("Response time calculation")}
                      options={responseTimeCalculationOptions ?? []}
                      value={getValues(`orchestrationSlas.${selectedSlaIndex}.responseTimeCalc`)}
                      onChange={(e) => setValues(`orchestrationSlas.${selectedSlaIndex}.responseTimeCalc`, e)}
                  />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.boxSection}>
            <Typography className={classes.boxSectionTitle}>
              {t("Deadline")}
            </Typography>
            <Box component={Grid} item xs={9} container justify="start" gridRowGap={10} mt={1} spacing={1}>
              {hourPeriods.map((period) => estimatedExecutionField(period, "deadline"))}
            </Box>
          </Box>
          <Box className={classes.boxSection}>
            <Typography className={classes.boxSectionTitle}>
              {t("Average running time")}
            </Typography>
            <Grid container flexDirection="row" justify="space-between" alignContent="center">
              <Box component={Grid} item xs={6} container justify="start" gridRowGap={10} mt={1} spacing={1}>
                {hourPeriods.map((period) => estimatedExecutionField(period, "averageRunningTime"))}
              </Box>
              <Box component={Grid} item mt={3} xs={5} alignContent="end" justifyItems="end">
                <CustomButton
                    disabled={isViewMode || disableFields}
                    view="primary"
                    onClick={() => setOpenEstimatedTimeComputingPopUp(true)}
                    size="small"
                >
                  {t("Update using historical data")}
                </CustomButton>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <MuiDialogActions className={classes.cardActions}>
          {!(isViewMode || disableFields) ? (
            <>
              <CustomButton
                view="cancelModal"
                size="medium"
                onClick={showMsgDiscard}
                elevation={0}
              >
                {t("Cancel")}
              </CustomButton>
              <CustomButton
                view="primary"
                size="medium"
                onClick={confirmSave}
                elevation={0}
              >
                {t("Save")}
              </CustomButton>
            </>
          ) : (
            <CustomButton
              view="primary"
              size="medium"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              {t("alerts.management.button.exit")}
            </CustomButton>
          )}
        </MuiDialogActions>
      </Grid>
      {openEstimatedTimeComputingPopUp && (
        <EstimationComputing
          setValues={setValues}
          openEstimatedTimeComputingPopUp={openEstimatedTimeComputingPopUp}
          processId={getValues("processId")}
          setOpenEstimatedTimeComputingPopUp={setOpenEstimatedTimeComputingPopUp}
          classes={classes}
          selectedSlaIndex={selectedSlaIndex}
        />
      )}
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to save the changes?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={t("Save changes")}
          buttonCancel={t("Cancel")}
        />
      )}
      {openMsgDiscard && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
    </CustomDialog>
  );
}
