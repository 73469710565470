import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import { editStatusResource } from "../../../../../redux/actions/services";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import RobotStatusAction from "../../RobotStatusAction";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import { isPermitted } from "components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";

export default function RobotTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const onlyCurrentUserSelected = selected.length === 1 && selected[0] === Number(currentUser.id);
  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
      editStatusResource(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("resource.hidden.success"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };

  const headerCells = [
    { label: "" },
    { label: t("Name") },
    { label: t("resource.management.orchestratorName") },
    { label: t("Resource type") },
    { label: t("resource.management.displayStatus") },
    { label: t("resource.management.processesRunning") },
    { label: `${t("robots.occupancy")} (${t("This Month")})` },
    { label: t("Paired automations"), titleInfo: t("The number of automations that this resource is able to run") },
    { label: t("Automation dependency"), titleInfo: t("The number of automations already scheduled with this resource") },
    {
      label:
          isPermitted(currentUser, "Edit Resources") && data?.totalElements > 0 && (
            <Grid container item xs={12} justify="flex-end" alignItems="center">
              <Grid item>
                <RobotStatusAction
                    selected={selected}
                    setSelected={setSelected}
                    classes={classes}
                    items={fetchedData}
                />
                <ConfirmMessage
                    message={t("resource.management.deleteResourceActionMessage")}
                    openStart={openDeletePopup}
                    onCancel={handleCloseConfirmationPopup}
                    onConfirm={onClick}
                    buttonConfirm={t("Accept")}
                    buttonCancel={t("Cancel")}
                    isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <Checkbox
                    checked={checkedAll && !onlyCurrentUserInList}
                    className={classes.rowActionButtons}
                    indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                    onChange={(e) => {
                      handleSelectedAll(e.target.checked);
                    }}
                    disabled={onlyCurrentUserInList}
                />
              </Grid>
            </Grid>
          ),
        width: 175
    }
  ]

  return (
    <CustomTableHeader labels={headerCells} />
  );
}
