import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import InformationIcon from "components/InformationIcon";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import useStyles from "../../style";

export default function StateItem({ state, isLoading, isLast }) {
  const classes = useStyles()();
  const { t } = useTranslation();

  return (
    <Grid item container xs={state.xs} alignItems="center">
      <Grid container item xs={5} justify="flex-end">
        <img src={state.icon} alt={state.label} className={classes.stateIcon} />
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <CircularLoader justifyContent="flex-start" height={20} size={15} />
                  ) : (
                    <Typography component="h4" className={classes.stateTitle}>
                      {state.state}
                    </Typography>
                  )}
        <InformationIcon
              titleContent={state.tooltipTitle}
              originalContent={(
                <Typography component="h6" className={classes.stateSubtitle}>
                  {t(state.label)}
                </Typography>
              )}
placement="right"
          />
      </Grid>
      {!isLast && (<Divider orientation="vertical" className={classes.divider} />)}
    </Grid>

  );
}
