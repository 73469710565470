import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";
import HttpsIcon from "@material-ui/icons/Https";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  deleteOrchestrator,
  enableOrDisableOchestrator,
} from "../../../../../../redux/actions/services";
import { isFleetAdministrator, getDatePattern } from "util";
import { OrchestratorTypes } from "util/helpers/orchestrator.helper";
import i18n from "i18n";
import { format } from "date-fns";
import CustomSwitch from "../../../../../Services/components/CustomSwitch";
import { SrLicensesIcon } from "../../../../../../components/CustomIcons";
import ConfirmMessage from "../../../../../../components/ConfirmMessage";
import CustomTableRow from "../../../../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../../../../components/TableComponents/CustomTableCell";
import StatusBadge from "../../../../../../components/StatusBadge";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import { isPermitted } from "components/HasPermission";
import useStyles from "../../../../../../components/TableComponents/style";

export default function OrchestratorTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = i18n;
  const active = get(row, "status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = get(row, "id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(
      enableOrDisableOchestrator(id, active ? "DISABLE" : "ACTIVE"),
    ).then((err) => {
      resetLoaders();
      if (err?.response?.data?.detail) {
        toast.error(t(err?.response?.data?.detail));
      } else {
        fetchEntities();
      }
      setOpenConfirmationPopup(false);
    });
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteOrchestrator(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        toast.success(t("orchestrator deleted successfully"));
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
      >
        <CustomTableCell width={50}>
          <StatusBadge level={row?.status} />
        </CustomTableCell>
        <CustomTableCell
          text={row?.name}
        />
        <CustomTableCell
            text={OrchestratorTypes[row?.orchestratorType]?.label}
        />
        <CustomTableCell
          text={row?.countResources}
        />
        <CustomTableCell
          text={row?.countAutomations}
        />
        <CustomTableCell
          text={row?.sessionNumber}
        />
        <CustomTableCell
            text={row?.databaseServer}
        />
        <CustomTableCell
            text={row?.lastSynchedSession ? format(new Date(row?.lastSynchedSession), getDatePattern(language)) : "--"}
        />
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {currentUser?.fleet?.instanceOwner
              && isFleetAdministrator(currentUser)
              && get(row, "isPrivate") && (
                <Grid item>
                  <Tooltip title={t("private")}>
                    <ButtonBase>
                      <HttpsIcon className={classes.instanceOwnerTag} />
                    </ButtonBase>
                  </Tooltip>
                </Grid>
              )}
            { (row?.orchestratorType !== "uipath") && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.license")}>
                  <IconButton
                      id="orchestrator-admin-licence-btn"
                      aria-label="licence"
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        return history.push(
                            `${entityBaseUrl}/licence/${get(row, "id")}`,
                        );
                      }}
                      className={classes.rowActionButtons}
                  >
                    <SrLicensesIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Edit Orchestrator") && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                >
                  <CustomSwitch
                    id="orchestrator-admin-enable-disable-switch"
                    checked={active}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    handleChange={() => {
                      setOpenConfirmationPopup(true);
                    }}
                    disabled={isCurrentUser || get(row, "isInstanceOwner")}
                    value={active}
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    size="small"
                    labelClassName={classes.switchLabel}
                  />
                </Tooltip>
              </Grid>
            )}

            {isPermitted(currentUser, "Edit Orchestrator") && (
              <Grid item>
                <CustomEditIcon
                    id="orchestrator-admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${get(row, "id")}`);
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
            )}
            {currentUser?.fleet?.instanceOwner
                && isFleetAdministrator(currentUser) && (
                <Grid item>
                  <CustomDeleteIcon
                    id="orchestrator-admin-delete-btn"
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(get(row, "id"));
                      setOpenMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  />
                </Grid>
            )}
            {currentUser?.fleet?.instanceOwner
                && isFleetAdministrator(currentUser) && (
                <Grid item>
                  <Checkbox
                  id="orchestrator-admin-select-checkbox"
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected?.map((item) => item?.id)?.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(row)
                      : list.splice(
                            list.findIndex((item) => item.id === parseInt(get(row, "id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
                </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>

      <ConfirmMessage
        message={
          active
            ? t("orchestrator.management.desaDes.confirmMsg")
            : t("orchestrator.management.enaDes.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("orchestrator.management.desaDes.button.confirm")
            : t("orchestrator.management.enaDes.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={t("orchestrator.management.delete.confirmMsg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
