import React from "react";
import Box from "@material-ui/core/Box";
import TagTable from "./TagTable";
import PageHeader from "components/PageHeader";
import TagForm from "./TagForm";
import useStyles from "../../../components/TableComponents/style";

function TagManagement(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="Tags" />
        <TagTable />
        <TagForm open={props?.showEditModal} />
      </Box>
    </Box>
  );
}
export default TagManagement;
