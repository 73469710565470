import {
    ASSIGN_AUTOMATION_EXCEPTION, AUTOMATION_EXCEPTION_ASSIGN_NOTIFY, BULK_ASSIGN_AUTOMATION_EXCEPTION, BULK_MARK_AUTOMATION_EXCEPTION_AS_DONE, BULK_RELEASE_AUTOMATION_EXCEPTION, BULK_REOPEN_AUTOMATION_EXCEPTION, BULK_TAKE_AUTOMATION_EXCEPTION, FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY, MARK_AUTOMATION_EXCEPTION_AS_DONE, RELEASE_AUTOMATION_EXCEPTION, REOPEN_AUTOMATION_EXCEPTION, TAKE_AUTOMATION_EXCEPTION
} from "redux/constants";
import { URL_AUTOMATION_EXCEPTION_WORKFLOW } from "redux/constants/endpoints";

export const fetchProcessExceptionWorkflowHistory = (executionId) => ({
    type: FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/history`,
        method: "get",
    },
});
export const bulkReopenProcessException = (executionIds) => {
    const params = { executionIds: executionIds.join(",") };
    return {
        type: BULK_REOPEN_AUTOMATION_EXCEPTION,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/reopen`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};
export const bulkMarkProcessExceptionAsDone = (executionIds) => {
    const params = { executionIds: executionIds.join(",") };
    return {
        type: BULK_MARK_AUTOMATION_EXCEPTION_AS_DONE,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/markAsDone`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const reopenProcessException = (executionId) => ({
    type: REOPEN_AUTOMATION_EXCEPTION,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/reopen`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const bulkReleaseProcessException = (executionIds) => {
    const params = { executionIds: executionIds.join(",") };
    return {
        type: BULK_RELEASE_AUTOMATION_EXCEPTION,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/release`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const markProcessExceptionAsDone = (executionId) => ({
    type: MARK_AUTOMATION_EXCEPTION_AS_DONE,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/markAsDone`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const assignNotifyProcessException = (userId, exceptionsNumber) => {
    const params = { exceptionsNumber };
    return {
        type: AUTOMATION_EXCEPTION_ASSIGN_NOTIFY,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/assignNotify/${userId}`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const releaseProcessException = (executionId) => ({
    type: RELEASE_AUTOMATION_EXCEPTION,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/release`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const bulkAssignProcessException = (executionIds, userId) => {
    const params = { executionIds: executionIds.join(",") };
    return {
        type: BULK_ASSIGN_AUTOMATION_EXCEPTION,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/assign/${userId}`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};
export const bulkTakeProcessException = (executionIds) => {
    const params = { executionIds: executionIds.join(",") };
    return {
        type: BULK_TAKE_AUTOMATION_EXCEPTION,
        request: {
            url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/take`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const assignProcessException = (executionId, userId) => ({
    type: ASSIGN_AUTOMATION_EXCEPTION,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/assign/${userId}`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const takeProcessException = (executionId) => ({
    type: TAKE_AUTOMATION_EXCEPTION,
    request: {
        url: `${URL_AUTOMATION_EXCEPTION_WORKFLOW}/${executionId}/take`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
