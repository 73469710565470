import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Controller } from "react-hook-form";
import {
  fetchExceptionsType,
  fetchExceptionsReason,
  fetchProcessExecutionExceptionsType,
  fetchProcessExecutionExceptionsReason,
} from "redux/actions/services";
import NoDataMenu from "../../../../../../components/NoData/NoDataMenu";
import InformationIcon from "components/InformationIcon";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";

export default function CustomTriggerRow({
  errors,
  classes,
  remove,
  control,
  append,
  fields,
  menuItems,
  disableFields,
  watch,
  processMenuItem,
  setValue,
  trigger,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const genericExceptionTypes = ["System Exception", "Business Exception", "Unspecified"];

  const exceptionsSelected = watch("customRules").filter((item) => (item.when === "Number of items with Exceptions" || item.when === "Number of process executions with exceptions") && watch("process"));

  const exceptionTypeSelected = watch("customRules").filter((item) => (item.when === "Number of items with Exceptions" || item.when === "Number of process executions with exceptions") && item.exceptionType).map((item, index) => ({ ...item, originalIndex: index }));

  const processIds = [watch("process")];

  const [itemsExceptionTypes, setItemsExceptionTypes] = useState(genericExceptionTypes);

  const [itemsExceptionReasons, setItemsExceptionReasons] = useState([]);

  const [excutionExceptionTypes, setExcutionExceptionTypes] = useState(genericExceptionTypes);

  const [excutionExceptionReasons, setExcutionExceptionReasons] = useState([]);

  useEffect(() => {
    if (exceptionsSelected.length === 0) {
      watch("customRules").forEach((item, index) => {
        setValue(`customRules[${index}].exceptionType`, null);
        setValue(`customRules[${index}].exceptionReason`, null);
      });
    }
    if (exceptionsSelected.length > 0 && watch("process")) {
      if (exceptionsSelected.some((item) => item?.when === "Number of items with Exceptions")) {
        dispatch(fetchExceptionsType(processIds)).then((response) => {
          setItemsExceptionTypes(Array.from(new Set([...response.data, ...genericExceptionTypes])));
        });
      }
      if (exceptionsSelected.some((item) => item?.when === "Number of process executions with exceptions")) {
        dispatch(fetchProcessExecutionExceptionsType(processIds)).then((response) => {
          setExcutionExceptionTypes(Array.from(new Set([...response.data, ...genericExceptionTypes])));
        });
      }
    }
  }, [
    watch("process"),
    watch("customRules").filter((item) => (item.when === "Number of process executions with exceptions" || item.when === "Number of items with Exceptions") && watch("process")).map((item) => item.when).join(",")
  ])

  useEffect(() => {
    if (exceptionTypeSelected.length > 0 && watch("process")) {
      exceptionTypeSelected?.forEach((item) => {
        if (itemsExceptionReasons.find((reason) => reason?.exceptionType === item?.exceptionType && reason?.processId === watch("process"))) return;
        if (excutionExceptionReasons.find((reason) => reason?.exceptionType === item?.exceptionType && reason?.processId === watch("process"))) return;
        if (item.when === "Number of items with Exceptions") {
          dispatch(fetchExceptionsReason([item.exceptionType], processIds)).then((response) => {
            const newExceptionReasons = {
              processId: watch("process"),
              exceptionType: item.exceptionType,
              exceptionReasons: response?.data?.map(({ exceptionReason }) => exceptionReason) || [],
            };
            setItemsExceptionReasons((prevReasons) => [...prevReasons.filter((prevReason) => prevReason.exceptionType !== item?.exceptionType), newExceptionReasons]);
            if (watch(`customRules[${item.originalIndex}].exceptionReason`) && response.data.includes(watch(`customRules[${item.originalIndex}].exceptionReason`))) return
            setValue(`customRules[${item.originalIndex}].exceptionReason`, null);
          });
        }
        if (item.when === "Number of process executions with exceptions") {
          dispatch(fetchProcessExecutionExceptionsReason(item.exceptionType, processIds)).then((response) => {
            const newExceptionReasons = {
              processId: watch("process"),
              exceptionType: item.exceptionType,
              exceptionReasons: response?.data?.map(({ exceptionReason }) => exceptionReason) || [],
            };
            setExcutionExceptionReasons((prevReasons) => [...prevReasons.filter((prevReason) => prevReason.exceptionType !== item?.exceptionType), newExceptionReasons]);
            if (watch(`customRules[${item.originalIndex}].exceptionReason`) && response.data.includes(watch(`customRules[${item.originalIndex}].exceptionReason`))) return
            setValue(`customRules[${item.originalIndex}].exceptionReason`, null);
          });
        }
      });
    }
  }, [exceptionTypeSelected, watch("process")])

  const getExceptionTypes = (when) => {
    if (when === "Number of items with Exceptions") {
      return itemsExceptionTypes;
    }
    if (when === "Number of process executions with exceptions") {
      return excutionExceptionTypes;
    }
    return [];
  };

  const getExceptionReasons = (when, exceptionType) => {
    if (when === "Number of items with Exceptions") {
      return itemsExceptionReasons.find((reasons) => reasons.exceptionType === exceptionType)?.exceptionReasons || [];
    }
    if (when === "Number of process executions with exceptions") {
      return excutionExceptionReasons.find((reasons) => reasons.exceptionType === exceptionType)?.exceptionReasons || [];
    }
    return [];
  };

  const isAnExceptionRuleSelected = (when) => (when === "Number of items with Exceptions" || when === "Number of process executions with exceptions") && watch("process");

  const calculateDisplayedGrids = (index) => {
    let count = 3;
    if (isAnExceptionRuleSelected(watch(`customRules[${index}].when`))) count += 1;
    if (isAnExceptionRuleSelected(watch(`customRules[${index}].when`)) && watch(`customRules[${index}].exceptionType`) && (itemsExceptionTypes.length > 0 || excutionExceptionTypes.length > 0)) count += 1;
    if (watch(`customRules[${index}].exceptionType`) && getExceptionReasons(watch(`customRules[${index}].when`), watch(`customRules[${index}].exceptionType`)).length === 0) count += 1;
    return count;
  };

  return (
    <>
      {fields.map((customRule, index) => (
        <Box component={Grid} key={customRule.id} container item spacing={1} className={classes.triggerRowContainer} gridTemplateColumns={`30% 20% repeat(${calculateDisplayedGrids(index)}, auto)`}>
          <Grid item>
            <Controller
              name={`customRules[${index}].when`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <CustomAutoComplete
                  options={menuItems.whenMenuItem ?? []}
                  label={t("alerts.management.when.placeholder")}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  value={field?.value}
                  onChange={field?.onChange}
                  disabled={disableFields}
                  error={errors.customRules?.[`${index}`]?.when}
                  helperText={errors.customRules?.[`${index}`]?.when && errors?.customRules?.[`${index}`]?.when?.message}
                  inputRef={field?.ref}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
            name="process"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <CustomAutoComplete
                options={processMenuItem ?? []}
                label={t("alerts.management.process.placeholder")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                optionLabel={(option) => option?.processDescription?.processDisplayName}
                value={processMenuItem?.find((item) => item?.id === field?.value)}
                onChange={(value) => {
                  field?.onChange(value?.id)
                }}
                disabled={disableFields || index > 0}
                error={errors?.processId}
                helperText={errors?.processId && errors?.processId?.message}
                inputRef={field?.ref}
                noOptionsNode={<NoDataMenu message={t("no.process.message")} marginX={8} />}
              />
            )}
            />
          </Grid>
          {isAnExceptionRuleSelected(watch(`customRules[${index}].when`))
          && (
            <Grid item>
              <Controller
              name={`customRules[${index}].exceptionType`}
              control={control}
              rules={{
                required: {
                  value: false,
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <CustomAutoComplete
                  options={getExceptionTypes(watch(`customRules[${index}].when`)) ?? []}
                  label={t("Exception Type")}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  optionLabel={(option) => option}
                  value={field?.value}
                  onChange={(value) => {
                    field?.onChange(value)
                  }}
                  disabled={disableFields || !watch("process")}
                  error={errors.customRules?.[`${index}`]?.exceptionType}
                  helperText={errors.customRules?.[`${index}`]?.exceptionType && errors.customRules?.[`${index}`]?.exceptionType?.message}
                  inputRef={field?.ref}
                />
              )}
              />
            </Grid>)}
          {isAnExceptionRuleSelected(watch(`customRules[${index}].when`)) && watch(`customRules[${index}].exceptionType`) && (itemsExceptionTypes.length > 0 || excutionExceptionTypes.length > 0)
          && (
          <Grid item>
            <Controller
            name={`customRules[${index}].exceptionReason`}
            control={control}
            rules={{
              required: {
                value: false,
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <CustomAutoComplete
                options={getExceptionReasons(watch(`customRules[${index}].when`), watch(`customRules[${index}].exceptionType`))}
                label={t("Exception Reason")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                optionLabel={(option) => option}
                value={field?.value}
                onChange={(value) => {
                  field?.onChange(value)
                }}
                disabled={
                  disableFields
                  || getExceptionReasons(watch(`customRules[${index}].when`), watch(`customRules[${index}].exceptionType`)).length === 0
                }
                error={errors.customRules?.[`${index}`]?.exceptionReason}
                helperText={errors.customRules?.[`${index}`]?.exceptionReason && errors.customRules?.[`${index}`]?.exceptionReason?.message}
                inputRef={field?.ref}
                InputProps={{
                  endAdornment: (
                     watch(`customRules[${index}].exceptionType`) && getExceptionReasons(watch(`customRules[${index}].when`), watch(`customRules[${index}].exceptionType`)).length === 0
                      && (
                      <InformationIcon
                              titleContent={t(
                                  "alerts.management.trigger.exceptionReasons.not.found",
                              )}
                          />
                      )
                  )
                }}
              />
            )}
            />
          </Grid>)}
          <Grid item>
            <Controller
            name={`customRules[${index}].operator`}
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <CustomAutoComplete
                options={menuItems.operatorMenuItem ?? []}
                label={t("alerts.management.operator.placeholder")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                value={menuItems.operatorMenuItem?.find((item) => item === field?.value)}
                onChange={(value) => {
                  field?.onChange(value)
                }}
                disabled={disableFields}
                error={errors.customRules?.[`${index}`]?.operator}
                helperText={errors.customRules?.[`${index}`]?.operator && errors.customRules?.[`${index}`]?.operator?.message}
                inputRef={field?.ref}
              />
            )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name={`customRules[${index}].condition`}
              rules={{
                required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                },
              }}
              render={({ field }) => (
                <FormControl size="small" fullWidth>
                  <CustomTextField
                    {...field}
                    fullWidth
                    name={`customRules[${index}].condition`}
                    label={t("alerts.management.condition.label")}
                    placeholder={t("alerts.management.condition.label")}
                    disabled={disableFields}
                    error={errors.customRules?.[`${index}`]?.condition}
                    classes={classes}
                    type="number"
                    onBlur={() => trigger(`customRules[${index}].condition`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {(watch(`customRules[${index}].when`)
                            === "Total time saved"
                            || watch(`customRules[${index}].when`)
                              === "Process execution duration"
                            || watch(`customRules[${index}].when`)
                              === "Item execution duration")
                            && t("Minutes")}
                          {(watch(`customRules[${index}].when`)
                            === "Items success rate"
                            || watch(`customRules[${index}].when`)
                              === "Items exception rate")
                            && "%"}
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                  />
                  <FormHelperText className={classes.validation_error}>
                    {errors.customRules?.[`${index}`]?.condition?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          {!disableFields && (
            <Grid
              item
              style={{
                marginTop: "10px",
                display: "flex",
              }}
              justify="flex-end"
            >
              <Tooltip placement="top" title={t("tooltip.action.delete")}>
                <IconButton
                  className={classes.deleteIcon}
                  disabled={fields.length === 1}
                  aria-label="delete"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title={t("tooltip.action.add")}>
                <IconButton
                  aria-label="add"
                  color="primary"
                  onClick={() => {
                    append({
                      when: "",
                      operator: "",
                      condition: "",
                    });
                  }}
                  className={(index !== fields.length - 1) && classes.addIconHidden}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Box>
      ))}
    </>
  );
}
