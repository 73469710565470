import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  rootTabs: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.secondary.main,
      height: 6,
    },
  },
  tab: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  firstGrid: {
    width: "100%",
  },
}));
