import React from "react";
import Box from "@material-ui/core/Box";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import useStyles from "pages/AutomationsPending/style";
import InformationIcon from "components/InformationIcon";

export default function GeneralInfo() {
  const form = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles()();
  return (
    <Box>
      <CustomTextField
        {...form.register("processDescription.processDisplayName", {
          required: t("field.is.required", {
            field: t("Name"),
          }),
        })}
        id="name"
        label={
          <InformationIcon
            titleContent={t("Name")}
            originalContent={t("Name")}
            placement="right"
          />
        }
        fullWidth
        InputLabelProps={{
          shrink: !!form.watch("processDescription.processDisplayName"),
        }}
        error={
          !!form.formState.errors?.processDescription?.processDisplayName
            ?.message
        }
        valued={form.getValues("processDescription.processDisplayName")}
        helperText={
          form.formState.errors?.processDescription?.processDisplayName
          && form.formState.errors?.processDescription?.processDisplayName?.message
        }
        className={classes.textField}
      />
      <CustomTextField
        {...form.register("processDescription.processDescription", {
          required: t("field.is.required", {
            field: t("Description"),
          }),
        })}
        id="description"
        label={
          <InformationIcon
            titleContent={t("Description")}
            originalContent={t("Description")}
            placement="right"
          />
        }
        multiline
        rowsMax={4}
        fullWidth
        InputLabelProps={{
          shrink: !!form.watch("processDescription.processDescription"),
        }}
        error={
          form.formState.errors?.processDescription?.processDescription?.message
        }
        valued={form.getValues("processDescription.processDescription")}
        helperText={
          form.formState.errors?.processDescription?.processDescription
          && form.formState.errors?.processDescription?.processDescription?.message
        }
        className={classes.textField}
      />
    </Box>
  );
}
