import React from "react";
import { Doughnut } from "react-chartjs-2";

const PieChart = ({
  data, optionsChart, width, height,
}) => (
  <Doughnut
    height={height}
    width={width}
    data={data}
    options={optionsChart}
  />
);

export default PieChart;
