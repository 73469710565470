import React from "react";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import CustomButton from "../../../components/CustomButton";
import useStyles from "../style";

function ProcessList(props) {
  const {
    processesData,
    selectedProcess,
    handleSelectProcess,
      isLoading
  } = props;
  const { t } = useTranslation()
  const classes = useStyles();
  return (
    <Box component={Grid} display="flex" alignContent="flex-start" item xs={4} container spacing={2}>
      <Grid item xs={12}>
        <Typography
            component="h2"
            className={classes.subTitle}
        >
          {t("recently.used.processes")}
        </Typography>
      </Grid>
      {isLoading ? (
        <CircularLoader height={310} />
            )
            : processesData?.slice(0, 5)?.map((process) => (
              <Grid item>
                <ListItem className={classes.listItem} key={process?.id}>
                  <CustomButton
                className={selectedProcess?.id === process?.id ? classes.selected : classes.noSelected}
                onClick={() => handleSelectProcess(process)}
                variant="outlined"
            >
                    <Typography variant="caption">
                      {process?.processDisplayName}
                    </Typography>
                  </CustomButton>
                </ListItem>
              </Grid>
        ))}
    </Box>
  );
}

export default ProcessList;
