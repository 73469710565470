import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "./style";

export default function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  checked,
  align,
  paddingLeft,
  height,
  cellFontWeight,
  emptyCell = false,
  transparentHeader = false,
}) {
  const classes = useStyles({ paddingLeft, height, cellFontWeight });
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCellContent = (headCell) => (typeof headCell?.label === "string" ? (
    <Typography className={clsx(classes.tableHeadLabel, transparentHeader ? classes.tableHeadLabelTransparent : "")} variant="h3">
      {t(headCell.label)}
    </Typography>
  ) : headCell?.label)

  return (
    <TableHead>
      <TableRow className={classes.tableHeadRow}>
        {checked && (
          <TableCell className={clsx(classes.tableCellHead, transparentHeader ? classes.tableCellHeadTransparent : "")} padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all rows" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={clsx(classes.tableCellHead, transparentHeader ? classes.tableCellHeadTransparent : "")}
          >
            {!headCell?.sortable && (
              headCell?.customizedComponent ? (
                <Grid container alignItems="center">
                  {headCellContent(headCell)}
                  {headCell.customizedComponent}
                </Grid>
              ) : (
                headCellContent(headCell)
              ))}
            {headCell?.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableHeadLabel}
              >
                <Typography className={classes.tableHeadLabel} variant="h3">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? t("sorted descending")
                      : t("sorted ascending")}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {emptyCell && <TableCell className={clsx(classes.tableCellHead, transparentHeader ? classes.tableCellHeadTransparent : "")} />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
