import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import { useTranslation } from "react-i18next";
import {
  Box, useMediaQuery, useTheme, StepContent,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProcessStep from "../ProcessStep";
import CircularLoader from "../Loaders/CircularLoader";
import { useQuery } from "@redux-requests/react";
import { LOAD_QUALIFICATIONS, LOAD_STEPS } from "redux/constants";
import { loadQualificationByProcess, loadStepsData } from "redux/actions/services/opportunities";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 25px)",
    right: "calc(50% + 25px)",
  },
  active: {
    "& $line": {
      borderColor: "#2cd46c",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#2cd46c",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  activeMobile: {
    position: "relative",
    left: "-5px",
  },
  active: {
    color: theme.palette.primary.main,

    "& $circle": {
      border: `1px solid ${theme.palette.primary.main}`,
      width: 36,
      height: 36,
    },

    "& $circleInside": {
      backgroundColor: theme.palette.primary.main,
      width: 14,
      height: 14,
    },
  },
  circle: {
    width: 26,
    height: 26,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.type === "light" ? theme.palette.common.white : theme.palette.grey[800],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circleInside: {
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[400],
    width: 10,
    height: 10,
  },
  completed: {
    color: "#FFF",
    zIndex: 1,
    fontSize: 18,
  },
  doneCircle: {
    backgroundColor: "#2cd46c",
    width: 26,
    height: 26,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();

  const { active, completed } = props;

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className={clsx(classes.root, isSmallScreen && active && classes.activeMobile, {
        [classes.active]: active,
      })}
    >
      {completed
        ? (
          <Box className={classes.doneCircle}>
            <Check className={classes.completed} />
          </Box>
        )
        : <div className={classes.circle}><div className={classes.circleInside} /></div>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  mobileStepper: {
    padding: theme.spacing(2),
  },
}));

const ProcessStepper = ({ processName, setProcessName, handleFieldValueChange }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeStep, setActiveStep] = useState(0)
  const { data: dataRes, loading: isLoading } = useQuery({ type: LOAD_STEPS })
  const steps = dataRes?.steps || []
  const { loading: isQualificationLoaded } = useQuery({ type: LOAD_QUALIFICATIONS })

  const classes = useStyles();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (id) await dispatch(loadQualificationByProcess(id))
      await dispatch(loadStepsData())
    }
    fetchData()
  }, [dispatch]);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBackStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (isLoading || !steps || isQualificationLoaded) return <CircularLoader />

  return (
    <div className={classes.root}>
      {!isLoading
        && (
        <Stepper className={clsx(isSmallScreen && classes.mobileStepper)} alternativeLabel={!isSmallScreen} activeStep={activeStep} connector={<QontoConnector />} orientation={isSmallScreen ? "vertical" : "horizontal"}>
          {steps.map((step) => (
            <Step key={step.id}>
              <StepLabel StepIconComponent={QontoStepIcon}>{t(step.name)}</StepLabel>
              {isSmallScreen
                && (
                <StepContent>
                  <ProcessStep
                    handleNextStep={handleNextStep}
                    handleBackStep={handleBackStep}
                    activeStep={activeStep}
                    processName={processName}
                    setProcessName={setProcessName}
                    handleFieldValueChange={handleFieldValueChange}
                  />
                </StepContent>
                )}
            </Step>
          ))}
          <Step key={17}>
            <StepLabel StepIconComponent={QontoStepIcon}>{t("Result")}</StepLabel>
            {isSmallScreen
              && (
              <StepContent>
                <ProcessStep
                  handleNextStep={handleNextStep}
                  handleBackStep={handleBackStep}
                  activeStep={activeStep}
                  processName={processName}
                  setProcessName={setProcessName}
                  handleFieldValueChange={handleFieldValueChange}
                />
              </StepContent>
              )}
          </Step>
        </Stepper>
        )}
      {!isSmallScreen
        && <ProcessStep
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        activeStep={activeStep}
        processName={processName}
        setProcessName={setProcessName}
        handleFieldValueChange={handleFieldValueChange}
      />}
    </div>
  );
};

export default ProcessStepper;
