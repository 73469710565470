export const triggers = ["queueItems", "timeBased"];
export const stopModes = ["sendStopRequest", "pauseQueueToStop", "immediateForcedStop", "letTheProcessSelfStop"];
export const processPriorities = ["veryImportant", "important", "regular", "notImportant"];
export const maxSessionsActions = ["SKIP_EXEC", "WAIT_FOR_END", "WAIT_UNTIL_TIMEOUT"];
export const minutes = Array.from({ length: 59 }, (_, i) => i + 1);
export const hours = Array.from({ length: 23 }, (_, i) => i + 1);
export const days = Array.from({ length: 30 }, (_, i) => i + 1);
export const weeks = Array.from({ length: 51 }, (_, i) => i + 1);
export const months = Array.from({ length: 11 }, (_, i) => i + 1);
export const years = Array.from({ length: 10 }, (_, i) => i + 1);
export const responseTimeCalculationOptions = ["item_creation_date", "date_last_updated_item"];
export const quartzInitialDays = [
  {
      id: 1, label: "Sun", value: "1", checked: true, fullLabel: "Sunday",
  },
  {
      id: 2, label: "Mon", value: "2", checked: true, fullLabel: "Monday",
  },
  {
      id: 3, label: "Tue", value: "3", checked: true, fullLabel: "Tuesday",
  },
  {
      id: 4, label: "Wed", value: "4", checked: true, fullLabel: "Wednesday",
  },
  {
      id: 5, label: "Thu", value: "5", checked: true, fullLabel: "Thursday",
  },
  {
      id: 6, label: "Fri", value: "6", checked: true, fullLabel: "Friday",
  },
  {
      id: 7, label: "Sat", value: "7", checked: true, fullLabel: "Saturday",
  },
];
const scheduleTypeDayOfMonthOptions = [
  "NTH_DAY_OF_MONTH",
  "SPECIFIC_DAYS_OF_WEEK_IN_MONTH",
  "LAST_DAY_OF_MONTH"
];
const composeDayConditionLabel = (condition, scheduleTypeDayOfMonth, dayOfStartDate, t) => {
  if (condition !== scheduleTypeDayOfMonth[0]) {
    return t(condition);
  }
  if (dayOfStartDate >= 4) return t(condition, { day: dayOfStartDate });
  return t(`${condition}.${dayOfStartDate}`);
}
const periods = [
  {
    value: "MINUTELY", frequency: minutes, frequencyLabel: "Minute(s)"
   },
  {
    value: "HOURLY", frequency: hours, frequencyLabel: "Hour(s)"
  },
  {
    value: "DAILY", frequency: days, frequencyLabel: "Day(s)"
  },
  {
    value: "WEEKLY", frequency: weeks, frequencyLabel: "Week(s)"
  },
  {
    value: "MONTHLY", frequency: months, frequencyLabel: "Month(s)"
  },
  {
    value: "YEARLY", frequency: years, frequencyLabel: "Year(s)"
  },
]
const dayOrder = [
  { value: 1, label: "First" },
  { value: 2, label: "Second" },
  { value: 3, label: "Third" },
  { value: 4, label: "Fourth" },
  { value: 5, label: "Last" },
];
export const composeScheduleDescription = (frequency, unitType, weekDays, executionTime, t, weekOrder, selectedDayId, startDay, condition, isPastDescription = false) => {
  const descriptionText = isPastDescription ? "orchestration.schedule.desc.past" : "orchestration.schedule.desc";
  let firstDescription = t(descriptionText, {
    frequency: frequency > 1 ? frequency : "",
    period: t(periods.find((period) => period.value === unitType)?.frequencyLabel),
    executionTime: !["MINUTELY", "HOURLY"].includes(unitType) ? executionTime : "",
  });
  if (!["MINUTELY", "HOURLY"].includes(unitType) && executionTime) firstDescription += `${t("orchestration.schedule.desc.hour", { executionTime })}`;
  let days = "";
  let secondDescription = "";
  const numberOfSelectedDays = weekDays.filter((day) => day.checked).length
  if ((numberOfSelectedDays > 0 && numberOfSelectedDays < 7) || unitType === "MONTHLY") {
    const filteredDays = weekDays.filter((day) => day.checked === numberOfSelectedDays <= 4)
    filteredDays.forEach((day, index) => {
      if (index === 0) {
        days += `${t(day.fullLabel)}`;
      }
      else if (index !== filteredDays.length - 1) {
        days += `, ${t(day.fullLabel)}`;
      }
      else if (index === filteredDays.length - 1) {
        days += ` ${t("and")} ${t(day.fullLabel)}`;
      }
    })
    if (unitType === "MONTHLY") {
      if (condition === scheduleTypeDayOfMonthOptions[1] && weekOrder && selectedDayId) {
        secondDescription = `. ${t("the")} ${t(dayOrder[+weekOrder - 1].label).toLocaleLowerCase()} ${t(quartzInitialDays[+selectedDayId - 1].fullLabel).toLocaleLowerCase()} ${t("of.the.month")}`
      } else if (condition !== scheduleTypeDayOfMonthOptions[1]) {
        secondDescription = `. ${composeDayConditionLabel(condition, scheduleTypeDayOfMonthOptions, startDay, t).toLocaleLowerCase()}`
      }
    }
    else secondDescription = numberOfSelectedDays > 4 ? t("orchestration.schedule.desc.week.except", { days }) : t("orchestration.schedule.desc.week", { days });
  }
  return `${firstDescription} ${secondDescription}`
}
