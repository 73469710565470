import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

export const StyledTable = withStyles(() => ({
    root: {
        borderCollapse: "separate",
        borderSpacing: ({ borderSpacing }) => `0 ${borderSpacing ?? "10px"}`,
        padding: "0 1px",
    }
}))(Table);

export default function CustomTable(props) {
    return (
      <StyledTable {...props}>
        {props?.children}
      </StyledTable>
    )
}
