import capitalize from "lodash/capitalize";
import { formatDateByLanguage } from "util/helpers/date.helpers";

const generateEmailBody = (user, page) => {
    const userFullName = user.fullName;
    const issueTime = formatDateByLanguage(new Date());
    return (
        `Hello SmartRoby Support Team,
        I've encountered a bug on SmartRoby and am providing the details below for your investigation:
        
        Date/Time of Issue: ${issueTime}
        Location of Bug: ${page}
        
        Brief Description of the Bug:
        [Provide a concise description of the issue you are facing]
        
        Steps to Reproduce:
        
        1. [Step 1 to reproduce the bug]
        2. [Step 2 to reproduce the bug]
        3. [Additional steps, if any]
                
        Expected vs. Actual Outcome:
         
         • Expected Outcome: [Describe what you expected to happen]
         • Actual Outcome: [Describe what actually happened]
        
        Attachments (if any):
        [Attach any relevant screenshots, logs, or files]
        
        Thank you for addressing this matter.
        
        Best regards,
        ${userFullName}`
    );
}

const reportBug = (user) => {
    const currentPage = capitalize(window.location.pathname.substring(1));
    const version = process.env.REACT_APP_VERSION;
    const recipient = "support.smartroby@novelis.io";
    const subject = `Bug on page ${currentPage} in version ${version}`;
    const body = generateEmailBody(user, currentPage);
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};

export default reportBug;
