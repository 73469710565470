import React from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "components/PageHeader";
import useStyles from "../../components/TableComponents/style"
import OrchestrationsTable from "./OrchestrationTable";

function OrchestrationManagement() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="Intelligent Orchestration" />
        <OrchestrationsTable />
      </Box>
    </Box>
  );
}

export default OrchestrationManagement;
