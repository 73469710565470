import { makeStyles, darken, fade } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "0 0 100%",
    marginBottom: "1%",
    cursor: "pointer",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    background: "white !important",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  rootGlobal: {
    padding: theme.spacing(2, 1),
  },
  autoComplete: {
    position: "relative",
    paddingRight: 36,
    "& >.MuiSvgIcon-root": {
      visibility: "hidden",
    },
    "&:hover >.MuiSvgIcon-root": {
      visibility: "visible",
    }
  },
  reportContainer: {
    boxShadow: theme.custom.shadow,
    margin: theme.spacing(2, 0),
    paddingBottom: theme.spacing(5),
    backgroundColor: "#fff",
  },
  exportBtn: {
    height: "100%",
    width: "100%",
    fontSize: 13,
  },
  activeExportBtn: {
    height: "100%",
    width: "100%",
    fontSize: 13,
    backgroundColor: theme.custom.color.chartColor4,
    color: fade(theme.palette.primary.main, 0.5),
  },
  exportMenu: {
    width: "200%",
    borderRadius: "0%",
  },
  exportListOption: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    cursor: "pointer",
  },
  optionsDivider: {
    width: "100%",
    margin: theme.spacing(1, 0),
    color: theme.palette.primary.main,
  },
  clearBtn: {
    height: 40,
    backgroundColor: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
    color: "white",
    "&:hover": {
      background: darken(theme.palette.error.main, 0.2),
    },
  },
  graphTitle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hideContainer: {
    display: "none",
  },
  exportButtonContainer: {
    height: "70%",
    width: "100%"
  },
  clearFilterContainer: {
    marginTop: "-7px",
    paddingRight: 20,
  },
  help: {
    fontSize: 13,
    position: "absolute",
    right: 20,
    bottom: 5,
    color: theme.palette.secondary.main
  },
  helpAutoComplete: {
    fontSize: 13,
    position: "absolute",
    right: 20,
    bottom: 5,
    color: theme.palette.secondary.main
    },
  circularLoaderContainer: {
    height: "200px",
    width: "100%",
  },
  createDialog: {
    margin: "auto",
    width: "500px",
    height: "450px"
  },
  createKpiDialog: {
    margin: "auto",
    width: "900px",
    height: "800px"
  },
  kpiNameTextField: {
    marginBottom: "20px"
  },
  createKpiDialogContent: {
    overflowY: "visible",
    marginRight: "5px"
  },
  addKpiButton: {
    color: theme.palette.info.main,
  },
  deleteKpiButton: {
    color: theme.palette.secondary.main,
  },
  deleteDialog: {
    margin: "auto",
    width: "450px",
    height: "400px"
  },
  deleteButton: {
    padding: "6px",
    marginLeft: "auto",
    height: "35px",
    width: "35px",
    borderRadius: "20px",
    color: theme.palette.secondary.main,
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      transition: "background-color 0.2s ease-in-out",
      backgroundColor: "#ECECEC",
      borderRadius: "20px",
      cursor: "pointer",
    }
  },
  disabledColor: {
    color: fade(theme.palette.primary.main, 0.5),
  },
  validation_error: {
    color: "red",
    margin: "0",
  },
  popupIcon: {
    color: theme.palette.info.main
  },
    input: {
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: "400",
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.info.main,
    borderWidth: 3,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.info.main,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: theme.palette.info.main,
    borderWidth: 3,
  },
},
  datesContainer: {
    marginTop: 2.5,
  },
  filterActions: {
    paddingTop: 30,
  },
  filterRoot: {
    paddingTop: "0 !important",
  },
  autoCompleteProcesses: {
    marginTop: 2
  },
  kpiButton: {
    fontSize: "1.1rem"
  },
  chipText: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  listItemText: {
    fontWeight: "600 !important",
    color: theme.palette.primary.main
  },
  customRenderOptionText: {
    maxWidth: "85%"
  },
  dateContainer: {
    padding: theme.spacing(1, 6),
    backgroundColor: fade(theme.custom.color.chartColor1, 0.7),
    textAlign: "center"
  },
  dateLabel: {
    fontWeight: 800,
    fontSize: "0.8rem",
    marginRight: 10,
    color: theme.palette.primary.main
  },
  dateValue: {
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    color: theme.palette.primary.main
  },
  createPresetDialog: {
    margin: "auto",
    width: "45%",
    height: "25%",
    borderRadius: "1%",
  },
  cancelButton: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "700",
    elevation: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    }
  },
  menuPaper: {
    borderRadius: 5,
    padding: theme.spacing(1),
    border: "1px solid rgba(0, 0, 0, 0.2)",
  },
  reverseMarginSpacing: {
    margin: theme.spacing(-1),
    marginLeft: theme.spacing(-2.25),
  },
  arrowIcon: {
    margin: theme.spacing(0, -0.75, 0, -2.25),
  }
}));
