import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    radio: {
      color: theme.palette.primary.main,
      "&.Mui-checked": {
        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
          {
              color: theme.palette.primary.main,
          },
        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
          color: theme.palette.secondary.main,
        },
      },
    },
    wrongFilterAlert: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: theme.palette.info.main,
      fontSize: "1rem",
      fontWeight: "600",
      height: "4rem",
      alignItems: "center",
      justifyContent: "center",
    },
    dialogDefaultText: {
      fontSize: "1rem",
      fontWeight: "600",
      color: theme.palette.primary.main,
    },
    dialogTitle: {
      color: theme.palette.primary.main,
      fontSize: "1.4rem",
      fontWeight: "800",
      marginLeft: theme.spacing(1.5),
    },
    dialogPaper: {
      width: "35%",
      padding: theme.spacing(3),
      borderRadius: "2%",
    },
}));
