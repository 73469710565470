import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropabbleTitle: {
    padding: theme.spacing(1),
    paddingLeft: 2
  },
  droppableContainer: {
    background: theme.palette.info.light,
    borderRadius: "4px",
    width: "100%",
    height: "300px",
    overflowY: "auto",
    overflowX: "hidden",
    border: `8px ${theme.palette.info.light} solid`,
    paddingRight: "6px",
  },
  card: {
    width: "100%",
    padding: 2
  },
  itemsContainer: {
    backgroundColor: theme.palette.info.light
  },
  listItem: {
    padding: "0px",
  },
  droppableList: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    boxShadow:
        "0px 0px 2px 0px rgb(172,186,255)",
  },
  processContainer: {
    width: "32%",
  },
  droppableTitle: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    paddingBottom: 0
  },
  processSeparator: {
    width: "1%",
  },
  checkBoxLabel: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: "0.9rem"
  },
  checkboxContainer: {
    marginLeft: theme.spacing(4.6)
  },
  assigningBtns: {
    marginTop: theme.spacing(3)
  }
}));
