import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 700,
    paddingBottom: 0
  },
}));
