export const ProcessInputTypes = {
  TEXT: "text",
  NUMBER: "number",
  FLAG: "flag",
  DATE: "date",
  DATETIME: "datetime",
  TIME: "time",
  TIMESPAN: "timespan",
};

export default ProcessInputTypes;
