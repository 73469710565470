import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useStyles from "pages/AutomationsPending/style";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import InformationIcon from "components/InformationIcon";

export default function AutomationControlSettings() {
  const form = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles()();
  return (
    <Box>
      <FormControl fullWidth className={classes.switchControl}>
        <CustomSwitch
          {...form.register("isSchedulable")}
          defaultChecked={form.getValues("isSchedulable")}
          label={
            <InformationIcon
              titleContent={t("kpi.parameter.allowScheduling.tooltip")}
              originalContent={
                <Typography className={classes.controlLabel}>
                  {t("kpi.parameter.allowScheduling")}
                </Typography>
              }
              placement="right"
            />
          }
          fullWidth
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.switchControl}>
        <CustomSwitch
          {...form.register("isManualAllowed")}
          defaultChecked={form.getValues("isManualAllowed")}
          label={
            <InformationIcon
              titleContent={t("kpi.parameter.allowManual.tooltip")}
              originalContent={
                <Typography className={classes.controlLabel}>
                  {t("kpi.parameter.allowManual")}
                </Typography>
              }
              placement="right"
            />
          }
          fullWidth
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.switchControl}>
        <CustomSwitch
          {...form.register("isRetryable")}
          defaultChecked={form.getValues("isRetryable")}
          label={
            <InformationIcon
              titleContent={t("kpi.parameter.parameter.retryable.tooltip")}
              originalContent={
                <Typography className={classes.controlLabel}>
                  {t("Items Exceptions can be retried with original")}
                </Typography>
              }
              placement="right"
            />
          }
          fullWidth
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.switchControl}>
        <CustomSwitch
          {...form.register("isRetryableWithData")}
          defaultChecked={form.getValues("isRetryableWithData")}
          label={
            <InformationIcon
              titleContent={t(
                "kpi.parameter.human.in.the.loop.activated.tooltip"
              )}
              originalContent={
                <Typography className={classes.controlLabel}>
                  {t("Item Exceptions can be retried with different data")}
                </Typography>
              }
              placement="right"
            />
          }
          fullWidth
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </FormControl>
    </Box>
  );
}
