import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    fontSize: 13
  },
  avatarColor: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  defaultAvatar: {
    backgroundColor: theme.custom.color.chartColor1,
    color: "white",
  },
}));
