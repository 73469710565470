import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import {
  formatImagePath,
  rateFloatFormat,
  numberRoiFloatFormat,
  secondsToFTPTime,
  round,
  formtNumberByLanguage,
  abbreviateNumber,
} from "util";
import InformationIcon from "components/InformationIcon";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
// eslint-disable-next-line import/no-cycle
import useStyles from "./style.js";

const TableChart = ({
 data, t, selectedKpi, customKpis, userFleet, setDateContainerWidth, kpi, exceptionsTypes = []
}) => {
  const classes = useStyles();
  const firstKpiRef = useRef()
  const kpiCodes = selectedKpi && [...selectedKpi.map((e) => e.code), ...exceptionsTypes];
  const customKpiDetails = customKpis && customKpis.map((e) => e.customKpiDetails).flat();
  const customKpiNames = selectedKpi && selectedKpi.map((e) => e.name);
  const breakEvenContent = ({ breakEvenUnit, breakEvenValue }) => {
    if (breakEvenUnit === undefined || breakEvenUnit === null) {
      return "--";
    }
    return breakEvenValue > 0
      ? `${t("ago", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`
      : `${t("point.amortissage.negative", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`;
  };
  const renderSwitch = (code, row) => {
    const exception = row?.analysisItemExceptionDTo?.find((a) => a.title === code)
    if (exception) {
      return formtNumberByLanguage(round(exception?.count, 2));
    }
    switch (code) {
      case "exceptionRate":
        return {
          value: `${rateFloatFormat(row[code])}%`,
          label: `${abbreviateNumber(row[code])} %`,
        };
      case "roi":
        return {
          value: `${numberRoiFloatFormat(row[code])}%`,
          label: `${abbreviateNumber(row[code])} %`,
        };
      case "breakEven":
        return breakEvenContent(row[code]);
      case "itemExecutionTime":
        return secondsToFTPTime(
          row[code] / 3600,
          userFleet?.hoursPerManDay,
          userFleet?.daysPerYear,
          t
        );
      case "occupancyRate":
        return {
          value: `${numberRoiFloatFormat(row[code])}%`,
          label: `${abbreviateNumber(row[code])} %`,
        };
      case "executionTime":
      case "timeSaved":
        return secondsToFTPTime(
          row[code],
          userFleet?.hoursPerManDay,
          userFleet?.daysPerYear,
          t
        );
      case "successRate":
        return {
          value: `${numberRoiFloatFormat(row[code])}%`,
          label: `${abbreviateNumber(row[code])} %`,
        };
      default:
        return row?.[code] ? formtNumberByLanguage(round(row[code], 2)) : 0;
    }
  };

    useEffect(() => {
        if (firstKpiRef?.current) setDateContainerWidth(firstKpiRef.current.clientWidth)
    }, [data, kpi]);

  if (data && !data[0]) return null;
  return data ? (
    <Box className={classes.rootGlobal}>
      {data.map((row, index) => {
        const processDisplayName = row?.entityName
          ? row?.entityName
          : "---";
        return (
          <Box className={classes.root} key={index} ref={index === 0 ? firstKpiRef : null}>
            <Box className={classes.itemAvatar}>
              {row && row.entityIcon ? (
                <img
                  src={formatImagePath(row?.entityIcon, row?.entityId)}
                  align="left"
                  alt="Service"
                  className={classes.resourceAvatar}
                />
              ) : (
                <ProcessDefaultIcon className={classes.resourceAvatar} />
              )}
            </Box>
            <Grid container alignItems="center" className={classes.content}>
              <Grid className={classes.displayName} item xs={12}>
                <InformationIcon
                    titleContent={processDisplayName}
                    originalContent={(
                      <Typography
                            variant="body2"
                            align="center"
                            className={clsx(
                                classes.processNameWrapper,
                                classes.textTruncated
                            )}
                        >
                        {processDisplayName}
                      </Typography>
                    )}
                    justifyContent="center"
                />
              </Grid>
              {kpi
                && kpi.filter((k) => kpiCodes && kpiCodes.includes(k.code))
                  .length > 0 && (
                  <Grid container item xs={12}>
                    {kpi.map(
                      (k) => kpiCodes
                        && kpiCodes.includes(k.code) && (
                          <Grid container item className={classes.kpiElement}>
                            <Grid
                              item
                              xs={12}
                              className={classes.analyticsTableLabel}
                            >
                              <InformationIcon
                                  titleContent={t(k.label)}
                                  originalContent={(
                                    <ButtonBase
                                          disableRipple
                                          className={clsx(
                                              classes.analyticsButtonLabel,
                                              classes.textTruncated
                                          )}
                                      >
                                      <Typography
                                            align="left"
                                            className={clsx(
                                                classes.kpiLabel,
                                                classes.textTruncated
                                            )}
                                        >
                                        {t(k.label)}
                                      </Typography>
                                    </ButtonBase>
                                  )}
                              />
                            </Grid>
                            <Grid
                              item
                              className={clsx(
                                classes.analyticsTableValue,
                                classes.textTruncated
                              )}
                            >
                              <InformationIcon
                                  titleContent={
                                      renderSwitch(k.code, row)?.value
                                      ?? renderSwitch(k.code, row)
                                  }
                                  originalContent={(
                                    <Typography
                                          align="right"
                                          className={clsx(
                                              classes.kpiValue,
                                              classes.textTruncated
                                          )}
                                      >
                                      {renderSwitch(k.code, row)?.label
                                            ?? renderSwitch(k.code, row)}
                                    </Typography>
                                  )}
                                  placement="right"
                              />
                            </Grid>
                          </Grid>
                        )
                    )}
                  </Grid>
                )}
              {row?.customKpiValues && (
                <Grid container xs={12}>
                  {customKpis.map(
                    (k) => customKpiNames
                      && customKpiNames.filter((s) => s === k.name).length > 0 && (
                        <Grid
                          container
                          direction="row"
                          xs={12}
                          className={classes.kpiElement}
                        >
                          <Grid
                            item
                            xs={12}
                            className={clsx(
                              classes.customAnalyticsTableLabel,
                              classes.textTruncated
                            )}
                          >
                            <InformationIcon
                                titleContent={t(k.name)}
                                originalContent={(
                                  <ButtonBase
                                        disableRipple
                                        className={classes.analyticsButtonLabel}
                                    >
                                    <Typography
                                          variant="body2"
                                          align="left"
                                          className={clsx(
                                              classes.kpiLabel,
                                              classes.textTruncated
                                          )}
                                      >
                                      {t(k.name)}
                                    </Typography>
                                  </ButtonBase>
                                )}
                            />
                          </Grid>
                          <Grid item container direction="column">
                            {row?.customKpiValues
                              ?.filter(
                                (customKpi) => customKpi?.customKpiId === k.id
                              )
                              ?.map(
                                (customKpiArray) => customKpiArray?.customKpiValue
                              )?.[0]
                              ?.map(
                                (kpiDetail, i) => kpiDetail && (
                                <Grid
                                      container
                                      sx={12}
                                      key={i}
                                      direction="row"
                                    >
                                  <Grid
                                        className={classes.analyticsTableLabel}
                                        item
                                      >
                                    <InformationIcon
                                        titleContent={`${t(
                                            `custom.kpi.${
                                                customKpiDetails?.find(
                                                    (e) => e.id === kpiDetail.first
                                                )?.element
                                            }`
                                        )} ${t(
                                            `custom.kpi.${
                                                customKpiDetails?.find(
                                                    (e) => e.id === kpiDetail.first
                                                )?.detail
                                            }`
                                        )}`}
                                        placement="right"
                                        originalContent={(
                                          <ButtonBase
                                                disableRipple
                                                className={clsx(
                                                    classes.analyticsButtonLabel,
                                                    classes.textTruncated
                                                )}
                                            >
                                            <Typography
                                                  variant="body2"
                                                  align="left"
                                                  className={clsx(
                                                      classes.kpiValue,
                                                      classes.textTruncated
                                                  )}
                                              >
                                              {`${t(
                                                    `custom.kpi.${
                                                        customKpiDetails?.find(
                                                            (e) => e.id === kpiDetail.first
                                                        )?.element
                                                    }`
                                                )} ${t(
                                                    `custom.kpi.${
                                                        customKpiDetails?.find(
                                                            (e) => e.id === kpiDetail.first
                                                        )?.detail
                                                    }`
                                                )}`}
                                            </Typography>
                                          </ButtonBase>
                                        )}
                                    />
                                  </Grid>
                                  <Grid
                                        item
                                        className={clsx(
                                          classes.analyticsTableValue
                                        )}
                                      >
                                    <InformationIcon
                                        titleContent={`${formtNumberByLanguage(
                                            round(kpiDetail.second, 2)
                                        )} ${
                                            customKpiDetails?.find(
                                                (e) => e.id === kpiDetail.first
                                            )?.unitOfMeasure
                                        }`}
                                        originalContent={(
                                          <Typography
                                                component="h6"
                                                align="right"
                                                className={clsx(
                                                    classes.kpiValue,
                                                    classes.textTruncated
                                                )}
                                            >
                                            {`${formtNumberByLanguage(
                                                  round(kpiDetail.second, 2)
                                              )} ${
                                                  customKpiDetails?.find(
                                                      (e) => e.id === kpiDetail.first
                                                  )?.unitOfMeasure
                                              }`}
                                          </Typography>
                                        )}
                                        placement="right"
                                    />
                                  </Grid>
                                </Grid>
                                  )
                              )}
                          </Grid>
                        </Grid>
                      )
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        );
      })}
    </Box>
  ) : (
    <CircularLoader />
  );
};

export default TableChart;
