import React from "react";
import { Chip, Tooltip } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import { getOrdinalSuffix } from "util";
import useStyles from "./style";

function AttemptChip({
  attempt, i18n, handleAttempClick, originalItemId,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const attemptValue = attempt || 1;
  const attemptWithOrdinal = getOrdinalSuffix(attemptValue, i18n.language);

  return (
    <Tooltip title={t("attempt.chip.tooltip", { attemptValue: attemptWithOrdinal })}>
      <Chip
        label={attemptWithOrdinal}
        size="small"
        className={classes.chip}
        clickable={attemptValue > 1}
        onClick={(e) => {
            e.stopPropagation()
            attemptValue > 1 && handleAttempClick && handleAttempClick(originalItemId)
        }}
      />
    </Tooltip>
  );
}

export default withTranslation()(AttemptChip);
