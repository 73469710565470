import React from "react";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

export default function EmptyDropSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box
      height="80%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gridRowGap={10}
    >
      <QueueOutlinedIcon className={classes.dragIcon} />
      <Typography className={classes.dragHelper}>
        {t("drag.and.drop.here")}
      </Typography>
    </Box>
  );
}
