import React, { useState, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Query } from "@redux-requests/react";
import { useDispatch, useSelector } from "react-redux";
import { isFleetAdministrator } from "util";
import { FETCH_FLEETS } from "../../../../redux/constants";
import { updateFilter } from "../../../../redux/slices/adminFilterSlice.js";
import { fetchFleets } from "../../../../redux/actions/services";
import { GROUPS_BASE_URL } from "util/helpers/route.helper";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTable from "../../../../components/TableComponents/CustomTable";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import FleetTableHeader from "./FleetTableHeader";
import FleetTableRow from "./FleetTableRow";
import AdminTableFilter from "../../AdminTable/AdminTableFilter";
import DataNotFound from "../../../../components/DataNotFound";
import ClientNotFound from "../../../../assets/No_Client.svg";

const fetchEntities = fetchFleets;

export default function FleetTable() {
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showTableHeader, setShowTableHeader] = useState(true);
  const [countUsers, setCountUsers] = useState(0);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);

  const statusList = [
    { code: "ACTIVE", label: "ACTIVE" },
    { code: "DISABLED", label: "DISABLED" },
  ];
  const sortByList = [
    { code: "companyName", label: "fleet.management.companyName" },
    { code: "email", label: "fleet.management.email" },
  ];

  const getStatusColor = (status) => {
    switch (status.code) {
      case "ACTIVE":
        return "ACTIVE";
      case "DISABLED":
        return "OFFLINE";
      default:
        return "OFFLINE";
    }
  };

  const handleChangeStatus = (event) => {
    const statuses = event?.map(({ code }) => code);
    dispatch(
      updateFilter({
        status: statuses,
        pageNo: 0,
      }),
    );
  };

  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      }),
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      }),
    );
  };

  const handleSelectedAll = (checked) => {
    if (!checked) {
      const filteredData = selected.filter((fd) => !fetchedData.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(fetchedData);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countUsers - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));

  const handleNextPage = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
  };
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  };

  return (
    <Box>
      <AdminTableFilter
        statusList={statusList}
        sortByList={sortByList}
        pageSize={filter.pageSize}
        fetchEntities={fetchEntities}
        searchLabel="Search"
        handleChangeStatus={handleChangeStatus}
        handleFilterValueChange={handleFilterValueChange}
        handleChangeSortBy={handleChangeSortBy}
        entityBaseUrl={GROUPS_BASE_URL}
        showAddIcon={isFleetAdministrator(currentUser)}
        addButtonText={t("fleet.management.add.button")}
        getStatusColor={getStatusColor}
      />

      <CustomTableContainer>
        <CustomTable>
          {showTableHeader && (
            <FleetTableHeader
                selected={selected}
                setSelected={setSelected}
                handleSelectedAll={handleSelectedAll}
                checkedAll={
                    fetchedData?.length > 0
                    && isAllSelected
                }
                checkedIndeterminate={
                    fetchedData?.length > 0
                    && !isAllSelected
                    && fetchedData.some((d) => selected.includes(d))
                }
                fetchedData={fetchedData}
                fetchEntities={fetchEntities}
                page={filter[filter.currModule].pageNo}
                rowsPerPage={filter[filter.currModule].pageSize}
                statusList={statusList}
            />
          )}
          <Query type={FETCH_FLEETS} loadingComponent={CircularLoader}>
            {({ data }) => {
              if (!data?.content?.length) setCountUsers(0);
              setFetchedData(data?.content);
              const length = data?.totalElements;
              setCountUsers(length);
              data?.content?.length > 0
                ? setShowTableHeader(true)
                : setShowTableHeader(false);
              return data?.content?.length > 0 ? (
                data.content?.map((row, index) => (
                  <FleetTableRow
                    key={`fleet-table-${index}`}
                    row={row}
                    index={index}
                    handleClick={() => {
                      history.push(`${GROUPS_BASE_URL}/${get(row, "id")}`);
                    }}
                    selected={selected}
                    setSelected={setSelected}
                    entityBaseUrl={GROUPS_BASE_URL}
                    fetchEntitiesReset={fetchEntitiesReset}
                    fetchEntities={fetchEntitiesWithFilter}
                    open={open}
                  />
                ))
              ) : (
                <DataNotFound
                  message={t("no.fleets.message")}
                  icon={ClientNotFound}
                />
              );
            }}
          </Query>
        </CustomTable>
      </CustomTableContainer>
      {showTableHeader && (
        <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={countUsers ?? 0}
          page={filter[filter.currModule].pageNo}
          rowsPerPage={filter[filter.currModule].pageSize}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          onNext={handleNextPage}
          onPrevious={handlePrevious}
        />
      )}
    </Box>
  );
}
