import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/CustomDialog";

export default function DeleteItem({
  selectedItem,
  handleDeleteConfirm,
  openDialog,
  setOpenDialog
}) {
  const { t } = useTranslation();
  const isExclusive = selectedItem?.type === "exclusive" || selectedItem?.costAllocations?.type === "exclusive";

  return (
    <div>
      {selectedItem && (
        <CustomDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            title={`${t("Delete Item")} ${isExclusive
                ? `"${selectedItem.name}"`
                : `"${selectedItem.costAllocations?.name}"`}`}
            contentText={(isExclusive)
                ? t("Are you sure you want to delete this cost ?")
                : t("This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.")}
            actionElements={[
              {
                label: t("Cancel"),
                onClick: () => setOpenDialog(false),
                color: "primary",
              },
              {
                label: t("Delete"),
                onClick: handleDeleteConfirm,
                view: "primary",
              }
            ]}
        />
      )}
    </div>
  );
}
