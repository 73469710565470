import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 10;

export const initialState = {
  process: [],
  searchText: "",
  fleet: [],
  divisions: [],
  tags: [],
  selectedDurationValue: "ALL_TIME",
  fromDate: null,
  toDate: null,
  processExecutions: [],
  pageNo: 0,
  pageSize: defaultPageSize,
  order: {
    id: "requestDate",
    label: "Requested date (Descending)",
    order: "desc",
  },
  exceptionType: [],
  exception: [],
  originalItemId: null,
};

const counterSlice = createSlice({
  name: "executionRequestsFilter",
  initialState,
  reducers: {
    initFilter: () => initialState,
    updateFilter: (state, action) => action.payload,
  },
});

export const { initFilter, updateFilter } = counterSlice.actions;
export default counterSlice.reducer;
