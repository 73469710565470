import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import { isSubModulePermitted } from "components/HasPermission";
import SubPagePanel from "components/SubPagePanel";
import SubPageContainer from "components/SubPageContainer";
import useStyles from "./style";
import HistoricalExecutionRequests from "./ExecutionRequestsPage/HistoricalExecutionRequests";
import ExecutionRequests from "./ExecutionRequestsPage/ExecutionRequests";

export default function ExecutionRequestPages() {
  const history = useHistory();
  const routes = ["/executionRequests", "/executionRequests/history"];
  const classes = useStyles();

  const value = routes.indexOf(history.location.pathname) !== -1 ? routes.indexOf(history.location.pathname) : 0;
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });

  return (
    <SubPageContainer>
      <div className={classes.rootTabs}>
        <Grid className={classes.firstGrid}>
          {(isSubModulePermitted(currentUser, "Execution Requests") || true) && (
            <SubPagePanel value={value} index={0}>
              <ExecutionRequests />
            </SubPagePanel>
          )}
          {(isSubModulePermitted(currentUser, "Execution Requests") || true) && (
            <SubPagePanel value={value} index={1}>
              <HistoricalExecutionRequests />
            </SubPagePanel>
          )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
