import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    marginBottom: "1rem",
  },
  todayButton: { height: "2rem", alignSelf: "center", boxShadow: "none" },
  view: { height: "2rem", alignSelf: "center" },
  label: { fontSize: "1.3rem" },
  arrow: { display: " flex", alignItems: "center" },
  alertWarning: {
    height: "15px",
    fontSize: "10px",
    backgroundColor: "orange",
    color: theme.palette.common.white,
  },
}));
