import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  pageHeading: {
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  mobileCard: {
    padding: 0,
  },
  pageHeader: {
    marginBottom: theme.spacing(1),
  },
}));
