import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularLoader from "components/Loaders/CircularLoader";
import { useTranslation } from "react-i18next";
import { numberFloatFormat } from "util/index";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { MONITORING_TIME, MONITORING_TIME_Params } from "pages/newDashboard/dashboardUtils";
import { fetchDashboardAverageHandlingTime } from "redux/actions/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "pages/newDashboard/style";

export default function WeeklyAverageHandlingTime() {
  const classes = useStyles()();
  const { t } = useTranslation();
    const dispatch = useDispatch();
    const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);
    const [averageHandlingTime, setAverageHandlingTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const fetchAverageHandlingTime = (withLoading = true) => {
        setIsLoading(withLoading);
        dispatch(
            fetchDashboardAverageHandlingTime({
                ...filterDashboard
            })
        ).then((res) => {
            setAverageHandlingTime(res?.data);
            setIsLoading(false);
        });
    }

    const fetchIntervalRef = useRef(null);
    useEffect(() => {
        handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchAverageHandlingTime, MONITORING_TIME_Params)
        return () => {
            clearInterval(fetchIntervalRef.current)
        }
    }, [filterDashboard])

    useEffect(() => {
        fetchAverageHandlingTime()
    }, [filterDashboard]);

  return (
    <Box className={classes.sectionContainer} marginTop="0 !important">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="div" className={classes.sectionTitle}>
          {t("Average Handling Time")}
        </Typography>
        <Typography component="div" className={classes.handlingTimeValue}>
          {isLoading ? (
            <CircularLoader justifyContent="flex-end" height={20} size={13} />
          ) : (
            `${numberFloatFormat(averageHandlingTime)} ${t("timeUnite.sec")}`
          )}
        </Typography>
      </Box>
    </Box>
  );
}
