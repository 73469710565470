import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useQuery } from "@redux-requests/react";
import { fetchProcessesForFilters } from "redux/actions/services";
import { FETCH_PROCESSES_FOR_FILTERS } from "redux/constants";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateFilterPageNo,
  updateFilterPageSize,
} from "redux/slices/pendingAutomationSlice";
import { fetchPendingAutomations } from "redux/actions/services/pendingAutomation";
import { useTranslation } from "react-i18next";
import PageHeader from "components/PageHeader";
import CustomTableContainer from "components/TableComponents/CustomTableContainer";
import CustomTable from "components/TableComponents/CustomTable";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import AutomationRow from "pages/AutomationsPending/AutomationRow";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomDialog from "components/CustomDialog";
import NoDataMenu from "components/NoData/NoDataMenu";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import ConfigurationSteps from "pages/AutomationsPending/ConfigurationSteps";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import useStyles from "./style";

const AutomationsPending = (props) => {
  const classes = useStyles()();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProcesses = useQuery({ type: FETCH_PROCESSES_FOR_FILTERS })?.data;
  const pendingAutomationFilters = useSelector(
    ({ pendingAutomationFilters }) => pendingAutomationFilters
  );
  const [openConfigConfirm, setOpenConfigConfirm] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState();
  const [copyConfiguration, setCopyConfiguration] = useState("NO");
  const [copiedAutomation, setCopiedAutomation] = useState(null);
  const [openConfigSteps, setOpenConfigSteps] = useState(false);
  const [configuredAutomations, setConfiguredAutomations] = useState(null);
  const headerCellProps = {
    paddingBottom: 0,
  };
  const headerCells = [
    { label: t(""), ...headerCellProps },
    { label: t("App Server"), ...headerCellProps },
    { label: t("Creation date"), ...headerCellProps },
    { label: t(""), ...headerCellProps },
  ];
  const handleConfigClick = (row) => {
    setConfiguredAutomations(row);
    setOpenConfigConfirm(true);
  };
  const handleNext = (page) => dispatch(updateFilterPageNo({ page: page + 1 }));
  const handlePrevious = (page) => dispatch(updateFilterPageNo({ page: page - 1 }));
  const handleChangePage = (event, newPage) => dispatch(updateFilterPageNo({ page: newPage }));
  const handleChangeRowsPerPage = (event) => {
    dispatch(updateFilterPageSize({ size: event.target.value }));
    dispatch(updateFilterPageNo({ page: 0 }));
  };
  const onFetchDataSuccess = ({ data }) => {
    setDataList(data?.content);
    setCount(data?.totalElements);
    setLoading(false);
  };

  const handleConfirm = () => {
    setOpenConfigConfirm(false);
    setOpenConfigSteps(true);
  };

  const handleClose = () => {
    setOpenConfigSteps(false);
    setCopyConfiguration("NO");
    setConfiguredAutomations(null);
    setCopiedAutomation(null);
    setOpenConfigConfirm(false);
  };

  useEffect(() => {
    dispatch(fetchProcessesForFilters());
  }, []);

  useEffect(() => {
    setLoading(true);
    props.fetchData(pendingAutomationFilters, onFetchDataSuccess);
  }, [pendingAutomationFilters]);

  return (
    <>
      <Box mt="15px">
        <PageHeader title="Automations Pending Configuration" />
        {loading ? (
          <CircularLoader height={500} />
        ) : (
          <CustomTableContainer className={classes.tableContainer}>
            {dataList && dataList.length > 0 ? (
              <CustomTable>
                <CustomTableHeader labels={headerCells} />
                <TableBody>
                  {dataList?.map((row) => (
                    <AutomationRow
                      key={row.id}
                      row={row}
                      handleConfigClick={handleConfigClick}
                    />
                  ))}
                </TableBody>
              </CustomTable>
            ) : (
              <DataNotFound />
            )}
          </CustomTableContainer>
        )}
        {count > 0 && dataList && (
          <CustomPagination
            count={count}
            rowsPerPage={pendingAutomationFilters.size}
            page={pendingAutomationFilters.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
      </Box>
      {openConfigConfirm && (
        <CustomDialog
          title={t(
            "Do you want to configure manually or copy from an existing automation?"
          )}
          open={openConfigConfirm}
          actionElements={[
            {
              label: t("Cancel"),
              onClick: handleClose,
              view: "cancelModal",
            },
            {
              label: t("Yes"),
              onClick: handleConfirm,
              view: "primary",
              disabled: copyConfiguration === "YES" && !copiedAutomation,
            },
          ]}
          width={550}
          borderRadius={7}
        >
          <Box pl={1}>
            <RadioGroup
              value={copyConfiguration}
              onChange={(e) => setCopyConfiguration(e.target.value)}
            >
              <FormControlLabel
                value="NO"
                control={<Radio className={classes.radio} />}
                label={
                  <Typography className={classes.dialogDefaultText}>
                    {t("Configure manually")}
                  </Typography>
                }
              />
              <FormControlLabel
                value="YES"
                control={<Radio className={classes.radio} />}
                label={
                  <Typography className={classes.dialogDefaultText}>
                    {t("Copy from an existing automation")}
                  </Typography>
                }
              />
            </RadioGroup>
            {copyConfiguration === "YES" && (
              <Box
                display="grid"
                gridTemplateColumns="3FR 4FR"
                gridColumnGap="20px"
                alignItems="flex-end"
              >
                <Typography className={classes.copyHelper}>
                  {`${t("Copy automation from")} :`}
                </Typography>
                <CustomAutoComplete
                  options={userProcesses || []}
                  optionLabel="processName"
                  value={copiedAutomation}
                  noOptionsNode={
                    <NoDataMenu message={t("no.process.message")} />
                  }
                  onChange={(value) => setCopiedAutomation(value)}
                  label={t("processes")}
                  iconPath="icon"
                />
              </Box>
            )}
          </Box>
        </CustomDialog>
      )}
      {openConfigSteps && (
        <ConfigurationSteps
          openConfigSteps={openConfigSteps}
          configuredAutomations={configuredAutomations}
          copiedAutomation={
            copyConfiguration === "YES" ? copiedAutomation : null
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchData: fetchPendingAutomations,
};
export default connect(null, mapDispatchToProps)(AutomationsPending);
