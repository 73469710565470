import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartStyles from "chartjs-plugin-style";

export default function MixedChart({ data, options }) {
  data.datasets[0].data = data?.datasets[0]?.data?.map((value) => parseFloat(value.toFixed(2)));

  return (
    <Line
      height={300}
      width={700}
      plugins={[ChartDataLabels, ChartStyles]}
      data={data}
      options={options}
    />
  );
}
