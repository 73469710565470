import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.common.white,
    margin: 0,
  },

}));
