import { getApiDomain } from "util/helpers/preference.helper";
import KeycloakConf from "../../conf/keycloakConf";

const checkImageExists = (url) => new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
});
const getImageURL = (path, id) => {
    const imageUrl = `${getApiDomain()}api/orchestrator/tags/${id}/icon`
    return checkImageExists(imageUrl) ? imageUrl : null;
};
// eslint-disable-next-line consistent-return
export const fetchImage = async (src, setFile) => {
        const encodedSrc = encodeURIComponent(src);
        const response = await fetch(encodedSrc, {
            headers: {
                Authorization: `Bearer ${KeycloakConf.token}`, // Replace with your token
            },
        });

        // If response is OK, create a URL from the Blob and set the image src
        if (response.ok) {
            const blob = await response.blob();
            setFile(URL.createObjectURL(blob));
        }
};
export const formatImagePath = (oldPath, id) => {
    let iconPath = null;
    try {
        // old process icons were on the server and had a /process/logo prefix
        let image = oldPath;
        if (image && image.includes("processes/logo/")) {
            image = image.replace("processes/logo/", "");
        }
        // eslint-disable-next-line global-require
        iconPath = require(`../../pages/Services/components/resources/svg/${image}`);
    } catch (e) { /* empty */ }
    return iconPath || !id ? iconPath : getImageURL(oldPath, id);
};
export const formatExportFilename = (filename) => {
    const prefix = filename.substring(0, filename.indexOf("-"));
    const desiredLength = 30 - prefix.length;
    const paddedPrefix = prefix.padEnd(filename, " ");
    const uuid = filename.substring(filename.indexOf("-"), filename.indexOf(".xls"));
    const truncatedUuid = `${uuid.substring(0, 5)}...${uuid.substring(uuid.length - (desiredLength - 8), uuid.length)}`;
    return `${paddedPrefix}${truncatedUuid}.xls`;
}
export const onExportSuccess = (response, exportName) => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    const disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches !== null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            link.setAttribute("download", filename);
        } else {
            link.setAttribute("download", exportName);
        }
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response;
}
