/* global html2pdf */
/* eslint no-undef: "error" */
import format from "date-fns/format";

const exportPdf = (NameProcess, setExportLoading, handleClose) => {
  const html2pdf_Library_URL = "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js";

  const script = document.createElement("script");
  script.src = html2pdf_Library_URL;
  document.head.appendChild(script);

  script.addEventListener("load", () => {
    const element = document.getElementById("pdfResult");

    const dateString = format(new Date(), "yyyy-MM-dd_HH-mm-ss");

    const opt = {
      margin: 0.5,
      filename: `SR_ProcessAssessment_${NameProcess.replace(/\s/g, "-")}_${dateString}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save()
      .then(() => {
        handleClose();
        setExportLoading(false);
      });
  });
};

export default exportPdf;
