import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CustomDialog from "pages/Services/components/CustomDialog";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ConfirmMessage from "../../../../../../components/ConfirmMessage";
import { formatDatePickerByLanguage, formatTimezone, getLocale } from "util";
import { frequencyScheduleAlerts } from "util/configs/alerts";
import {
allDaysSelected, DEFAULT_FROM_TIME, DEFAULT_TO_TIME, joinDays
} from "util/helpers/date.helpers";
import { format, getTimezoneOffset } from "date-fns-tz";
import QuestionMarkIcon from "../../../../../../components/QuestionMarkIcon";
// eslint-disable-next-line import/no-cycle
import { USE_CUMULATIVE, USE_INTERVALS } from "../..";
import CustomButton from "../../../../../../components/CustomButton";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete"
import CustomAlert from "components/CustomAlert";
import WeekDaysCalendar from "components/TimeSelectors/WeekDaysCalendar";
import DayHoursSelector from "components/TimeSelectors/DayHoursSelector";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import { frequencies, getLabelByPeriod, getSelectOptionsByPeriod } from "./utils"

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const allowedAdvancedSettingsFrequencies = [0];

export default function ScheduleForm({
  classes,
  value,
  handleChangeTab,
  open,
  handleClose,
  register,
  handleChangeEndDate,
  handleChangeStartDate,
  endDate,
  startDate,
  disableFields,
  handleCloseDialog,
  handleChangeFrequency,
  discardSchedulerChanges,
  frequency,
  confirmSaveScheduler,
  submittedScheduler,
  useInterval,
  handleChangeUseInterval,
  disableSchedulerInfo,
  errors,
  days,
  setDays,
  setError,
  clearErrors,
  fromTime,
  toTime,
  setToTime,
  setFromTime,
  selectedTimeZone,
  setSelectedTimeZone,
  resetAdvancedSettings,
  currentUserTimeZone
}) {
  const { t } = useTranslation();
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = React.useState(false);
  const [startDateError, setStartDateError] = React.useState();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [timezones, setTimezones] = useState([]);

  const showMsgDiscard = () => {
    setOpenMsgDiscard(true);
  };

  const confirmDiscard = () => {
    setOpenMsgDiscard(false);
    handleCloseDialog();
    discardSchedulerChanges();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };

  const confirmSave = () => {
    if (startDate) {
        confirmSaveScheduler();
        setOpenMsgConfirm(false);
        if (
          !errors.alertScheduler?.fromSelectedDate
          && !errors.alertScheduler?.toSelectedDate
        ) {
          handleCloseDialog();
        }
        }
    else setStartDateError("alerts.management.required.fields")
  };

  const handleSchedulerSubmit = () => {
    if (startDate) {
      setOpenMsgConfirm(true);
    } else setStartDateError("alerts.management.required.fields")
  }

  useEffect(() => {
    const timezones_ = Intl.supportedValuesOf("timeZone")
        .map((time) => ({
          name: time,
          offset: getTimezoneOffset(time, new Date()) / 3.6e6,
        }))
        .map((e) => ({
          ...e,
          label: formatTimezone(e.name, e.offset, e.name),
        }))
        .sort((a, b) => a.offset - b.offset || a.label.localeCompare(b.label));
    setTimezones(timezones_);
    return () => setTimezones([]);
  }, []);

  const resetSettings = () => {
    resetAdvancedSettings();
    setShowAdvancedSettings(false);
  };

  return (
    <CustomDialog
      onClose={handleClose}
      fullWidth
      open={open}
      disableBackdropClick
      maxWidth="sm"
      classes={{
        paper: classes.dialogSchedulePaper,
      }}
    >
      <Grid className={classes.schedulerRoot}>
        <Grid container justify="start">
          <Grid item xs={8} justify="flex-start">
            <Typography variant="h5" className={classes.title}>
              {t("alerts.management.checking.frequence")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12} align="left">
              <FormControl
                component="fieldset"
                disabled={disableFields || disableSchedulerInfo}
              >
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  {...register("alertScheduler.useInterval")}
                  onChange={handleChangeUseInterval}
                  value={useInterval}
                  disabled={disableFields || disableSchedulerInfo}
                >
                  <FormControlLabel
                    checked={useInterval === USE_INTERVALS}
                    value={USE_INTERVALS}
                    control={<Radio />}
                    label={(
                      <span className={classes.radioSchedulerTitles}>
                        <Typography className={classes.scheduleFormText}>
                          {t("use intervals")}
                        </Typography>
                        <QuestionMarkIcon titleContent={t("The calculation of the KPIs will be based on an interval")} />
                      </span>
                    )}
                  />
                  <FormControlLabel
                    checked={useInterval === USE_CUMULATIVE}
                    value={USE_CUMULATIVE}
                    control={<Radio />}
                    label={(
                      <span className={classes.radioSchedulerTitles}>
                        <Typography className={classes.scheduleFormText}>
                          {t("use cumulative")}
                        </Typography>
                        {" "}
                        <QuestionMarkIcon titleContent={t("Calculation of the KPIs for the whole time")} />
                      </span>
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
                fullWidth
              >
                <FormControl fullWidth>
                  <DatePicker
                    InputLabelProps={{
                        shrink: startDate || null,
                    }}
                    InputProps={{
                      endAdornment: <CalendarToday className={classes.datePopupIcon} fontSize="small" />,
                    }}
                    className={classes.dateInput}
                    {...register("alertScheduler.startDate")}
                    clearable
                    onChange={handleChangeStartDate}
                    value={startDate || null}
                    disablePast={false}
                    format={formatDatePickerByLanguage(false)}
                    label={t("Start Date")}
                    disabled={disableFields || disableSchedulerInfo}
                    helperText={!startDate && t(startDateError)}
                    error={!startDate && startDateError}
                    minDate={new Date().getTime() + 24 * 3600 * 1000}
                    maxDate={endDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ justifyContent: "space-between", alignContent: "center" }} justify="center">
            <Grid item xs={4}>
              <CustomAutoComplete
                label={t("Alert Frequency")}
                options={frequencies}
                optionLabel={(option) => option.label}
                optionValue={(option) => option.value}
                onChange={handleChangeTab}
                value={frequencies.find((option) => option.value === value)}
                disabled={disableFields || disableSchedulerInfo}
              />
            </Grid>
            <Grid container xs={8} direction="row" spacing={1} style={{ alignContent: "end" }} justify="center">
              <Grid item xs={2} style={{ alignSelf: "end" }}>
                <Typography className={classes.scheduleFormText}>{t("Every")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <CustomAutoComplete
                  inputProps={{ "aria-label": "age" }}
                  {...register("alertScheduler.period", {})}
                  onChange={handleChangeFrequency}
                  value={frequency}
                  disabled={disableFields || disableSchedulerInfo}
                  options={getSelectOptionsByPeriod(value)}
                  error={errors.alertScheduler?.period}
                  helperText={errors.alertScheduler?.period && errors.alertScheduler?.period?.message}
                />
              </Grid>
              <Grid item xs={7} style={{ alignSelf: "end" }}>
                <Typography className={classes.scheduleFormText}>{getLabelByPeriod(value, startDate, t)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9} mb={1.5}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl fullWidth>
                  <DatePicker
                    InputLabelProps={{
                      shrink: endDate || null,
                    }}
                    InputProps={{
                      endAdornment: <CalendarToday className={classes.datePopupIcon} fontSize="small" />,
                    }}
                    className={classes.dateInput}
                    {...register("alertScheduler.endDate")}
                    clearable
                    onChange={handleChangeEndDate}
                    value={endDate || null}
                    disablePast={false}
                    format={formatDatePickerByLanguage(false)}
                    label={t("End date")}
                    disabled={disableFields || disableSchedulerInfo}
                    minDate={startDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          {allowedAdvancedSettingsFrequencies.includes(value) && (
          <CustomAlert
            alertMsg={!showAdvancedSettings && startDate && t("alerts.management.advanced.settings.alert.text", {
              date: format(new Date(startDate), `${formatDatePickerByLanguage(false)}`),
              time: fromTime?.slice(0, 5),
              days: allDaysSelected(days) ? t("week day") : joinDays(days, t)
            })}
            severity={frequencyScheduleAlerts.info.severity}
          >
            <CustomButton
              view="secondaryText"
              endIcon={showAdvancedSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={() => setShowAdvancedSettings((prevState) => !prevState)}
              className={classes.advancedSettingsBtn}
            >
              {t("alerts.management.advanced.settings.btn")}
            </CustomButton>
            {showAdvancedSettings && (
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.daysContainer}>
                  <WeekDaysCalendar
                    title="alerts.management.advanced.settings.days.label"
                    allDaysLabel="alerts.management.advanced.settings.all.days.label"
                    titleStyle={classes.weekDaysTitle}
                    days={days}
                    setDays={setDays}
                    allDaysChecked={allDaysSelected(days)}
                    disableFields={disableFields || disableSchedulerInfo}
                  />
                </Grid>
                <Grid item xs={8}>
                  <DayHoursSelector
                    title="alerts.management.advanced.settings.time.label"
                    titleStyle={classes.weekDaysTitle}
                    setError={setError}
                    clearErrors={clearErrors}
                    errors={errors}
                    fromTime={fromTime}
                    toTime={toTime}
                    setFromTime={setFromTime}
                    setToTime={setToTime}
                    pattern="HH:mm"
                    disableFields={disableFields || disableSchedulerInfo}
                  />
                </Grid>
                <Grid container item alignItems="flex-end">
                  <Grid item xs={6}>
                    <CustomSwitch
                        checked={selectedTimeZone === currentUserTimeZone}
                        handleChange={(e) => setSelectedTimeZone(e.target.checked ? currentUserTimeZone : null)}
                        label={
                          <Typography color="primary" variant="h6" className={classes.flagLabel}>
                            {t("Profile Time zone")}
                          </Typography>
                        }
                        disabled={disableFields || disableSchedulerInfo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomAutoComplete
                      options={timezones || []}
                      value={timezones?.find(
                          ({ name }) => name === selectedTimeZone
                      )}
                      onChange={(newValue) => {
                        setSelectedTimeZone(newValue?.name);
                      }}
                      optionLabel="label"
                      optionUuid="name"
                      label={t("time.zone")}
                      error={!!errors?.timeZone}
                      helperText={
                        errors?.timeZone && errors?.timeZone.message
                      }
                      disabled={selectedTimeZone === currentUserTimeZone || disableFields || disableSchedulerInfo}
                    />
                  </Grid>
                </Grid>
                { (!DEFAULT_FROM_TIME.startsWith(fromTime) || !DEFAULT_TO_TIME.startsWith(toTime) || !allDaysSelected(days)) && (
                <Grid container item justify="flex-end">
                  <CustomButton view="tertiary" onClick={resetSettings} disabled={disableFields || disableSchedulerInfo}>
                    {t("alerts.management.simple.settings.btn")}
                  </CustomButton>
                </Grid>)}
              </Grid>
            )}
          </CustomAlert>)}
        </Grid>
        <MuiDialogActions className={classes.cardActions}>
          {!disableFields && !disableSchedulerInfo ? (
            <>
              <Box
                ml="1rem"
                component={Button}
                variant="contained"
                size="medium"
                onClick={showMsgDiscard}
                className={classes.resetButton}
              >
                {t("Cancel")}
              </Box>
              <CustomButton
                view="primary"
                size="medium"
                onClick={submittedScheduler.frequency ? handleSchedulerSubmit : confirmSave}
                elevation={0}
              >
                {submittedScheduler.frequency
                  ? t("Update")
                  : t("Add")}
              </CustomButton>
            </>
          ) : (
            <CustomButton
              view="primary"
              size="medium"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              {t("alerts.management.button.exit")}
            </CustomButton>
          )}
        </MuiDialogActions>
      </Grid>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to save the changes?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={t("Save changes")}
          buttonCancel={t("Cancel")}
        />
      )}
      {openMsgDiscard && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
    </CustomDialog>
  );
}
