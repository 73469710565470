import React, { cloneElement, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import SubPagePanel from "../../../components/SubPagePanel";
import SubPageContainer from "../../../components/SubPageContainer";
import UserManagement from "../UserManagement";
import FleetManagement from "../FleetManagement";
import useStyles from "../style";
import { isSubModulePermitted } from "../../../components/HasPermission";
import { isFleetAdministrator } from "util";
import { updateModule } from "../../../redux/slices/adminFilterSlice";
import TagManagement from "../TagManagement";
import { GROUPS_BASE_URL, TAGS_BASE_URL, USERS_BASE_URL } from "util/helpers/route.helper";

export default function UsersAdministration(props) {
    const routes = [
        GROUPS_BASE_URL,
        USERS_BASE_URL,
        TAGS_BASE_URL,
    ];
    const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
    const currModule = useSelector(({ adminFilter }) => adminFilter.currModule);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const value = () => {
        const path = routes.find((element) => history.location.pathname.includes(element));
        return routes.indexOf(path);
    }

    const module = history.location.pathname.split("/").slice(1, 2).join("/");
    useEffect(() => {
        if (module !== currModule) {
            dispatch(updateModule({ module: module === "groups" ? "fleets" : module }));
        }
    }, [module]);

    return (
      <SubPageContainer>
        <div className={classes.root}>
          {props?.standAloneModalComponent}
          {(props.showEditModal || props.tableView) && (
              cloneElement(props.modalComponent, { open: props.showEditModal || props.tableView })
          )}
          <Grid className={classes.firstGrid}>
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel value={value()} index={0} classes={classes.noPadding}>
                <FleetManagement />
              </SubPagePanel>
            )}
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel
                value={value()}
                index={1}
                classes={classes.noPadding}
              >
                <UserManagement />
              </SubPagePanel>
            )}
            {isSubModulePermitted(currentUser, "Tags Module") && (
              <SubPagePanel value={value()} index={2} classes={classes.noPadding}>
                <TagManagement />
              </SubPagePanel>
            )}
          </Grid>
        </div>
      </SubPageContainer>
    );
}
