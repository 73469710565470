import React from "react";
import TableBody from "@material-ui/core/TableBody"
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { formatDateByLanguage, formtNumberByLanguage } from "util";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchOrchestrator } from "redux/actions/services";
import { getLicenses } from "redux/actions/services/license";
import { GROUPS_BASE_URL, ORCHESTRATORS_BASE_URL } from "util/helpers/route.helper";
import CustomDialog from "components/CustomDialog";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import CustomTableRow from "components/TableComponents/CustomTableRow";
import CustomTableCell from "components/TableComponents/CustomTableCell";
import CustomTableContainer from "components/TableComponents/CustomTableContainer";
import CustomTable from "components/TableComponents/CustomTable";
import DataNotFound from "components/DataNotFound";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "./style";

function OrchestratorLicense({ allData, title = "sync.license" }) {
  const history = useHistory();
  const { idOrchestrator } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClose = () => {
    history.push(allData ? GROUPS_BASE_URL : ORCHESTRATORS_BASE_URL);
  };
  const onSuccess = (result) => {
    setData(result?.data?.licenses);
    setIsLoaded(true);
  };
  const onLoadListSuccess = (result) => {
    setData(result?.data);
    setIsLoaded(true);
  };
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const getLicenseList = () => {
    setIsLoaded(false);
    dispatch(getLicenses(onLoadListSuccess));
  };

  const getOrchestratorLicenseList = () => {
    setIsLoaded(false);
    dispatch(fetchOrchestrator(idOrchestrator, onSuccess));
  };

  React.useEffect(() => {
    if (allData) getLicenseList();
    else getOrchestratorLicenseList();
  }, [allData]);

  const tableCellProps = {
    paddingTop: 15,
    paddingBottom: 15
  };

  return (
    <CustomDialog
        open
        onClose={onClose}
        title={t(title)}
        withCloseButton
        withDivider
        maxWidth="lg"
    >
      <CustomTableContainer width={753}>
        {!isLoaded && <CircularLoader />}
        {isLoaded
          && data.length > 0 ? (
            <CustomTable stickyHeader classes={{ root: classes.rootGlobal }}>
              <CustomTableHeader labels={headCells(allData).filter(({ hidden }) => !hidden)?.map((row) => ({ ...row, label: t(row.label) }))} />
              <TableBody>
                {data?.map((row) => (
                  <CustomTableRow backgroundColor={theme.palette.info.light}>
                    <CustomTableCell {...tableCellProps} text={formtNumberByLanguage(row?.originalId || row?.id)} />
                    {allData && (<CustomTableCell {...tableCellProps} text={row?.licensee} />)}
                    <CustomTableCell {...tableCellProps} text={formatDateByLanguage(row?.starts ?? row?.startsAt)} />
                    <CustomTableCell {...tableCellProps} text={formatDateByLanguage(row?.expires ?? row?.endsAt)} />
                    {!allData && (<CustomTableCell {...tableCellProps} text={formatDateByLanguage(row?.installedOn, true) || "--"} />)}
                    {allData && (<CustomTableCell {...tableCellProps} text={formtNumberByLanguage(row?.maxProcesses)} />)}
                    <CustomTableCell {...tableCellProps} text={formtNumberByLanguage(row?.maxConcurrentSessions)} />
                    {!allData && (<CustomTableCell {...tableCellProps} text={t(`orchestrator.license.status.${row?.status}`)} />)}
                  </CustomTableRow>
                  ))}
              </TableBody>
            </CustomTable>
          ) : (
            isLoaded && (
            <DataNotFound
                      backgroundColor="transparent"
                      boxShadow="none"
                      iconHeight={120}
                      iconWidth={120}
                      width={750}

        />
            ))}
      </CustomTableContainer>
    </CustomDialog>
  );
}

const translationPrefix = "orchestrator.license.";
const tableHeaderCellProps = {
  paddingTop: 5,
  paddingBottom: "0"
};
const headCells = (allData) => [
  {
    label: "licence.id",
    disablePadding: false,
    sortable: false,
    ...tableHeaderCellProps
  },
  {
    label: "licence.licensee",
    disablePadding: false,
    sortable: false,
    hidden: !allData,
    ...tableHeaderCellProps
  },
  {
    label: `${translationPrefix}starts`,
    disablePadding: false,
    sortable: false,
    ...tableHeaderCellProps
  },
  {
    label: `${translationPrefix}expires`,
    disablePadding: false,
    sortable: false,
    ...tableHeaderCellProps
  },
  {
    label: `${translationPrefix}installedOn`,
    disablePadding: false,
    sortable: false,
    hidden: allData,
    ...tableHeaderCellProps
  },
  {
    label: "licence.max.automations",
    disablePadding: false,
    sortable: false,
    hidden: !allData,
    ...tableHeaderCellProps
  },
  {
    label: `${translationPrefix}maxConcurrentSessions`,
    disablePadding: false,
    sortable: false,
    ...tableHeaderCellProps
  },
  {
    label: `${translationPrefix}status`,
    disablePadding: false,
    sortable: false,
    hidden: allData,
    ...tableHeaderCellProps
  },
];

export default OrchestratorLicense;
