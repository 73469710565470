/* eslint-disable no-return-assign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  entityId: null,
  queueAction: "",
  processesAction: []
};

const executionManagerSlice = createSlice({
  name: "executionManager",
  initialState,
  reducers: {
    openQueue: (state, action) => (state = {
        open: true,
        queueId: action.payload
    }),
    close: () => initialState,
    triggerQueueAction: (state, action) => (state = {
 ...state, queueAction: action.payload.queueAction, entityId: action.payload.id, orchId: action.payload.orchId
}),
    setProcessesAction: (state, action) => (state = { ...state, processesAction: action.payload.processesAction, queueAction: action.payload.queueAction }),
    updateQueueAction: (state, action) => ({
      ...state, queueAction: action.payload.queueAction,
    })
  },
});

export const {
 openQueue, close, triggerQueueAction, setProcessesAction, updateQueueAction
} = executionManagerSlice.actions;
export default executionManagerSlice.reducer;
