import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { useTranslation } from "react-i18next";
import useStyles from "../style";

const WeekDaysCalendar = ({
    title,
    allDaysLabel,
    titleStyle,
    days,
    setDays,
    allDaysChecked,
    disableFields,
    preventUncheckAllDays = false
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const handleClick = (val) => setDays(days.map((e) => (e.id === val ? { ...e, checked: !e.checked } : e)));

    const handleChange = (event) => setDays(days.map((e) => ({ ...e, checked: event.target.checked })));
    return (
      <Box className={classes.container}>
        <Typography className={titleStyle}>
          {t(title)}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          {days?.map((day) => (
            <Chip
                icon={(
                  <CalendarToday
                     className={classes.chipIcon}
                    />
                )}
                color={day.checked || allDaysChecked ? "primary" : "default"}
                label={t(day.label)}
                onClick={() => handleClick(day.id)}
                className={classes.chip}
                disabled={disableFields}
            />
          ))}
        </Box>
        <FormControlLabel
            classes={{
                label: classes.label,
                root: classes.checkboxContainer
            }}
            control={(
              <Checkbox
                 checked={allDaysChecked}
                 disabled={(preventUncheckAllDays && allDaysChecked) || disableFields}
                 onChange={handleChange}
                 classes={{
                     root: classes.checkboxRoot,
                     checked: classes.checkboxChecked
                 }}
              />
            )}
            label={t(allDaysLabel)}
        />
      </Box>
    ) };
export default WeekDaysCalendar;
