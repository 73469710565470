import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
    stepIconContainer: {
      border: `2px solid ${theme.custom.color.chartColor1}`,
      height: 24,
      width: 24,
      marginRight: 20,
      zIndex: 2,
      borderRadius: "50%",
      "& .MuiSvgIcon-root": {
        display: "none",
      },
    },
    stepIconContainerActive: {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    stepIconContainerCompleted: {
      backgroundColor: theme.palette.secondary.main,
    },
    stepLabel: {
      fontSize: "0.8rem",
      fontWeight: 700,
      color: theme.custom.color.chartColor1,
      padding: theme.spacing(0.5, 0.7),
    },
    stepActive: {
      fontSize: "0.8rem",
      fontWeight: "700 !important",
      color: `${theme.custom.color.chartColor3} !important`,
      backgroundColor: theme.custom.color.containerBackground,
      width: "fit-content",
      padding: theme.spacing(0.5, 0.8),
      borderRadius: 5,
    },
    stepCompleted: {
      fontSize: "0.8rem",
      fontWeight: "700 !important",
      color: `${theme.palette.primary.main} !important`,
    },
    stepperRoot: {
      "& .MuiStepConnector-root": {
        marginLeft: 11.5,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: -1.5,
        marginBottom: -1.2,
      },
      "& .MuiStepConnector-line": {
        borderColor: theme.custom.color.chartColor1,
        borderLeftWidth: 2,
        minHeight: 28,
      },
      ...getConnectorClasses(props?.activeStep, theme),
    },
    divider: {
      height: 420,
      width: 2,
      backgroundColor: theme.custom.color.staticInput,
      borderRadius: 9,
    },
    nextButton: {
      marginLeft: 10,
    },
    stepContainer: {
      overflowY: "auto",
      [theme.breakpoints.down(1600)]: {
        maxHeight: 550,
      },
      [theme.breakpoints.up(1600)]: {
        maxHeight: 750,
      },
    },
  }));

const getConnectorClasses = (activeStep, theme) => {
  const classes = {};
  for (let i = 1; i <= activeStep; i += 1) {
    classes[`& :nth-child(${i + i})`] = {
      "& .MuiStepConnector-line": {
        borderColor: theme.palette.secondary.main,
      },
    };
  }
  return classes;
};

export default useStyles;
