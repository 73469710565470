import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
  title: {
    marginLeft: 110,
  },
  image: {
    maxWidth: 50,
  },
  disabled: {
    opacity: 0.5,
  },
  btn: {
    textTransform: "none",
  },

  card: {
    width: "100%",
    fheight: "100%",
    padding: theme.spacing(2, 2),
  },
  cardHeader: {
    marginTop: theme.spacing(1),
  },
  gridContent: {
    padding: theme.spacing(2, 2),
  },
  lineContent: {
    padding: theme.spacing(2, 0),
  },
  contentHeader: {
    padding: theme.spacing(2, 1),
    height: "50%",
    marginBottom: theme.spacing(2),
  },
  contentHeaderTitle: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    fontWeight: 600,
  },
  lineContentTitle: {
    fontWeight: "800",
  },
  content: {
    fontWeight: "600",
  },
  cardRoot: {
    borderLeft: `6px solid ${theme.palette.primary.main} !important`,
  },
  cardRootSR: {
    borderLeft: `6px solid ${theme.palette.secondary.main} !important`
  },
  resourceAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  clearPriority: {
    color: theme.palette.secondary.main,
    marginTop: "auto",
    marginBottom: "auto"
  },
  processCard: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  priorityLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  okBtn: {
    color: theme.palette.info.main,
    marginTop: "auto",
    marginBottom: "auto"
  },
  tagChip: {
    fontSize: 12,
    color: theme.palette.info.main,
    fontWeight: 700,
    backgroundColor: "rgba(123, 176, 255, 0.13)",
    height: 28,
    minWidth: 60,
    margin: 4
  },
  tagChipWidth: {
    width: `${(props + 9) * 7}px`
  },
  tagChipLabel: {
    paddingRight: 8,
    paddingLeft: 8
  },
  tagTooltipTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 5,
    marginBottom: 4
  }
}));

export default useStyles;
