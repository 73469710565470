import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import CustomButton from "components/CustomButton";
import { Delete } from "@material-ui/icons";
import { secondsToTime } from "util/index";

export default function SlaInfo({
  classes,
  handleClickOpen,
  disableFields,
  slas,
  setSlas,
  setValue,
  setIsViewMode,
  setSelectedSlaIndex,
}) {
  const { t } = useTranslation();

  const headCells = [
    {
      id: "Name",
      label: t("Name"),
    },
    {
      id: "Description",
      label: t("Description"),
    },
    {
      id: "Tag Filter",
      label: t("Tag Filter"),
    },
    {
      id: "Deadline",
      label: t("Deadline"),
    },
    {
      id: "Average running time",
      label: t("Average running time"),
    },
    {
      id: "Response time calculation",
      label: t("Response time calculation"),
    },
    {
      id: "actions",
      label: t("Actions"),
    },
  ];

  const deleteSla = (e, index) => {
    e.stopPropagation();
    const newSlas = [...slas];
    newSlas.splice(index, 1);
    setSlas(newSlas);
    setValue("orchestrationSlas", newSlas);
  };

  const update = (e, index) => {
    setSelectedSlaIndex(index);
    e.stopPropagation();
    setIsViewMode(false);
    handleClickOpen(false);
  };

  const show = (index) => {
    setSelectedSlaIndex(index);
    setIsViewMode(true);
    handleClickOpen(true);
  };

  const getTimeValueInSeconds = (time) => secondsToTime(time.hours * 3600 + time.minutes * 60 + time.seconds, t)

  return (
    <Grid container item spacing={2}>
      <TableContainer>
        <Table aria-label="Actions Table" classes={{ root: classes.table }}>
          {slas?.length > 0 && (
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              align="start"
            />
          )}
          <TableBody>
            {(slas || []).map((sla, index) => (
              <TableRow hover role="checkbox" tabIndex={index} onClick={() => show(index)} className={classes.clickableRow}>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.name
                    ? sla?.name
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.description
                    ? sla?.description
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.tagFilter
                    ? sla?.tagFilter
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.deadline
                    ? getTimeValueInSeconds(sla?.deadline)
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.averageRunningTime
                    ? getTimeValueInSeconds(sla?.averageRunningTime)
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={
                    disableFields ? classes.tableCellGrey : classes.tableCell
                  }
                >
                  {sla?.responseTimeCalc
                    ? t(sla?.responseTimeCalc)
                    : "---"}
                </TableCell>
                <TableCell
                  align="start"
                  className={classes.tableCell}
                >
                  <Grid container justify="start" direction="row" className={classes.actionsGrid}>
                    <Tooltip title={t("tooltip.action.edit")}>
                      <CustomButton onClick={(e) => update(e, index)} disabled={disableFields}>
                        <EditIcon />
                      </CustomButton>
                    </Tooltip>
                    <Tooltip title={t("tooltip.action.edit")}>
                      <CustomButton onClick={(e) => deleteSla(e, index)} disabled={disableFields}>
                        <Delete />
                      </CustomButton>
                    </Tooltip>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
