import theme, { fontFamily } from "../../style/orchestratorTheme";
import { abbreviateNumber, minimizeString } from "util";

export const MAX_PROCESSES_HANDLING_TIME = 4;
export const MAX_PROCESSES_ACTIVITIES = 4;
export const MAX_ROBOT_PRODUCTIVITY = 4;
export const LINE_CHART_LABELS = (chartsCount = 1) => ({
  fontColor: theme.palette.primary.main,
  boxWidth: 15,
  boxHeight: 15,
  padding: 30,
  generateLabels: (chart) => chart.data.labels.slice(0, chartsCount).map((l, i) => ({
    datasetIndex: i,
    text: chart.data.datasets[i]?.label,
    fillStyle: chart.data.datasets[i]?.borderColor,
    strokeStyle: chart.data.datasets[i]?.borderColor,
    hidden: chart?.data?.datasets[i] && chart?.getDatasetMeta(i)?.hidden
  }))
});

export const LINE_CHART_X_AXES = [
  {
    gridLines: {
      drawOnChartArea: false,
    },
    ticks: {
      fontColor: theme.palette.primary.main,
      callback: (value) => minimizeString(value)
    },
    offset: true,
  },
];

export const LINE_CHART_DATA_LABELS = {
  formatter: (value, context) => (context.datasetIndex === 0 ? abbreviateNumber(value) : ""),
  color: theme.palette.primary.main,
  opacity: 1,
  align: "top",
  font: {
    weight: 700,
    family: fontFamily
  },
  padding: {
    bottom: 15
  },
};

export const LINE_CHART_DATASET_OPTION = {
    fill: false,
    borderColor: theme.palette.secondary.main,
    borderWidth: 4,
    pointRadius: 5,
    pointHoverRadius: 6,
    pointBorderColor: theme.palette.background.default,
    pointBackgroundColor: theme.palette.secondary.main,
    pointBorderWidth: 3,
    pointHoverBorderWidth: 3.5,
    shadowOffsetX: 0,
    shadowOffsetY: 3,
    shadowBlur: 4,
    shadowColor: "rgba(255,6,126,0.4)",
    pointShadowColor: theme.palette.secondary.main,
    pointShadowBlur: 3,
    pointShadowOffsetX: 0,
    pointShadowOffsetY: 0,
    backgroundColor: (ctx) => {
    const gradient = ctx.chart.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 500);
    gradient.addColorStop(0, "rgba(255, 6, 136, 0.35)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
    return gradient;
  }

};

export const LINE_CHART_TOOLTIP_OPTIONS = {
  yAlign: "bottom",
  backgroundColor: theme.palette.background.default,
  titleFontColor: theme.palette.info.main,
  bodyFontColor: theme.palette.primary.main,
  xPadding: 10,
  yPadding: 10,
  bodyFontStyle: "bold",
  cornerRadius: 4,
  shadowOffsetX: -3,
  shadowOffsetY: 1,
  shadowBlur: 20,
  shadowColor: "rgba(123,176,255,0.35)",
  displayColors: false,
  intersect: false
};

export const handleRequestMonitoringMode = (refreshTime, fetchIntervalRef, dispatchRequest) => {
  clearInterval(fetchIntervalRef.current);
  if (refreshTime) {
    fetchIntervalRef.current = setInterval(() => {
      dispatchRequest();
    }, refreshTime);
  }
  return () => clearInterval(fetchIntervalRef.current);
}
