import React, { forwardRef } from "react"
import { fade, withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import theme from "../../../style/orchestratorTheme";

export const TABLE_ROW_STYLE = {
    backgroundColor: ({ backgroundColor }) => backgroundColor ?? theme.palette.info.light,
    marginBottom: 10,
    cursor: ({ onClick }) => (onClick ? "pointer" : "default"),
    transition: "box-shadow 0.35s",
    width: "100%",
    "&:hover": {
        backgroundColor: ({ backgroundColor }) => (backgroundColor ? "" : theme.custom.color.containerBackgroundHover),
    },
    "& td p": {
        color: ({ disabled }) => (disabled ? `${fade(theme.palette.primary.main, 0.6)} !important` : ""),
    }
}

export const StyledTableRow = withStyles(() => ({
    root: {
        ...TABLE_ROW_STYLE
    }
}))(TableRow);

const CustomTableRow = forwardRef((props, ref) => (
  <StyledTableRow ref={ref} {...props}>
    {props?.children}
  </StyledTableRow>
));

export default CustomTableRow;
