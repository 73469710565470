import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FolderIcon from "@material-ui/icons/Folder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  archiveTag,
  deleteTag,
  enableOrDisableTag,
} from "../../../../../redux/actions/services";
import { tagStatus } from "util/index";
import { disabledFunctionality } from "util/constants/PermitedFeature";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import PermissionManagement from "../../../PermissionsManagement/PermissionsManagement";
import { isPermitted } from "../../../../../components/HasPermission";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import useStyles from "../../../../../components/TableComponents/style";

const displayFields = [
  !disabledFunctionality && {
    id: "tagStatus",
    verticalAlign: "middle",
    valuePath: "status",
    customContent: ({ valuePath }) => <StatusBadge level={valuePath} />,
    width: 50,
  },
  {
    id: "tagDisplayName",
    verticalAlign: "middle",
    valuePath: "name",
  },
  {
    id: "tagDescription",
    verticalAlign: "middle",
    valuePath: "description",
  },
];

export default function TagTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = get(row, "status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgConfirmArchive, setOpenMsgConfirmArchive] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState(null);
  const [toArchivedId, setToArchivedId] = React.useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
    archive: false,
  });

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false, archive: false });

  const handleChangeStatus = () => {
    const id = get(row, "id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableTag(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
        setOpenConfirmationPopup(false);
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
    setOpenMsgConfirmArchive(false);
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };
  const onConfirmArchiveSuccess = () => {
    setOpenMsgConfirmArchive(false);
    setIsLoading({ ...isLoading, archive: false });
    fetchEntitiesReset();
    toast.success(t("tag.archived.success"));
  };
  const onConfirmArchiveError = () => {
    setOpenMsgConfirmArchive(false);
    setIsLoading({ ...isLoading, archive: false });
    fetchEntitiesReset();
    toast.error("Unexpected Error");
  };
  const confirmArchive = () => {
    setIsLoading({ ...isLoading, archive: true });
    dispatch(
      archiveTag(
        toArchivedId,
        onConfirmArchiveSuccess,
        onConfirmArchiveError,
      ),
    );
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteTag(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        toast.success(t("tag.deleted.success"));
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };
  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const getFieldValue = (row, field) => {
    const value = Array.isArray(field.valuePath)
      ? field.valuePath.reduce(
          (acc, currentValue) => `${acc} ${t(getFieldData(currentValue))}`.trim(),
          "",
        )
      : getFieldData(field.valuePath);
    if (!value && field.defaultValue) return field.defaultValue;
    if (field.format) return field.format(value);
    return value;
  };
  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
      >
        {displayFields.map(
          (field, i) => field
            && (field?.customContent ? (
              <CustomTableCell verticalAlign="middle">
                {field.customContent({
                  valuePath: getFieldData(field?.valuePath),
                  indexRow: getFieldData("id"),
                  indexField: get(field, "id"),
                  valueAvatarPath: getFieldData(field?.avatarPath),
                })}
              </CustomTableCell>
            ) : (
              <CustomTableCell
                key={i}
                text={getFieldValue(row, field)}
                verticalAlign={field?.verticalAlign}
                width={field?.width}
              />
            )),
        )}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {(isPermitted(currentUser, "Edit tag")
                && get(row, "status") !== tagStatus.DISABLED) && (
                <Grid item>
                  <Tooltip placement="top" title={t("tooltip.action.edit")}>
                    <CustomEditIcon
                    id="tag-admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${get(row, "id")}`);
                    }}
                    className={classes.rowActionButtons}
                    />
                  </Tooltip>
                </Grid>
            )}
            {isPermitted(currentUser, "Archive")
              && !disabledFunctionality
              && get(row, "status") !== tagStatus.DISABLED && (
                <Grid item>
                  <Tooltip placement="top" title={t("tooltip.action.archive")}>
                    <IconButton
                      id="tag-tag-archive-btn"
                      classes={{ colorPrimary: classes.archiveColor }}
                      color="primary"
                      aria-label="tag-archive-btn"
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        setToArchivedId(get(row, "id"));
                        setOpenMsgConfirmArchive(true);
                      }}
                    >
                      <FolderIcon className={classes.rowActionButtons} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

            {get(row, "status") === tagStatus.DISABLED
              && !disabledFunctionality && (
                <Chip label={t("tag.management.archived")} variant="outlined" />
              )}
            {isPermitted(currentUser, "Delete tag") && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <CustomDeleteIcon
                    id="tag-admin-delete-btn"
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(get(row, "id"));
                      setOpenMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  />
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Edit tag") && (
              <Grid item>
                <Checkbox
                  id="tag-admin-select-checkbox"
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected?.map((item) => item?.id)?.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(row)
                      : list.splice(
                            list.findIndex((item) => item.id === parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("tag.management.disable.confirmMsg")
            : t("tag.management.enable.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("tag.management.disable.button.confirm")
            : t("tag.management.enable.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={t("tag.management.hide.confirmMsg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      <ConfirmMessage
        message={t("archive.confirm.msg")}
        openStart={openMsgConfirmArchive}
        onCancel={cancelConfirm}
        onConfirm={confirmArchive}
        buttonConfirm={t("tag.management.btn.confirm")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.archive}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
    </>
  );
}
