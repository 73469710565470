import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import get from "lodash/get";
import { isPermitted } from "../../../../components/HasPermission/index.js";
import ConfirmMessage from "../../../../components/ConfirmMessage/index.js";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../../components/TableComponents/CustomTableCell";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import useStyles from "components/TableComponents/style";

export default function AlertsTableRow({
  row,
  displayFields,
  deleteEntity,
  deleteEntityLabel,
  isDefaultAlertsPage = false,
  isCustomAlertsPage = false,
  fetchEntitiesReset,
  handleAlertDialogOpen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
  });

  const moduleName = isDefaultAlertsPage ? "default" : "custom";

  const resetLoaders = () => {
    setIsloading({ switch: false, deleteAction: false });
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(deleteEntity(toDeleteId)).then(() => {
      fetchEntitiesReset();
      resetLoaders();
      setOpenMsgConfirm(false);
      toast.success(t("alert.management.formControl.delete"))
    });
  };

  const handleEditAction = (event, row) => {
    event.stopPropagation();
    handleAlertDialogOpen(get(row, "id"), "edit");
  }

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const getFieldData = (valuePath) => get(row, valuePath);

  return (
    <>
      <CustomTableRow onClick={() => handleAlertDialogOpen(get(row, "id"), "view")}>
        {displayFields.map((field, i) => (
            !field.disableSmsFunctionality
            && (field?.customContent ? (
              <CustomTableCell
                        key={i}
                        text={field?.contentTypeString && (
                            field.customContent({
                              index: get(row, "id"),
                              value: getFieldData(field?.valuePath),
                              field: field.valuePath,
                            })
                        )}
                        width={field?.width}
                    >
                {!field?.contentTypeString && field.customContent({
                        field: field.valuePath,
                        value: getFieldData(field?.valuePath),
                        index: get(row, "id"),
                      })}
              </CustomTableCell>
                ) : (
                  <CustomTableCell
                        key={i}
                        text={getFieldData(field?.valuePath)}
                    />
                )
            )
        ))}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center" spacing={1}>
            {((isDefaultAlertsPage
                && isPermitted(currentUser, "Edit default alerts"))
            || (isCustomAlertsPage
                && isPermitted(currentUser, "Edit custom alerts"))) && (
                  <Grid item>
                    <CustomEditIcon
                        id={`${moduleName}-alert-edit-btn`}
                        aria-label="edit"
                        onClick={(e) => handleEditAction(e, row)}
                        className={classes.rowActionButtons}
                      />
                  </Grid>
            )}
            {((isDefaultAlertsPage
                && isPermitted(currentUser, "Delete default alert"))
            || (isCustomAlertsPage
                && isPermitted(currentUser, "Delete custom alert"))) && (
                  <Grid item>
                    <CustomDeleteIcon
                        id={`${moduleName}-alert-delete-btn`}
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation()
                          setToDeleteId(get(row, "id"));
                          setOpenMsgConfirm(true);
                        }}
                        className={classes.rowActionButtons}
                    />
                  </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          typeof deleteEntityLabel === "string"
            ? t(deleteEntityLabel)
            : deleteEntityLabel
        }
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
