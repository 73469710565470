import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyToClipboardButton({
  text,
  className,
  size,
  onCopy,
  tooltipTitle = "",
  customContent = null,
  wrapperComponent = null
}) {
  const [copied, setCopied] = React.useState(false);
  const WrapperComponent = wrapperComponent ?? IconButton;
  const handleCopy = () => {
    onCopy && onCopy(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <WrapperComponent
        className={className}
        onClick={(e) => {
            e.stopPropagation();
        }}
        size={size}
      >
        <Tooltip title={tooltipTitle} placement="top">
          {customContent ? customContent(
                    copied ? (
                      <CheckRoundedIcon fontSize="inherit" />
                    ) : (
                      <FileCopyOutlinedIcon fontSize="inherit" />
                    )
                ) : ((copied && (<CheckRoundedIcon fontSize="inherit" />)) || (!copied && (<FileCopyOutlinedIcon fontSize="inherit" />)))}
        </Tooltip>
      </WrapperComponent>
    </CopyToClipboard>
  )
}
