/**
 * @module All
 */

/**
 *
 * @type {string[]}
 */
export const SUPPORTED_PROTOCOLS = ["https:", "http:", "ftp:", "ftps:", "sftp:"]

/**
 * language local storage key
 * @type {string}
 */
export const languageLSKey = "lgn"

export default languageLSKey
