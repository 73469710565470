import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "none",
    padding: 0,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  infoTableContainer: {
    marginTop: 30,
    marginBottom: 15,
  },
  dialogTitle: {
    fontSize: "1.35rem",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  tableCellText: {
    fontWeight: "700",
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
    sectionContainer: {
        position: "relative",
        border: `1px solid ${theme.palette.primary.light}`,
        padding: theme.spacing(2, 3),
        marginTop: 30,
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: "10px"
    },
    sectionTitle: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        left: 16,
        top: -13,
        backgroundColor: theme.palette.background.default,
        padding: "0 10px",
    },
    sectionTitleText: {
        fontSize: "1rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginRight: 5
    },
}));
