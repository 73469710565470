import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
  },
  cardActions: {
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  rootGrid: {
    marginTop: theme.spacing(5),
  },
  trigger: {
    color: theme.palette.primary.main,
    fontSize: "1.4rem",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  help: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  notificationHelper: {
    fontSize: "0.875rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  NotificationIcons: {
    color: theme.palette.primary.main,
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flagLabel: {
    fontSize: "1rem",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
  flagLabelGrey: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "grey",
  },
  triggerIcon: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    color: theme.palette.primary.main,
  },
  triggerGridIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
  },
  select: {
    height: "30px",
  },
  valid: {
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
  },
  warningColor: {
    color: theme.palette.warning.main,
    "&.Mui-checked": {
      color: theme.palette.warning.main,
    },
  },
  errorColor: {
    color: theme.palette.error.main,
    "&.Mui-checked": {
      color: theme.palette.error.main,
    },
  },
  infoColor: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  personIcon: {
    color: theme.palette.primary.main,
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
  },
  title: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  dialogPaper: {
    width: "50%",
    height: "90%",
    borderRadius: "1%",
  },
  boxSection: {
    position: "relative",
    padding: "20px 35px 35px",
    border: `1px solid ${theme.palette.primary.light}`,
  },
  boxSectionTitle: {
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: -14,
    left: 25,
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    fontWeight: 700,
    padding: "0 10px"
  },
  fleetForm: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  resetButton: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "700",
    elevation: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    }
  },
  alertInfoContainer: {
    height: "90%",
    overflowX: "hidden",
  }
}));
