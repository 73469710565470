import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 10;

const initialState = {
  searchText: "",
  process: [],
  resource: [],
  status: [],
  trigger: [],
  tag: [],
  executionSelectedDurationValue: "ALL_TIME",
  executionFromDate: null,
  executionToDate: null,
  order: {
    id: "launchingDate",
    label: "Launching date (Descending)",
    order: "desc",
  },
  pageNo: 0,
  pageSize: defaultPageSize,
  tags: [],
  fleets: []
};

const filterExecutions = createSlice({
    initialState,
    name: "filterExecutions",
    reducers: {
      updateFilter: (state, action) => action.payload,
      initFilter: () => ({ ...initialState }),
    }
});

export const { updateFilter, initFilter } = filterExecutions.actions;
export default filterExecutions.reducer;
