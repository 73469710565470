import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { fetchTodayUnhandledExceptions } from "../../../redux/actions/services/index";
import Folded from "./Folded";
import { FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI } from "../../../redux/constants";

const AppBar = () => {
  const dispatch = useDispatch();
  const { data: exceptions } = useQuery({ type: FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI });

  useEffect(() => {
    if (!exceptions) dispatch(fetchTodayUnhandledExceptions());
  }, [dispatch]);

  return (
    <Folded
      nbrExeptions={exceptions?.todayItemExceptions}
    />
  );
}

export default AppBar;
