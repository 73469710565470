import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import CustomTriggerRow from "./CustomTriggerRow/index";
// eslint-disable-next-line import/no-cycle
import ScheduleForm from "./ScheduleForm/index";
import AlertSeverityInput from "../../../components/AlertSeverityInput";
import ScheduleInfo from "./ScheduleInfo";
import SubscribersInput from "pages/AlertsManagement/components/SubscribersInput";
import CustomButton from "../../../../../components/CustomButton";
import NoDataMenu from "../../../../../components/NoData/NoDataMenu";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";

export default function CustomAlertInfo({
  classes,
  register,
  disableFields,
  errors,
  clearErrors,
  showDefaultData,
  trigger,
  fields,
  append,
  remove,
  menuItems,
  idAlert,
  control,
  handleChangeEndDate,
  handleChangeStartDate,
  endDate,
  startDate,
  activeCheck,
  handleSchedulerSwitch,
  watch,
  open,
  handleClickOpen,
  handleCloseDialog,
  handleChangeFrequency,
  discardSchedulerChanges,
  frequency,
  mode,
  handleChangeTab,
  value,
  setValue,
  confirmSaveScheduler,
  processMenuItem,
  submittedScheduler,
  handleDeleteScheduler,
  resetCustomAlertScheduler,
  isLoading,
  useInterval,
  handleChangeUseInterval,
  fromSelectedDate,
  onFromDateChange,
  toSelectedDate,
  onToDateChange,
  disableSchedulerInfo,
  sameFleetUsers,
  selectedSubscribers,
  handleSubscribersChange,
  userFleet,
  selectedFleet,
  handleChangeFleet,
  isInstanceOwner,
  defaultFleetSelected,
  schedulerError,
  days,
  setDays,
  setError,
  selectedTimeZone,
  setSelectedTimeZone,
  fromTime,
  toTime,
  setToTime,
  setFromTime,
  resetAdvancedSettings,
  currentUserTimeZone
}) {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.general")}
          </Typography>
          <CustomTextField
            fullWidth
            id="alertName"
            label={t("alerts.management.name.label")}
            disabled={disableFields}
            InputLabelProps={{
              shrink: !!watch("alertName"),
            }}
            error={!!errors.alertName}
            {...register("alertName", {
                required: {
                value: true,
                message: t("alerts.management.required.fields"),
                },
                maxLength: {
                  value: 55,
                  message: t("alerts.management.alertName.maxLength.error"),
                },
            })}
          />
          <p className={classes.validation_error}>
            {errors.alertName && (
              <Typography color="error" variant="subtitle1" className={classes.optText}>
                {t(errors.alertName.message)}
              </Typography>
            )}
          </p>
          <CustomTextField
            fullWidth
            id="alertDescription"
            label={t("alerts.management.alertDescription.label")}
            disabled={disableFields}
            InputLabelProps={{
              shrink: !!watch("alertDescription"),
            }}
            {...register("alertDescription", {
                required: false,
                maxLength: {
                  value: 65535,
                  message: t("alerts.management.alertDescription.maxLength.error"),
                },
            })}
            error={!!errors.alertDescription}
          />
          <p className={classes.validation_error}>
            {errors.alertDescription && (
              <Typography color="primary" variant="subtitle1" className={classes.optText}>
                {t(errors.alertDescription.message)}
              </Typography>
            )}
          </p>
          <AlertSeverityInput
            classes={classes}
            control={control}
            errors={errors}
            disableFields={disableFields}
          />
          {isInstanceOwner && (
            <Box display="flex">
              <Grid item xs={4}>
                <Controller
                  {...register("fleet")}
                  name="fleet"
                  control={control}
                  defaultValue={defaultFleetSelected}
                  render={(field) => (
                    <CustomAutoComplete
                      options={userFleet ?? []}
                      label={t("fleets.fleet")}
                      optionLabel="companyName"
                      value={userFleet?.find((fleet) => fleet.id === selectedFleet) ?? null}
                      onChange={handleChangeFleet}
                      disabled={disableFields}
                      error={!!errors.fleet}
                      errorText={errors?.fleet?.message}
                      noOptionsNode={<NoDataMenu message={t("no.fleets.message")} />}
                      inputRef={field?.ref}
                    />
                  )}
                />
                <p className={classes.validation_error}>
                  {errors.fleet && (
                    <Typography color="error" variant="subtitle1" className={classes.optText}>
                      {t(errors.fleet.message)}
                    </Typography>
                  )}
                </p>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.triggers")}
          </Typography>
          <Grid container direction="column" item spacing={2}>
            <CustomTriggerRow
              showDefaultData={showDefaultData}
              errors={errors}
              register={register}
              clearErrors={clearErrors}
              classes={classes}
              fields={fields}
              append={append}
              remove={remove}
              disableFields={disableFields}
              menuItems={menuItems}
              control={control}
              setValue={setValue}
              watch={watch}
              processMenuItem={processMenuItem}
              trigger={trigger}
            />
          </Grid>
        </Box>
      </Grid>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.checking.frequence")}
          </Typography>
          <Grid container item spacing={2}>
            <Grid container item justify="center">
              {submittedScheduler?.frequency ? (
                <ScheduleInfo
                  classes={classes}
                  submittedScheduler={submittedScheduler}
                  handleClickOpen={handleClickOpen}
                  handleDeleteScheduler={handleDeleteScheduler}
                  disableFields={disableFields}
                  resetCustomAlertScheduler={resetCustomAlertScheduler}
                  idAlert={idAlert}
                  isLoading={isLoading}
                />
              ) : (
                !disableFields && (
                  <>
                    <CustomButton
                      view="primary"
                      onClick={handleClickOpen}
                      startIcon={<AddIcon />}
                    >
                      {t("Choose.the.checking.frequency")}
                    </CustomButton>
                    <Typography className={classes.schedule_error}>
                      {schedulerError}
                    </Typography>
                  </>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.subscribers")}
          </Typography>
          <SubscribersInput
            sameFleetUsers={sameFleetUsers}
            mode={mode}
            disableFields={disableFields}
            selectedSubscribers={selectedSubscribers}
            handleSubscribersChange={handleSubscribersChange}
            control={control}
            errors={errors}
            classes={classes}
          />
        </Box>
      </Grid>
      <Grid container item xs={12} md={12} lg={10}>
        <Card className={classes.paper} square>
          <Grid item container xs={12} direction="column" spacing={7}>

            {(open || showDefaultData) && (
              <ScheduleForm
                trigger={trigger}
                classes={classes}
                value={value}
                handleChangeTab={handleChangeTab}
                open={open}
                handleCloseDialog={handleCloseDialog}
                register={register}
                handleChangeEndDate={handleChangeEndDate}
                handleChangeStartDate={handleChangeStartDate}
                endDate={endDate}
                startDate={startDate}
                activeCheck={activeCheck}
                handleSchedulerSwitch={handleSchedulerSwitch}
                disableFields={disableFields}
                showDefaultData={showDefaultData}
                watch={watch}
                handleChangeFrequency={handleChangeFrequency}
                discardSchedulerChanges={discardSchedulerChanges}
                frequency={frequency}
                confirmSaveScheduler={confirmSaveScheduler}
                submittedScheduler={submittedScheduler}
                useInterval={useInterval}
                handleChangeUseInterval={handleChangeUseInterval}
                fromSelectedDate={fromSelectedDate}
                onFromDateChange={onFromDateChange}
                toSelectedDate={toSelectedDate}
                onToDateChange={onToDateChange}
                errors={errors}
                disableSchedulerInfo={disableSchedulerInfo}
                days={days}
                setDays={setDays}
                setError={setError}
                clearErrors={clearErrors}
                selectedTimeZone={selectedTimeZone}
                setSelectedTimeZone={setSelectedTimeZone}
                fromTime={fromTime}
                toTime={toTime}
                setFromTime={setFromTime}
                setToTime={setToTime}
                resetAdvancedSettings={resetAdvancedSettings}
                currentUserTimeZone={currentUserTimeZone}
              />
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
