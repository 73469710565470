import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestMonitoringMode } from "../dashboardUtils";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import OccupancyChart from "./OccupancyChart";
import ProductivityChart from "./ProductivityChart";
import useStyles from "../style";
import AllRobotsProductivity from "./AllRobotsProductivity";
import { fetchDashboardResourceProductivity } from "redux/actions/services/dashboard";

export default function ResourceProductivity({ xs, userFleet }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);
  const [resourceProductivity, setResourceProductivity] = React.useState();

  const fetchResourceProductivity = () => {
      if (userFleet && filterDashboard) {
          setIsLoading(true);
          dispatch(
              fetchDashboardResourceProductivity({
                  ...filterDashboard,
                  fleetIds: filterDashboard.fleets
              })
          ).then((res) => {
              setResourceProductivity(res?.data);
              setIsLoading(false);
          });
      }
  }

  useEffect(() => {
    fetchResourceProductivity()
  }, [filterDashboard, userFleet?.id]);

  useEffect(() => {
      handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchResourceProductivity)
  }, [refreshTime])

  return (
    <>
      <Grid item container xs={xs} className={classes.container}>
        <Typography className={classes.title}>
          {t("Resource Productivity")}
        </Typography>
        {isLoading || !resourceProductivity ? (
          <CircularLoader height={500} />
        ) : (
          <>
            <Grid item xs={12}>
              <ProductivityChart data={resourceProductivity?.productivity} userFleet={userFleet} setOpenTable={setOpenTable} />
            </Grid>
            <Grid item xs={12}>
              <OccupancyChart data={resourceProductivity?.occupancy} />
            </Grid>
          </>
        )}
      </Grid>
      {open && (
      <AllRobotsProductivity
                userFleet={userFleet}
                open={openTable}
                handleDialogClose={() => setOpenTable(false)}
              />
          )}

    </>
  );
}
