import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { connect } from "react-redux";
import { fetchOrchestrationsList } from "redux/actions/services";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import { EnhancedTableToolbar } from "components/DataTable/EnhancedTableToolbar";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import CustomPagination from "../CustomPagination";
import { SearchFilter } from "components/Filter";
import {
  composeScheduleDescription,
  quartzInitialDays,
  triggers,
} from "pages/IntelligentOrchestration/util";
import useStyles from "./style";

const IntelligentOrchestration = (props) => {
  const classes = useStyles();
  const { processId } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const { t } = useTranslation();

  const headerCells = [
    { label: t("name") },
    { label: t("Description") },
    { label: t("Trigger") },
    { label: t("Trigger details") },
    { label: t("Resource group") },
  ];
  const getFieldData = (row, valuePath) => get(row, valuePath);
  const generateText = (row, field) => (field.translate ? t(getFieldData(row, field?.valuePath)) : getFieldData(row, field?.valuePath));

  const renderCustomTriggerDetails = (row) => {
    const trigger = getFieldData(row, "trigger");
    if (trigger === triggers[0]) {
      return (
        <Grid container alignItems="center" direction="row" wrap="nowrap">
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle}>
              {`${t("Queue name")}: `}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              className={classes.cellTitleQueueName}
            >
              {getFieldData(row, "workQueue.name")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle}>
              {` ${t("using.slas")} :`}
            </Typography>
          </Grid>
          <Grid item>
            <Box width="fit-content">
              <Chip
                label={getFieldData(row, "orchestrationSlas")}
                className={classes.tagChip}
              />
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (trigger === triggers[1]) {
      const schedulingInfo = getFieldData(row, "schedulingInfo");
      const selectedDays = schedulingInfo?.allowedDays
        ? quartzInitialDays.map((day) => ({
            ...day,
            checked: schedulingInfo?.allowedDays.includes(day.id),
          }))
        : quartzInitialDays;
      const selectedDayId = quartzInitialDays.find((d) => d.checked)?.id;
      const startDay = new Date(schedulingInfo?.startDate).getDay();
      return (
        <Grid alignItems="center">
          <Typography variant="subtitle2" className={classes.cellTitle}>
            {composeScheduleDescription(
              schedulingInfo?.frequency,
              schedulingInfo?.unitType,
              selectedDays,
              schedulingInfo?.executionTime,
              t,
              schedulingInfo?.weekOrder,
              selectedDayId,
              startDay,
              schedulingInfo?.scheduleTypeDayOfMonth
            )}
          </Typography>
        </Grid>
      );
    }
    return "---";
  };

  const displayFields = [
    {
      id: "name",
      valuePath: "name",
    },
    {
      id: "description",
      valuePath: "description",
    },
    {
      id: "trigger",
      valuePath: "trigger",
      translate: true,
    },
    {
      id: "triggerDetails",
      customContent: (row) => renderCustomTriggerDetails(row),
    },
    {
      id: "resourceGroup",
      customContent: (row) => (
        <Box width="fit-content">
          <Chip
            label={getFieldData(row, "resourceGroupName")}
            className={classes.tagChip}
          />
        </Box>
      ),
    },
  ];

  const getOrchestrationsList = (page, size, searchCriteria) => {
    setIsLoaded(false);
    props
      .fetchOrchestrationsList({
        processesIds: [processId],
        pageNo: page,
        pageSize: size,
        searchCriteria,
      })
      .then((result) => {
        setData(result?.data?.content);
        setCount(result?.data?.totalElements);
      })
      .then(() => setIsLoaded(true));
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getOrchestrationsList(page, rowsPerPage, searchText);
    }
  }, [page, rowsPerPage, searchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOrchestrationsList(newPage, rowsPerPage, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getOrchestrationsList(0, event.target.value, searchText);
  };

  const handlePrevious = (page) => {
    setPage(page - 1);
    getOrchestrationsList(page - 1, rowsPerPage, searchText);
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getOrchestrationsList(page + 1, rowsPerPage, searchText);
  };

  const handleChangeSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value);
    getOrchestrationsList(page, rowsPerPage, value);
  };

  return (
    <div className={classes.root}>
      <Paper elevation="0" className={classes.paper}>
        <EnhancedTableToolbar
          filtred
          numSelected={0}
          filterWidth={6}
          filterInput={
            <Grid container item spacing={3} alignItems="center">
              <Grid item xs={5} className={classes.searchContainer}>
                <SearchFilter
                  callback={handleChangeSearchText}
                  value={searchText}
                />
              </Grid>
            </Grid>
          }
        />
        <TableContainer className={classes.linkTable}>
          {isLoaded ? (
            <Table
              stickyHeader
            >
              {count > 0 && (
                <EnhancedTableHead
                  classes={classes}
                  rowCount={count}
                  headCells={headerCells}
                />
              )}
              <TableBody>
                {count > 0 ? (
                  data.map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {displayFields.map((field) => (
                        <TableCell align="left" className={classes.tableCell}>
                          {field.customContent
                            ? field.customContent(row)
                            : generateText(row, field)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <DataNotFound
                    padding={50}
                    message={t("no.orchestration.message")}
                  />
                )}
              </TableBody>
            </Table>
          ) : (
            <CircularLoader />
          )}
        </TableContainer>

        {count > 0 && (
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            onChangePage={handleChangePage}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
};
const mapDispatchToProps = {
  fetchOrchestrationsList,
};
export default connect(null, mapDispatchToProps)(IntelligentOrchestration);
