export const itemsColumns = [
  {
    id: "requestDate",
    label: "Requested date (Descending)",
    order: "desc",
  },
  {
    id: "requestDate",
    label: "Requested date (Ascending)",
    order: "asc",
  },
  {
    id: "maxWaitTime",
    label: "MaxWaitTime (Descending)",
    order: "desc",
  },
  {
    id: "maxWaitTime",
    label: "MaxWaitTime (Ascending)",
    order: "asc",
  },
  {
    id: "estimatedDuration",
    label: "EstimatedDuration (Descending)",
    order: "desc",
  },
  {
    id: "estimatedDuration",
    label: "EstimatedDuration (Ascending)",
    order: "asc",
  },
  {
    id: "archivedAt",
    label: "archivedAt (Descending)",
    order: "desc",
  },
  {
    id: "archivedAt",
    label: "archivedAt (Ascending)",
    order: "asc",
  },
];

export default itemsColumns;
