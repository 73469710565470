import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 10;
const defaultModule = "itemsExceptions";

const initialState = {
  searchText: "",
  process: [],
  exceptionType: [],
  exception: [],
  fleet: [],
  tags: [],
  exceptionsSelectedDurationValue: "ALL_TIME",
  exceptionsFromDate: null,
  exceptionsToDate: null,
  processExecutions: [],
  showHidden: false,
  pageNo: 0,
  pageSize: defaultPageSize,
};

const filterException = {
  itemsExceptions: {
    ...initialState,
    workflowStatus: ["READY", "TAKEN"],
    usersInCharge: [],
    order: {
      id: "exceptionTime",
      label: "Exception Time (Descending)",
      order: "desc",
    },
  },
  processesExceptions: {
    ...initialState,
    workflowStatus: ["READY", "TAKEN"],
    usersInCharge: [],
    order: {
      id: "executionEndTime",
      label: "End date (Descending)",
      order: "desc",
    },
  },
  currModule: defaultModule,
};

const filterListException = createSlice({
  initialState: filterException,
  name: "filterException",
  reducers: {
    initFilter: (state) => ({
      ...state,
      [state.currModule]: {
        ...filterException[state.currModule],
      },
    }),
    initFilterItems: (state) => ({
      ...state,
      items: {
        ...filterException.items,
      },
    }),
    initFilterItemsField: (state, field) => ({
      ...state,
      items: {
        ...state.items,
        [field.payload]: filterException.items[field.payload],
      },
    }),
    updateModule: (state, action) => ({
      ...state,
      currModule: action.payload.module,
    }),
    updateFilter: (state, action) => ({
      ...state,
      [state.currModule]: {
        ...state[state.currModule],
        ...action.payload[state.currModule],
      },
    }),
    updatePageNumber: (state, action) => ({
      ...state,
      items: {
        ...state[state.currModule],
        pageNo: action.payload.pageNo,
      },
    }),
  },
});

export const {
  initFilter,
  updateFilter,
  updateModule,
  initFilterItems,
  initFilterItemsField,
  updatePageNumber
} = filterListException.actions;
export default filterListException.reducer;
