import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    chartContainer: {
        width: "100%",
        height: "300px",

        [theme.breakpoints.up("sm")]: {
            height: "500px",
        },

        [theme.breakpoints.up("md")]: {
            height: "450px",
        },
    },
    tooltip: {
        background: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
        padding: "5px 9px",
        display: "flex",
        alignItems: "center",
    },
    tooltipIndicator: {
        display: "block",
        width: "12px",
        height: "12px",
        background: "rgb(33, 33, 33)",
        margin: "0px 3px 0px 0px",
    },

}));
