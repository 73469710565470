import React, { useCallback, useEffect, useState } from "react";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import { useTranslation } from "react-i18next";
import { InfoOutlined as Help, CalendarToday, DeleteOutlineOutlined } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import ExportButton from "../../../../components/ExportButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { format } from "date-fns-tz";
import { useSelector } from "react-redux";
import { get } from "react-hook-form";
import {
  arrayIsNullOrEmpty,
  formatDatePickerByLanguage,
  getLocale,
  handleDisplayedLabel,
} from "../../../../util/index.js";
import clsx from "clsx";
import ClearFilter from "../../../../components/ClearFilter/index.js";
import CreatePresetDialog from "./CreatePresetDialog";
import DeletePresetDialog from "./DeletePresetDialog";
import CreateKpiDialog from "./CreateKpiDialog";
import DeleteKpiDialog from "./DeleteKpiDialog/DeleteKpiDialog.js";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../../../components/CustomButton";
import PageHeader from "components/PageHeader";
import ReportingMenu from "pages/Reporting/components/Filter/ReportingMenu";
import { ReactComponent as DeleteKpiIcon } from "../../../../assets/common/deletekpi.svg";
import { ReactComponent as EditKpiIcon } from "../../../../assets/common/editkpi.svg";
import useStyles from "../../style.js";

const dateFormat = formatDatePickerByLanguage();

function Filter({
  processes,
  reportTypes,
  kpi,
  selectedReportType,
  setSelectedReportType,
  selectedProcess,
  setSelectedProcess,
  selectedKpi,
  setSelectedKpi,
  handleSaveReport,
  handleCopyToClipboard,
  handleExportPdf,
  handleExportCsv,
  handleClear,
  fromSelectedDate,
  setFromDate,
  toSelectedDate,
  setToDate,
  toInputValue,
  setAnchorEl,
  anchorEl,
  exportLoading,
  validReportTypes,
  filterPresets,
  handleDeletePreset,
  handleCreatePreset,
  customKpis,
  handleCreateOrUpdateCustomKpi,
  handleDeleteCustomKpi,
  selectedTags,
  setSelectedTags,
  exceptionsTypes,
  selectedExceptionsTypes,
  setSelectedExceptionsTypes,
  tags
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorPreset, setAnchorPreset] = useState(null);
  const [anchorLoadedPresets, setAnchorLoadedPresets] = useState(null);
  const [showCreatePreset, setShowCreatePreset] = useState(false);
  const [deletePresetId, setDeletePresetId] = useState(-1);
  const [showCreateKpi, setShowCreateKpi] = useState(false);
  const [editedCustomKpi, setEditedCustomKpi] = useState(null);
  const [customKpiDialogMode, setCustomKpiDialogMode] = useState(null);
  const [deleteKpiId, setDeleteKpiId] = useState(-1);
  const [showExceptionTypeFilter, setShowExceptionTypeFilter] = useState(false);
  const fromFormattedDate = (d) => {
    let res = null;
    try {
      res = format(d, dateFormat);
    } catch (e) {
      res = d;
    }
    return res;
  };

  const ANALYSIS_DATA = useSelector(({ requests }) => get(requests, "queries.FETCH_ANALYSIS_DATA.data"));
  const ANALYSIS_TABLE = useSelector(({ requests }) => get(requests, "queries.FETCH_ANALYSIS_TABLE_DATA.data"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeProcess = (newVal) => {
      setSelectedProcess(newVal)
  }

  const handleChangeKpi = (newKpi) => {
    setSelectedKpi(newKpi);
  };

  const handleChangeReportType = (event) => {
    setSelectedReportType(event.target.value);
  };

  const dateFormatter = (str) => str;

  const onFromDateChange = (date) => {
    setFromDate(date);
  };

  const onToDateChange = (date) => {
    setToDate(date);
  };

  const handleSavePreset = () => {
    setShowCreatePreset(true);
    setAnchorPreset(null);
  };

  const handleLoadPresets = (event) => {
    setAnchorLoadedPresets(event.currentTarget);
  };

  const onPresetSubmit = (presetName) => {
    if (presetName) {
      handleCreatePreset(presetName);
      setShowCreatePreset(false);
    }
  };

  const onDeletePresetSubmit = (presetId) => {
    if (presetId) {
      handleDeletePreset(presetId);
      setDeletePresetId(-1);
    }
  }

  const onCreateKpi = (e) => {
    e.stopPropagation();
    setEditedCustomKpi(null);
    setCustomKpiDialogMode("create");
    setShowCreateKpi(true);
  };

  const handleDeleteKpiClick = (e, kpi) => {
    e.stopPropagation();
    setDeleteKpiId(kpi.id)
  }
  const handleChangeExceptionType = (newVal) => {
    setSelectedExceptionsTypes(newVal)
  }
  const onDeleteCustomKpi = (customKpiId) => {
    if (customKpiId) {
      handleDeleteCustomKpi(customKpiId)
      setDeleteKpiId(-1);
    }
  };

  const onEditCustomKpi = (e, kpi) => {
    e.stopPropagation();
    setEditedCustomKpi(kpi);
    setCustomKpiDialogMode("edit");
    setShowCreateKpi(true);
  };

  const onPresetClick = (presetId) => {
    const selectedPreset = filterPresets.filter((preset) => preset.id === presetId);
    if (selectedPreset) {
      const loadedProcesses = processes.filter((process) => selectedPreset[0]?.selectedProcesses.split(",").includes(process.id.toString()));
      const loadedTags = tags.filter((tag) => selectedPreset[0]?.selectedTags?.split(",").includes(tag.id.toString()));
      const loadedKpi = kpi.filter((kpi) => selectedPreset[0]?.kpi.split(",").includes(kpi.id.toString()));
      const loadedType = reportTypes.filter((type) => selectedPreset[0]?.type.includes(type.id.toString()))[0];
      const loadedFromDate = new Date(selectedPreset[0]?.fromTime);
      const loadedToDate = new Date(selectedPreset[0]?.toTime);
      loadedProcesses && setSelectedProcess(loadedProcesses);
      loadedTags && setSelectedTags(loadedTags);
      loadedKpi && setSelectedKpi(loadedKpi);
      loadedType && setSelectedReportType(loadedType);
      loadedFromDate && setFromDate(loadedFromDate);
      loadedToDate && setToDate(loadedToDate);
      setAnchorPreset(null);
      setAnchorLoadedPresets(null);
    }
  };

  const isEmptySelectedProcesses = arrayIsNullOrEmpty(selectedProcess);
  const isEmptySelectedTags = arrayIsNullOrEmpty(selectedTags);
  const isEmptySelectedKpi = arrayIsNullOrEmpty(selectedKpi);
  const isEmptySelectedExceptionType = arrayIsNullOrEmpty(selectedExceptionsTypes);

  useEffect(() => {
    if (isEmptySelectedKpi && isEmptySelectedExceptionType) {
      setSelectedReportType(null)
    }
  }, [isEmptySelectedKpi, isEmptySelectedExceptionType])
  useEffect(() => {
  if (isEmptySelectedProcesses && isEmptySelectedTags) {
     setSelectedKpi([])
     }
  }, [isEmptySelectedProcesses, isEmptySelectedTags])
  const handleChangeTagsFilter = (newVal) => {
    setSelectedTags(newVal)
  }

  useEffect(() => {
    const exceptionTypeOptionSelected = selectedKpi.some((option) => option.code === "exceptionType");
    setShowExceptionTypeFilter(exceptionTypeOptionSelected);

    if (!exceptionTypeOptionSelected) {
      setSelectedExceptionsTypes([]);
    }
  }, [selectedTags, selectedReportType, selectedKpi]);

  const customPaperComponent = useCallback((options) => {
    const { containerProps, children } = options;
    return (
      <Paper className={classes.paper} {...containerProps}>
        {children}
        <CustomButton
            onMouseDown={(e) => onCreateKpi(e)}
            fullWidth
        >
          <Typography variant="body2" color="primary">
            <strong>{t("reporting.createKpi")}</strong>
          </Typography>
        </CustomButton>
      </Paper>
    )
  }, []);

  const exportOptions = [
    { label: "reporting.save", onClick: handleSaveReport },
    { label: "reporting.exportPDF", onClick: handleExportPdf },
    ...(selectedReportType?.code === "table"
        ? [{ label: "reporting.exportCSV", onClick: () => handleExportCsv(false) }]
        : []),
    ...(selectedReportType?.code === "table"
        ? [{ label: "reporting.exportCSV.all", onClick: () => handleExportCsv(true) }]
        : []),
    { label: "reporting.copyClipboard", onClick: handleCopyToClipboard },
  ];

  const presetsOptions = [
    { label: "analytics.preset.load", onClick: handleLoadPresets, startIcon: <ArrowLeftIcon className={classes.arrowIcon} /> },
    ...((!((!ANALYSIS_DATA && !ANALYSIS_TABLE) || !selectedReportType || isEmptySelectedKpi))
        ? [{ label: "analytics.preset.dialog.field.submit", onClick: handleSavePreset }]
        : [])
  ];

  const loadedPresetsOptions = filterPresets.length
      ? filterPresets?.map((item) => ({
        label: item?.presetName,
        onClick: () => onPresetClick(item.id),
        endIcon: <DeleteOutlineOutlined onClick={() => setDeletePresetId(item.id)} className={classes.deleteButton} />
      }))
      : [{ label: "analytics.preset.noPresets" }];

  return (
    <Grid container>
      <Grid container item justify="space-between" alignItems="center">
        <PageHeader title="Rapports" />
        <Grid container item xs={2} spacing={1} justify="flex-end">
          <Grid item>
            <CustomButton
                view="secondary"
                onClick={(event) => setAnchorPreset(event.currentTarget)}
                iconBtn
            >
              <Tooltip title={t("analytics.preset.button.label")}>
                <RotateLeftOutlinedIcon fontSize="small" />
              </Tooltip>
            </CustomButton>
          </Grid>
          <Grid item>
            <ExportButton
                  loading={exportLoading}
                  onClick={handleClick}
                  disabled={(!ANALYSIS_DATA && !ANALYSIS_TABLE) || !selectedReportType || isEmptySelectedKpi}
              />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
      >
        <Grid item xs={3}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {isEmptySelectedTags
                && <Tooltip title={t("help.tags")} placement="top"><Help className={classes.help} /></Tooltip>}
            <CustomAutoComplete
                multiple
                options={tags ?? []}
                optionLabel="name"
                value={selectedTags}
                noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                onChange={handleChangeTagsFilter}
                label={t("Tags")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {isEmptySelectedProcesses
                && <Tooltip title={t("help.process")} placement="top"><Help className={classes.help} /></Tooltip>}
            <CustomAutoComplete
                multiple
                options={processes ?? []}
                optionLabel="processName"
                value={selectedProcess}
                noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
                onChange={handleChangeProcess}
                label={t("processes")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            fullWidth
            className={clsx(
              classes.autoComplete,
              classes.autoCompleteProcesses
            )}
          >
            {(!isEmptySelectedProcesses || !isEmptySelectedTags) && (
              <Tooltip title={t("help.kpi")} placement="top">
                <Help className={classes.help} />
              </Tooltip>
            )}
            <CustomAutoComplete
              disabled={isEmptySelectedProcesses && isEmptySelectedTags}
              label={t("reporting.kpi")}
              id="tags-standard-kpi"
              multiple
              value={selectedKpi}
              onChange={handleChangeKpi}
              options={kpi?.concat(customKpis)?.sort((a, b) => b?.kpiType?.localeCompare(a?.kpiType)) ?? []}
              groupBy={(option) => t(option?.kpiType)}
              optionLabel={(option) => option.label || option.name}
              showBadge
              customRenderOption={(option) => {
                if (customKpis?.includes(option)) {
                return (
                  <Grid container xs={9} justify="space-between" alignItems="center">
                    <Grid item xs={7} zeroMinWidth wrap="nowrap" className={classes.customRenderOptionText}>
                      <Typography className={classes.chipText}>
                        {handleDisplayedLabel(option?.name)}
                      </Typography>
                    </Grid>
                    <Box component={Grid} container item xs={5} spacing={1} justify="flex-end" pl={1.5}>
                      <Grid item xs={4}>
                        <IconButton
                            size="small"
                            onClick={(e) => onEditCustomKpi(e, option)}
                        >
                          <EditKpiIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                            size="small"
                            onClick={(e) => handleDeleteKpiClick(e, option)}
                        >
                          <DeleteKpiIcon />
                        </IconButton>
                      </Grid>
                    </Box>
                  </Grid>
                );
                }
                return (
                  <Grid container xs={9} justify="space-between" alignItems="center">
                    <Grid item xs={11} zeroMinWidth wrap="nowrap" className={classes.customRenderOptionText}>
                      <Typography className={classes.chipText}>
                        {handleDisplayedLabel(t(option.label))}
                      </Typography>
                    </Grid>
                  </Grid>
                );
                }}
              PaperComponent={customPaperComponent}
              concernedOptions={kpi}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <ClearFilter
              clearFilter={handleClear}
          />
        </Grid>
        {showExceptionTypeFilter
        && (
        <Grid item xs={3}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {(!isEmptySelectedProcesses || !isEmptySelectedTags) && (
              <Tooltip title={t("help.exception.type")} placement="top">
                <Help className={classes.help} />
              </Tooltip>
            )}
            <CustomAutoComplete
                disabled={isEmptySelectedProcesses && isEmptySelectedTags}
                multiple
                options={exceptionsTypes ?? []}
                value={selectedExceptionsTypes}
                noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                onChange={handleChangeExceptionType}
                label={t("exception.type")}
            />
          </FormControl>
        </Grid>)}
        <Grid
          container
          item
          xs={3}
          className={classes.autoComplete}
          alignItems="flex-end"
        >
          {!isEmptySelectedKpi && (
          <Tooltip title={t("help.reportType")} placement="top">
            <Help className={classes.helpAutoComplete} />
          </Tooltip>
            ) }

          <CustomSelectField
              options={validReportTypes || []}
              optionLabel="label"
              value={selectedReportType || ""}
              onChange={handleChangeReportType}
              label={t("report.type")}
              customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
              disabled={isEmptySelectedKpi && isEmptySelectedExceptionType}
              isCustom
          />
        </Grid>
        <Grid
            item
            xs={3}
            className={classes.autoComplete}
            alignItems="flex-end"
        >
          <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocale()}
          >
            <FormControl margin="none" fullWidth className={classes.datesContainer}>
              <DatePicker
                  label={t("reporting.from")}
                  ampm={false}
                  autoOk
                  value={fromSelectedDate}
                  format={formatDatePickerByLanguage(false)}
                  onChange={onFromDateChange}
                  disableFuture
                  cancelLabel={t("user.button.cancel")}
                  invalidDateMessage={t(
                      "fleet.management.formControl.invalidDate",
                  )}
                  maxDateMessage={t("reporting.date.errorPast", {
                    dateValue: fromFormattedDate(toSelectedDate),
                  })}
                  className={classes.input}
                  InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                  renderInput={(params) => (
                    <TextField className={classes.input} {...params} />
                  )}
                  views={["year", "month", "date"]}
                  hideTabs
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
            item
            xs={3}
            className={classes.autoComplete}
            alignItems="flex-end"
        >
          <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocale()}
          >
            <FormControl margin="none" fullWidth className={classes.datesContainer}>
              <DatePicker
                  label={t("reporting.to")}
                  ampm={false}
                  autoOk
                  value={toSelectedDate}
                  format={formatDatePickerByLanguage(false)}
                  inputValue={toInputValue}
                  onChange={onToDateChange}
                  rifmFormatter={dateFormatter}
                  minDate={new Date(fromSelectedDate)}
                  cancelLabel={t("user.button.cancel")}
                  invalidDateMessage={t(
                      "fleet.management.formControl.invalidDate",
                  )}
                  minDateMessage={t("reporting.date.errorFuture", {
                    dateValue: fromFormattedDate(fromSelectedDate),
                  })}
                  className={classes.input}
                  InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                  renderInput={(params) => (
                    <TextField className={classes.input} {...params} />
                  )}
                  views={["year", "month", "date"]}
                  hideTabs
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <CreatePresetDialog open={showCreatePreset} onClose={() => setShowCreatePreset(false)} onSubmit={onPresetSubmit} />
      <DeletePresetDialog presetId={deletePresetId} onClose={() => setDeletePresetId(-1)} onSubmit={onDeletePresetSubmit} />
      <CreateKpiDialog
        open={showCreateKpi}
        key={customKpiDialogMode === "edit" ? editedCustomKpi?.id : -1}
        onClose={() => setShowCreateKpi(false)}
        handleCreateOrUpdateCustomKpi={handleCreateOrUpdateCustomKpi}
        editedCustomKpi={editedCustomKpi}
        customKpiDialogMode={customKpiDialogMode} />
      <DeleteKpiDialog onClose={() => setDeleteKpiId(-1)} onSubmit={onDeleteCustomKpi} kpiId={deleteKpiId} />
      <ReportingMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={exportOptions}
      />
      <ReportingMenu
        anchorEl={anchorPreset}
        setAnchorEl={setAnchorPreset}
        options={presetsOptions}
      />
      <ReportingMenu
        anchorEl={anchorLoadedPresets}
        setAnchorEl={setAnchorLoadedPresets}
        options={loadedPresetsOptions}
        anchorOriginVertical="top"
        anchorOriginHorizontal="left"
        menuPaperClass={classes.reverseMarginSpacing}
      />
    </Grid>
  );
}

export default Filter;
