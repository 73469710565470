import {
    CREATE_USER,
    DELETE_LIST_USERS,
    DELETE_USER_BY_ID,
    FETCH_FLEET_USERS,
    FETCH_CURRENT_USER,
    FETCH_USER,
    FETCH_USERS,
    FETCH_USERS_CIVILITIES,
    FETCH_USERS_JOBS,
    FETCH_USERS_PERMISSIONS_TEMPLATES,
    UPDATE_PASSWORD,
    UPDATE_USER,
    UPDATE_USER_STATUS,
    FETCH_PERMISSIONS_ROLES,
    ADD_PERMISSIONS_ROLE,
    UPDATE_PERMISSIONS_ROLE,
    DELETE_PERMISSIONS_ROLE,
    FETCH_PERMISSIONS_ROLE_BY_ID,
    FETCH_FLEETS_FOR_PERMISSIONS_ROLES,
} from "redux/constants";
import {
    URL_CURRENT_USER, URL_FLEET, URL_ROLES, URL_UPDATE_PASSWORD, URL_USER, URL_USER_PERMISSIONS
} from "redux/constants/endpoints";
import { currentUser, template } from "util/configs/backendEntities";

export const fetchUsers = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, fleets, sortBy,
    } = filter;
    return {
        type: `${FETCH_USERS}`,
        request: {
            url: encodeURI(`${URL_USER}/list${searchCriteria ? `?userName=${searchCriteria}` : ""}`),
            params: {
                page: pageNo,
                size: pageSize,
                sort: sortBy,
                status: status ? status.join() : null,
                fleetIds: fleets ? fleets.join() : null,
            },
        },
    };
};
export const updateProfile = (user, file, onSuccess, onError) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user", JSON.stringify(user));
    return {
        type: "UPDATE_PROFILE",
        request: {
            url: `${URL_USER}/profile`,
            method: "put",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const updateUserPassword = (
    token,
    userCredentials,
    onSuccess,
    onError,
) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_UPDATE_PASSWORD}?token=${token}`,
        method: "post",
        data: userCredentials,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: () => {
            if (onError) onError();
        },
    },
});
export const updateCurrentUserPassword = (userCredentials, onSuccess, onError) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_USER}/${currentUser}/password`,
        method: "patch",
        data: userCredentials,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const fetchCurrentUser = () => ({
    type: FETCH_CURRENT_USER,
    request: { url: `${URL_CURRENT_USER}` },
});

export const enableOrDisableUser = (id, userStatus, onSuccess, onError) => ({
    type: UPDATE_USER_STATUS,
    request: {
        url: `${URL_USER}/status/${id}`,
        method: "put",
        data: { status: userStatus },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const deleteUsers = (idUsers, onSuccess, onError) => ({
    type: DELETE_LIST_USERS,
    request: {
        url: `${URL_USER}/`,
        method: "delete",
        params: { idUsers: idUsers.join(",") },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
export const deleteUser = (id, onSuccess, onError) => ({
    type: DELETE_USER_BY_ID,
    request: {
        url: `${URL_USER}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});
export const createUser = (fleet, onSuccess, onError) => {
    const data = new FormData();
    data.append("user", JSON.stringify(fleet));
    return {
        type: CREATE_USER,
        request: {
            url: `${URL_USER}/`,
            method: "post",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const fetchUser = (id) => ({
    type: `${FETCH_USER}`,
    request: {
        url: `${URL_USER}/${id}`,
    },
});
export const traceLogin = () => ({
    type: "TRACE_LOGIN",
     request: {
        url: `${process.env.REACT_APP_GATEWAY}/trace-login`,
    }
})
export const logout = () => ({
    type: "LOGOUT",
    request: {
        url: `${process.env.REACT_APP_GATEWAY}/logout`,
    },
    meta: {
        onSuccess: (response) => {
            const currectUrl = window.location.origin;
            window.location.href = `${process.env.REACT_APP_GATEWAY
            }/login/sso?redirect_url=${encodeURI(currectUrl)}`;
            return response;
        },
    },
});
export const validateToken = (token) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_UPDATE_PASSWORD}/?token=${token}`,
        method: "get",
    },
});
export const updateUserPermissions = (id, permissions) => ({
    type: "UPDATE_USER_PERMISSION",
    request: {
        url: `${URL_USER_PERMISSIONS}/${id}`,
        method: "put",
        data: permissions,
    },
});

export const createUserPermissionsTemplate = (id, name, permissions) => ({
    type: "CREATE_USER_PERMISSION_TEMPLATE",
    request: {
        url: `${URL_USER_PERMISSIONS}/${id}/${template}`,
        method: "post",
        data: { name, permissions },
    },
});
export const fetchJobs = () => ({
    type: FETCH_USERS_JOBS,
    request: {
        url: `${URL_USER}/jobs`,
    },
});

export const fetchUserPermissionsTemplates = (fleetId) => ({
    type: FETCH_USERS_PERMISSIONS_TEMPLATES,
    request: {
        url: `${URL_USER_PERMISSIONS}/${template}`,
        params: {
            fleetId,
        }
    },
});
export const deleteUserPermissionsTemplate = (id) => ({
    type: "DELETE_USER_PERMISSIONS_TEMPLATE",
    request: {
        url: `${URL_USER_PERMISSIONS}/${template}/${id}`,
        method: "delete",
    },
});
export const modifyUser = (userId, fleet, onSuccess, onError) => {
    const data = new FormData();
    data.append("user", JSON.stringify(fleet));
    return {
        type: UPDATE_USER,
        request: {
            url: `${URL_USER}/${userId}`,
            method: "put",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const fetchCivilities = () => ({
    type: FETCH_USERS_CIVILITIES,
    request: {
        url: `${URL_USER}/civility`,
    },
});
export const fetchFleetUsers = (fleetId, processId = null) => ({
    type: FETCH_FLEET_USERS,
    request: {
        url: `${URL_USER}/fleet-users/${fleetId}`,
        method: "get",
        params: {
            processId
        }
    },
});
export const fetchPermissionsRoles = (params, onSuccess, onError, t) => ({
    type: FETCH_PERMISSIONS_ROLES,
    request: {
        url: URL_ROLES,
        params,
    },
    meta: {
        onSuccess: (response) => {
            const newContent = response.data.content.map((item) => {
                if (["COE", "MMGT", "SME"].includes(item.name)) {
                    item.isFixed = true;
                    item.name += ` (${t("user.permissions.default")})`
                }
                return item;
            })
            const customResponse = { ...response, data: { ...response?.data, content: newContent } }
            if (onSuccess) onSuccess(customResponse);
            return customResponse;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});
export const addPermissionsRole = (data, onSuccess, onError) => ({
    type: ADD_PERMISSIONS_ROLE,
    request: {
        url: URL_ROLES,
        method: "post",
        data,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});

export const updatePermissionsRole = (data, onSuccess, onError) => ({
    type: UPDATE_PERMISSIONS_ROLE,
    request: {
        url: `${URL_ROLES}/${data.id}`,
        method: "put",
        data,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});

export const deletePermissionsRole = (id, onSuccess, onError) => ({
    type: DELETE_PERMISSIONS_ROLE,
    request: {
        url: `${URL_ROLES}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});

export const fetchPermissionsRole = (id, onSuccess, onError) => ({
    type: FETCH_PERMISSIONS_ROLE_BY_ID,
    request: {
        url: `${URL_ROLES}/${id}`
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});
export const fetchFleetsForPermissionsRoles = (onSuccess, onError) => ({
    type: FETCH_FLEETS_FOR_PERMISSIONS_ROLES,
    request: {
        url: `${URL_FLEET}/without-role`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
});
