import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchQuery: "",
    objects: [],
    users: [],
    actions: [],
    modules: [],
    period: {
        from: null,
        to: null,
        duration: null,
    },
    sortBy: [
        { name: "timestamp", dir: "desc" },
    ],
    pageNo: 0,
    pageSize: 10,
};

const auditFilters = createSlice({
    name: "auditFilters",
    initialState,
    reducers: {
        updateFilter: (state, action) => (state = action.payload), // eslint-disable-line
        initialFilter: (state) => (state = initialState), // eslint-disable-line
    },
});

export const { updateFilter, initialFilter } = auditFilters.actions;

export default auditFilters.reducer;
