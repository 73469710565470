import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  actionButtons: {
    marginLeft: "auto",
  },
  manageRolesButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "0.3125rem",
    fontWeight: 700
  }
}));
