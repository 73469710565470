import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageHeaderTitle: {
    fontWeight: 700,
  },
  pageFilteredTitle: {
    fontWeight: 700,
    display: "inline",
    color: theme.palette.secondary.main,
    fontSize: 17,
  }
}));
