import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartStyles from "chartjs-plugin-style";
import { Line } from "react-chartjs-2";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { LINE_CHART_TOOLTIP_OPTIONS } from "../../dashboardUtils";
import theme from "../../../../style/orchestratorTheme";
import DataNotFound from "../../../../components/DataNotFound";
import useStyles from "../../style";

const getData = (occupancyData) => ({
  labels: occupancyData?.map(({ entityName }) => entityName),
  datasets: [
    {
      type: "bar",
      label: "Occupancy",
      yAxisID: "occupancy",
      data: occupancyData?.map(({ values }) => values[0]?.toFixed(2)),
      barThickness: 20,
      backgroundColor: [
        theme.palette.info.main,
        theme.palette.secondary.main,
        theme.palette.primary.main,
        theme.custom.color.chartColor1,
        theme.custom.color.chartColor4,
      ],
    },
  ],
});

const lineOptions = {
  legend: {
    display: false,
    labels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
        },
        offset: true,
      },
    ],
    yAxes: [
      {
        id: "occupancy",
        display: true,
        position: "left",
        gridLines: {
          lineWidth: 0.3,
        },
        ticks: {
          stepSize: 10,
          beginAtZero: true,
          fontColor: theme.palette.primary.main,
        },
      },
    ],
  },
  layout: {
    padding: {
      top: 25,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`,
    },
    ...LINE_CHART_TOOLTIP_OPTIONS
  },
  plugins: {
    datalabels: {
      formatter: (value) => `${+value}%`,
      color: theme.palette.primary.main,
      opacity: 1,
      align: "top",
      anchor: "end",
    },
  },
};

export default function OccupancyChart({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const occupancyData = getData(data);
  return (
    <>
      <Typography component="span" className={classes.subtitle}>
        {t("Occupancy")}
      </Typography>
      {data?.length === 0 || data?.every(({ values }) => values?.[0] === 0) ? (
        <DataNotFound
          backgroundColor="transparent"
          boxShadow="none"
          iconHeight="20%"
          iconWidth="20%"
          message="occupancy.waiting.more"
          padding={50}
        />
      ) : (
        <Line
          height={300}
          data={occupancyData}
          plugins={[ChartDataLabels, ChartStyles]}
          options={lineOptions}
        />
      )}
    </>
  );
}
