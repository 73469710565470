import i18n from "../../../../../../i18n";
import range from "lodash/range";
import format from "date-fns/format";

// eslint-disable-next-line import/prefer-default-export
export const makeDay = (startDate, t, dayNameView = false) => {
    const { language } = i18n;
    const startDay = new Date(startDate).getDate()
    if (dayNameView && startDate) {
        const formattedDate = format(new Date(startDate), "EEEE")
        return `${t("on")} ${formattedDate}`
    }
    if (startDate) {
        if (startDay === 1 || (startDay === 21 && language === "en")) {
            return t("processe.schedules.onDay.first", { startDay });
        }
        if (startDay === 2 || (startDay === 22 && language === "en")) {
            return t("processe.schedules.onDay.second", { startDay });
        }
        if (startDay === 3 || (startDay === 23 && language === "en")) {
            return t("processe.schedules.onDay.third", { startDay });
        }

        return t("processe.schedules.onDay", { startDay });
    }

    return "";
};

export const getSelectOptionsByPeriod = (Period) => {
  switch (Period) {
    case 0:
      return hours;
    case 1:
      return days;
    case 2:
      return weeks;
    case 3:
      return months;
    default:
      return [hours];
  }
};

export const getLabelByPeriod = (Period, startDate, t) => {
  switch (Period) {
    case 0:
      return ` ${t("Hour(s)")}`;
    case 1:
      return ` ${t("Day(s)")}`;
    case 2:
      return ` ${t("Week(s)")}${makeDay(startDate, t, true)}`;
    case 3:
      return ` ${t("Month(s)")}${makeDay(startDate, t)}`;
    default:
      return ` ${t("Hour(s)")}`;
  }
};

export const frequencies = [
  { value: 0, label: "Hourly" },
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
  { value: 3, label: "Monthly" },
]

const hours = range(0.5, 24, 0.5);
const days = range(1, 31);
const weeks = range(1, 52);
const months = range(1, 12);
