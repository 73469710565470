import React from "react"
import { fade, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import isArray from "lodash/isArray";
import { FormProvider } from "react-hook-form";
import CustomButton from "../CustomButton";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";

const StyledDialog = withStyles(() => ({
  root: {},
  paper: {
    borderRadius: ({ borderRadius }) => borderRadius ?? 0,
    padding: ({ dividers }) => (dividers ? 0 : "16px 24px 24px"),
    maxWidth: ({ width }) => width || "",
    width: ({ customWidth }) => customWidth || "",
    maxHeight: ({ maxHeight }) => maxHeight || "",
  }
}))(Dialog);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    padding: ({ dividers }) => (dividers ? 5 : 0),
    paddingBottom: ({ dividers }) => (dividers ? 5 : 16),
    paddingLeft: ({ dividers }) => (dividers ? 30 : null),
  }
}))(DialogTitle);

const StyledDialogContent = withStyles(() => ({
  root: {
    padding: ({ dividers, padding }) => (dividers && !padding ? "0px 30px 30px" : (padding ?? 0)),
    overflowY: ({ dividers }) => (dividers ? "hidden" : "auto"),
    overflowX: "hidden",
    "&:first-child ": {
      paddingTop: 0
    }
  }
}))(DialogContent);

const StyledDialogContentText = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    fontSize: "1rem",
    fontWeight: 600,
    paddingBottom: 16,
    margin: 0
  }
}))(DialogContentText);

const StyledDialogHelper = withStyles((theme) => ({
  root: {
    backgroundColor: fade(theme.palette.info.main, 0.08),
    color: theme.palette.info.main,
    fontSize: "0.85rem",
    fontWeight: 600,
    padding: theme.spacing(2),
    borderRadius: "0.4rem",
  }
}))(Box);

const StyledDialogActions = withStyles(() => ({
  root: {
    padding: 0,
    paddingTop: 16
  }
}))(DialogActions);
const FormPopper = (props) => (
  <FormProvider {...props?.formProps}>
    <Paper component="form" onSubmit={props?.formProps?.onSubmit} {...props}>
      {props.children}
    </Paper>
  </FormProvider>
)
export default function CustomDialog({
  title,
  contentText,
  actionElements,
  children,
  onClose,
  dialogHelper,
  formProps,
  padding,
  customWidth,
  withCloseButton = false,
  withDivider = false,
  closeOnEscape = true,
  ...rest
}) {
  const { t } = useTranslation()
  const enableQuickClose = closeOnEscape && !actionElements
  const handleEsc = () => enableQuickClose && onClose()
  return (
    <StyledDialog
        aria-describedby="dialog-description"
        dividers={withDivider}
        PaperComponent={formProps ? FormPopper : Paper}
        disableEscapeKeyDown={enableQuickClose}
        onBackdropClick={() => enableQuickClose && onClose()}
        onEscapeKeyDown={handleEsc}
        customWidth={customWidth}
        PaperProps={{ formProps }}
        {...rest}
    >
      {title && (
        <StyledDialogTitle dividers={withDivider} disableTypography>
          {title}
          {withCloseButton && <CustomCloseButton aria-label="close" onClick={onClose} size="small" />}
        </StyledDialogTitle>
      )}
      {contentText && (
        <StyledDialogContentText id="dialog-description" dividers={withDivider}>
          {contentText}
        </StyledDialogContentText>
      )}
      {(children || dialogHelper) && (
        <StyledDialogContent dividers={withDivider} padding={padding}>
          {children}
          {dialogHelper && (
            <StyledDialogHelper>
              {dialogHelper}
            </StyledDialogHelper>
          )}
        </StyledDialogContent>
      )}
      {actionElements && (
        <StyledDialogActions>
          {isArray(actionElements) ? actionElements?.map(({
            label, view, onClick, className, variant, isLoading, hidden, ...rest
          }) => (
            hidden ? null : (
              <CustomButton
                view={view}
                variant={variant}
                onClick={onClick}
                className={className}
                {...rest}
              >
                {isLoading && <CircularProgress size={14} />}
                {t(label)}
              </CustomButton>
            )
          )) : (
            actionElements
          )}
        </StyledDialogActions>
      )}
    </StyledDialog>
  )
}
