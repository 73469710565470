import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import useStyles from "./style"
import isNull from "lodash/isNull";

export default function TimeSpanField({
  error,
  name,
  value,
  setValue,
  InputProps,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [timespan, setTimespan] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });

  const handleChange = (period, value) => {
    const newValue = parseInt(value, 10);
    if (!isNaN(newValue)) {
      setTimespan((prevState) => ({
        ...prevState,
        [period]: newValue,
      }));
    }
  };

  const formatInputOnBlur = () => {
    let noFieldIsFull = true;
    Object.values(timespan)
      .forEach((v) => {
        if (!isNull(v)) {
          noFieldIsFull = false;
        }
      });
    if (!noFieldIsFull) {
      Object.keys(timespan)
        .filter((p) => isNull(timespan[p]))
        .forEach((p) => setTimespan((prevState) => ({ ...prevState, [p]: 0 })));
    }
  }

  useEffect(() => {
    if (!isEmpty(value)) {
      const [days, time] = value.split(".")
      const [hours, minutes, seconds] = time.split(":");
      setTimespan({
        days,
        hours,
        minutes,
        seconds
      });
    }
  }, []);

  useEffect(() => {
    if (isEmpty(value)) {
      setTimespan({
        days: null,
        hours: null,
        minutes: null,
        seconds: null
      });
    }
  }, [value]);

  useEffect(() => {
    if (Object.values(timespan).findIndex((v) => isNull(v)) === -1) {
      setValue(getTimespanValue());
    }
  }, [timespan]);

  const getTimespanValue = () => `${timespan.days}.${timespan.hours}:${timespan.minutes}:${timespan.seconds}`;

  return (
    <>
      <Typography color="primary" variant="h6" className={classes.title}>
        {t(name.charAt(0).toUpperCase() + name.slice(1))}
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(timespan).map((period, index) => {
          const minVal = 0;
          let maxVal = Number.POSITIVE_INFINITY;
          if (period === "seconds" || period === "minutes") {
            maxVal = 59;
          } else if (period === "hours") {
            maxVal = 23;
          }
          return (
            <Grid key={index} item xs={3}>
              <CustomTextField
                value={timespan[period]}
                onChange={(e) => handleChange(period, e.target.value)}
                onBlur={formatInputOnBlur}
                label={t(period.charAt(0).toUpperCase() + period.slice(1))}
                InputProps={{
                  ...InputProps,
                  inputProps: {
                    min: minVal,
                    max: maxVal,
                    pattern: "[0-9]*",
                  },
                }}
                InputLabelProps={{
                  shrink: !isNull(timespan[period]),
                }}
                type="number"
                fullWidth
              />
            </Grid>
          )
        })}

        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Grid>
    </>
  )
}
