 const supportedLanguages = [
    {
        flag: "us",
        key: "en",
        text: "English",
    },
    {
        flag: "fr",
        key: "fr",
        text: "French",
    },
    {
        flag: "es",
        key: "es",
        text: "Spanish",
    },
    {
        flag: "pl",
        key: "pl",
        text: "Polish",
    },
    {
        flag: "de",
        key: "de",
        text: "Germany",
    },
    {
        flag: "ar",
        key: "ar",
        text: "Arabic",
    },
].filter(({ key }) => process.env.REACT_APP_SUPPORTED_LANGUAGE?.split(",")?.includes(key));
export default supportedLanguages;
