import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    popupIcon: {
        color: `${theme.palette.info.main} !important`,
    },
    popupIconDefault: {
        color: `${theme.custom.color.chartColor1} !important`,
    },
    popupIconDisabled: {
        color: `${theme.custom.color.chartColor4} !important`,
    },
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: 15
    },
    inputLabelShrinked: {
        color: fade(theme.palette.primary.main, 0.6),
        fontSize: 15
    }
}));
