import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const CloseAlertButton = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.editButtonContainer}>
      <Tooltip placement="bottom" title={t("tooltip.action.close")}>
        <IconButton
          className={classes.editButton}
          aria-label="close"
          onClick={onClick}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CloseAlertButton;
