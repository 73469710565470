import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { CalendarToday } from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  isAfter,
  isBefore,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import { Duration } from "util/constants";
import { getClientTimezone, getLocale, localToZonedTime } from "util";
import CustomSelectField from "components/FormFields/CustomSelectField";
import useStyles from "pages/Services/components/DateFilter/style";

const DatePeriodField = ({
                      period,
                      handlePeriodChange,
                      dateFormat,
                      formControlClassName,
                      isCustom = false,
                      xs = 3,
                      clearErrors,
                      setClearErrors
                    }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [invalidEndDate, setInvalidEndDate] = useState(false);

  const handleLocalPeriodChange = (p) => {
    const clientTz = getClientTimezone();
    if (!p.duration) {
      handlePeriodChange(p);
    } else {
      handlePeriodChange({
        start: localToZonedTime(p.start, clientTz),
        end: localToZonedTime(p.end, clientTz),
        duration: p.duration
      });
    }
  }

  useEffect(() => {
    if (clearErrors) {
      setInvalidStartDate(false);
      setInvalidEndDate(false);
      setClearErrors(false)
    }
  }, [clearErrors]);
  const handlePeriodSelect = ({ target: { value } }) => {
    switch (value) {
      case Duration.DAY:
        handleLocalPeriodChange({
          start: subDays(new Date(), 1),
          end: new Date(),
          duration: Duration.DAY,
        });
        break;
      case Duration.WEEK:
        handleLocalPeriodChange({
          start: subWeeks(new Date(), 1),
          end: new Date(),
          duration: Duration.WEEK,
        });
        break;
      case Duration.MONTH:
        handleLocalPeriodChange({
          start: subMonths(new Date(), 1),
          end: new Date(),
          duration: Duration.MONTH,
        });
        break;
      case Duration.YEAR:
        handleLocalPeriodChange({
          start: subYears(new Date(), 1),
          end: new Date(),
          duration: Duration.YEAR,
        });
        break;
      case Duration.CUSTOM:
        handleLocalPeriodChange({
          start: subMonths(new Date(), 1),
          end: new Date(),
          duration: Duration.CUSTOM,
        });
        break;
      default:
        handleLocalPeriodChange({
          start: null,
          end: null,
          duration: null,
        });
    }
  };

  const onStartDateChange = (newStartDate) => {
    if (isAfter(newStartDate, period.end)) {
      setInvalidStartDate(true);
    } else {
      setInvalidStartDate(false);
      setInvalidEndDate(false);
      handlePeriodChange({ ...period, start: newStartDate });
    }
  };

  const onEndDateChange = (newEndDate) => {
    if (isBefore(newEndDate, period.start)) {
      setInvalidEndDate(true);
    } else {
      setInvalidEndDate(false);
      setInvalidStartDate(false);
      handlePeriodChange({ ...period, end: newEndDate });
    }
  };

  return (
    <>
      <Grid
        item
        xs={xs}
        className={classes.customDate}
      >
        <CustomSelectField
          options={Duration.list}
          optionLabel="label"
          optionValue="value"
          value={period.duration}
          onChange={handlePeriodSelect}
          variant="standard"
          label={t("Time period")}
          formControlClassName={formControlClassName}
          isCustom={isCustom}
        />
      </Grid>
      {period.duration === Duration.CUSTOM && (
        <Grid container direction="row" item xs={xs * 2}>
          <Grid item xs={6} className={classes.dateInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <FormControl className={classes.customDateInput} fullWidth>
                <DateTimePicker
                  label={t("reporting.from")}
                  InputProps={{ endAdornment: <CalendarToday className={isCustom ? classes.popupIcon : ""} fontSize="small" /> }}
                  className={isCustom ? classes.input : ""}
                  value={period.start}
                  onChange={onStartDateChange}
                  format={dateFormat}
                  fullWidth
                  showTodayButton
                  todayLabel={t("Today")}
                  cancelLabel={t("user.button.cancel")}
                  ampm={false}
                  DialogProps={{
                    className: classes.dateTimePicker,
                  }}
                  maxDate={new Date()}
                />
                {invalidStartDate && <FormHelperText error>{t("start date cannot be newer than the end date")}</FormHelperText>}
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} className={classes.dateInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <FormControl className={classes.customDateInput} fullWidth>
                <DateTimePicker
                  label={t("reporting.to")}
                  InputProps={{ endAdornment: <CalendarToday className={isCustom ? classes.popupIcon : ""} fontSize="small" /> }}
                  className={isCustom ? classes.input : ""}
                  value={period.end}
                  onChange={onEndDateChange}
                  showTodayButton
                  format={dateFormat}
                  fullWidth
                  cancelLabel={t("user.button.cancel")}
                  todayLabel={t("Today")}
                  ampm={false}
                  classes={{
                    icon: classes.popupIcon
                  }}
                  DialogProps={{
                    className: classes.dateTimePicker,
                  }}
                  maxDate={new Date()}
                />
                {invalidEndDate && <FormHelperText error>{t("end date cannot be earlier than the start date")}</FormHelperText>}
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DatePeriodField;
