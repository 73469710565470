import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DefaultTriggerRow from "./DefaultTriggerRow/index";
import AlertSeverityInput from "../../../components/AlertSeverityInput";
import SubscribersInput from "pages/AlertsManagement/components/SubscribersInput";
import NoDataMenu from "../../../../../components/NoData/NoDataMenu";
import Box from "@material-ui/core/Box";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import { Controller } from "react-hook-form";

export default function DefaultAlertInfo({
  disableFields,
  register,
  trigger,
  errors,
  classes,
  menuItems,
  control,
  watch,
  mode,
  processMenuItem,
  sameFleetUsers,
  selectedSubscribers,
  handleSubscribersChange,
  userFleet,
  selectedFleet,
  handleChangeFleet,
  isInstanceOwner,
  defaultFleetSelected
}) {
  const { t } = useTranslation();
  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.general")}
          </Typography>
          <CustomTextField
            fullWidth
            id="alertName"
            label={t("alerts.management.name.label")}
            disabled={disableFields}
            InputLabelProps={{
              shrink: !!watch("alertName"),
            }}
            error={!!errors.alertName}
            {...register("alertName", {
                required: {
                value: true,
                message: t("alerts.management.required.fields"),
                },
                maxLength: {
                  value: 55,
                  message: t("alerts.management.alertName.maxLength.error"),
                },
            })}
          />
          <p className={classes.validation_error}>
            {errors.alertName && (
              <Typography color="error" variant="subtitle1" className={classes.optText}>
                {t(errors.alertName.message)}
              </Typography>
            )}
          </p>
          <CustomTextField
            fullWidth
            id="alertDescription"
            label={t("alerts.management.alertDescription.label")}
            disabled={disableFields}
            InputLabelProps={{
              shrink: !!watch("alertDescription"),
            }}
            {...register("alertDescription", {
                required: false,
                maxLength: {
                  value: 65535,
                  message: t("alerts.management.alertDescription.maxLength.error"),
                },
            })}
            error={!!errors.alertDescription}
          />
          <p className={classes.validation_error}>
            {errors.alertDescription && (
              <Typography color="primary" variant="subtitle1" className={classes.optText}>
                {t(errors.alertDescription.message)}
              </Typography>
            )}
          </p>
          <AlertSeverityInput
            classes={classes}
            control={control}
            errors={errors}
            disableFields={disableFields}
          />
          {isInstanceOwner && (
            <Box display="flex">
              <Grid item xs={4}>
                <Controller
                  {...register("fleet")}
                  name="fleet"
                  control={control}
                  defaultValue={defaultFleetSelected}
                  render={(field) => (
                    <CustomAutoComplete
                      options={userFleet ?? []}
                      label={t("fleets.fleet")}
                      optionLabel="companyName"
                      value={userFleet?.find((fleet) => fleet.id === selectedFleet) ?? null}
                      onChange={handleChangeFleet}
                      disabled={disableFields}
                      error={!!errors.fleet}
                      helperText={errors.fleet && errors?.fleet?.message}
                      noOptionsNode={<NoDataMenu message={t("no.fleets.message")} />}
                      inputRef={field?.ref}
                    />
                  )}
                />
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.trigger")}
          </Typography>
          <Grid container item spacing={2}>
            <DefaultTriggerRow
              classes={classes}
              register={register}
              trigger={trigger}
              control={control}
              errors={errors}
              disableFields={disableFields}
              menuItems={menuItems}
              processMenuItem={processMenuItem}
              selectedFleet={selectedFleet}
            />
          </Grid>
        </Box>
      </Grid>
      <Grid item alignContent="center" alignItems="center">
        <Box className={classes.boxSection}>
          <Typography className={classes.boxSectionTitle}>
            {t("alerts.management.subscribers")}
          </Typography>
          <SubscribersInput
            sameFleetUsers={sameFleetUsers}
            mode={mode}
            disableFields={disableFields}
            selectedSubscribers={selectedSubscribers}
            handleSubscribersChange={handleSubscribersChange}
            control={control}
            errors={errors}
            classes={classes}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
