import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ConfirmMessage from "components/ConfirmMessage";
import CustomSelectField from "components/FormFields/CustomSelectField";
import CircularProgress from "components/Loaders/CircularLoader"
import { fetchExecutionTimeEstimation } from "redux/actions/services";

const periodOptions = [
  { value: 7, label: "Last week" },
  { value: 30, label: "fleet.management.be.period.1" },
  { value: 90, label: "fleet.management.be.period.2" },
  { value: 180, label: "fleet.management.be.period.3" },
]

function EstimationComputing({
  setValues,
  processId,
  openEstimatedTimeComputingPopUp,
  setOpenEstimatedTimeComputingPopUp,
  classes,
  selectedSlaIndex,
}) {
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [estimation, setEstimation] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const setFormValues = () => {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in estimation) {
      setValues(`orchestrationSlas.${selectedSlaIndex}.averageRunningTime.${key}`, estimation[key]);
      setOpenEstimatedTimeComputingPopUp(false);
    }
    setOpenEstimatedTimeComputingPopUp(false)
  };
  const getEstimation = (period) => {
    setLoading(true);
    dispatch(fetchExecutionTimeEstimation(processId, period)).then((res) => {
      if (res.status === 200) {
        const durationInSeconds = res.data;
        const duration = {
          days: Math.floor(durationInSeconds / (3600 * 24)),
          hours: Math.floor((durationInSeconds % (3600 * 24)) / 3600),
          minutes: Math.floor((durationInSeconds % 3600) / 60),
          seconds: durationInSeconds % 60,
          milliseconds: (durationInSeconds - Math.floor(durationInSeconds)) * 1000
        };
        setLoading(false);
        setEstimation(duration);
      }
      },);
  };
  const reject = () => {
    setSelectedPeriod(null);
    setEstimation({});
    setOpenEstimatedTimeComputingPopUp(false);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value)
    setEstimation({});
    getEstimation(e.target.value)
  }

  const hideEstimation = estimation.days === 0
      && estimation.hours === 0
      && estimation.minutes === 0
      && estimation.seconds === 0

  const isEstimationValid = Object.keys(estimation).length !== 0

  return (
    <ConfirmMessage
      title={t("Use Historical data")}
      message={t("Choose the period")}
      openStart={openEstimatedTimeComputingPopUp}
      onClose={() => setOpenEstimatedTimeComputingPopUp(false)}
      onCancel={reject}
      onConfirm={setFormValues}
      buttonConfirm={t("Accept")}
      buttonCancel={t("user.button.cancel")}
      isLoading={loading}
      disableConfirm={!selectedPeriod || hideEstimation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item xs={6}>
          <CustomSelectField
              label={t("Choose period")}
              options={periodOptions}
              optionValue="value"
              optionLabel="label"
              value={selectedPeriod}
              InputLabelShrinks={{
                shrink: selectedPeriod,
              }}
              onChange={(e) => handlePeriodChange(e)}
              isCustom
          />
        </Grid>
        {loading && (
        <Grid item>
          <CircularProgress />
        </Grid>
        )}
        <Grid item>
          {isEstimationValid
              && (hideEstimation ? (
                <Typography className={classes.executionTextSla}>
                  {t("no execution in this period")}
                </Typography>
              ) : (
                <Typography className={classes.executionTextSla}>
                  {t("Estimated Execution")}
                  {["days", "hours", "minutes", "seconds"].map(
                        (unit) => estimation[unit] !== 0 && (
                        <Typography key={unit} variant="span" className={classes.executionValueSla}>
                          {`${estimation[unit].toString()} ${t(
                                      unit.charAt(0).toUpperCase() + unit.slice(1),
                                  )} `}
                        </Typography>
                            ),
                    )}
                </Typography>
              ))}
        </Grid>
      </Grid>
    </ConfirmMessage>
  );
}
export default EstimationComputing;
