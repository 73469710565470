import React from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { formatImagePath } from "util";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "./style";

export default function ProcessIconName({
  imgSrc,
  processName,
  badge = null,
  disabled = false,
}) {
  const classes = useStyles();
  return (
    <Box container className={classes.processContainer} pl={0.5}>
      <Box className={classes.image}>
        {imgSrc ? (
          <img
            src={formatImagePath(imgSrc)}
            alt="Service"
            className={clsx(classes.image, disabled && classes.disabledAvatar)}
          />
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={badge}
          >
            <ProcessDefaultIcon className={classes.image} />
          </Badge>
        )}
      </Box>
      <Box>
        <Typography variant="span" align="left">
          <Box
            className={clsx(
              classes.processDisplayName,
              disabled && classes.disabledProcessDisplayName,
            )}
          >
            {processName || "---"}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}
