import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cardHeader: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    height: 24,
  },
  cardRow: {
    marginBottom: theme.spacing(1),
  },
  cardFooterKey: {
    // color: theme.palette.primary.main,
  },
  cardFooterValue: {
    // color: theme.palette.primary.main,
  },
  cardShadow: {
    boxShadow: theme.custom.shadow,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  redZoneColor: {
    color: "rgb(232, 88, 88)",
  },
  yellowZoneColor: {
    color: "rgb(184 187 28)",
  },
  greenZoneColor: {
    color: "rgb(37, 202, 118)",
  },
  resultCard: {
    height: "100%",
  },
  displayNone: {
    display: "none"
  }
}));
