import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddRounded from "@material-ui/icons/AddRounded";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@redux-requests/react";
import {
  FETCH_PROCESSES_BY_FLEET,
  FETCH_ROBOTS,
} from "../../../../../../redux/constants";
import InformationIcon from "components/InformationIcon";
import CustomAutoComplete from "../../../../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../../../../components/NoData/NoDataMenu";
import useStyles from "../style";

export default function TaskSession({ task, i, index }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialProcesses = useQuery({ type: FETCH_PROCESSES_BY_FLEET })
    ?.data?.list;
  const initialRobots = useQuery({ type: FETCH_ROBOTS })?.data?.content;

  const [availableRobots, setAvailableRobots] = useState([]);
  const [availableProcesses, setAvailableProcesses] = useState([]);

  const {
    watch,
    formState: { errors },
    register,
    setValue,
    unregister,
  } = useFormContext();
  const onAddSession = () => {
    const fieldPath = `tasks.${index}.taskSessions`;
    setValue(fieldPath, [...watch(fieldPath), { process: null, resource: null }]);
  };

  const onDeleteSession = () => {
    const fieldPath = `tasks.${index}.taskSessions`;
    const newSessions = watch(fieldPath)?.filter((row, index) => index !== i)
    unregister(fieldPath);
    setValue(
        fieldPath,
        newSessions
    );
  };
  const handleProcessChange = (processId, assignedToQueue = false) => {
    const processesRobotsIds = initialProcesses?.find(({ id }) => id === processId)?.resources?.map(({ id }) => id)
    setAvailableRobots(initialRobots?.filter(({ id }) => processesRobotsIds?.includes(id)));
    if (i === 0 && assignedToQueue) setValue(`tasks.${index}.taskSessions`, [watch(`tasks.${index}.taskSessions.0`)]);
    setValue(`tasks.${index}.taskSessions.${i}.resource`, null);
  };

  const disableRobots = !watch(`tasks.${index}.taskSessions.${i}.process`);

  const disableSession = !watch("isProcessSchedule") && !watch("tasks.0.taskSessions.0.process");

  useEffect(() => {
    if (initialRobots && availableRobots?.length === 0) setAvailableRobots(initialRobots);
    if (initialProcesses && availableProcesses?.length === 0) setAvailableProcesses(initialProcesses);
  }, [initialRobots, initialProcesses]);

  useEffect(() => {
    if (watch("orchestratorId")) {
      setAvailableProcesses(initialProcesses?.filter((process) => {
        const isIncluded = `${process?.orchestrator?.id}` === `${watch("orchestratorId")}`;
        if (watch("isProcessSchedule")) return isIncluded
        if (index === 0 && i === 0) return true
        return isIncluded
      }));
    }
  }, [watch("orchestratorId"), initialRobots, initialProcesses]);

  useEffect(() => {
    if (!watch(`tasks.${index}.taskSessions.0.process.assignedToQueue`) && i !== 0) setAvailableProcesses(initialProcesses?.filter(({ assignedToQueue }) => !assignedToQueue));
  }, [watch(`tasks.${index}.taskSessions.0.process.assignedToQueue`), initialProcesses]);

  useEffect(() => {
    if (watch("tasks.0.taskSessions.0.process") && (index !== 0 || i !== 0)) setAvailableProcesses(initialProcesses?.filter(({ queueId }) => queueId === watch("queueId")))
  }, [watch("queueId")]);

  return (
    <>
      <Grid item xs={4}>
        <FormControl
            margin="normal"
            fullWidth
            className={classes.formControls}
        >
          <InformationIcon
                titleContent={`${t("The process to be executed in this task session")} ${disableSession && !(index === 0 && i === 0) ? ` - ${t("task.required.first.session.process")}` : ""}`}
                originalContent={(
                  <CustomAutoComplete
                        options={availableProcesses || []}
                        optionLabel="processDescription.processDisplayName"
                        noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
                        disabled={disableSession && !(index === 0 && i === 0)}
                        label={t("process")}
                        {...register(`tasks.${index}.taskSessions.${i}.process`, {
                          required: t("Your input is required"),
                        })}
                        value={watch(`tasks.${index}.taskSessions.${i}.process`)}
                        onChange={(newVal) => {
                          setValue(`tasks.${index}.taskSessions.${i}.process`, {
                            id: newVal?.id,
                            originalId: newVal?.originalId,
                            assignedToQueue: newVal?.assignedToQueue,
                            queueId: newVal?.queueId,
                            orchestratorId: newVal?.orchestrator?.id
                          });
                          handleProcessChange(newVal?.id, newVal?.assignedToQueue);
                        }}
                        error={errors?.tasks?.[index]?.taskSessions?.[i]?.process}
                        helperText={
                          errors?.tasks?.[index]?.taskSessions?.[i]?.process?.message
                        }
                        translateLabel={false}
                        disableCloseOnSelect={false}
                        fullWidth
                    />
                )}
                placement="right"
                alignItems="flex-end"
            />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          margin="normal"
          fullWidth
          className={classes.formControls}
        >
          <InformationIcon
                titleContent={`${t("The resource (robot on prod) on which the process will be executed")} ${(disableRobots || disableSession) ? ` - ${t(disableSession ? "task.required.process" : "task.required.first.session.process")}` : ""}`}
                originalContent={(
                  <CustomAutoComplete
                        options={availableRobots || []}
                        disabled={disableRobots || disableSession}
                        optionLabel="resourceDisplayName"
                        noOptionsNode={<NoDataMenu message={t("no.resource.message")} />}
                        label={t("resource")}
                        {...register(`tasks.${index}.taskSessions.${i}.resource`, {
                          required: t("Your input is required"),
                        })}
                        value={watch(`tasks.${index}.taskSessions.${i}.resource`)}
                        onChange={(newVal) => {
                          setValue(`tasks.${index}.taskSessions.${i}.resource`, {
                            id: newVal?.id,
                            resourceName: newVal?.resourceName,
                          });
                        }}
                        error={errors?.tasks?.[index]?.taskSessions?.[i]?.resource}
                        helperText={
                          errors?.tasks?.[index]?.taskSessions?.[i]?.resource?.message
                        }
                        translateLabel={false}
                        disableCloseOnSelect={false}
                        fullWidth
                    />
                )}
                placement="right"
                alignItems="flex-end"
            />
        </FormControl>
      </Grid>
      <Box component={Grid} pt="30px" item xs={4}>
        {!task?.taskSessions?.[0]?.process?.assignedToQueue && (
        <Tooltip title={t("task.add.session")}>
          <IconButton size="small" onClick={onAddSession}>
            <AddRounded className={classes.addSessionIcon} />
          </IconButton>
        </Tooltip>
        )}
        {task.taskSessions.length > 1 && (
          <Tooltip title={t("task.delete.session")}>
            <IconButton size="small" onClick={() => onDeleteSession()}>
              <DeleteRounded className={classes.deleteSessionIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  );
}
