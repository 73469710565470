import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import CustomSwitch from "../../pages/Services/components/CustomSwitch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ChevronRight as NextIcon,
  ChevronLeft as BackIcon,
  FunctionsOutlined as CalculateIcon,
  Send as SubmitIcon,
  SaveOutlined as SaveAsDraftIcon,
  CalendarToday,
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@date-io/date-fns";
import { CriteriaTypes } from "util/constants";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import { format } from "date-fns";

import InformationIcon from "../InformationIcon";
import CircularLoader from "../Loaders/CircularLoader";
import Result from "../Result";
import { resetProcessesState } from "../../redux/slices/processesSlice";
import {
  formatDatePickerByLanguage,
  formtNumberByLanguage,
  formatNumberInputOnBlur,
  formatNumberInputOnFocus,
  handleInputChanges,
  truncateString,
  getLocale,
 calculateTotalTimeSpent, saveData
} from "util";
import ConfirmMessage from "../ConfirmMessage";
import { toast } from "react-toastify";

import { useQuery } from "@redux-requests/react";
import {
  LOAD_QUALIFICATIONS, LOAD_STEPS, PROCESS_SUBMIT, PROCESS_UPDATED
} from "redux/constants";
import { calculateFactors, submitProcessStepper, updateProcessStepper } from "redux/actions/services/opportunities";
import { Autocomplete } from "@material-ui/lab";
import CustomButton from "../CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  calculateBtn: {
    backgroundColor: theme.palette.success.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(0.8),
  },
  textFieldDisabled: {
    color: "black",
  },
}));

const buildRouteName = (str) => str.replace(/\s/g, "").toLowerCase();

const generateRequestData = (steps, data, status) => {
  const request = [];

  steps.forEach((step, stepIndex) => {
    const requestJson = {
      id: step.id,
      name: step.name,
      qualifications: [],
    };
    step.criterias.forEach((criteria) => {
      const criteriaClone = { ...criteria };

      criteriaClone.step = {
        id: step.id,
        name: step.name,
      };

      const qualification = {
        assessment: null,
        value: null,
        comment: "",
        criteria: criteriaClone,
      };

      if (criteria.assessments.length > 0) {
        if (criteria.type === CriteriaTypes.Boolean) {
          qualification.assessment = criteria.assessments[data[stepIndex][buildRouteName(criteria.name)] ? 0 : 1];
        } else {
          const assessmentId = data[stepIndex][buildRouteName(criteria.name)];
          const assessment = criteria.assessments.find(
            (assessment) => assessment.id === assessmentId,
          );

          qualification.assessment = assessment !== undefined ? assessment : null;
        }
      }
      // input [number, string or date]
      else if (criteria.assessments.length === 0) {
        qualification.value = data[stepIndex][buildRouteName(criteria.name)];
      }

      // comment
      qualification.comment = data[stepIndex][`${buildRouteName(criteria.name)}-comment`];

      requestJson.qualifications.push(qualification);
    });
    request.push(requestJson);
  });
   calculateTotalTimeSpent(data, steps)
   request[3].qualifications[3].value = data[3][buildRouteName("totaltimespentinhoursperyear")]
  const requestToSend = { qualificationsRequestList: request, status };

  return requestToSend;
};
const compare = (a, b) => {
  if (a.rating > 0 || b.rating > 0) return a.rating - b.rating;
  return a.name.localeCompare(b.name);
};
function getDateForm(dateIn) {
  const dt = new Date(dateIn);
  const pattern = "yyyy-MM-dd'T'HH:mm";
  return format(dt, pattern);
}
const ProcessStep = (props) => {
    const {
      handleNextStep, handleBackStep, activeStep, handleFieldValueChange, setProcessName
    } = props
  const { id } = useParams();
    const { data: dataQualifications, loading: isQualificationLoaded } = useQuery({ type: LOAD_QUALIFICATIONS })
  const qualifications = id ? (dataQualifications || []) : []
    const { data: dataRes = { steps: [], data: [], mode: [] }, loading: isLoading } = useQuery({ type: LOAD_STEPS })
    const { steps = [], data = [], mode = [] } = dataRes || {};
    const { loading: isSubmittingUpdate } = useQuery({ type: PROCESS_UPDATED })
    const { loading: isSubmittingSubmit } = useQuery({ type: PROCESS_SUBMIT })
    const [result, setResult] = useState(null)
    const [isCalculating, seIsCalculating] = useState(true)
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const [msgConfirm, setMsgConfirm] = useState("");
  const [buttonConfirm, setButtonConfirm] = useState("");
  const [status, setStatus] = useState("");
  const [loadedArray, setLoadArray] = useState([
    { id: 0, loaded: false },
    { id: 1, loaded: false },
    { id: 2, loaded: false },
    { id: 3, loaded: false },
    { id: 4, loaded: false },
  ]);
  const processReferenceLabel = "Process reference";
  const processNameLabel = "Process name";
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
    trigger,
      unregister
  } = useForm({
    defaultValues: {
      ...data[activeStep],
    },
  });
  const dateFns = new DateFnsAdapter();
  const today = dateFns.date();

  useEffect(() => {
    reset(data[activeStep]);
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 4) {
      calculateTotalTimeSpent(data, steps)
    }
    if (data && data[3]) {
      setValue(
        "totaltimespentinhoursperyear",
        data[3][buildRouteName("totaltimespentinhoursperyear")],
      );
    }
  }, [data]);

  const handleNext = (stepData) => {
    if (activeStep < steps.length - 1) {
      if (stepData.date) {
        stepData.date = getDateForm(getValues("date"));
      }

      saveData(data, stepData, activeStep)
      handleNextStep()

      if (activeStep === 2) {
        calculateTotalTimeSpent(data, steps)
      }
    } else if (activeStep === steps.length - 1) {
        saveData(data, stepData, activeStep)
        handleNextStep()
    }
    if (
      activeStep < steps.length - 1
      && !loadedArray[activeStep + 1].loaded
      && qualifications.length > 0
    ) {
      loadedArray[activeStep + 1].loaded = true;
      setLoadArray(loadedArray);
      qualifications.forEach((field) => {
        setValue(
          buildRouteName(field.criteria.name),
          // eslint-disable-next-line no-nested-ternary
          field.assessment != null
            ? field.criteria.type === CriteriaTypes.Boolean
              ? field.assessment.name === "true"
              : field.assessment.id
            : field.value,
        );
        if (field.comment) {
          setValue(
            `${buildRouteName(field.criteria.name)}-comment`,
            field.comment,
          );
        }
      });
    }
  };

  useEffect(() => {
    if (activeStep === steps.length && steps.length > 0 && result === null) {
      const calculateRequest = generateRequestData(steps, data, "null");
      const fetchCalcule = async () => {
        const resCalcule = await dispatch(calculateFactors(calculateRequest))
        setResult(resCalcule.data)
        seIsCalculating(resCalcule.loading)
      }
      fetchCalcule()
    }

    if (data && data[activeStep] && data[activeStep][buildRouteName("date")]) {
      if (!getValues("date")) setValue("date", today);
    }

    if (
      activeStep === 0
      && !loadedArray[0].loaded
      && qualifications.length > 0
    ) {
      loadedArray[0].loaded = false;
      setLoadArray(loadedArray);
      const formValue = {};
      qualifications.forEach((field) => {
        // eslint-disable-next-line no-nested-ternary
        formValue[buildRouteName(field.criteria.name)] = field.assessment != null
          ? field.criteria.type === CriteriaTypes.Boolean
            ? field.assessment.name === "true"
            : field.assessment.id
          : field.value;
        if (field.comment) {
          formValue[`${buildRouteName(field.criteria.name)}-comment`] = field.comment;
        }
      });
      setStartDate(formValue?.date);
      reset(formValue);
    }
  }, [activeStep, history, data, dispatch, steps, result, qualifications]);

  const handleBack = () => {
    saveData(data, getValues(), activeStep)
    handleBackStep()
    reset(data[activeStep - 1]);
  };

  const resetForm = () => {
    let defaultData = {}
    data.forEach((step) => {
      defaultData = { ...defaultData, ...step }
    })
    setProcessName("")
    unregister(Object.keys(defaultData))
    reset()
  }

  const onSuccessSubmitProcess = () => {
    resetForm()
    setOpenMsgConfirm(false)
    setDisableConfirmButton(false)
      history.push(OPPORTUNITY_ASSESSMENT_BASE_URL);
  }

  const handleSubmitProcess = (status) => {
    const submitRequest = generateRequestData(steps, data, status);
    dispatch(
      id
        ? updateProcessStepper(id, submitRequest, onSuccessSubmitProcess)
        : submitProcessStepper(submitRequest, onSuccessSubmitProcess)
    ).then(() => {
      resetForm()
      setOpenMsgConfirm(false)
      history.push(OPPORTUNITY_ASSESSMENT_BASE_URL);
      if (status === "not submitted") {
        toast.success(t("Case saved but not submitted"))
      } else if (status === "submitted") {
        toast.success(t("Case saved and submitted for approval"))
      }
    });
  };
  const handleInputChange = (event) => {
    handleFieldValueChange(event.target.value);
  };

  const onSuccessEditProcess = () => {
    resetForm()
    setOpenMsgConfirm(false)
    history.push(OPPORTUNITY_ASSESSMENT_BASE_URL);
  }

  const handleEditProcess = (status) => {
    // send also current step data
    const dataClone = [...data];
    dataClone[activeStep] = getValues();

    if (dataClone[activeStep].date) {
      dataClone[activeStep].date = getDateForm(getValues("date"));
      // yyyy-MM-dd ex: 2020-08-12
    }

    const saveRequest = generateRequestData(steps, dataClone, status);
    dispatch(updateProcessStepper(id, saveRequest, onSuccessEditProcess))
  };

  const onSuccessSaveAsDraft = () => {
    resetForm()
    setOpenMsgConfirm(false)
    history.push(OPPORTUNITY_ASSESSMENT_BASE_URL);
    toast.success(t("Case saved as a draft"))
    setDisableConfirmButton(false)
  }

  const handleSaveAsDraft = () => {
    // send also current step data
    const dataClone = JSON.parse(JSON.stringify(data));
    dataClone[activeStep] = getValues();
    if (dataClone[activeStep].date) {
      dataClone[activeStep].date = getDateForm(getValues("date"));
      // yyyy-MM-dd ex: 2020-08-12
    }
    let nextStep = activeStep + 1;
    if (activeStep < steps.length - 1 && id && qualifications.length > 0) {
      while (loadedArray[nextStep] && loadedArray[nextStep].loaded) {
        nextStep += 1;
      }
      if (
        nextStep < steps.length - 1
        && id
        && qualifications.length > 0
        && !loadedArray[activeStep + 1].loaded
      ) {
        while (nextStep <= steps.length - 1) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in dataClone[nextStep]) {
            if (dataClone[nextStep].hasOwnProperty(key)) {
              let found;
              if (key.includes("-comment")) {
                found = qualifications.find(
                  // eslint-disable-next-line no-loop-func
                  (element) => buildRouteName(element.criteria.name)
                    === key.split("-comment")[0],
                );
                dataClone[nextStep][key] = found ? found?.comment : "";
              } else {
                found = qualifications.find(
                  // eslint-disable-next-line no-loop-func
                  (element) => buildRouteName(element.criteria.name) === key,
                );
                // eslint-disable-next-line no-nested-ternary
                dataClone[nextStep][key] = found
                  ? found?.assessment != null
                    ? found.assessment.id
                    : found?.value
                  : "";
              }
            }
          }
          nextStep += 1;
        }
      }
    }
    const saveAsDraftRequest = generateRequestData(steps, dataClone, "draft");
    dispatch(resetProcessesState());
    dispatch(
        id
          ? updateProcessStepper(id, saveAsDraftRequest, onSuccessSaveAsDraft)
          : submitProcessStepper(saveAsDraftRequest, onSuccessSaveAsDraft)
      )
  };

  const onSuccessSubmitDraftProcess = () => {
    setOpenMsgConfirm(false)
  }

  const handleSubmitDraftProcess = () => {
    const processToSubmit = generateRequestData(steps, data, "not submitted");

    processToSubmit.id = process.id;
    // processToSubmit.createdBy = process.createdBy;

    dispatch(
      updateProcessStepper(id, processToSubmit, onSuccessSubmitDraftProcess)
    );
  };
  const theme = useTheme();

  const { t } = useTranslation();

  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const confirmChange = () => {
    setDisableConfirmButton(true);
    switch (status) {
      case "draft":
        handleSaveAsDraft();
        break;
      case "submitted":
        handleSubmitProcess("submitted");
        break;
      case "not submitted":
        handleSubmitProcess("not submitted");
        break;

      default:
        break;
    }
  };

  const changeData = (status) => {
    let msgConfirm = "";
    let buttonConfirm = "";
    switch (status) {
      case "draft":
        buttonConfirm = t("save draft");
        msgConfirm = t("Are you sure you want to save this case as a draft ?");
        break;
      case "submitted":
        buttonConfirm = t("rpaqual.submit");
        msgConfirm = t("Are you sure you want to save and submit this case ?");
        break;
      case "not submitted":
        buttonConfirm = t("Submit later");
        msgConfirm = t(
          "Are you sure you want to save and submit this case later ?",
        );
        break;

      default:
        break;
    }
    setStatus(status);
    setOpenMsgConfirm(true);
    setMsgConfirm(msgConfirm);
    setButtonConfirm(buttonConfirm);
  };
  const getValuesForm = (name) => {
    let value = "";
    if (id) {
      if (data[activeStep][buildRouteName(name)]) {
        value = data[activeStep][buildRouteName(name)];
      } else {
        qualifications.forEach((field) => {
          if (field.criteria.name === name && field.assessment !== null) {
            value = field.assessment.id;
          }
        });
      }
    } else if (data[activeStep][buildRouteName(name)]) {
      value = data[activeStep][buildRouteName(name)];
    }
    return value;
  };
  const getValuesBoolean = (name) => {
    let value = false;
    if (id) {
      if (data[activeStep][buildRouteName(name)]) {
        value = data[activeStep][buildRouteName(name)];
      } else {
        qualifications.forEach((field) => {
          if (field.criteria.name === name && field.assessment !== null) {
            value = field.assessment.name === "true";
          }
        });
      }
    } else if (data[activeStep][buildRouteName(name)]) {
      value = data[activeStep][buildRouteName(name)];
    }
    return value;
  };
  const allowToShow = () => (
    (!isLoading && activeStep !== steps.length && steps.length > 0 && !id)
      || (!isLoading
        && activeStep !== steps.length
        && steps.length > 0
        && id
        && qualifications && !isQualificationLoaded)
  );

  if (isLoading || !steps || !data || isQualificationLoaded || !qualifications) {
    return <CircularLoader />
  }

  const handleDraftClick = async () => {
    const result = activeStep === 0 ? await trigger() : true;
    if (result) {
      changeData("draft")
    }
  }

  return (
    <Box>
      {allowToShow()
        && (mode === "create") && (
          <form
            className={classes.formContainer}
            noValidate
            onSubmit={handleSubmit(handleNext)}
          >
            {!isLoading
              && steps[activeStep].criterias
                .slice()
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map(
                  (criteria, index) => ![
                    "Occurence time granularity",
                    "Time granularity",
                  ].includes(criteria.name) && (
                  // criteria.name !== "Time granularity" && (
                  <Grid
                    key={criteria.id}
                    container
                    justify="center"
                    alignItems="flex-start"
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} md={4}>
                      <Box mt={1}>
                        <Typography variant="subtitle1" component="h5">
                          <InformationIcon
                            titleContent={(
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                }}
                              >
                                {t(criteria.help)}
                              </span>
                                  )}
                            showTitle={criteria.help}
                            originalContent={t(criteria.name)}
                          />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item container xs={12} md={4}>
                      {criteria.assessments.length > 0
                            && criteria.type === CriteriaTypes.String && (
                              <TextField
                                key={`rpa-qual-${buildRouteName(
                                  criteria.name,
                                )}`}
                                {...register(buildRouteName(criteria.name), {
                                  required: criteria.isMandatory,
                                })}
                                defaultValue={getValuesForm(criteria.name)}
                                error={
                                  errors[buildRouteName(criteria.name)] && true
                                }
                                fullWidth
                                select
                                value={getValues(buildRouteName(criteria.name))}
                                size="medium"
                                label={
                                  isMobileSm
                                    ? truncateString(t(criteria.name), 20)
                                    : t(criteria.name)
                                }
                                variant="outlined"
                                required
                                autoFocus={index === 0}
                                InputLabelProps={{
                                  shrink: !!watch(buildRouteName(criteria.name)),
                                }}
                              >
                                {criteria?.assessments
                                  ?.slice()
                                  .sort(compare)
                                  .map((assessment) => (
                                    <MenuItem
                                      onClick={() => setValue(
                                        buildRouteName(criteria.name),
                                        assessment,
                                      )}
                                      key={assessment.id}
                                      value={assessment.id}
                                    >
                                      {t(`assessment.${assessment.name}`)}
                                    </MenuItem>
                                  ))}
                              </TextField>
                      )}

                      {/* Boolean Input */}
                      {criteria.type === CriteriaTypes.Boolean && (
                      <CustomSwitch
                        {...register(buildRouteName(criteria.name))}
                        defaultChecked={getValuesBoolean(criteria.name)}
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}
                      />
                      )}
                      {criteria.assessments.length === 0
                            && criteria.type === CriteriaTypes.Date && (
                              <MuiPickersUtilsProvider
                                utils={DateFnsAdapter}
                                locale={getLocale()}
                              >
                                <DateTimePicker
                                  InputProps={{
                                    endAdornment: <CalendarToday />,
                                  }}
                                  {...register(buildRouteName(criteria.name), {
                                    required: criteria.isMandatory,
                                  })}
                                  defaultValue={
                                    new Date(
                                      data[activeStep][
                                        buildRouteName(criteria.name)
                                      ],
                                    ) || dateFns.date()
                                  }
                                  value={startDate}
                                  onChange={(value) => {
                                    setStartDate(value);
                                    setValue(
                                      buildRouteName(criteria.name),
                                      value,
                                    );
                                  }}
                                  clearable
                                  error={!!errors.startDate}
                                  format={formatDatePickerByLanguage()}
                                  label={
                                    isMobileSm
                                      ? truncateString(t(criteria.name), 20)
                                      : t(criteria.name)
                                  }
                                  inputVariant="outlined"
                                  fullWidth
                                  size="medium"
                                  required
                                  helperText={
                                    errors.date && errors.date.message
                                  }
                                  cancelLabel={t("user.button.cancel")}
                                  todayLabel={t("Today")}
                                  clearLabel={t("")}
                                  ampm={false}
                                />
                              </MuiPickersUtilsProvider>
                      )}

                      {/* String input */}
                      {criteria.assessments.length === 0
                            && (criteria.type === CriteriaTypes.String
                              || criteria.type === CriteriaTypes.Text) && (
                              <TextField
                                error={
                                  criteria.name === processReferenceLabel ? false : criteria.isMandatory
                                  && errors[buildRouteName(criteria.name)]
                                  && true
                                }
                                {...register(buildRouteName(criteria.name), {
                                  required: criteria.name === processReferenceLabel ? false : criteria.isMandatory,
                                  validate: (value) => criteria.name === processReferenceLabel || !criteria.isMandatory || !!value.trim(),
                                })}
                                rows={5}
                                multiline={criteria.type === CriteriaTypes.Text}
                                size="medium"
                                fullWidth
                                label={
                                  isMobileSm
                                    ? truncateString(t(criteria.name), 20)
                                    : t(criteria.name)
                                }
                                variant="outlined"
                                required={criteria.name === processReferenceLabel ? false : criteria.isMandatory}
                                defaultValue={
                                  data[activeStep][
                                    buildRouteName(criteria.name)
                                  ] || ""
                                }
                                onChange={(e) => {
                                  if (criteria.name === processNameLabel) {
                                    handleInputChange(e);
                                  }
                                  register(buildRouteName(criteria.name)).onChange(e);
                                }}
                              />
                      )}

                      {/* Number input */}

                      {/* {criteria.name !== "Time spent for one execution" && */}
                      {![
                        "Time spent for one execution",
                        "Frequency of occurence",
                      ].includes(criteria.name)
                            && criteria.assessments.length === 0
                            && criteria.type === CriteriaTypes.Number && (
                              <TextField
                                error={
                                  errors[buildRouteName(criteria.name)] && true
                                }
                                type="text"
                                InputProps={{
                                  inputProps: { min: 0 },
                                }}
                                required
                                size="medium"
                                fullWidth
                                label={
                                  isMobileSm
                                    ? truncateString(t(criteria.name), 20)
                                    : t(criteria.name)
                                }
                                variant="outlined"
                                defaultValue={
                                  data[activeStep][
                                    buildRouteName(criteria.name)
                                  ]
                                }
                                {...register(buildRouteName(criteria.name), {
                                  required: criteria.isMandatory,
                                  validate: (data) => data >= 0,
                                })}
                              />
                      )}

                      {/* {criteria.name === "Time spent for one execution" && */}
                      {["Time spent for one execution"].includes(
                        criteria.name,
                      )
                            && criteria.assessments.length === 0
                            && criteria.type === CriteriaTypes.Number && (
                              <Grid container spacing={2}>
                                <Grid item xs={9}>
                                  <TextField
                                    error={
                                      errors[buildRouteName(criteria.name)]
                                      && true
                                    }
                                    type="text"
                                    {...register(
                                      buildRouteName(criteria.name),
                                      {
                                        required: criteria.isMandatory,
                                        validate: (data) => data > 0,
                                      },
                                    )}
                                    InputProps={{
                                      inputProps: { min: 0 },
                                    }}
                                    required
                                    size="medium"
                                    fullWidth
                                    label={
                                      isMobileSm
                                        ? truncateString(t(criteria.name), 20)
                                        : t(criteria.name)
                                    }
                                    variant="outlined"
                                    defaultValue={
                                      data[activeStep][
                                        buildRouteName(criteria.name)
                                      ]
                                    }
                                    onInput={(e) => handleInputChanges(e, false)}
                                    onFocus={(e) => formatNumberInputOnFocus(e)}
                                    onBlur={(e) => formatNumberInputOnBlur(e)}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    {...register(
                                      buildRouteName("Time granularity"),
                                      { required: criteria.isMandatory },
                                    )}
                                    defaultValue={getValuesForm(
                                      "Time granularity",
                                    )}
                                    error={
                                      errors[
                                        !watch(buildRouteName("Time granularity"))
                                        && buildRouteName("Time granularity")
                                      ] && true
                                    }
                                    fullWidth
                                    select
                                    size="medium"
                                    variant="outlined"
                                    required
                                    autoFocus={index === 0}
                                  >
                                    {steps[activeStep].criterias
                                      .find(
                                        (e) => e.name === "Time granularity",
                                      )
                                      .assessments?.slice()
                                      .sort(compare)
                                      .map((assessment) => (
                                        <MenuItem
                                          key={assessment.id}
                                          value={assessment.id}
                                        >
                                          {t(assessment.name)}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                      )}

                      {["Frequency of occurence"].includes(criteria.name)
                            && criteria.assessments.length === 0
                            && criteria.type === CriteriaTypes.Number && (
                              <Grid container spacing={2}>
                                <Grid item xs={9}>
                                  <TextField
                                    error={
                                      errors[buildRouteName(criteria.name)]
                                      && true
                                    }
                                    type="text"
                                    {...register(
                                      buildRouteName(criteria.name),
                                      {
                                        required: criteria.isMandatory,
                                        validate: (data) => data > 0,
                                      },
                                    )}
                                    InputProps={{
                                      inputProps: { min: 0 },
                                    }}
                                    required
                                    size="medium"
                                    fullWidth
                                    label={
                                      isMobileSm
                                        ? truncateString(t(criteria.name), 20)
                                        : t(criteria.name)
                                    }
                                    variant="outlined"
                                    defaultValue={
                                      data[activeStep][
                                        buildRouteName(criteria.name)
                                      ]
                                    }
                                    onInput={(e) => handleInputChanges(e, false)}
                                    onFocus={(e) => formatNumberInputOnFocus(e)}
                                    onBlur={(e) => formatNumberInputOnBlur(e)}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    {...register(
                                      buildRouteName(
                                        "Occurence time granularity",
                                      ),
                                      { required: criteria.isMandatory },
                                    )}
                                    defaultValue={getValuesForm(
                                      "Occurence time granularity",
                                    )}
                                    error={
                                      errors[
                                      !watch(buildRouteName("Occurence time granularity"))
                                      && buildRouteName(
                                          "Occurence time granularity",
                                        )
                                      ] && true
                                    }
                                    fullWidth
                                    select
                                    size="medium"
                                    variant="outlined"
                                    required
                                    autoFocus={index === 0}
                                  >
                                    {steps[activeStep].criterias
                                      .find(
                                        (e) => e.name
                                          === "Occurence time granularity",
                                      )
                                      .assessments?.slice()
                                      .sort(compare)
                                      .map((assessment) => (
                                        <MenuItem
                                          key={assessment.id}
                                          value={assessment.id}
                                        >
                                          {t(assessment.name)}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                      )}

                      {/* Number read only */}
                      {criteria.assessments.length === 0
                            && criteria.type === CriteriaTypes.NumberReadOnly && (
                              <TextField
                                multiline
                                InputProps={{
                                  classes: {
                                    input: classes.textFieldDisabled,
                                  },
                                }}
                                InputLabelProps={{
                                  shrink:
                                    data[activeStep][
                                      buildRouteName(criteria.name)
                                    ] !== null,
                                }}
                                type="text"
                                {...register(buildRouteName(criteria.name))}
                                required
                                size="medium"
                                fullWidth
                                label={
                                  isMobileSm
                                    ? truncateString(t(criteria.name), 20)
                                    : t(criteria.name)
                                }
                                variant="outlined"
                                value={formtNumberByLanguage(
                                  data[activeStep][
                                    buildRouteName(criteria.name)
                                  ],
                                )}
                              />
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {!["Business Applications"].includes(criteria.name) && (
                        <TextField
                          {...register(
                            `${buildRouteName(criteria.name)}-comment`,
                          )}
                          fullWidth
                          label={t("Comment")}
                          size="medium"
                          rows={5}
                          multiline={criteria.type === CriteriaTypes.Text}
                          defaultValue={
                                data[activeStep][
                                  `${buildRouteName(criteria.name)}-comment`
                                ]
                              }
                          variant="outlined"
                        />
                      )}
                      {["Business Applications"].includes(criteria.name) && (
                        <Autocomplete
                          multiple
                          id="applications-tags"
                          options={[]}
                          defaultValue={getValues(`${buildRouteName(criteria.name)}-comment`)?.split(",")}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("rpa.create.businessApplication")}
                              size="medium"
                              rows={5}
                              multiline={criteria.type === CriteriaTypes.Text}
                              variant="outlined"
                            />
                          )}
                          onChange={(event, newValue) => {
                            setValue(`${buildRouteName(criteria.name)}-comment`, newValue.join(","))
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  ),
                )}
            <Box
              display="flex"
              justifyContent="flex-end"
              flexDirection={!isMobileSm ? "row" : "column"}
            >
              {mode === "create" && (
                <CustomButton
                  endIcon={<SaveAsDraftIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleDraftClick}
                  className={clsx(
                    !isMobileSm && classes.button,
                    isMobileSm && classes.marginBottom,
                  )}
                >
                  {t("save as draft")}
                </CustomButton>
              )}
              {mode === "edit" && process.status === "draft" && (
                <CustomButton
                  endIcon={<SaveAsDraftIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleEditProcess.bind(this, "draft")}
                  className={clsx(
                    !isMobileSm && classes.button,
                    isMobileSm && classes.marginBottom,
                  )}
                >
                  {t("save draft")}
                </CustomButton>
              )}
              <CustomButton
                startIcon={<BackIcon />}
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={clsx(
                  !isMobileSm && classes.button,
                  isMobileSm && classes.marginBottom,
                )}
              >
                {t("Back")}
              </CustomButton>
              <CustomButton
                view="primary"
                className={
                  activeStep === steps.length - 1
                    ? classes.calculateBtn
                    : undefined
                }
                type="submit"
                endIcon={
                  activeStep === steps.length - 1 ? (
                    <CalculateIcon />
                  ) : (
                    <NextIcon />
                  )
                }
              >
                {activeStep === steps.length - 1 ? t("Calculate") : t("next")}
              </CustomButton>
            </Box>
          </form>
      )}
      {!isLoading && activeStep === steps.length && activeStep > 0 && (
        <div>
          <Grid>
            {isCalculating && <CircularLoader />}
            {!isCalculating && result !== null && <Result result={result} />}
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop={4}
            flexDirection={!isMobileSm ? "row" : "column"}
          >
            {mode === "create" && (
              <CustomButton
                endIcon={<SaveAsDraftIcon />}
                variant="outlined"
                color="primary"
                onClick={changeData.bind(this, "not submitted")}
                className={clsx(
                  !isMobileSm && classes.button,
                  isMobileSm && classes.marginBottom,
                )}
              >
                {t("Submit later")}
              </CustomButton>
            )}
            {mode === "edit" && (
              <CustomButton
                endIcon={<SaveAsDraftIcon />}
                variant="outlined"
                color="primary"
                onClick={handleEditProcess.bind(this, "not submitted")}
                className={clsx(
                  !isMobileSm && classes.button,
                  isMobileSm && classes.marginBottom,
                )}
              >
                {t("Submit later")}
              </CustomButton>
            )}
            <CustomButton
              startIcon={<BackIcon />}
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              className={clsx(
                !isMobileSm && classes.button,
                isMobileSm && classes.marginBottom,
              )}
            >
              {t("Back")}
            </CustomButton>
            {mode === "create" && (
              <CustomButton
                view="primary"
                endIcon={<SubmitIcon />}
                onClick={changeData.bind(this, "submitted")}
              >
                {(isSubmittingUpdate || isSubmittingSubmit) ? t("Submitting...") : t("rpaqual.submit")}
              </CustomButton>
            )}

            {mode === "edit" && (
              <CustomButton
                view="primary"
                endIcon={<SubmitIcon />}
                onClick={handleSubmitDraftProcess}
              >
                {(isSubmittingUpdate || isSubmittingSubmit) ? t("Submitting...") : t("Submit")}
              </CustomButton>
            )}
          </Box>
        </div>
      )}
      {openMsgConfirm && (
        <ConfirmMessage
          message={msgConfirm}
          openStart={openMsgConfirm}
          isLoading={disableConfirmButton}
          onCancel={cancelConfirm}
          onConfirm={confirmChange}
          buttonConfirm={buttonConfirm}
          buttonCancel={t("Cancel")}
        />
      )}
    </Box>
  );
};

export default ProcessStep;
