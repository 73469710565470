import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  warningColor: {
    color: theme.palette.warning.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  infoColor: {
    color: theme.palette.primary.main,
  },
  iconStyle: {
    verticalAlign: "middle",
    marginRight: "8px",
    fontSize: 18
  },

  active: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  inactive: {
    color: theme.custom.color.dividerColor,
    cursor: "pointer",
  },
  subscribe: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.info.main,
    cursor: "pointer",
    view: "contained",
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  },
  unsubscribe: {
    color: theme.palette.background.default,
    backgroundColor: theme.custom.color.chartColor1,
    view: "contained",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.custom.color.chartColor1,
    },
  },

}));
