import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = 5;

const initialState = {
  searchCriteria: "",
  status: [],
  fleets: [],
  orchestrator: [],
  pageNo: 0,
  pageSize: defaultPageSize,
  sortBy: "",
  showDeleted: false,
};

const adminFilter = {
  fleets: initialState,
  users: initialState,
  orchestrators: initialState,
  resources: initialState,
  queues: initialState,
  tags: initialState,
  intelligentorchestration: initialState,
  currModule: "fleets",
};

const counterSlice = createSlice({
  name: "adminFilter",
  initialState: adminFilter,
  reducers: {
    initFilter: (state) => ({
      ...state,
      [state.currModule]: adminFilter[state.currModule],
    }),
    initFilterAdmin: () => ({
      ...initialState,
    }),
    updateFilter: (state, action) => ({
      ...state,
      [state.currModule]: {
        ...state[state.currModule],
        ...action.payload,
      },
    }),
    updateModule: (state, action) => ({
      ...state,
      currModule: action.payload.module,
    }),
  },
});

export const {
  initFilter, updateFilter, initFilterAdmin, updateModule,
} = counterSlice.actions;
export default counterSlice.reducer;
