import React, { useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getQuery } from "@redux-requests/core";
import { fetchDashboardPendingItemsCount } from "redux/actions/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import renderValue, {
    DOUGHNUT_CHART_DATASET_DATA,
    DOUGHNUT_CHART_DATASET_OPTION, MONITORING_TIME,
    renderContent, translationXValue, translationYValue
} from "pages/newDashboard/dashboardUtils";
import { isEnvParamEnabled } from "util/index";
import { isPermitted } from "components/HasPermission";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import Circle from "assets/NewDashboardIcons/circle.svg";
import useStyles from "../../style";

const Transactions = ({
  transactionsCompleted,
  exceptionsCounts,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const [translationX, setTranslationX] = useState(translationXValue(percentage));
  const [translationY, setTranslationY] = useState(translationYValue(percentage));
  const classes = useStyles({ translationX, translationY, percentage })();
  const [transactionsInProgress, setTransactionsInProgress] = useState();
  const isTransactionsInProgressLoading = useSelector((state) => getQuery(state, { type: "FETCH_DASHBOARD_PENDING_ITEMS_COUNT" }).loading);
  const dispatch = useDispatch();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const filterDashboard = useSelector(
        ({ dashboardFilters }) => dashboardFilters,
    );
  const isRiyadBankEnvEnabled = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const fetchTransactionsInProgress = () => {
    dispatch(
        fetchDashboardPendingItemsCount(filterDashboard, (res) => {
            setTransactionsInProgress(res?.data);
        }),
    );
  };

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchTransactionsInProgress)
    return () => {
        clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    if (filterDashboard?.selectedDurationValue === "DAY") {
        fetchTransactionsInProgress();
    }
  }, [filterDashboard]);

  useEffect(() => {
    if (transactionsCompleted + transactionsInProgress) {
      setPercentage((transactionsCompleted / (transactionsInProgress + transactionsCompleted)) * 100)
    }
  }, [transactionsInProgress, transactionsCompleted]);

  const getTotalItems = () =>
    {
        let total = transactionsCompleted;
        if (isRiyadBankEnvEnabled) total += transactionsInProgress;
        if (isPermitted(currentUser, "System exceptions")) total += exceptionsCounts?.systemExceptions ?? 0;
        if (isPermitted(currentUser, "Business exceptions")) total += exceptionsCounts?.businessExceptions ?? 0;
        return total;
    }

    useEffect(() => {
        setTranslationX(translationXValue(percentage))
        setTranslationY(translationYValue(percentage))
    }, [percentage]);

  const Component = () => (
    <Box component={Grid} container item alignItems="center" direction="column" spacing={2} position="relative">
      <Grid item className={classes.chartContainer}>
        <Doughnut data={DOUGHNUT_CHART_DATASET_DATA(percentage)} options={DOUGHNUT_CHART_DATASET_OPTION} />
        <img src={Circle} alt="circle" className={classes.circleImage} key={`chart-percentage-${percentage}-${translationX}-${translationY}`} />
      </Grid>
      <Box
        className={classes.centeredContent}
        textAlign="center"
      >
        <Typography className={classes.textBold}>
          {renderValue({
            value: getTotalItems()
          })}
        </Typography>
        <Typography className={classes.textLight}>
          {isRiyadBankEnvEnabled ? t("transactions.header.label") : t("items.header.label")}
        </Typography>
      </Box>
      {isRiyadBankEnvEnabled && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography className={classes.textRegular}>
              {t("inProgress.transactions.status")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Chip label={renderValue({ value: transactionsInProgress })} className={clsx(classes.chip, classes.defaultChip)} />
          </Grid>
        </Grid>
      )}
      <Grid container item justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <Typography className={classes.textRegular}>
            {t("completed.transactions.status")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Chip label={renderValue({ value: transactionsCompleted })} className={clsx(classes.chip, classes.darkChip)} />
        </Grid>
      </Grid>
      {isPermitted(currentUser, "System exceptions") && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography className={classes.textRegular}>
              {t("System exceptions")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Chip
label={renderValue({ value: exceptionsCounts?.systemExceptions })}
                          className={clsx(classes.chip, classes.fushiaChip)} />
          </Grid>
        </Grid>
        )}
      {isPermitted(currentUser, "Business exceptions") && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography className={classes.textRegular}>
              {t("Business exceptions")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Chip
label={renderValue({ value: exceptionsCounts?.businessExceptions })}
                          className={clsx(classes.chip, classes.fushiaChip)} />
          </Grid>
        </Grid>
        )}
    </Box>
  );

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={isRiyadBankEnvEnabled ? t("transactions.header.label") : t("items.header.label")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent>
        { renderContent(isLoading || isTransactionsInProgressLoading, [transactionsInProgress, transactionsCompleted], <Component />) }
      </CardContent>
    </Box>
  );
};

export default Transactions;
