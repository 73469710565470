import React from "react";
import Alert from "@material-ui/lab/Alert";
import { fade, withStyles } from "@material-ui/core/styles";
import { frequencyScheduleAlerts } from "util/configs/alerts";

const generateAlertColor = (severity, theme) => {
    let alertPalette;
    switch (severity) {
        case frequencyScheduleAlerts.error.severity:
            alertPalette = {
                backgroundColor: fade(theme.palette.secondary.main, 0.08),
                color: theme.palette.secondary.main,
            };
            break;
        case frequencyScheduleAlerts.info.severity:
            alertPalette = {
                backgroundColor: theme.palette.info.light,
                color: theme.palette.primary.main,
            };
            break;
        default:
            alertPalette = {
                backgroundColor: theme.custom.color.alert.defaultBg,
                color: theme.custom.color.alert.defaultText,
            };
    }
    return alertPalette;
};

const CustomStyledAlert = withStyles((theme) => ({
    root: (props) => ({
        ...generateAlertColor(props?.severity, theme),
        margin: theme.spacing(2, 0),
        fontSize: "0.9rem",
        fontWeight: 500,
        borderRadius: "7px",
        width: "100%"
    }),
}))(Alert);
const CustomAlert = ({ children, severity, alertMsg }) => (
  <CustomStyledAlert severity={severity} icon={false}>
    {alertMsg}
    {children}
  </CustomStyledAlert>
    );

export default CustomAlert;
