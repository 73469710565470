import {
  ADD_TAG,
  DELETE_TAG_BY_ID,
  DELETE_LIST_TAGS,
  FETCH_TAG,
  FETCH_TAG_BY_ID,
  FETCH_TAG_FOR_FILTERS,
  UPDATE_TAG,
  UPDATE_TAG_STATUS,
  ARCHIVE_TAG,
  BULK_ARCHIVE_TAG,
  FETCH_ALL_TAGS_OF_USER,
  FETCH_ICON,
  FETCH_TAG_FOR_PLANNER_FILTER
} from "../../constants";
  import { URL_TAG, URL_TAG_ADMIN, URL_USER } from "../../constants/endpoints";

  export const fetchTags = (filter = {}) => {
    const {
   searchCriteria, pageNo, pageSize, status, sortBy, fleets
  } = filter;
    return {
      type: FETCH_TAG,
      request: {
        url: encodeURI(`${URL_TAG}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
        params: {
          page: pageNo,
          size: pageSize,
          sort: sortBy,
          status: status?.join(","),
          fleetsIds: fleets?.join(),
        },
      },
    };
  };

  export const fetchTagsForFilters = (fleetIds = [], processIds) => ({
    type: FETCH_TAG_FOR_FILTERS,
    request: {
      url: `${URL_TAG}/filters`,
      params: {
        fleetIds: fleetIds?.join(),
        processIds: processIds?.join()
      }
    }
  });

  export const fetchTagsForPlannerFilter = () => ({
    type: FETCH_TAG_FOR_PLANNER_FILTER,
    request: {
      url: `${URL_TAG}/filter/planner`,
    }
  });

  export const fetchAllTagsOfUser = (userId) => ({
    type: FETCH_ALL_TAGS_OF_USER,
    request: {
        url: `${URL_USER}/tags/${userId}`,
        method: "get"
    }
  });

  export const enableOrDisableTag = (
    id,
    TagStatus,
    onSuccess,
    onError
  ) => ({
    type: UPDATE_TAG_STATUS,
    request: {
      url: `${URL_TAG}/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });

  export const deleteTag = (id, onSuccess, onError) => ({
    type: DELETE_TAG_BY_ID,
    request: {
      url: `${URL_TAG_ADMIN}/${id}`,
      method: "delete",
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });

  export const deleteTags = (ids, onSuccess, onError) => ({
    type: DELETE_LIST_TAGS,
    request: {
      url: `${URL_TAG_ADMIN}`,
      method: "delete",
      params: { ids: ids.join(",") },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });

  export const fetchTagById = (id) => ({
    type: `${FETCH_TAG_BY_ID}`,
    request: {
      url: `${URL_TAG}/${id}`,
    },
  });

  export const addTag = (Tag, onSuccess, onError) => ({
    type: ADD_TAG,
    request: {
      url: `${URL_TAG}`,
      method: "post",
      data: Tag,
      headers: { "Content-Type": "multipart/form-data" },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });

  export const updateTag = (id, Tag, onSuccess, onError) => ({
    type: UPDATE_TAG,
    request: {
      url: `${URL_TAG}/${id}`,
      method: "put",
      data: Tag,
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });
  export const archiveTag = (id, onSuccess, onError) => ({
    type: ARCHIVE_TAG,
    request: {
      url: `${URL_TAG}/${id}`,
      method: "patch",
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess();
        return response;
      },
      onError: (error) => {
        if (onError) onError();
        throw error;
      },
    },
  });

export const archiveTags = (ids, onSuccess, onError) => ({
  type: BULK_ARCHIVE_TAG,
  request: {
    url: `${URL_TAG}`,
    method: "patch",
    params: { ids: ids.join(",") },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});
export const fetchIcon = (id) => ({
  type: FETCH_ICON,
  request: {
    url: `${URL_TAG}/${id}/icon`,
  },
});
