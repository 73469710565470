export const CriteriaTypes = {
  String: "string",
  Text: "text",
  Boolean: "boolean",
  Date: "date",
  Number: "number",
  NumberReadOnly: "number-readonly",
};

export default CriteriaTypes;
