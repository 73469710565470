import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
   title: {
       color: theme.palette.primary.main,
      fontSize: 18,
      fontWeight: 700,
      paddingBottom: 0
   },
   infoIcon: {
      fontSize: 13,
      color: theme.palette.secondary.main,
      alignSelf: "flex-end",
      marginLeft: 2
   }
}));
