import { FormControlLabel } from "@material-ui/core";
import React from "react";
import { CriteriaTypes } from "util/constants"
import {
  formatDateByLanguage,
  formtNumberByLanguage,
} from "util";
import { useTranslation } from "react-i18next";
import CustomSwitch from "../../Services/components/CustomSwitch";

function ProcessAssessmentByCriteria({ criteria, processStore }) {
  const { t } = useTranslation();
  switch (criteria.type) {
    case CriteriaTypes.Date:
      return formatDateByLanguage(
        processStore.qualifications.find((q) => q.criteria.id === criteria.id)
          ?.value ?? null
      );
    case CriteriaTypes.Number:
    case CriteriaTypes.NumberReadOnly:
      return formtNumberByLanguage(
        processStore.qualifications.find((q) => q.criteria.id === criteria.id)
          ?.value
      );
    case CriteriaTypes.String:
      return criteria?.assessments.length > 0
        ? t(
            `assessment.${
              processStore.qualifications.find(
                (q) => q.criteria.id === criteria.id
              )?.assessment?.name
            }`
          )
        : processStore.qualifications.find((q) => q.criteria.id === criteria.id)
            ?.value ?? null;
    case CriteriaTypes.Boolean:
      return (
        <FormControlLabel
          disabled
          control={
            <CustomSwitch
              checked={
                processStore.qualifications.find(
                  (q) => q.criteria.id === criteria.id
                )?.assessment.name === "true"
              }
            />
          }
          label={
            processStore.qualifications.find(
              (q) => q.criteria.id === criteria.id
            )?.assessment.name === "true"
              ? t("Yes")
              : t("No")
          }
        />
      );

    default:
      return processStore.qualifications.find(
        (q) => q.criteria.id === criteria.id
      )?.value ?? null;
  }
}

export default ProcessAssessmentByCriteria;
