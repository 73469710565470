import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { isPermitted } from "../../../../components/HasPermission";
import useStyles from "./style";

const EditAlertButton = ({ mode, currentUser, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return mode === "view" && isPermitted(currentUser, "Edit default alerts") ? (
    <div className={classes.editButtonContainer}>
      <Tooltip placement="bottom" title={t("tooltip.action.edit")}>
        <IconButton
          className={classes.editButton}
          aria-label="modify"
          onClick={onClick}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null;
};

export default EditAlertButton;
