import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const CircularLoader = ({ height, size = 35, justifyContent = "center" }) => (
  <Box display="flex" justifyContent={justifyContent} alignItems="center" width="100%" height={height}>
    <CircularProgress color="primary" size={size} />
  </Box>
);

export default CircularLoader;
