import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import DeleteIcon from "@material-ui/icons/Delete";
import { Close } from "@material-ui/icons";
import { Button, Checkbox, Divider } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import InboxIcon from "@material-ui/icons/Inbox";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tooltip from "@material-ui/core/Tooltip";
import { formatExportFilename } from "util";
import { FETCH_EXPORTED_FILES } from "redux/constants/index.js";
import {
  deleteExportedFileByName,
  deleteSelectedExportedFiles,
  exportFile,
  exportSelectedFiles,
  fetchExportedFiles,
} from "redux/actions/services/index.js";
import { useQuery } from "@redux-requests/react";
import { EXPORT_STATUS } from "util/constants/Status.js";
import NoData from "components/NoData";
import CustomButton from "../../../components/CustomButton";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import ConfirmMessage from "../../../components/ConfirmMessage";
import CustomDialog from "../../../components/CustomDialog";
import clsx from "clsx";
import useStyles from "./style.js";

function ExportDialog({ exportOpen, setExportOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [exportedFiles, setExportedFiles] = React.useState([]);
  const exportedData = useQuery({ type: FETCH_EXPORTED_FILES });
  const [exportedLoading, setExportedLoading] = React.useState(false);
  const [openDeleteBatchConfirmation, setOpenDeleteBatchConfirmation] = React.useState(false);
  const [openDeleteSelectedConfirmation, setOpenDeleteSelectedConfirmation] = React.useState(false);
  const [filename, setFilename] = React.useState(null);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const handleConfirmDeleteSelected = () => {
    setExportedLoading(true);
    dispatch(deleteSelectedExportedFiles(selectedFiles, refreshExportedFiles));
    handleCloseSlide();
    setSelectedFiles([]);
  }
  const handleConfirmDeleteSelectedFile = () => {
    setExportedLoading(true);
    dispatch(deleteExportedFileByName(filename, refreshExportedFiles));
    handleCloseSlide();
    setSelectedFiles([]);
  }
  const handleCloseSlide = () => {
    setOpenDeleteBatchConfirmation(false);
    setOpenDeleteSelectedConfirmation(false);
  }
  React.useEffect(() => {
    setExportedFiles(exportedData.data);
  }, [exportedData]);

  const exportFileByName = (filename) => {
    dispatch(exportFile(filename));
  };
  const exportSelected = () => {
    if (selectedFiles.length === 1) {
      exportFileByName(selectedFiles[0]);
    } else {
      dispatch(exportSelectedFiles(selectedFiles));
    }
    setSelectedFiles([]);
  };
  const refreshExportedFiles = (withLoading = true) => {
    if (withLoading && !exportedLoading) {
      setExportedLoading(true);
    }
    dispatch(fetchExportedFiles(onFetchExportedDataSuccess));
  };
  const onDeleteExportedFile = (fileName) => {
    setFilename(fileName);
    setOpenDeleteSelectedConfirmation(true);
  };
  const deleteSelectedFiles = () => {
    setOpenDeleteBatchConfirmation(true);
  }
  const onFetchExportedDataSuccess = () => {
    setExportedLoading(false);
  }
  const exportNotificationsClass = (fileStatus) => {
    switch (fileStatus) {
        case EXPORT_STATUS.COLLECTING:
            return classes.collectingStatus;
        case EXPORT_STATUS.EXPORTING:
            return classes.exportingStatus;
        case EXPORT_STATUS.FINISHED:
            return classes.finishedStatus;
        default:
            return classes.defaultStatus;
    }
  }

  const isFileSelected = (file) => selectedFiles.includes(file?.fileName)

  const handleSelectClick = (row) => {
    if (isFileSelected(row)) {
      const filteredFiles = selectedFiles.filter((fileName) => fileName !== row?.fileName);
      setSelectedFiles(filteredFiles);
    } else {
      setSelectedFiles([...selectedFiles, row?.fileName]);
    }
  };

  const isAllSelected = () => exportedFiles?.length === selectedFiles.length;

  const handleAllSelect = () => {
    if (isAllSelected()) {
      setSelectedFiles([]);
    } else {
      const selectedFiles = exportedFiles?.map((file) => file?.fileName);
      setSelectedFiles(selectedFiles);
    }
  };
  const getIsDownloadValid = () => {
    const selectedFilesFiltered = exportedFiles?.filter((file) => selectedFiles.includes(file?.fileName) && file?.exportState !== EXPORT_STATUS.FINISHED);
    return selectedFiles.length > 0 && selectedFilesFiltered.length === 0;
  }
  const renderCase = () => {
    if (exportedFiles?.length > 0 && exportedLoading) {
      return (
        <Box marginTop="15px">
          <CircularLoader />
        </Box>
      )
    }
    return (
      <NoData message={t("exporting.no.data")} withVariant={false}>
        <InboxIcon className={classes.nodataIcon} />
      </NoData>
    )
  }
  return (
    <CustomDialog
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        classes={{
          paper: classes.dialogPaper,
        }}
        fullWidth
      >
      <Grid container justify="space-between" alignItems="start" className={classes.dialogHeader}>
        <Grid item>
          <Typography className={classes.title}>{t("exporting.title")}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setExportOpen(false)} className={classes.closeIcon}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Box className={classes.dialogContent}>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Box component={Button} onClick={refreshExportedFiles}>
              <RefreshIcon fontSize="small" className={classes.refreshIcon} />
              <Typography className={classes.refreshButtonText}>
                {t("dashboar.refresh")}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <CustomButton
                    view="primary"
                    disabled={!exportedData.data?.length > 0 || !getIsDownloadValid()}
                    startIcon={<VerticalAlignBottomIcon fontSize="small" />}
                    size="small"
                    classes={{ sizeSmall: classes.refreshBtn }}
                    onClick={exportSelected}
                  >
              {t("exporting.btn.download")}
            </CustomButton>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item>
            <IconButton onClick={deleteSelectedFiles} className={classes.deleteIcon} disabled={!selectedFiles.length || !exportedFiles?.length}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Checkbox
              id="checkbox"
              checked={isAllSelected() && exportedFiles?.length > 0}
              onChange={handleAllSelect}
              className={classes.selectCheckbox}
              disabled={exportedFiles?.length === 0}
            />
          </Grid>
        </Grid>
        {exportedFiles?.length > 0 && !exportedLoading ? (
                exportedFiles?.sort((fileA, fileB) => new Date(fileB?.createdAt) - new Date(fileA?.createdAt)).map((expFile) => (
                  <Box className={classes.fileContainer}>
                    <Grid container>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justify="space-between"
                      >
                        <Box
                          alignItems="center"
                          alignContent="center"
                          justifyContent="start"
                          display="flex"
                          flexDirection="row"
                          direction="row"
                          xs={7}
                          component={Grid}
                        >
                          <Grid item xs={1} alignItems="center" justifyContent="center">
                            <DescriptionOutlinedIcon className={classes.xlsFile} />
                          </Grid>
                          <Grid item alignItems="center" justifyContent="center" flexDirection="column">
                            <Tooltip title={expFile.fileName} placement="bottom">
                              <ButtonBase>
                                <Typography className={classes.fileName}>
                                  {formatExportFilename(expFile.fileName)}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                            <Box>
                              {expFile?.exportState === EXPORT_STATUS.EXPORTING && (
                                <LinearProgress color="primary" />
                              )}
                            </Box>
                          </Grid>
                        </Box>
                        <Box
                          xs={5}
                          component={Grid}
                          justifyContent="end"
                          alignItems="center"
                          flexDirection="row"
                          display="flex"
                        >
                          <Grid container item justify="center" xs={4} alignItems="center">
                            <Chip
                              variant="outlined"
                              label={t(expFile.exportState)}
                              size="small"
                              className={clsx(classes.status, exportNotificationsClass(expFile.exportState))}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              disabled={
                                expFile.exportState !== EXPORT_STATUS.FINISHED
                              }
                              color="primary"
                              onClick={() => exportFileByName(expFile.fileName)}
                            >
                              <VerticalAlignBottomIcon />
                            </IconButton>
                            <IconButton
                              className={classes.deleteIcon}
                              onClick={() => onDeleteExportedFile(expFile.fileName)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Checkbox
                              id="checkbox"
                              checked={isFileSelected(expFile)}
                              onChange={() => handleSelectClick(expFile)}
                              className={classes.selectCheckbox}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))
              ) : (
                renderCase()
              )}
      </Box>
      <ConfirmMessage
          message={t("export.confirm.msg.delete.selected")}
          openStart={openDeleteBatchConfirmation}
          onCancel={handleCloseSlide}
          onConfirm={handleConfirmDeleteSelected}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
      />
      <ConfirmMessage
          message={t("export.confirm.msg.delete.one")}
          openStart={openDeleteSelectedConfirmation}
          onCancel={handleCloseSlide}
          onConfirm={handleConfirmDeleteSelectedFile}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
      />
    </CustomDialog>
  );
}
export default ExportDialog;
