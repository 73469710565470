import React from "react";
import Box from "@material-ui/core/Box"
import FleetTable from "./FleetTable";
import PageHeader from "components/PageHeader";
import useStyles from "../../../components/TableComponents/style";

export default function FleetManagement() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="groups" />
        <FleetTable />
      </Box>
    </Box>
  );
}
