import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../FormFields/CustomAutoComplete";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "../../assets/common/searchicon.svg";
import InformationIcon from "components/InformationIcon";
import useStyles from "./style"

export const SearchFilter = ({
  callback,
  placeHolder,
  value,
  fullWidth = true,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const handleUrlParamChange = (e) => {
    callback(e);
  };

  return (
    <InformationIcon
          titleContent={t("enhanced.search.tooltip")}
          originalContent={(
            <CustomTextField
                color="primary"
                id="standard-adornment-search"
                type="text"
                fullWidth={fullWidth}
                value={value}
                onChange={(event) => handleUrlParamChange(event)}
                placeholder={placeHolder || t("Search")}
                InputProps={{ endAdornment: <SearchIcon className={classes.searchIcon} stroke={theme.palette.info.main} height={15} /> }}
            />
    )}
          placement="right"
    />
  );
};

export const useQuery = () => new URLSearchParams(useLocation().search);
