import React from "react";
import { get } from "lodash";
import { Controller, useFormState } from "react-hook-form";
import { TextField, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { CustomTextField } from "../../FormFields/CustomAutoComplete";

export default function TextFieldForm({
  fieldName,
  label,
  placeholder,
  shrink,
  fullWidth,
  type,
  disabled,
  rules,
  size,
  classes,
  helperText,
  readOnly,
  min,
  onBlur,
  onFocus,
  onInput,
  InputProps,
  maxLength,
  isCustom = false,
  format = (value) => value,
  onChange = () => "",
}) {
  const { errors } = useFormState();
  const TextFieldComponent = isCustom ? CustomTextField : TextField;

  const handleInputChange = (field, inputValue) => {
    if (type === "number") {
      inputValue = inputValue.replace(/[^0-9.]/g, "");
      inputValue = inputValue.replace(/^\.+/, "");
      const decimalCount = inputValue.split(".").length - 1;
      if (decimalCount > 1) {
        inputValue = inputValue.replace(/\.+$/, "");
      }
      if (inputValue !== "") {
        field.onChange(inputValue);
        onChange(inputValue);
        return;
      }
    }

    field.onChange(inputValue);
    onChange(inputValue);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        rules={rules}
        render={({ field }) => (
          <TextFieldComponent
            type={type}
            size={size}
            placeholder={placeholder}
            variant="standard"
            fullWidth={fullWidth}
            onChange={(e) => handleInputChange(field, e.target.value)}
            onBlur={(e) => {
              field.onBlur(e);
              if (onBlur) onBlur(fieldName);
            }}
            onFocus={() => {
              if (onFocus) onFocus(fieldName);
            }}
            onInput={(e) => {
              if (onInput) onInput(e);
            }}
            value={format(field.value)}
            inputRef={field.ref}
            disabled={disabled}
            error={Boolean(errors && get(errors, `${fieldName}.message`))}
            InputLabelProps={{
              shrink,
            }}
            classes={{
              root: classes,
            }}
            helperText={helperText}
            label={label}
            rows={5}
            InputProps={{
              ...InputProps,
              inputProps: {
                min,
                inputMode: type === "number" ? "decimal" : undefined,
                step: type === "number" ? "any" : undefined,
                pattern: type === "number" ? "[0-9]*" : undefined,
                maxLength
              },
              readOnly,
            }}
          />
        )}
      />

      {errors && get(errors, `${fieldName}.message`) && (
        <Typography variant="caption" color="error">
          {get(errors, `${fieldName}.message`)}
        </Typography>
      )}
    </FormControl>
  );
}
