import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "unset",
    height: "100%",
  },
  deleteIcon: {
    color: theme.palette.secondary.main,
  },
  tableCell: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: "2px 10px",
    fontWeight: "700",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`
  },
  paper: {
    backgroundColor: "unset",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  linkTable: {
    height: "370px",
  },
  searchContainer: {
    paddingTop: "17px !important",
    paddingBottom: "0px !important",
  },
  linkContainer: {
    height: "100%"
  },
  urlTableCell: {
    maxWidth: theme.spacing(38)
  },
  descriptionTableCell: {
    maxWidth: theme.spacing(15)
  },
  actionsTableCell: {
    maxWidth: theme.spacing(6)
  },
  urlText: {
    fontWeight: "700"
  },
  negativeLeftMargin: {
    marginLeft: theme.spacing(-2),
  }
}));
