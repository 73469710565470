import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({
  data, optionsChart, width, height,
}) => (
  <Line height={height} width={width} data={data} options={optionsChart} />
);

export default LineChart;
