import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steps: [],
  isStepsLoading: false,
  stepsLoaded: false,
  isProcessLoading: false,
  processLoaded: false,
  process: {},
};

const viewProcessSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {
    startStepsLoading: (state) => {
      state.isStepsLoading = true;
    },
    startProcessLoading: (state) => {
      state.isProcessLoading = true;
    },
    getProcess: (state, action) => {
      state.process = action.payload.process;
      state.isProcessLoading = false;
      state.processLoaded = true;
    },
    getSteps: (state, action) => {
      state.steps = action.payload.steps;
      state.stepsLoaded = true;
      state.isStepsLoading = false;
    },
    resetViewProcessData: (state) => {
      state.stepsLoaded = false;
      state.processLoaded = false;
      state.steps = [];
      state.process = [];
    },
  },
});

// Processes reducer
export default viewProcessSlice.reducer;

// Actions
export const {
  startStepsLoading,
  startProcessLoading,
  getProcess,
  getSteps,
  resetViewProcessData,
} = viewProcessSlice.actions;

// load process async
// load process async
