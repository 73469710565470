import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatDatePickerByLanguage } from "util";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import AvatarTableCell from "../../../components/TableComponents/AvatarTableCell";
import useStyles from "../style";

export default function Audit({ row }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <CustomTableRow>
      <CustomTableCell title={t(row?.module)} />
      <CustomTableCell text={t(row?.action)} />
      <CustomTableCell text={row?.objectLabel} />
      <CustomTableCell
        text={
          <Box
            display="grid"
            gridTemplateColumns="32px 1fr"
            alignItems="center"
            gridGap="7px"
            className={classes.userName}
          >
            <AvatarTableCell
              indexRow={0}
              valPath={null}
              valAvatarPath={null}
              indexField={null}
            />
            <Typography component="span">
              {row?.username}
            </Typography>
          </Box>
        }
      />
      <CustomTableCell text={format(parseISO(row?.timestamp), `${formatDatePickerByLanguage(false)} HH:mm`)} />
    </CustomTableRow>
  );
}
