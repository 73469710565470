import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButton";

const ClearFilter = ({ clearFilter }) => {
  const { t } = useTranslation();
  return (
    <CustomButton view="cancelModal" onClick={clearFilter}>
      {t("Clear")}
    </CustomButton>
  );
}

export default ClearFilter;
