import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid"
import { Box, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton"
import Add from "@material-ui/icons/Add";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { kpiTypes } from "util";
import clsx from "clsx";
import CustomButton from "components/CustomButton";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomDialog from "../../../../../components/CustomDialog";
import useStyles from "../../../style.js";

const forEachValues = [
  { key: "custom.kpi.item", value: "item" },
  // { key: "custom.kpi.processExecution", value: "processExecution" },
  // { key: "custom.kpi.process", value: "process" },
];

const detailValues = [
  // { key: "custom.kpi.pending", value: "pending", uses: ["item", "processExecution"] },
  { key: "custom.kpi.completed", value: "completed", uses: ["item"/* , "processExecution" */] },
  { key: "custom.kpi.completedWithException", value: "completedWithException", uses: ["item"/* , "processExecution" */] },
  { key: "custom.kpi.completedWithBusinessException", value: "completedWithBusinessException", uses: ["item"] },
  { key: "custom.kpi.completedWithIntSysException", value: "completedWithIntSysException", uses: ["item"] },
  // { key: "custom.kpi.executionTimeHours", value: "executionTimeHours", uses: ["process", "item", "processExecution"] },
  // { key: "custom.kpi.timeSavedHours", value: "timeSavedHours", uses: ["process", "item", "processExecution"] },
];

function CreateKpiDialog({
  open, onClose, handleCreateOrUpdateCustomKpi, editedCustomKpi, customKpiDialogMode,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [kpiType, setKpiType] = React.useState(editedCustomKpi?.kpiType ?? null);
  const [uniteOfMeasure, setUniteOfMeasure] = React.useState(editedCustomKpi?.customKpiDetails[0].unitOfMeasure || "");

  const {
    formState: { errors }, register, handleSubmit, reset, control, setValue, watch,
  } = useForm({
      defaultValues: {
        kpis: editedCustomKpi?.customKpiDetails?.map((kpi) => ({ ...kpi })) || [{
          element: "",
          detail: "",
          allocation: "",
          unitOfMeasure: "",
        }],
        kpiName: editedCustomKpi?.name || "",
        uniteOfMeasure: editedCustomKpi?.customKpiDetails[0].unitOfMeasure || "",
        kpiType: editedCustomKpi?.kpiType || null,
      },
    });

  const onSubmit = () => {
    const kpis = watch("kpis");
    const kpiData = {
      name: watch("kpiName"),
      kpiType: watch("kpiType"),
      id: customKpiDialogMode === "edit" ? editedCustomKpi?.id : null,
      customKpiDetails: kpis?.map((kpi) => ({
        id: kpi?.id || null,
        element: kpi.element,
        detail: kpi.detail,
        allocation: kpi.allocation,
        unitOfMeasure: uniteOfMeasure,
      })),
    }
    handleCreateOrUpdateCustomKpi(kpiData);
    setUniteOfMeasure(uniteOfMeasure || "")
    reset();
    resetFields();
    onClose();
  }

  const resetFields = () => {
    setValue("kpis", [{
      element: "",
      detail: "",
      allocation: "",
      unitOfMeasure: "",
    }]);
    setValue("kpiName", "");
    setValue("uniteOfMeasure", "");
    setValue("kpiType", null);
    setKpiType(null);
    setUniteOfMeasure("");
  }

  const onTextChange = (value, label, index) => {
    const updatedKpis = [...watch("kpis")];
    updatedKpis[index][label] = value;
    setValue("kpis", updatedKpis);
  }

  const handleAllocationChange = (event, index) => {
    const newValue = event.target.value;
    // Will update text only if it passes regex test
    if (/^[0-9]*$/.test(newValue)) {
      onTextChange(event.target.value, "allocation", index);
    }
  };

  const onKpiRemove = (index) => {
    const updatedKpis = watch("kpis");
    updatedKpis.splice(index, 1);
    reset();
    setValue("kpis", updatedKpis);
  }

  const onKpiNameChange = (event) => {
    setValue("kpiName", event.currentTarget.value);
  }

  React.useEffect(() => {
    if (customKpiDialogMode === "edit" && editedCustomKpi) {
      setValue("kpiName", editedCustomKpi?.name || "");
      setValue("kpiType", editedCustomKpi?.kpiType || null);
      setValue("uniteOfMeasure", editedCustomKpi?.customKpiDetails[0]?.unitOfMeasure || "");
      setValue("kpis", editedCustomKpi?.customKpiDetails?.map((kpi) => ({ ...kpi })) || []);
      setUniteOfMeasure(editedCustomKpi?.customKpiDetails[0]?.unitOfMeasure || "");
      setKpiType(editedCustomKpi?.kpiType || null);
    }
  }, [customKpiDialogMode, editedCustomKpi, open]);

  const handleAddClick = () => {
    setValue("kpis", [...watch("kpis"), {
        element: "",
        detail: "",
        allocation: "",
        unitOfMeasure: "",
    }])
  };

  const handleCancelClick = () => {
    resetFields()
    reset();
    onClose();
  }

  return (
    <CustomDialog
      title={customKpiDialogMode === "create" ? t("custom.kpi.dialog.create.label") : t("custom.kpi.dialog.create.label.edit")}
      open={open}
      onClose={handleCancelClick}
      disableBackdropClick
      fullWidth
      maxWidth={900}
      className={classes.createKpiDialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomTextField
                label={t("custom.kpi.dialog.field.name")}
                fullWidth
                onChange={(event) => onKpiNameChange(event)}
                value={watch("kpiName")}
                className={classes.kpiNameTextField}
                {...register("kpiName", {
                  required: "custom.kpi.formControl.required"
                })
                }
                error={!!errors?.kpiName}
                helperText={t(errors?.kpiName?.message)}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              rules={{
                required: "custom.kpi.formControl.required"
              }}
              name="kpiType"
              control={control}
              render={() => (
                <CustomAutoComplete
                    options={Object.keys(kpiTypes)}
                    fullWidth
                    id="kpiType"
                    label={t("custom.kpi.dialog.field.type")}
                    value={kpiType}
                    className={classes.kpiNameTextField}
                    error={!!errors?.kpiType}
                    helperText={t(errors?.kpiType?.message)}
                    onChange={(event) => {
                      setKpiType(event)
                      setValue("kpiType", event)
                    }}
                  />
              )}
          />
          </Grid>
        </Grid>
        {watch("kpis")?.map((kpi, index) => (
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Controller
                rules={{
                  required: "custom.kpi.formControl.required"
                }}
                name={`kpis.[${index}].element`}
                control={control}
                render={(field) => (
                  <CustomAutoComplete
                    {...field}
                    options={forEachValues}
                    id="element"
                    label={t("custom.kpi.dialog.field.element")}
                    value={forEachValues.find((item) => item.value === kpi.element)}
                    optionUuid="value"
                    optionLabel={(option) => t(option.key)}
                    onChange={(event) => {
                      onTextChange(event?.value, "element", index)
                    }}
                    error={!!errors?.kpis?.[index]?.element}
                    helperText={t(errors?.kpis?.[index]?.element?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                rules={{
                  required: "custom.kpi.formControl.required"
                }}
                name={`kpis.[${index}].detail`}
                control={control}
                render={(field) => (
                  <CustomAutoComplete
                    {...field}
                    options={detailValues?.filter((detail) => detail.uses.includes(kpi.element))}
                    id="detail"
                    label={t("custom.kpi.dialog.field.detail")}
                    value={detailValues?.filter((detail) => (detail.uses.includes(kpi.element))).find((item) => item.value === kpi.detail)}
                    optionLabel={(option) => t(option.key)}
                    onChange={(event) => {
                      onTextChange(event?.value, "detail", index)
                    }}
                    disabled={kpi.element === ""}
                    error={!!errors?.kpis?.[index]?.detail}
                    helperText={t(errors?.kpis?.[index]?.detail?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomTextField
                  {...register(`kpis.${index}.allocation`, {
                    required: "custom.kpi.formControl.required",
                    pattern: {
                        value: /^[0-9]*$/,
                        message: "custom.kpi.formControl.pattern",
                    },
                  })}
                  value={kpi.allocation}
                  onChange={(event) => handleAllocationChange(event, index)}
                  id="allocation"
                  label={t("custom.kpi.dialog.field.allocation")}
                  fullWidth
                  defaultValue={kpi.allocation}
                  error={!!errors?.kpis?.[index]?.allocation}
                />
              <p className={classes.validation_error}>
                {t(errors?.kpis?.[index]?.allocation?.message)}
              </p>
            </Grid>
            <Grid item xs={3}>
              <CustomTextField
                  id="unitOfMeasure"
                  disabled={index > 0}
                  label={t("custom.kpi.dialog.field.unitofmesure")}
                  fullWidth
                  value={uniteOfMeasure}
                  {...register("uniteOfMeasure", {
                    required: "custom.kpi.formControl.required"
                  })
                  }
                  onChange={(event) => setUniteOfMeasure(event.currentTarget.value)}
                  error={!!errors?.uniteOfMeasure}
                  helperText={t(errors?.uniteOfMeasure?.message)}
                />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => onKpiRemove(index)} disabled={watch("kpis").length === 1} className={watch("kpis").length > 1 ? classes.deleteKpiButton : clsx(classes.deleteKpiButton, classes.disabledColor)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
            {watch("kpis").length === index + 1 && (
              <Grid item xs={1}>
                <IconButton onClick={handleAddClick} className={classes.addKpiButton}>
                  <Add />
                </IconButton>
              </Grid>)}
          </Grid>
          ))}
        <Grid container direction="row" alignItems="flex-end" justify="flex-end" style={{ paddingTop: "1.5rem" }}>
          <Box
            ml="1rem"
            component={Button}
            variant="contained"
            size="medium"
            onClick={handleCancelClick}
            className={classes.cancelButton}
          >
            {t("Cancel")}
          </Box>
          <CustomButton
            view="primary"
            size="medium"
            type="submit"
            id="submit"
          >
            {customKpiDialogMode === "create" ? t("custom.kpi.dialog.create.field.submit") : t("custom.kpi.dialog.create.field.edit")}
          </CustomButton>
        </Grid>
      </form>
    </CustomDialog>
  );
}

export default (CreateKpiDialog);
