import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    rpaHeading: {
        fontWeight: 400,
        marginBottom: theme.spacing(3),
    },
    myCasesHeading: {
        fontWeight: 400,
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(6),
    },
    addBtn: {
        marginRight: theme.spacing(2),
    },
    myCasesContainer: {
        marginTop: theme.spacing(8),
    },

    marginRight: {
        marginRight: theme.spacing(2),
    },
    pageHeaderContainer: {
        padding: theme.spacing(0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 2),
        },
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    sectionHeader: {
        marginBottom: theme.spacing(2),
    },
    archiveButtonContainer: {
        height: "100%",
        marginLeft: "10px",
    },
    radioInput: {
        width: "25%",
        marginBottom: "2%",
        marginRight: "2%",
    },
    pageHeader: {
        fontWeight: 700
    },
    showArchivedSwitch: {
        marginBottom: theme.spacing(-2.5),
    },
    mainContainer: {
        padding: theme.spacing(3, 1)
    }
}));
