import React, { useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Query } from "@redux-requests/react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { isFleetAdministrator } from "util";
import { USERS_BASE_URL } from "util/helpers/route.helper";
import { FETCH_USERS } from "../../../../redux/constants";
import { updateFilter } from "../../../../redux/slices/adminFilterSlice.js";
import { fetchUsers } from "../../../../redux/actions/services";
import CustomPagination from "pages/Services/components/CustomPagination/index.js";
import CustomTable from "../../../../components/TableComponents/CustomTable";
import ManageRoles from "pages/Administration/UserManagement/RolesManagement/ManageRoles";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import UserTableHeader from "./UserTableHeader";
import UserTableRow from "./UserTableRow";
import AdminTableFilter from "../../AdminTable/AdminTableFilter";
import DataNotFound from "../../../../components/DataNotFound";
import UserNotFound from "../../../../assets/No_Users.svg";

export default function UserTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showTableHeader, setShowTableHeader] = React.useState(true);
  const [countUsers, setCountUsers] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [openRoleList, setOpenRoleList] = useState(false);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = React.useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const fetchEntities = fetchUsers;
  const statusList = [
    { code: "ACTIVE", label: "ACTIVE" },
    { code: "DISABLE", label: "DISABLE" },
  ];
  const sortByList = [
    { code: "firstName", label: "firstName" },
    { code: "lastName", label: "lastName" },
    { code: "fleet", label: "user.management.formLabel.fleet" },
  ];
  const getStatusColor = (status) => {
    switch (status.code) {
      case "ACTIVE":
        return "ACTIVE";
      case "DISABLE":
        return "OFFLINE";
      default:
        return "OFFLINE";
    }
  };

  const handleChangeStatus = (event) => {
    const statuses = event?.map(({ code }) => code);
    dispatch(
      updateFilter({
        status: statuses,
        pageNo: 0,
      }),
    );
  };

  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      }),
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      }),
    );
  };

  const handleSelectedAll = (checked) => {
    if (!checked) {
      const filteredData = selected.filter((fd) => !fetchedData.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(fetchedData);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleChangeShowDeleted = (event) => {
    dispatch(
      updateFilter({
        showDeleted: event.target.checked,
      }),
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countUsers - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));

  const handleNextPage = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
  };
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  };
  const handleChangeFleet = (values) => {
    dispatch(
        updateFilter({
          fleets: values,
          pageNo: 0,
        }),
    );
  };

  return (
    <>
      <Box>
        <AdminTableFilter
            statusList={statusList}
            sortByList={sortByList}
            pageSize={filter.pageSize}
            fetchEntities={fetchEntities}
            searchLabel="Search"
            searchField="userName"
            handleChangeStatus={handleChangeStatus}
            handleFilterValueChange={handleFilterValueChange}
            handleChangeSortBy={handleChangeSortBy}
            entityBaseUrl={USERS_BASE_URL}
            showAddIcon={isFleetAdministrator(currentUser)}
            addButtonText={t("user.management.add.button")}
            handleChangeShowDeleted={handleChangeShowDeleted}
            isUserAdministrationPage
            handleChangeFleets={handleChangeFleet}
            handleRolesListOpen={() => setOpenRoleList(true)}
            getStatusColor={getStatusColor}
        />
        <CustomTableContainer>
          <CustomTable>
            {showTableHeader && (
              <UserTableHeader
                  selected={selected}
                  setSelected={setSelected}
                  handleSelectedAll={handleSelectedAll}
                  checkedAll={
                      fetchedData?.length > 0
                      && isAllSelected
                  }
                  checkedIndeterminate={
                      fetchedData?.length > 0
                      && !isAllSelected
                      && fetchedData.some((d) => selected.includes(d))
                  }
                  fetchedData={fetchedData}
                  fetchEntities={fetchEntities}
                  page={filter[filter.currModule].pageNo}
                  searchCriteria={filter[filter.currModule].searchCriteria}
                  rowsPerPage={filter[filter.currModule].pageSize}
                  showDeleted={filter[filter.currModule].showDeleted}
                  statusList={statusList}
              />
            )}
            <Query type={FETCH_USERS} loadingComponent={CircularLoader}>
              {({ data }) => {
                if (!data?.content?.length) setCountUsers(0);
                setFetchedData(data?.content);
                const length = data.totalElements;
                setCountUsers(length);
                data?.content?.length > 0
                    ? setShowTableHeader(true)
                    : setShowTableHeader(false);
                return data?.content?.length > 0 ? (
                    data.content?.map((row, index) => (
                      <UserTableRow
                            key={`user-table-${index}`}
                            row={row}
                            index={index}
                            handleClick={() => {
                              history.push(`${USERS_BASE_URL}/${get(row, "id")}`);
                            }}
                            selected={selected}
                            setSelected={setSelected}
                            entityBaseUrl={USERS_BASE_URL}
                            fetchEntitiesReset={fetchEntitiesReset}
                            fetchEntities={fetchEntitiesWithFilter}
                            open={open}
                        />
                    ))
                ) : (
                  <DataNotFound
                        message={t("no.users.message")}
                        icon={UserNotFound}
                    />
                );
              }}
            </Query>
          </CustomTable>
        </CustomTableContainer>
        {showTableHeader && (
        <CustomPagination
                rowsPerPageOptions={[5, 10, 25]}
                count={countUsers ?? 0}
                page={filter[filter.currModule].pageNo}
                rowsPerPage={filter[filter.currModule].pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                onNext={handleNextPage}
                onPrevious={handlePrevious}
            />
        )}
      </Box>
      {openRoleList && (
      <ManageRoles open={openRoleList} handleDialogClose={() => setOpenRoleList(false)} />
      )}
    </>
  );
}
