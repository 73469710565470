import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: fade(theme.palette.background.default, 0.8),
    overflowX: "hidden",
    overflowY: "hidden",
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  DialogContentRoot: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tabs: {
    backgroundColor: theme.palette.primary.main,
  },
  cardHeader: {
    textAlign: "center",
  },

  span: {
    backgroundColor: theme.palette.grey[200],

    width: "30%",
  },
  content: {
    justifyContent: "center",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),

  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  footer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  forgotTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    textAlign: "right",
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableCell: {
    minWidth: 300,
    fontSize: 15,
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1, 0, 3, 0),
    textTransform: "none",
    width: "230px",
    height: "60px",
    backgroundColor: "#0284fe",
    color: "#fff",
    fontSize: "18px",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#0284fe",
      border: "1px solid #0284fe",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      transition: "0.5s",
    },
  },
  visibilityButton: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: "primary",
    textDecoration: "none",
  },
  card: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  error: {
    color: "red",
  },
  success: {
    color: "green",
  },
  inputField: {
    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
  },
  localeRoot: {
    position: "absolute",
    width: "200px",
    top: "20px",
    right: "20px",
    textAlign: "right",
    zIndex: 9999,
    "& ul": {
      display: "none",
      listStyle: "none",
      position: "absolute",
      backgroundColor: "#fff",
      right: 0,
      top: "20px",
      minWidth: "100px",
      padding: "2px 0",
      border: "solid 1px #bbb",
    },
    "&:hover ul": {
      display: "block",
      margin: 0,
    },
    "& ul li": {
      display: "block",
      padding: "5px 14px",
      color: "#000",
      textDecoration: "none",
      lineHeight: "20px",
      textAlign: "left",
      cursor: "pointer",
    },
    "& ul li:hover": {
      backgroundColor: "#d4edfa",
    },
    currentLocale: {
      display: "block",
      padding: "0 5px",
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.2)",
      },
    },
  },
}));
