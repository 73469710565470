import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../style/orchestratorTheme";

export default makeStyles((theme) => ({
  rootGlobal: {
    padding: theme.spacing(2, 0),
  },
  tableContainer: {
    padding: theme.spacing(0, 1)
  },
  toggleMenu: {
    paddingTop: 15,
  },
  userName: {
    fontFamily,
  },
  timePeriodField: {
    marginTop: -1.5
  }
}));
