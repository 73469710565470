import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  deleteUser,
  enableOrDisableUser,
} from "../../../../../redux/actions/services";
import {
  formatDateByLanguage,
  isFleetAdministrator,
} from "util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import AvatarTableCell from "../../../../../components/TableComponents/AvatarTableCell";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import useStyles from "../../../../../components/TableComponents/style";

const displayFields = [
  {
    id: "status",
    valuePath: "status",
    customContent: ({ valuePath }) => <StatusBadge level={valuePath} />,
    width: 50,
  },
  {
    id: "logo",
    valuePath: "",
    avatarPath: "logo",
    customContent: ({
 indexRow, valuePath, indexField, valueAvatarPath
}) => (
  <AvatarTableCell
        indexRow={indexRow}
        valPath={valuePath}
        valAvatarPath={valueAvatarPath}
        indexField={indexField}
      />
    ),
  },
  {
    id: "name",
    valuePath: ["civility.code", "firstName", "lastName"],
  },
  {
    id: "creationDate",
    valuePath: "creationDate",
    defaultValue: "--:--:--",
    format: (v) => formatDateByLanguage(v, false),
  },
  {
    id: "email",
    valuePath: "email",
  },
  {
    id: "fleet",
    valuePath: "fleet.companyName",
  },
  {
    id: "lastLogin",

    valuePath: "lastLogin",
    defaultValue: "---",
    format: (v) => formatDateByLanguage(v),
  },
];
export default function UserTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableUser(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
        setOpenConfirmationPopup(false);
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteUser(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        if (res?.data === 0) {
          toast.success(t("users deleted successfully"))
          setTimeout(() => fetchEntitiesReset(), 1500);
        } else toast.error(t("user has exception item"))
      } else if (res?.response?.data?.title) toast.error(t(res?.response?.data?.title));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const getFieldValue = (row, field) => {
    const value = Array.isArray(field.valuePath)
      ? field.valuePath.reduce(
          (acc, currentValue) => `${acc} ${t(getFieldData(currentValue))}`.trim(),
          "",
        )
      : getFieldData(field.valuePath);
    if (!value && field.defaultValue) return field.defaultValue;
    if (field.format) return field.format(value);
    return value;
  };

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (field?.customContent ? (
          <CustomTableCell width={field?.width}>
            {field.customContent({
                valuePath: getFieldData(field?.valuePath),
                indexRow: getFieldData("id"),
                indexField: get(field, "id"),
                valueAvatarPath: getFieldData(field?.avatarPath),
              })}
          </CustomTableCell>
          ) : (
            <CustomTableCell
              key={i}
              text={getFieldValue(row, field)}
            />
          )),)}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {isFleetAdministrator(currentUser) && (
              <Grid item className={classes.switchContainer}>
                <Tooltip
                  placement="top"
                  title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                >
                  <CustomSwitch
                    id="user-admin-enable-disable-switch"
                    checked={active}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    handleChange={() => {
                      setOpenConfirmationPopup(true);
                    }}
                    disabled={isCurrentUser || getFieldData("isInstanceOwner")}
                    value={active}
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    size="small"
                    labelClassName={classes.switchLabel}
                  />
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.edit")}>
                  <CustomEditIcon
                    id="user-admin-edit-btn"
                    className={classes.rowActionButtons}
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `${entityBaseUrl}/edit/${getFieldData("id")}`,
                      );
                    }}
                  />
                </Tooltip>
              </Grid>
            )}

            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <CustomDeleteIcon
                    id="user-admin-delete-btn"
                    className={classes.rowActionButtons}
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                  />
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Checkbox
                  id="user-admin-select-checkbox"
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected?.map((item) => item?.id)?.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(row)
                      : list.splice(
                          list.findIndex((item) => item.id === parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("user.management.desaDes.confirmMsg")
            : t("user.management.enaDes.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("user.management.desaDes.button.confirm")
            : t("user.management.enaDes.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={t("user.management.delete.confirmMsg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
