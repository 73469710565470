import { fade, makeStyles } from "@material-ui/core/styles";

const useStyles = () => makeStyles((theme) => ({
    settingsIcon: {
      color: theme.palette.primary.main,
      fontSize: "1.5rem",
    },
    radio: {
      color: theme.palette.primary.main,
      "&.Mui-checked": {
        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
          color: theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
          color: theme.custom.color.color6,
        },
      },
    },
    dialogDefaultText: {
      fontSize: "1.1rem",
      fontWeight: 700,
      color: fade(theme.palette.primary.main, 0.6),
    },
    copyHelper: {
      fontSize: "1.1rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    textField: {
      marginTop: 15,
    },
    controlLabel: {
      fontSize: "1.05rem",
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginLeft: 15,
    },
    switchControl: {
      margin: "10px 0",
    },
    tableHeaderCell: {
      fontSize: "0.9rem",
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    dataMappingTableBody: {
      backgroundColor: theme.custom.color.containerBackground,
      maxHeight: 300,
    },
    dataMappingTableContainer: {
      maxHeight: "70vh",
      margin: "0 0 20px 0",
    },
    divider: {
      height: 2,
      width: "100%",
      backgroundColor: theme.custom.color.staticInput,
      borderRadius: 9,
      margin: "40px 0 40px 0",
    },
    verticalDivider: {
      width: 2,
      height: "100%",
      backgroundColor: theme.custom.color.staticInput,
      borderRadius: 9,
    },
    executionTimeTitle: {
      fontSize: 13,
      color: theme.custom.color.color6,
      fontWeight: 600,
    },
    daysContainer: {
      backgroundColor: theme.custom.color.containerBackground,
      padding: 15,
      marginTop: 10,
    },
    keepPolicyContainer: {
      display: "grid",
      gridTemplateColumns: "8FR 1FR 2FR 1FR",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "10px",
      width: "65%",
      marginTop: 15,
    },
    hourTypography: {
      display: "flex",
    },
    inputLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    infoIcon: {
      fontSize: 13,
      color: theme.custom.color.color6,
    },
  }));

export default useStyles;
