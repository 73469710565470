import { getDefaultFormat } from "util";
import { CriteriaTypes, TimeGranularity, TimeSpentCriteria } from "util/constants";

export const buildRouteName = (str) => str.replace(/\s/g, "").toLowerCase();

const initialValue = {
  mode: "create",
  steps: [],
  qualifications: [],
  activeStep: 0,
  data: [],
  result: null,
  process: null,
};

export const formatQualificationData = (res) => {
  const returnedValue = initialValue
  returnedValue.steps = res
  const data = [];
  if (returnedValue.mode === "create") {
      // init data
      returnedValue.steps.forEach((step) => {
      const formData = {};
      step.criterias.forEach((criteria) => {
          switch (criteria.type) {
          case CriteriaTypes.Date:
              formData[buildRouteName(criteria.name)] = new Date().toJSON();
              break;
          case CriteriaTypes.Boolean:
              formData[buildRouteName(criteria.name)] = false;
              break;
          default:
              formData[buildRouteName(criteria.name)] = "";
              break;
          }
          formData[`${buildRouteName(criteria.name)}-comment`] = "";
      });
      data.push(formData);
      });
  } else {
      returnedValue.steps.forEach((step) => {
      const formData = {};
      step.criterias.forEach((criteria) => {
          switch (criteria.type) {
          case CriteriaTypes.Date:
              // eslint-disable-next-line prefer-destructuring
              formData[buildRouteName(criteria.name)] = new Date(
              returnedValue.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
              ).value,
              )
              .toJSON()
              .split("T")[0];
              break;
          case CriteriaTypes.Boolean:
              formData[buildRouteName(criteria.name)] = returnedValue.process.qualifications.find(
              (q) => q.criteria.id === criteria.id,
              ).assessment.name === "true";
              break;
          case CriteriaTypes.String:
              if (criteria.assessments.length > 0) {
              formData[buildRouteName(criteria.name)] = returnedValue.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
              ).assessment
                  ? returnedValue.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
                  ).assessment.id
                  : "";
              } else {
              formData[buildRouteName(criteria.name)] = returnedValue.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
              ).value;
              }
              break;
          default:
              formData[buildRouteName(criteria.name)] = returnedValue.process.qualifications.find(
              (q) => q.criteria.id === criteria.id,
              ).value;
              break;
          }
          formData[`${buildRouteName(criteria.name)}-comment`] = returnedValue.process.qualifications.find(
          (q) => q.criteria.id === criteria.id,
          ).comment;
      });
      data.push(formData);
      });
  }
  returnedValue.data = data;
  return returnedValue
}

export const saveData = (data, newData, activeStep) => {
  data[activeStep] = newData
}

export const calculateTotalTimeSpent = (data, steps) => {
  const timeSpentOneExcecution = data[2][
    buildRouteName(TimeSpentCriteria.TimeSpentForOneExection)
  ];
  const timeGranularityId = data[2][buildRouteName(TimeSpentCriteria.TimeGranularity)];
  const occurenceTimeGranularityId = data[2][
    buildRouteName(TimeSpentCriteria.OccurenceTimeGranularity)
  ];

  const timeGranularity = steps[2]?.criterias?.find((criteria) => criteria.name === TimeSpentCriteria.TimeGranularity)
    ?.assessments?.find(
      (assessment) => assessment.id === timeGranularityId,
    )?.name;
  const occurenceTimeGranularity = steps[2]?.criterias && steps[2].criterias
    ?.find(
      (criteria) => criteria?.name === TimeSpentCriteria.OccurenceTimeGranularity,

    )
    .assessments?.find(
      (assessment) => assessment.id === Number(occurenceTimeGranularityId),
    )?.name;

  const occurrence = getDefaultFormat(data[2][buildRouteName(TimeSpentCriteria.Occurrence)]);
  let totalTimeSpent = 0;
  let frequency = 0;

  switch (occurenceTimeGranularity?.toLowerCase()) {
    case TimeGranularity.Hours:
      frequency = 1760 / occurrence;
      break;
    case TimeGranularity.Days:
      frequency = 220 / occurrence;
      break;
    case TimeGranularity.Weeks:
      frequency = 52 / occurrence;
      break;
    case TimeGranularity.Months:
      frequency = 12 / occurrence;
      break;
    case TimeGranularity.Years:
      frequency = 1 / occurrence;
      break;

    default:
      // Nothing to do :-)
      break;
  }

  switch (timeGranularity?.toLowerCase()) {
    case TimeGranularity.Seconds:
      totalTimeSpent = timeSpentOneExcecution * frequency * 0.000277777777777778;
      break;
    case TimeGranularity.Minutes:
      totalTimeSpent = timeSpentOneExcecution * frequency * 0.0166666666666667;
      break;
    case TimeGranularity.Hours:
      totalTimeSpent = timeSpentOneExcecution * frequency * 1;
      break;
    case TimeGranularity.Days:
      totalTimeSpent = timeSpentOneExcecution * frequency * 8;
      break;
    default:
      // Nothing to do :-)
      break;
  }

  data[3][
    buildRouteName(TimeSpentCriteria.TotalTimeSpentInHoursPerYear)
  ] = totalTimeSpent;
}
