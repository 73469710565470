export const workflowStatus = {
  READY: "READY",
  TAKEN: "TAKEN",
  DONE: "DONE",
};

export const workflowActions = {
  TAKE: "TAKE",
  ASSIGN: "ASSIGN",
  MARK_AS_DONE: "MARK_AS_DONE",
  RELEASE: "RELEASE",
  REOPEN: "REOPEN",
};

export const getWorkflowStatusColor = (status) => {
  switch (status) {
    case workflowStatus.READY:
      return "#DD4124";
    case workflowStatus.TAKEN:
      return "#303167";
    case workflowStatus.DONE:
      return "#009B77";
    default:
      return "";
  }
};

export const getWorkflowStatusFromAction = (action) => {
  switch (action) {
    case workflowActions.TAKE:
      return workflowStatus.TAKEN;
    case workflowActions.ASSIGN:
      return workflowStatus.TAKEN;
    case workflowActions.MARK_AS_DONE:
      return workflowStatus.DONE;
    case workflowActions.RELEASE:
      return workflowStatus.READY;
    case workflowActions.REOPEN:
      return workflowStatus.READY;
    default:
      return "";
  }
};

const isTheActualUser = (items, currentUser) =>
  // TODO check with === (use parseInt)
  // eslint-disable-next-line
     items.every(item => item?.userInCharge?.id == currentUser?.id);
const isNotTheActualUser = (items, currentUser) => items.every((item) => item?.userInCharge?.id !== currentUser?.id);

export const isSelectionOkforBulkActions = (items, currentUser) => {
  /*
        Bulk actions are allowed when all selected items:
        - Have the same status. -> Done
        - Have ready and taken(from different users) status
        - Are not done.
        - Taken by the same user if they're taken.
    */
  const selectionOkforBulkActions = {
    TAKE: false,
    MARK_AS_DONE: false,
    RELEASE: false,
    REOPEN: false,
    ASSIGNEE: false,
  };
  if (!items || !items.length) return selectionOkforBulkActions;
  const sameStatus = items.every((item) => item.workflowStatus === items[0].workflowStatus);
  if (sameStatus && items[0].workflowStatus === workflowStatus.TAKEN) {
    if (sameStatus && isTheActualUser(items, currentUser)) {
      selectionOkforBulkActions.MARK_AS_DONE = true;
      selectionOkforBulkActions.RELEASE = true;
    } else if (sameStatus && isNotTheActualUser(items, currentUser)) {
      selectionOkforBulkActions.TAKE = true;
    }
  } else if (sameStatus && items[0].workflowStatus === workflowStatus.READY) {
    selectionOkforBulkActions.ASSIGNEE = true;
    selectionOkforBulkActions.TAKE = true;
  } else if (sameStatus && items[0].workflowStatus === workflowStatus.DONE) {
    selectionOkforBulkActions.REOPEN = sameStatus;
  }
  return selectionOkforBulkActions;
};
