/* eslint-disable import/prefer-default-export */
import {
  DELETE_ORCHESTRATION, FETCH_ALL_ORCHESTRATIONS,
  FETCH_ORCHESTRATION_BY_ID, FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID, SAVE_ORCHESTRATION, UPDATE_ORCHESTRATION,
  UPDATE_ORCHESTRATION_STATUS, START_ORCHESTRATION
} from "redux/constants";
import { URL_ORCHESTRATIONS } from "redux/constants/endpoints";

export const fetchOrchestrationsList = (filter = {}) => {
  const {
      searchCriteria,
      pageNo,
      pageSize,
      status,
      sortBy,
      showDeleted,
      orchestrator,
      processesIds
  } = filter;
  return {
      type: FETCH_ALL_ORCHESTRATIONS,
      request: {
          url: encodeURI(`${URL_ORCHESTRATIONS}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
          method: "GET",
          params: {
              page: pageNo,
              size: pageSize,
              sort: sortBy,
              status: status ? status.join() : null,
              showHidden: showDeleted,
              orchestratorId: orchestrator?.id,
              processesIds: processesIds?.join(),
          },
      },
  };
};

export const fetchOrchestrationById = (id) => ({
    type: FETCH_ORCHESTRATION_BY_ID,
    request: {
      url: `${URL_ORCHESTRATIONS}/${id}`,
      method: "GET",
    },
  });

export const saveOrchestration = (orchestration, onSuccess, onError) => ({
    type: SAVE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}`,
      method: "POST",
      data: orchestration,
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

export const updateOrchestration = (id, orchestration, onSuccess, onError) => ({
    type: UPDATE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}/${id}`,
      method: "PUT",
      data: orchestration,
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

export const deleteOrchestrations = (ids, onSuccess, onError) => ({
    type: DELETE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}?ids=${ids.join(",")}`,
      method: "DELETE",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess && response.status === 200) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    }
  });
export const updateOrchestrationStatus = (id, status, onSuccess, onError) => ({
    type: UPDATE_ORCHESTRATION_STATUS,
    request: {
      url: `${URL_ORCHESTRATIONS}/status/${id}`,
      method: "PUT",
      params: { status },
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

  export const fetchWorkQueuesByOrchestratorId = (id) => ({
    type: FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID,
    request: {
      url: `${URL_ORCHESTRATIONS}/queue/${id}`,
      method: "GET",
    },
  });

export const startOrchestration = (id, onSuccess, onError) => ({
    type: START_ORCHESTRATION,
    request: {
        url: `${URL_ORCHESTRATIONS}/start/${id}`,
        method: "GET",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess && response.status === 200) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    }
});
