import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    rowActionButtons: {
        color: theme.palette.primary.dark,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    disableDelete: {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "transparent"
    },
}));
