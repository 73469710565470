import React from "react";
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import CustomDialog from "pages/Services/components/CustomDialog/index.js";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../../../style.js";

function DeleteKpiDialog({ onClose, onSubmit, kpiId }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <CustomDialog
        open={kpiId !== -1}
        onClose={onClose}
        disableBackdropClick
        fullWidth
        className={classes.deleteDialog}
      >
        <DialogTitle>{t("custom.kpi.dialog.delete.label")}</DialogTitle>
        <DialogContent>
          <Typography>{t("custom.kpi.dialog.delete.text")}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton variant="outlined" onClick={onClose}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton
                view="primary"
                onClick={() => onSubmit(kpiId)}
            >
            {t("custom.kpi.dialog.delete.submit")}
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    );
}

export default DeleteKpiDialog;
