import React from "react";
import {
  Box, Typography, Card, CardContent
} from "@material-ui/core";

const NoData = ({ message, children, withVariant = true }) => (
  <Card variant={withVariant ? "outlined" : null}>
    <CardContent>
      <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" flex="1">
        {children && children}
        <Typography component="p" color="primary">
          {message}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

export default NoData;
