import React from "react";
import CustomAlert from "../CustomAlert";
import CustomDialog from "../CustomDialog";
import useStyles from "./style"

function ConfirmMessage({
  title,
  message,
  openStart,
  onConfirm,
  onCancel,
  buttonConfirm,
  buttonCancel,
  isLoading,
  children,
  alertMsg,
  alertType,
  disableCancel,
  disableConfirm,
  viewConfirmBtn = "primary",
  hideCancel = false,
  ...rest
}) {
  const classes = useStyles();
  return (
    <CustomDialog
      title={title}
      contentText={
        <>
          {message}
          {alertMsg && <CustomAlert alertMsg={alertMsg} severity={alertType} />}
        </>
      }
      open={openStart}
      actionElements={[
          {
        label: buttonCancel,
        onClick: onCancel,
        color: "primary",
        hidden: hideCancel,
        disabled: isLoading || disableCancel,
      },
        {
        label: buttonConfirm,
        onClick: onConfirm,
        view: viewConfirmBtn,
        disabled: isLoading || disableConfirm,
        isLoading
      }
      ]}
      aria-labelledby="confirm_message-dialog-title"
      className={classes.dialog}
      {...rest}
    >
      {children}
    </CustomDialog>
  );
}

export default ConfirmMessage;
