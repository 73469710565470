import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundColor: "unset",
        height: "100%",
    },
    tableCell: {
        paddingLeft: theme.spacing(1),
        color: theme.palette.primary.main,
        padding: "2px 10px",
        fontWeight: "700",
        borderBottom: `2px solid ${theme.custom.color.staticInput}`
    },
    paper: {
        backgroundColor: "unset",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    linkTable: {
        height: "370px",
    },
    searchContainer: {
        paddingTop: "17px !important",
        paddingBottom: "0px !important",
    },
    tagChip: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: 700,
        backgroundColor: "rgba(123, 176, 255, 0.13)",
        height: 28,
        minWidth: 60,
        margin: 4
    },
    cellTitle: {
        fontSize: "0.8rem",
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    cellTitleQueueName: {
        fontSize: "0.8rem",
        fontWeight: 800,
        color: theme.palette.info.main,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));
