import React from "react";
import Popover from "@material-ui/core/Popover";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import useStyles from "../../../style.js";

const ReportingMenu = ({
 anchorEl,
 setAnchorEl,
 options,
 anchorOriginVertical = "bottom",
 anchorOriginHorizontal = "right",
 menuPaperClass
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(false)}
      anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      classes={{ paper: clsx(classes.menuPaper, menuPaperClass) }}
      elevation={0}
    >
      {options?.map((option, index) => (
        <Grid container alignItems="center" className={classes.exportListOption}>
          {option?.startIcon}
          <Typography align="center" onClick={option.onClick}>
            {t(option.label)}
          </Typography>
          {option?.endIcon}
          { (index + 1 !== options.length) && <Divider className={classes.optionsDivider} /> }
        </Grid>
      ))}
    </Popover>
  );
};

export default ReportingMenu;
