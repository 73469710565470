import React, { useEffect, useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  saveProcessRestriction,
  fetchProcesses,
  fetchProcessesDetails,
} from "../../../../../redux/actions/services";
import {
  formtNumberByLanguage,
  minimizeString,
  numberRoiFloatFormat,
  rateFloatFormat,
} from "util";
import { OrchestratorTypes } from "util/helpers/orchestrator.helper";
import clsx from "clsx";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomizedDialogs from "../../ModalService";
import { roiInSufficientData } from "../../../../../WrapperContainer/components/AppBar/components/SRCards";
import QuestionMarkIcon from "../../../../../components/QuestionMarkIcon";
import StatusButton from "../../../../../components/StatusButton";
import { isSRQueuesDisabled } from "../../../../../util/constants/PermitedFeature";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import CustomTooltip from "../../../../../components/CustomTooltip";
import { ReactComponent as UiPathLogo } from "../../../../../assets/OrchestratorTypes/UiPath.svg";
import { ReactComponent as BluePrismLogo } from "../../../../../assets/OrchestratorTypes/BluePrism.svg";
import { ReactComponent as IrisLogo } from "../../../../../assets/OrchestratorTypes/IrisLogo.svg";
import useStyles from "./style.js";

const sortByRecentActivity = "recentActivity,DESC";
function TableCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { process: _process, statusData } = props;
  const { editId, setEditId } = props;
  const [processState, setProcessState] = useState(null);
  const breakEvenContent = ({ breakEvenUnit, breakEvenValue }) => {
    if (breakEvenUnit === undefined || breakEvenUnit === null) {
      return "--";
    }
    return breakEvenValue > 0
      ? `${t("ago", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`
      : `${t("point.amortissage.negative", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`;
  };

  const title = _process?.processDisplayName;
  const content = _process?.processDescription;
  const priority = (_process?.priority === 0) ? "--" : _process?.priority;
  const insuffisantData = roiInSufficientData(_process?.processDetail?.roi);
  const roiValue = rateFloatFormat(_process?.processDetail?.roi);
  const roi = roiValue && roiValue !== "--" && !insuffisantData ? (
    <>
      {roiValue}
      %
    </>
  ) : (
    <>
      --
      <QuestionMarkIcon titleContent={t("kpi.header.roi.tooltip.nodata")} />
    </>
  );

  const breakEvenValue = breakEvenContent(
    _process?.processDetail?.processBreakEven || {},
  );
  const breakEven = breakEvenValue
    && breakEvenValue !== "--"
    && !insuffisantData ? (
      breakEvenValue
    ) : (
      <>
        --
        <QuestionMarkIcon
          titleContent={t("kpi.header.breakEven.tooltip.nodata")}
        />
      </>
    );

  const classes = useStyles(props.width)();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openSlide, setOpenSlide] = React.useState(false);
  const [newPriority, setNewPriority] = React.useState(priority);
  const filterProcess = useSelector(({ filterProcess: i }) => i);

  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  const closeDialog = () => {
    setOpen(false);
    setEditId(0);
    setProcessState(null)
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    );
  };
  const openAndResetValue = () => {
    setIsLoading(true)
    dispatch(fetchProcessesDetails(_process?.id)).then((res) => {
      setValue(0);
      setIsLoading(false)
      setProcessState(res.data);
      setOpen(true);
    });
  };
  const changePriority = (reset = true) => {
    const dataToSend = { priority: null };
    if (_process?.priority) dataToSend.priority = parseInt(_process?.priority);
    dataToSend.priority = reset ? null : parseInt(newPriority);
    dispatch(saveProcessRestriction(_process?.id, dataToSend)).then(() => {
      setEditId(0);
      dispatch(
        fetchProcesses(
          filterProcess.search,
          filterProcess.fleet,
          filterProcess.tags,
          filterProcess.pageNo,
          filterProcess.pageSize,
          sortByRecentActivity
        ),
      );
    });
    if (reset) setNewPriority(priority);
  };

  const getTagLabel = () => {
    const firstTagName = _process?.tags?.[0]?.name
    if (_process.tags && _process.tags.length > 1) {
       const result = map(_process?.tags, "name").reduce((accumulator, currentTag, index) => {
           const combinedLength = accumulator.length + currentTag.length + 2;
       if (index === 0 || combinedLength <= props.width) {
      return index === 0 ? currentTag : `${accumulator}, ${currentTag}`;
    }
      return accumulator;
  }, firstTagName);
      return `${minimizeString(result, props.width + 4)} (+${_process.tags.length - result.split(", ").length})`
    }
    return firstTagName
  }

  useEffect(() => {
    if (editId === _process.id) {
      setOpen(false);
      setNewPriority(priority);
    }
  }, [editId]);

  useEffect(() => {
    setEditId(0);
  }, [filterProcess.showAll]);

  const getLogo = (orchestratorType) => {
    switch (orchestratorType) {
      case "blueprism": return <BluePrismLogo />;
      case "uipath": return <UiPathLogo />;
      case "iris": return <IrisLogo />;
      default:
        return null;
    }
  };

  return (
    <>
      <CustomTableRow
            onClick={() => editId !== _process.id && openAndResetValue()}
            key={`${_process.id}`}
      >
        <CustomTableCell className={(!isSRQueuesDisabled && _process?.queueId) ? classes.cardRootSR : classes.cardRoot}>
          <Box component="span" display="grid" gridTemplateColumns={`1FR 50px ${props.width ? (props.width + 9) * 7 : 0}px`} gridColumnGap={5} alignItems="center" justifyContent="space-between">
            <Tooltip title={_process.queueId && !isSRQueuesDisabled ? t("process.assigned.srqueue.tooltip") : t("process.not.assigned.srqueue.tooltip")} placement="top">
              <Box>
                <ProcessIconName
                    imgSrc={_process?.icon}
                    processName={title}
                />
              </Box>
            </Tooltip>
            <Tooltip title={OrchestratorTypes[_process?.orchestratorType]?.label} placement="top">
              <IconButton>
                {getLogo(_process?.orchestratorType)}
              </IconButton>
            </Tooltip>
            {_process?.tags?.length > 0 && (
              <CustomTooltip
                      title={(
                        <Box width="fit-content">
                          <Typography color="primary" className={classes.tagTooltipTitle}>{t("Tags Assigned")}</Typography>
                          {_process?.tags?.map(({ id, name }) => (
                            <Chip
                                    key={id}
                                    label={name}
                                    className={classes.tagChip}
                                    classes={{
                                      label: classes.tagChipLabel
                                    }}
                                />
                            ))}
                        </Box>
                      )}
                      placement="top-start"
                      arrow
                  >
                <ButtonBase component={Box} disableRipple>
                  <Chip
                          label={getTagLabel()}
                          className={clsx(classes.tagChip, classes.tagChipWidth)}
                          classes={{
                            label: classes.tagChipLabel
                }}
              />
                </ButtonBase>
              </CustomTooltip>
            )}
          </Box>
        </CustomTableCell>
        <CustomTableCell
              text={formtNumberByLanguage(
                  _process?.processDetail?.executionCount,
              )}
          />
        <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.itemsCount,)}
          />
        {/* <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.exceptionCount,)}
              {...cellProps}
          /> */}
        <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.pendingCount,)}
          />
        <CustomTableCell
              text={roi}
          />
        <CustomTableCell
              text={breakEven}
          />
        <CustomTableCell>
          <StatusButton
                isProcess
                status={_process.processStatus}
                label={statusData[_process.processStatus]?.label || "Idle"}
            />
        </CustomTableCell>
      </CustomTableRow>
      {open && processState?.id && (
        <CustomizedDialogs
                open={open}
                handleClose={closeDialog}
                handleClickOpen={() => openAndResetValue()}
                content={content}
                title={title}
                _process={processState}
                setProcess={setProcessState}
                value={value}
                setValue={setValue}
                callAfterClose={setEditId}
                changePriority={changePriority}
                statusData={statusData}
                isLoading={isLoading}
            />
        )}
      {openSlide && (
        <ConfirmMessage
                message={t("priority.error")}
                openStart={openSlide}
                onCancel={handleCloseSlide}
                onConfirm={handleCloseSlide}
                buttonConfirm={t("Accept")}
                buttonCancel={t("Cancel")}
            />
        )}
    </>
  );
}

export default TableCard;
