import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import TablePagination from "@material-ui/core/TablePagination";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const CustomPagination = ({
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onNext,
  onPrevious,
  rowsPerPageOptions = [5, 10, 25]
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hidePageNumbers = Math.ceil(count / rowsPerPage) === 1;
  const CustomRowsPerPageLabel = () => (
    <Typography className={classes.paginationText} variant="caption">
      {t("Rows Per page")}
    </Typography>
    );

  return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
      <Grid container direction="row" alignItems="center" item xs={5} wrap="nowrap">
        <Grid item>
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            labelRowsPerPage={<CustomRowsPerPageLabel />}
            labelDisplayedRows={() => ""}
            nextIconButtonProps={{
              style: { display: "none" },
            }}
            backIconButtonProps={{
              style: { display: "none" },
            }}
          />
        </Grid>
        <Grid container item>
          <Grid item className={classes.range}>
            <Typography
              className={classes.paginationText}
              variant="caption"
              color="primary"
            >
              {`${!count ? 0 : (page * rowsPerPage + 1)} - ${
                page + 1 === Math.ceil(count / rowsPerPage) || !count
                  ? count
                  : Math.min((page + 1) * rowsPerPage, count)
              } ${t("pagination.of")}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
                className={classes.paginationText}
                variant="caption"
                color="primary"
            >
              {count}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6} alignItems="center" justify="flex-start">
        {!hidePageNumbers && (
          <Grid item>
            <Button
              color="primary"
              size="small"
              className={classes.root}
              disabled={Math.ceil(count / rowsPerPage) === 1 || page === 0}
              onClick={() => onPrevious(page)}
            >
              {t("Previous")}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Pagination
            renderItem={(item) => {
              const isDots = item.type === "start-ellipsis" || item.type === "end-ellipsis";
              return (
                <Box key={item.page} className={classes.paginationContainer}>
                  {!hidePageNumbers && (
                    <Typography
                      className={
                        item.selected
                          ? classes.customTypography
                          : classes.inactive
                      }
                      color={item.color}
                      variant="caption"
                      onClick={(e) => {
                        if (!isDots) {
                          onChangePage(e, item.page - 1);
                        }
                      }}
                    >
                      {isDots ? "..." : item.page}
                    </Typography>
                  )}
                </Box>
              );
            }}
            disabled={Math.ceil(count / rowsPerPage) === 1}
            color="primary"
            page={page + 1}
            onChange={(event, newPage) => onChangePage(newPage - 1)}
            count={Math.ceil(count / rowsPerPage)}
            hideNextButton
            hidePrevButton
          />
        </Grid>
        {!hidePageNumbers && (
          <Grid item>
            <Button
              color="primary"
              size="small"
              className={classes.root}
              disabled={
                page + 1 === Math.ceil(count / rowsPerPage) || count === 0
              }
              onClick={() => onNext(page)}
            >
              {t("next")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

CustomPagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default CustomPagination;
