import React, { useState, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Query } from "@redux-requests/react";
import get from "lodash/get";
import { INTELLIGENT_ORCHESTRATORS_BASE_URL } from "util/helpers/route.helper";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_ALL_ORCHESTRATIONS } from "../../../redux/constants";
import { updateFilter } from "../../../redux/slices/adminFilterSlice.js";
import { fetchOrchestrationsList } from "../../../redux/actions/services";
import CustomPagination from "pages/Services/components/CustomPagination/index.js";
import CustomTable from "../../../components/TableComponents/CustomTable";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../components/DataNotFound";
import OrchestratorNotFound from "../../../assets/No_Orchestrator.svg";
import AdminTableFilter from "pages/Administration/AdminTable/AdminTableFilter";
import OrchestrationTableHeader from "./OrchestrationHeader";
import OrchestrationTableRow from "./OrchestrationRow";

const statusList = [
  { code: true, label: "ACTIVE" },
  { code: false, label: "DISABLED" },
];
const fetchEntities = fetchOrchestrationsList;

export default function OrchestrationsTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showTableHeader, setShowTableHeader] = useState(true);
  const [countUsers, setCountUsers] = useState(0);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const handleChangeStatus = (event) => {
    const statuses = event?.map(({ code }) => code);
    dispatch(
      updateFilter({
        status: statuses,
        pageNo: 0,
      }),
    );
  };

  const getStatusColor = (status) => {
    if (!status.code) return "OFFLINE";
    if (status.code) return "ACTIVE";
    return "UNKNOWN";
  };

  const handleChangeOrchestrator = (value) => {
    dispatch(
      updateFilter({
        orchestrator: value,
        pageNo: 0,
      }),
    );
  };

  const handleChangeFleet = (values) => {
    dispatch(
      updateFilter({
        fleets: values,
        pageNo: 0,
      }),
    );
  };

  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      }),
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      }),
    );
  };

  const handleSelectedAll = (checked) => {
    const data = fetchedData.map((d) => d.id);
    if (!checked) {
      const filteredData = selected.filter((fd) => !data.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    setSelected([])
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleChangeShowDeleted = () => {
    dispatch(
      updateFilter({
        showDeleted: !filter[filter.currModule]?.showDeleted,
        pageNo: 0,
      }),
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countUsers - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d.id)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));

  const handleNextPage = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
  };
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  };

  const isOrchestratorSelected = filter[filter.currModule]?.orchestrator?.id;

  return (
    <Box>
      <AdminTableFilter
        statusList={statusList}
        pageSize={filter.pageSize}
        searchLabel="Search"
        searchField="searchCriteria"
        handleChangeStatus={handleChangeStatus}
        handleChangeOrchestrator={handleChangeOrchestrator}
        handleFilterValueChange={handleFilterValueChange}
        handleChangeSortBy={handleChangeSortBy}
        isOrchestrationPage
        handleChangeFleets={handleChangeFleet}
        entityBaseUrl={INTELLIGENT_ORCHESTRATORS_BASE_URL}
        handleChangeShowDeleted={handleChangeShowDeleted}
        getStatusColor={getStatusColor}
        showAddIcon
        addButtonText={t("Add an orchestration")}
      />
      {
        isOrchestratorSelected ? (
          <CustomTableContainer>
            <CustomTable>
              {showTableHeader && (
                <OrchestrationTableHeader
                  selected={selected}
                  setSelected={setSelected}
                  handleSelectedAll={handleSelectedAll}
                  checkedAll={
                    fetchedData?.length > 0
                    && fetchedData.every((d) => selected.includes(d.id))
                  }
                  checkedIndeterminate={
                    fetchedData?.length > 0
                    && !isAllSelected
                    && fetchedData.some((d) => selected.includes(d.id))
                  }
                  fetchedData={fetchedData}
                  fetchEntities={fetchEntities}
                  page={filter[filter.currModule].pageNo}
                  rowsPerPage={filter[filter.currModule].pageSize}
                  showDeleted={filter[filter.currModule].showDeleted}
                  statusList={statusList}
                />
              )}
              <Query type={FETCH_ALL_ORCHESTRATIONS} loadingComponent={CircularLoader}>
                {({ data }) => {
                  if (!data?.content?.length) setCountUsers(0);
                  setFetchedData(data?.content);
                  const length = data.totalElements;
                  setCountUsers(length);
                  data?.content?.length > 0
                    ? setShowTableHeader(true)
                    : setShowTableHeader(false);
                  return data?.content?.length > 0 ? (
                    data.content?.map((row, index) => (
                      <OrchestrationTableRow
                        key={`orchestration-table-${index}`}
                        row={row}
                        index={index}
                        handleClick={() => {
                          history.push(`${INTELLIGENT_ORCHESTRATORS_BASE_URL}/${get(row, "id")}`);
                        }}
                        selected={selected}
                        setSelected={setSelected}
                        entityBaseUrl={INTELLIGENT_ORCHESTRATORS_BASE_URL}
                        fetchEntitiesReset={fetchEntitiesReset}
                        fetchEntities={fetchEntitiesWithFilter}
                      />
                    ))
                  ) : (
                    <DataNotFound
                      message={t("no.orchestration.message")}
                      icon={OrchestratorNotFound}
                    />
                  );
                }}
              </Query>
            </CustomTable>
          </CustomTableContainer>
        )
          : <DataNotFound
            message={t("select.orchestrator")}
            icon={OrchestratorNotFound}
          />
      }
      {showTableHeader && isOrchestratorSelected && (
        <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={countUsers ?? 0}
          page={filter[filter.currModule].pageNo}
          rowsPerPage={filter[filter.currModule].pageSize}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          onNext={handleNextPage}
          onPrevious={handlePrevious}
        />
      )}
    </Box>
  );
}
