import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    dragIcon: {
        fontSize: 40,
        color: theme.custom.color.archivedBackground
    },
    dragHelper: {
        fontSize: 16,
        color: theme.custom.color.archivedBackground
    },
    searchField: {
       width: "96%",
        marginBottom: 10
    },
    searchIcon: {
        color: theme.custom.color.chartColor1
    }
}))
