/* eslint-disable import/prefer-default-export */
import {
  CREATE_RESOURCE_CREDENTIAL, DELETE_RESOURCE_CREDENTIAL, FETCH_RESOURCE_CREDENTIAL_BY_ID, FETCH_RESOURCE_CREDENTIALS, FETCH_RESOURCE_CREDENTIALS_BY_USER_ID, UPDATE_RESOURCE_CREDENTIAL
} from "redux/constants";
import { URL_RESOURCE_CREDENTIALS } from "redux/constants/endpoints";

export const fetchResourceCredentialsByUserId = (userId) => ({
  type: FETCH_RESOURCE_CREDENTIALS_BY_USER_ID,
  request: {
    url: `${URL_RESOURCE_CREDENTIALS}/user/${userId}`,
    method: "GET",
  },
});

export const fetchResourceCredentials = (params, onSuccess, onError) => ({
  type: FETCH_RESOURCE_CREDENTIALS,
  request: {
      url: URL_RESOURCE_CREDENTIALS,
      method: "GET",
      params,
  },
  meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          throw error;
      },
  }
});

export const updateResourceCredential = (resourceCredentialDto, onSuccess, onError) => ({
  type: UPDATE_RESOURCE_CREDENTIAL,
  request: {
    url: `${URL_RESOURCE_CREDENTIALS}/update`,
    method: "PUT",
    data: resourceCredentialDto,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const createResourceCredential = (resourceCredentialDto, onSuccess, onError) => ({
  type: CREATE_RESOURCE_CREDENTIAL,
  request: {
    url: `${URL_RESOURCE_CREDENTIALS}/create`,
    method: "POST",
    data: resourceCredentialDto,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const fetchResourceCredentialById = (id, onSuccess, onError) => ({
  type: FETCH_RESOURCE_CREDENTIAL_BY_ID,
  request: {
    url: `${URL_RESOURCE_CREDENTIALS}/${id}`,
    method: "GET",
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const deleteResourceCredential = (id, onSuccess, onError) => ({
  type: DELETE_RESOURCE_CREDENTIAL,
  request: {
    url: `${URL_RESOURCE_CREDENTIALS}/${id}`,
    method: "DELETE",
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});
