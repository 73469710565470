import React from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import ConfirmMessage from "components/ConfirmMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchResourceList,
  editStatusResource
} from "../../../../redux/actions/services";
import { useTranslation } from "react-i18next";
import { resourceStatus } from "util";
import { ADMIN_MODULE } from "util/constants";

function RobotStatusAction({
  selected,
  setSelected,
  items,
  classes
}) {
  const [openHidePopup, setopenHidePopup] = React.useState(false);
  const [openUnhidePopup, setopenUnhidePopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedHidden, setSelectedHidden] = React.useState([])
  const [selectedUnhidden, setSelectedUnhidden] = React.useState([])
  const dispatch = useDispatch();
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const { t } = useTranslation();

  const getNumberOfSelectedResources = () => {
    const hide = [];
    const unhide = [];
    selected.forEach((s) => {
      const item = items.find((itm) => itm.id === s)
      if (item.isActive === resourceStatus.ACTIVE) {
        hide.push(s);
      }
      if (item.isActive === resourceStatus.DISABLE) {
        unhide.push(s);
      }
    })
    setSelectedUnhidden(unhide)
    setSelectedHidden(hide)
  }
  React.useEffect(() => {
    getNumberOfSelectedResources();
  }, [selected.join()])

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setopenHidePopup(false);
    setopenUnhidePopup(false);
  };
  const onClick = () => {
    if (openHidePopup) {
      resourceCallback(editStatusResource, "show");
    }
    if (openUnhidePopup) {
      resourceCallback(editStatusResource, "hide");
    }
  }

  const resourceCallback = (callback, action) => {
    if (callback) {
      setIsLoading(true);
      dispatch(
        callback(action === "hide" ? selectedUnhidden : selectedHidden, () => {
          setIsLoading(false);
          dispatch(fetchResourceList(filter[ADMIN_MODULE.ROBOTS]));
          setSelected([]);
          handleCloseConfirmationPopup();
        })
      );
    }
  };

  const confirmationMsg = (status) => {
    if (status) return t("resource.tooltip.hide", { count: selectedHidden.length })
    return t("resource.tooltip.unhide", { count: selectedUnhidden.length })
  }

  return (
    <>
      {!isEmpty(selectedHidden) && (
        <Tooltip placement="top" title={confirmationMsg(true)}>
          <IconButton
            aria-label="delete"
            disabled={isEmpty(selectedHidden)}
            onClick={() => {
              setopenHidePopup(true);
            }}
            className={classes.rowActionButtons}
          >
            <Badge badgeContent={selectedHidden.length}>
              <VisibilityOffIcon className={classes.hideIcon} />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {!isEmpty(selectedUnhidden) && (
        <Tooltip placement="top" title={confirmationMsg(false)}>
          <IconButton
            className={classes.rowActionButtons}
            aria-label="delete"
            disabled={isEmpty(selectedUnhidden)}
            onClick={() => {
              setopenUnhidePopup(true);
            }}
          >
            <Badge badgeContent={selectedUnhidden.length}>
              <VisibilityIcon className={classes.showIcon} />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      <ConfirmMessage
        message={confirmationMsg(openHidePopup, openUnhidePopup)}
        openStart={openHidePopup || openUnhidePopup}
        onCancel={handleCloseConfirmationPopup}
        onConfirm={onClick}
        buttonConfirm={t("Accept")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
    </>
  );
}

export default RobotStatusAction;
