import { createMuiTheme, styled } from "@material-ui/core";
import { makeStyles, darken } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: "black",
        backgroundColor: "white",
        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
      },
    },
  },
});

export const DayStyledCard = styled("div")(
  ({ gridRowStart, span, backgroundColor }) => ({
    gridRow: `${gridRowStart}/ span ${span}`,
    backgroundColor: "white",
    color: backgroundColor,
    border: "0px",
    margin: "7px",
    boxShadow: "rgb(0 0 0 / 15%) 0px 5.95px 10px",
    borderRadius: "5px",
    position: "relative",
    padding: "10px",
    zIndex: 1,
    minWidth: "25px",
    width: "max-content",
    isolation: "isolate",
    "&::before": {
      content: "\"\"",
      display: "block",
      height: "calc(100% - 10px)",
      width: "5px",
      backgroundColor,
      position: "absolute",
      left: 4,
      top: 5,
      borderRadius: "5px",
    },
    "&::after": {
      content: "\"\"",
      display: "block",
      height: "100%",
      width: "100%",
      zIndex: -1,
      backgroundColor: `${backgroundColor}11`,
      position: "absolute",
      left: 0,
      top: 0,
      borderRadius: "5px",
    },
    cursor: "pointer",
  }),
);

export const StyledCard = styled("div")(
  ({
    gridrowstart, span, backgroundcolor,
  }) => ({
    gridRow: `${gridrowstart}/ span ${span}`,
    backgroundColor: "white",
    color: backgroundcolor,
    border: "0px",
    margin: "2px",
    borderRadius: "3px",
    position: "relative",
    padding: "5px",
    zIndex: 1,
    wordBreak: "break-word",
    minWidth: "25px",
    width: "max-content",
    isolation: "isolate",
    "&::before": {
      content: "\"\"",
      display: "block",
      height: "calc(100% - 10px)",
      width: "2px",
      backgroundColor: backgroundcolor,
      position: "absolute",
      left: 4,
      top: 5,
      borderRadius: "5px",
    },
    "&::after": {
      content: "\"\"",
      display: "block",
      height: "100%",
      width: "100%",
      zIndex: -1,
      backgroundColor: `${backgroundcolor}11`,
      position: "absolute",

      left: 0,
      top: 0,
      borderRadius: "5px",
    },
    cursor: "pointer",
  }),
);

export default makeStyles((theme) => ({
  vertical: {
    left: "calc(50% - 0.5rem)",
    position: "relative",
    transform: "rotate(180deg)",
    textAlign: "center",
    whithSpace: "nowrap",
    writingMode: "vertical-rl",
  },
  horizontal: {
    left: "calc(50% - 0.5rem)",
    paddingLeft: "10px",
    paddingTop: "5px",
    textAlign: "center",
    whithSpace: "nowrap",
  },
  calendar: {
    height: "700px",
    backgroundColor: "white",
  },
  calendarFullScreen: {
    backgroundColor: "white",
    height: "900px",
  },
  buttonAddScheduler: {
    marginTop: "15px",
    padding: "6px 8px"
  },
  schedulerTitles: {},
  alertHigh: {
    height: "15px",
    fontSize: "10px",
    backgroundColor: "#f44336",
    color: theme.palette.common.white,
  },
  alertWarning: {
    height: "15px",
    fontSize: "10px",
    backgroundColor: "orange",
    color: theme.palette.common.white,
  },
  clearBtn: {
    height: 35,
    backgroundColor: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    color: "white",
    "&:hover": {
      background: darken(theme.palette.error.main, 0.2),
    },
  },
  calendarContainer: {
    padding: theme.spacing(3),
  },
  mainContainer: {
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(1),
  },
  btnBasicMargin: {
    marginTop: "15px",
    paddingRight: 0,
    paddingLeft: 0,
  },
  filtersFormControl: {
    justifyContent: "end",
    maxHeight: 50
  },
  processChip: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
  processListItem: {
    justifyContent: "center"
  },
  groupContainer: {
    paddingRight: "12px !important",
  },
  eventTooltip: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  scheduleContainer: {
    minWidth: theme.breakpoints.values.lg,
    minHeight: 600
  },
  taskInfo: {
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    fontWeight: "600",
}
}));
