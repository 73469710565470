import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  toolbar: {
    paddingRight: 24,
    paddingLeft: "0 !important",
    justifyContent: "space-between",
    height: 55,
    minHeight: 50,
    backgroundColor: theme.palette.info.light,
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
  leftToolbar: theme.mixins.toolbar,
  appBar: {
    marginLeft: theme.custom.variables.menuLeftCollapsedWidth,
    width: `calc(100% - ${theme.custom.variables.menuLeftCollapsedWidth}px)`,
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: ({ menuLeftWidth }) => menuLeftWidth || theme.custom.variables.menuLeftWidth,
    width: ({ menuLeftWidth }) => `calc(100% - ${menuLeftWidth || theme.custom.variables.menuLeftWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  actionContainer: {
    height: "100%",
  },
  subMenuItem: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontWeight: 700,
      fontSize: "0.85rem",
      color: theme.palette.primary.main,
      padding: theme.spacing(0.7),
    },
    padding: theme.spacing(0, 3),
    cursor: "pointer",
    "&:hover p": {
      color: theme.palette.info.main,
    }
  },
  subMenuItemActive: {
    backgroundColor: theme.palette.background.default,
    "& p": {
      color: theme.palette.secondary.main,
    },
    "&:hover p": {
      color: theme.palette.secondary.main,
    }
  },
  menuPaper: {
    borderRadius: 5,
    boxShadow: "0px 0px 11px 0px #00000040",
    padding: theme.spacing(2, 1),
},
  menuPaperProfile: {
    minWidth: 220,
},
  popupItemIcon: {
    stroke: theme.palette.info.main,
    width: 11,
    height: 11,
},
  subMenuPaper: {
    padding: theme.spacing(1, 2),
},
  withourAppBar: {
    width: "100%",
    margin: 0,
  },
  logo: {
    width: 150,
    cursor: "pointer"
  },
  riyadbanklogo: {
    width: 110,
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(1),
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    top: -2,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
    },
  },
  switcher: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  centerBox: {
    textAlign: "center",
  },

  alertLow: {
    backgroundColor: "#0da8ad",
    color: theme.palette.common.white,
  },
  alertMeduim: {
    backgroundColor: "#FF9800",
    color: theme.palette.common.white,
  },
  alertHigh: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  popupItems: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    width: "minmax(100%, max-content)",
    fontWeight: 700,
    cursor: "pointer",
    "& *": {
      fontWeight: 700,
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      "& *": {
        stroke: `${theme.palette.secondary.main} !important`,
      },
    },
    marginBottom: 0,
    marginTop: 0,
  },
  popupItemsActive: {
    backgroundColor: theme.palette.info.light,
  },
  purple: {
    height: 24,
    width: 24,
  },
  subMenuAvatar: {
    height: 30,
    width: 30,
  },
  headerCardWrapper: {
    width: "50%",
  },
  headerCard: {
    "&:not(:last-child):after": {
      content: "\"\"",
      width: "1px",
      height: "80%",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  darkHeaderCard: {
    color: "#FFFFFF",
    "&:not(:last-child):after": {
      backgroundColor: "#FFFFFF",
    },
  },
  dark: {
    background: theme.palette.primary.main,
  },
  darkDivider: {
    height: 25,
    width: 3,
    backgroundColor: theme.custom.color.chartColor4,
    margin: "auto 15px",
    opacity: 0.4,
    borderRadius: 14,
    border: 0
  },
  darkIconBtn: {
    color: "#FFFFFF",
  },
  infoIcon: {
    fontSize: "0.825rem",
    marginLeft: theme.spacing(0.2),
  },
  "@keyframes badgeAnimation": {
    "0%": {
      transform: "scale(1) translate(50%, -50%)",
    },
    "50%": {
      transform: "scale(1.2) translate(50%, -50%)"
    },
    "100%": {
      transform: "scale(1) translate(50%, -50%)"
    },
  },
  badgeStyled: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    animation: "$badgeAnimation 1.7s ease-in-out infinite",
  },
  exceptionBadge: {
    fontSize: 5,
    padding: "0 5px"
  },
  nameItem: {
    color: theme.palette.primary.main,
    fontSize: "0.85rem",
    fontWeight: 700,
  },
  emailItem: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  settingsIcon: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem"
  },
  iconsButtonsHover: {
    "&:hover .MuiSvgIcon-root": {
      color: theme.palette.info.main,
    },
  }
}));
