import { makeStyles, fade } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  drawerPaper: {
    width: "550px",
    padding: theme.spacing(0, 2, 2, 2),
  },
  workflowActions: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 2),
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    borderRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
  },
  timeline: {
    marginTop: theme.spacing(3),
    overflowY: "scroll",
    borderRadius: "4px",
    border: "1px solid #DDD",
  },
  timelineOppositeContent: {
    maxWidth: "1px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  assigmentButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "0.3125rem",
    marginRight: 10,
    fontWeight: 700
  }
}));
