import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Editor } from "@monaco-editor/react";
import { formatDateByLanguage } from "util";
import CustomTableContainer from "components/TableComponents/CustomTableContainer";
import CustomTableRow from "components/TableComponents/CustomTableRow";
import CustomTableCell from "components/TableComponents/CustomTableCell";
import CustomTable from "components/TableComponents/CustomTable";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import CustomButton from "components/CustomButton";
import { useDispatch } from "react-redux";
import generateJsonData from "redux/actions/services/generation";
import useStyles from "./style";
import DataNotFound from "components/DataNotFound";
import { jsonEntity, routeApi, srqueuesEntity } from "util/configs/backendEntities";

/* const dataMock = [
  {
    creationDate: "01/01/2024",
    tag: "tag test",
    queue: "queue test",
    nbrOccurrences: 10,
  },
  {
    creationDate: "01/01/2024",
    tag: "tag test",
    queue: "queue test",
    nbrOccurrences: 10,
  },
  {
    creationDate: "01/01/2024",
    tag: "tag test",
    queue: "queue test",
    nbrOccurrences: 10,
  },
];
*/

const headerCells = [
  {
    id: "creationDate",
    label: "Creation date",
  },
  {
    id: "queue",
    label: "Queue",
  },
  {
    id: "tag",
    label: "Tag",
  },
  {
    id: "nbrOccurrences",
    label: "Number of occurrences",
  },
];

const GenerationPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError
  } = useForm();
  const [data, setData] = useState([]);

  // fct to start listening for sse
  const fetchData = () => {
    const eventSource = new EventSource(`${process.env.REACT_APP_DOMAIN}${routeApi}/${srqueuesEntity}/${jsonEntity}/stream`);

    eventSource.onmessage = (event) => {
      const newData = JSON.parse(event.data);
      setData((prevData) => [...prevData, newData]);
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed: ", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }

  const onSubmit = (formData) => {
    dispatch(generateJsonData(formData.text, fetchData));
  };

  const validateJson = (value) => {
    try {
      JSON.parse(value);
      clearErrors("text");
      return true;
    } catch (error) {
      setError("text", { type: "manual", message: "Invalid JSON" });
      return false;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item className={classes.topMargin}>
            <Controller
                name="text"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Box className={classes.boxSection}>
                      <Typography className={classes.boxSectionTitle}>{t("JSON Text")}</Typography>
                      <Editor
                        height={400}
                        language="json"
                        value={field.value}
                        onChange={(value) => {
                          setValue("text", value)
                          validateJson(value);
                        }}
                        options={{
                          minimap: { enabled: false },
                          lineNumbers: "off",
                          renderLineHighlight: "none",
                          scrollbar: {
                            vertical: "auto",
                            horizontal: "auto",
                            verticalScrollbarSize: 5,
                            horizontalScrollbarSize: 5,
                          },
                          scrollBeyondLastLine: false,
                          overviewRulerBorder: false,
                          automaticLayout: true,
                        }}
                    />

                    </Box>
                    <Typography color="error">{errors?.text?.message}</Typography>
                  </>
                )}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            item
            className={classes.topMargin}
          >
            <CustomButton type="submit" view="primary">
              {t("Generate")}
            </CustomButton>
            <CustomButton>{t("Cancel")}</CustomButton>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={9}>
        <CustomTableContainer>
          {data.length ? (
            <CustomTable>
              <CustomTableHeader labels={headerCells} className={classes.stickyHeader} />
              <TableBody className={classes.tableContainer}>
                {data?.map((row) => (
                  <CustomTableRow>
                    <CustomTableCell
                      text={formatDateByLanguage(row?.creationDate)}
                    />
                    <CustomTableCell text={row?.queue} />
                    <CustomTableCell text={row?.tag} />
                    <CustomTableCell text={row?.nbrOccurrences} />
                  </CustomTableRow>
                ))}
              </TableBody>
            </CustomTable>
          ) : (
            <DataNotFound />
          )}
        </CustomTableContainer>
      </Grid>
    </Grid>
  );
};

export default GenerationPage;
