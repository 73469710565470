export const FETCH_OPPORTUNITIES = "FETCH_OPPORTUNITIES"
export const QUALIFY_PROCESS = "QUALIFY_PROCESS"
export const DELETE_PROCESS = "DELETE_PROCESS"
export const ARCHIVE_PROCESS = "ARCHIVE_PROCESS"
export const SUBMIT_PROCESS = "SUBMIT_PROCESS"
export const LOAD_STEPS = "LOAD_STEPS"
export const LOAD_QUALIFICATIONS = "LOAD_QUALIFICATIONS"
export const PROCESS_UPDATED = "PROCESS_UPDATED"
export const PROCESS_SUBMIT = "PROCESS_SUBMIT"
export const CALCULATE_FACTOR = "CALCULATE_FACTOR"
export const LOAD_PROCESS = "LOAD_PROCESS"
export const LOAD_STEPS_DATA = "LOAD_STEPS_DATA"
