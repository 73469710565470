import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  fetchProcessDataMapping,
  updateProcessDataMapping,
} from "redux/actions/services";
import { connect } from "react-redux";
import CustomTable from "components/TableComponents/CustomTable";
import useStyles from "pages/AutomationsPending/style";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import InformationIcon from "components/InformationIcon";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomTableCell from "components/TableComponents/CustomTableCell";
import CustomTableRow from "components/TableComponents/CustomTableRow";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";

const DataMapping = (props) => {
  const form = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const { fields } = useFieldArray({
    control: form.control,
    name: "dataMappings",
  });
  const { t } = useTranslation();
  const classes = useStyles()();
  const tableCellProps = {
    paddingTop: 13,
    paddingBottom: 13,
  };
  const handleChangeSwitch = (e, row, index) => {
    const { checked, name } = e.target;
    const newData = form.getValues("dataMappings").map((d) => {
      if (d.key === row.key) {
        fields[index][name] = checked;
        return {
          ...d,
          [name]: checked,
        };
      }
      return d;
    });
    form.setValue("dataMappings", newData);
  };
  useEffect(() => {
    if (form.getValues("id")) {
      setIsLoading(true);
      props.fetchProcessDataMapping(form.getValues("id")).then((result) => {
        form.setValue("dataMappings", result?.data);
        setIsLoading(false);
      });
    }
  }, []);
  return (
    <TableContainer className={classes.dataMappingTableContainer}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <CustomTable
          stickyHeader
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          borderSpacing={0}
        >
          {form.getValues("dataMapping")?.length > 0 ? (
            <>
              <EnhancedTableHead
                headCells={headCells(t, classes)}
                align="left"
                transparentHeader
              />
              <TableBody className={classes.dataMappingTableBody}>
                {fields?.map((row, index) => (
                  <CustomTableRow key={index} backgroundColor="transparent">
                    <CustomTableCell text={row?.key} {...tableCellProps} />
                    <CustomTableCell align="left" {...tableCellProps}>
                      <CustomTextField
                        {...form.register(`dataMappings.${index}.label`)}
                        value={form.watch(`dataMappings.${index}.label`)}
                      />
                    </CustomTableCell>
                    <CustomTableCell align="left" {...tableCellProps}>
                      <Checkbox
                        {...form.register(`dataMappings.${index}.isDisplayed`)}
                        checked={form.watch(
                          `dataMappings.${index}.isDisplayed`
                        )}
                        name="isDisplayed"
                        handleChange={(e) => handleChangeSwitch(e, row, index)}
                        className={classes.rowActionButtons}
                        size="small"
                      />
                    </CustomTableCell>
                    <CustomTableCell align="left" {...tableCellProps}>
                      <Checkbox
                        {...form.register(`dataMappings.${index}.editForRetry`)}
                        checked={form.watch(
                          `dataMappings.${index}.editForRetry`
                        )}
                        name="editForRetry"
                        handleChange={(e) => handleChangeSwitch(e, row, index)}
                        className={classes.rowActionButtons}
                        size="small"
                      />
                    </CustomTableCell>
                    <CustomTableCell align="left" {...tableCellProps}>
                      <Checkbox
                        {...form.register(
                          `dataMappings.${index}.displayInItemsList`
                        )}
                        checked={form.watch(
                          `dataMappings.${index}.displayInItemsList`
                        )}
                        name="displayInItemsList"
                        handleChange={(e) => handleChangeSwitch(e, row, index)}
                        className={classes.rowActionButtons}
                        size="small"
                      />
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <DataNotFound
              padding={100}
              iconHeight={150}
              iconWidth={200}
              message={t("no.data-mapping.message")}
            />
          )}
        </CustomTable>
      )}
    </TableContainer>
  );
};

const headCells = (t, classes) => [
  {
    label: (
      <InformationIcon
        titleContent={t("Data")}
        originalContent={
          <Typography className={classes.tableHeaderCell}>
            {t("Data")}
          </Typography>
        }
        placement="right"
      />
    ),
  },
  {
    label: (
      <InformationIcon
        titleContent={t("New name")}
        originalContent={
          <Typography className={classes.tableHeaderCell}>
            {t("New name")}
          </Typography>
        }
        placement="right"
      />
    ),
  },
  {
    label: (
      <InformationIcon
        titleContent={t("Display")}
        originalContent={
          <Typography className={classes.tableHeaderCell}>
            {t("Display")}
          </Typography>
        }
        placement="right"
      />
    ),
  },
  {
    label: (
      <InformationIcon
        titleContent={t("can.be.edited.for.retry")}
        originalContent={
          <Typography className={classes.tableHeaderCell}>
            {t("can.be.edited.for.retry")}
          </Typography>
        }
        placement="right"
      />
    ),
  },
  {
    label: (
      <InformationIcon
        titleContent={t("Display in item list'")}
        originalContent={
          <Typography className={classes.tableHeaderCell}>
            {t("Display in item list'")}
          </Typography>
        }
        placement="right"
      />
    ),
  },
];

const mapDispatchToProps = {
  fetchProcessDataMapping,
  updateProcessDataMapping,
};
export default connect(null, mapDispatchToProps)(DataMapping);
