import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    title: {
        fontWeight: 800
    },
    text: {
        fontWeight: 600,
        fontSize: "0.8rem",
        wordBreak: "break-word",
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: "0.8rem",
            wordBreak: "break-word",
            fontFamily: "inherit !important"
        }
    },
    sortedDownIcon: {
        filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)",
        WebkitTransform: "rotate(90deg)",
        MozTransform: "rotate(90deg)",
        MsTransform: "rotate(90deg)",
        OTransform: "rotate(90deg)",
        transform: "rotate(90deg)",
        display: "inline-block",
        fontSize: 23,
        opacity: 0.4,
        cursor: "pointer",
    },
    sortedUpIcon: {
        filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
        WebkitTransform: "rotate(-90deg)",
        MozTransform: "rotate(-90deg)",
        MsTransform: "rotate(-90deg)",
        OTransform: "rotate(-90deg)",
        transform: "rotate(-90deg)",
        display: "inline-block",
        fontSize: 23,
        opacity: 0.4,
        cursor: "pointer",
    },
    unsortedUpIcon: {
        marginTop: "-1.75rem",
    },
    unsortedDownIcon: {
        marginBottom: "-1.75rem",
    },
}));
