export const selectItem = (e, item, array, setArray) => {
    const isChecked = e.target.checked;
    setArray((prevCheckedItems) => (isChecked
        ? [...prevCheckedItems, item]
        : prevCheckedItems.filter((elt) => elt?.id !== item?.id)));
}
export const selectAll = (e, array, setArray) => {
    const isChecked = e.target.checked;
    setArray(isChecked ? array : []);
}

export const removeFromAvailable = (available, checked) => available?.filter((item) => !checked?.some((checkedItem) => item?.id === checkedItem?.id));
export const addToSelected = (selected, checked) => [...selected, ...checked.filter((item) => !selected?.some((selectedItem) => selectedItem?.id === item?.id))];
