import {
    ARCHIVE_PROCESS,
    CALCULATE_FACTOR,
    DELETE_PROCESS, FETCH_AVAILABLE_RPA_QUAL_STATUS,
    FETCH_OPPORTUNITIES,
    LOAD_PROCESS,
    LOAD_QUALIFICATIONS,
    LOAD_STEPS,
    LOAD_STEPS_DATA,
    PROCESS_SUBMIT,
    PROCESS_UPDATED,
    QUALIFY_PROCESS,
    SUBMIT_PROCESS, UPDATE_DEV_COST
} from "redux/constants"
import {
    URL_PROCESS_CALCULATE_FACTOR,
    URL_PROCESS_UPDATED,
    URL_RPA_QUAL_PROCESSES,
    URL_RPA_QUAL_PROCESSES_ARCHIVE,
    URL_STEPPER_LOAD_STEPS, URL_UPDATE_DEV_COST
} from "redux/constants/endpoints"
import { formatQualificationData } from "util"

export const loadOpportunities = ({
  page, limit, name = "", status = "", fleets, showArchived
  }) => ({
  type: FETCH_OPPORTUNITIES,
  request: {
    url: `${URL_RPA_QUAL_PROCESSES}`,
    method: "get",
    params: {
      type: "mycases",
      userid: null,
      page,
      limit,
      name,
      status,
      fleetsIds: fleets?.join(),
      archive: showArchived
    },
    headers: { "Content-Type": "application/json" }
  }
})

export const qualifyProcess = (process, onSuccess, onError) => ({
  type: QUALIFY_PROCESS,
  request: {
    url: `${URL_RPA_QUAL_PROCESSES}/${process.id}`,
    method: "put",
    data: process,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess()
        return response
    },
    onError: (error) => {
        if (onError) onError()
        throw error
    }
  }
})

export const deleteProcess = (process, onSuccess, onError) => ({
  type: DELETE_PROCESS,
  request: {
    url: `${URL_RPA_QUAL_PROCESSES}/${process.id}`,
    method: "delete",
    data: process,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess()
        return response
    },
    onError: (error) => {
        if (onError) onError()
        throw error
    }
  }
})

export const archiveProcess = (process, onSuccess, onError) => ({
  type: ARCHIVE_PROCESS,
  request: {
    url: `${URL_RPA_QUAL_PROCESSES_ARCHIVE}/${process.id}`,
    method: "put",
    data: process,
  },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess()
            return response
        },
        onError: (error) => {
            if (onError) onError()
            throw error
        }
    }
})

export const submitProcess = (process) => ({
  type: SUBMIT_PROCESS,
  request: {
    url: `${URL_RPA_QUAL_PROCESSES_ARCHIVE}/${process.id}/status`,
    method: "put",
    data: process,
  },
});

export const loadStepsData = () => ({
  type: LOAD_STEPS,
  request: {
    url: `${URL_STEPPER_LOAD_STEPS}`,
    method: "get",
  },
  meta: {
    onSuccess: (res) => ({ ...res, data: formatQualificationData(res.data) }),
    onError: (err) => {
      throw err
    },
  },
});

export const loadQualificationByProcess = (id) => ({
  type: LOAD_QUALIFICATIONS,
  request: {
      url: `${URL_STEPPER_LOAD_STEPS}/${id}`,
      method: "get",
  }
})

export const updateProcessStepper = (id, data, onSuccess) => ({
  type: PROCESS_UPDATED,
  request: {
      url: `${URL_PROCESS_UPDATED}/${id}`,
      method: "put",
      data
  },
  meta: {
    onSuccess: (result) => {
        if (onSuccess) onSuccess()
        return result
    }
  }
})

export const submitProcessStepper = (data, onSuccess) => ({
  type: PROCESS_SUBMIT,
  request: {
      url: `${URL_RPA_QUAL_PROCESSES}`,
      method: "post",
      data
  },
  meta: {
    onSuccess: (result) => {
        if (onSuccess) onSuccess()
        return result
    }
  }
})

export const calculateFactors = (data) => ({
  type: CALCULATE_FACTOR,
  request: {
      url: `${URL_PROCESS_CALCULATE_FACTOR}`,
      method: "post",
      data
  },
})

export const loadProcess = (id, onSuccess, onError) => ({
  type: LOAD_PROCESS,
  request: {
      url: `${URL_RPA_QUAL_PROCESSES}/${id}`,
      method: "get",
  },
  meta: {
      onSuccess: (res) => {
          if (onSuccess) onSuccess()
          return res
      },
      onError: (err) => {
          if (onError) onError()
          throw err
      }
  }
})

export const loadSteps = (onSucess, onError) => ({
  type: LOAD_STEPS_DATA,
  request: {
      url: `${URL_STEPPER_LOAD_STEPS}`,
      method: "get",
  },
  meta: {
      onSucess: (res) => {
          if (onSucess) onSucess()
          return res
      },
      onError: (err) => {
          if (onError) onError()
          throw err
      }
  }
});

export const fetchAvailableRpaQualStatus = () => ({
    type: FETCH_AVAILABLE_RPA_QUAL_STATUS,
    request: {
        url: `${URL_RPA_QUAL_PROCESSES}/status/available`,
        method: "get",
    },
});
export const updateDevelopementCost = (id, devCost) => ({
    type: UPDATE_DEV_COST,
    request: {
        url: `${URL_UPDATE_DEV_COST}/${id}`,
        method: "put",
        data: devCost,
    },
});
