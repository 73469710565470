import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import { format } from "date-fns-tz";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import {
  secondsToTime, formatDateByLanguage
} from "util";
import { useQuery } from "@redux-requests/react";
import { languageLSKey } from "util/configs/general";
import { FETCH_CURRENT_USER } from "../../../redux/constants";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchExecutions,
  fetchAllStatus,
  exportFilteredExecutionsXLSX,
  fetchExportedFiles,
  fetchTagsForFilters,
  fetchExecutionsResources,
  fetchFleetsForFilter,
  fetchExecutionsCount,
  fetchProcessesForFilters,
  fetchProcessExecutionOrsConf, changeOrsStatus, requestProcessExecutionStop
} from "../../../redux/actions/services";
import Filter from "./Filter";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../components/TableComponents/CustomTable";
import ProcessIconName from "../../../components/TableComponents/ProcessIconName";
import DataNotFound from "../../../components/DataNotFound";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import StatusButton from "../../../components/StatusButton";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import OccurrencesNotFound from "../../../assets/Process_Overview.svg";
import AdditionalInfoTooltip from "components/AdditionalInfoTooltip";
import Details from "components/DataDetail";
import useStyles from "./style";
import { Stop, Fullscreen, FullscreenExit } from "@material-ui/icons";
import clsx from "clsx";
import ConfirmMessage from "components/ConfirmMessage";
import { URL_GUACAMOLE } from "redux/constants/endpoints";
import CustomDialog from "components/CustomDialog";
import CustomSwitch from "pages/Services/components/CustomSwitch";

const dateFormat = "yyyy/MM/dd";
function ExecutionsPage(props) {
  const [iframeDetails, setIframeDetails] = useState({
    width: null,
    height: null,
    src: null,
  });
  const [resourceIframeDetails, setResourceIframeDetails] = useState({
    width: null,
    height: null,
    src: null,
  });
  const classes = useStyles({ width: 1400, height: 600 })();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("launchingDate");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [processName, setProcessName] = React.useState([]);
  const [processes, setProcesses] = React.useState([]);
  const [tagName, setTagName] = React.useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMsgDetail, setOpenMsgDetail] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [statusRef, setStatusRef] = React.useState([]);
  const [resourceName, setResourceName] = React.useState([]);
  const [resources, setResources] = React.useState([]);
  const [triggers, setTriggers] = React.useState([]);
  const [executionStartTime, setExecutionStartTime] = React.useState(null);
  const [executionEndTime, setExecutionEndTime] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const executionsFilter = useSelector(({ executionsFilter }) => executionsFilter);
  const [showCustomDate, setShowCustomDate] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [fleets, setFleets] = useState([]);
  const [selectedFleets, setSelectedFleets] = useState([]);
  const [parsedInputs, setParsedInputs] = useState([]);
  const [selectedExecutionId, setSelectedExecutionId] = useState("");
  const [isOrsOpen, setIsOrsOpen] = useState(false);
  const [isOrsLoading, setIsOrsLoading] = useState(false);
  const [isOrsEnabled, setIsOrsEnabled] = useState(true);
  const [orsConnectionId, setOrsConnectionId] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const EMPTY_DEFAULT_VALUE = "---";
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const [executionStopMessageOpen, setExecutionStopMessageOpen] = useState(false);
  const [executionStopMessageLoading, setExecutionStopMessageLoading] = useState(false);
  const labels = [
    { label: "" },
    { label: t("Resource") },
    { label: t("Start date") },
    { label: t("End date") },
    { label: t("Execution Time") },
    { label: t("Items processed") },
    { label: t("Status") },
  ];

  const additionalInfos = (row) => [
    { name: t("Launching date"), value: formatDateByLanguage(row?.launchingTime) || EMPTY_DEFAULT_VALUE },
    { name: t("Trigger"), value: row?.executionTrigger || EMPTY_DEFAULT_VALUE },
    ...(row?.missedReason ? [{ name: t("missed raison"), value: t(row.missedReason) }] : []),
    ...(row?.exceptionType ? [{ name: t("Exception Type"), value: t(row?.exceptionType) }] : []),
    ...(row?.exceptionReason ? [{ name: t("Exception Reason"), value: t(row?.exceptionReason) }] : []),
  ];

  const onClickLaunch = (rowId, connectionId) => {
  setIsOrsOpen(true)
  setIsOrsLoading(true)
  setOrsConnectionId(connectionId)
  dispatch(fetchProcessExecutionOrsConf(connectionId, onSuccessFetchOrsConf, onErrorFetchOrsConf))
};
  const updateOrsStatus = () => {
    dispatch(changeOrsStatus(orsConnectionId, !isOrsEnabled, onSuccessChangeOrsStatus, onErrorChangeOrsStatus))
  }
  const onSuccessChangeOrsStatus = () => {
    setIsOrsEnabled((prevState) => !prevState);
    dispatch(fetchProcessExecutionOrsConf(orsConnectionId, onSuccessFetchOrsConf, onErrorFetchOrsConf))
  }
  const onErrorChangeOrsStatus = () => {
    toast.error(t("something went wrong"));
  }
 const onSuccessFetchOrsConf = (res) => {
   const {
 width, height, path, orsDisabled
} = res.data;
   const src = `${process.env.REACT_APP_GATEWAY + URL_GUACAMOLE}/guacamole${path}`;
   setIframeDetails({ width, height, src });
   setResourceIframeDetails({ width, height, src });
   setIsOrsLoading(false)
   if (orsDisabled)
     { toast.warn("enable.ors"); }
  }
 const onErrorFetchOrsConf = (res) => {
   setIsOrsLoading(false)
   let message = "something went wrong";
   if (res.response.status === 422) message = "ors.connection.lost";
   toast.error(t(message));
   setIsOrsOpen(false)
 }

  useEffect(() => {
    if (!loaded && currentUser) {
      setLoaded(true);
      props.fetchProcessesForFilters().then((result) => {
        setProcesses(result?.data);
        props.fetchExecutionsResources(result.data?.map(({ id }) => id)).then((result) => {
          setResources(result.data);
        })
      });
      props.fetchTagsForFilters().then((result) => {
        setTags(result.data);
      });
      props.fetchAllStatus().then((result) => {
        let statusList = result.data;
        if (result?.data) {
          statusList = result?.data;
          statusList = statusList.sort((a, b) => (`${a.statusLabel}`).localeCompare(b.statusLabel));
        }
        setStatusRef(statusList);
      });
      props.fetchFleetsForFilter().then((res) => {
        setFleets(res.data);
      });
      getExecutions(
        page,
        rowsPerPage,
        executionsFilter.order.id,
        executionsFilter.order.order,
        executionsFilter.process,
        executionsFilter.status,
        executionsFilter.executionFromDate,
        executionsFilter.executionToDate,
        executionsFilter.searchText,
        executionsFilter.resource,
        executionsFilter.trigger,
        executionsFilter.tags,
        selectedFleets
      );
    }
  }, []);

  useEffect(() => {
    props.fetchTagsForFilters(executionsFilter?.fleets, executionsFilter.process?.map(({ id }) => id)).then((result) => {
      setTags(result.data);
    });
  }, [executionsFilter.process, executionsFilter?.fleets]);
  useEffect(() => {
    props.fetchProcessesForFilters(tagName.map(({ id }) => id), executionsFilter?.fleets).then((result) => {
      setProcesses(result?.data);
      props.fetchExecutionsResources(result.data?.map(({ id }) => id)).then((result) => {
        setResources(result.data);
      })
    });
  }, [tagName, executionsFilter?.fleets]);

  const getDateDifference = (time) => secondsToTime(time, t);

  const handleChangeProcess = (values) => {
    setProcessName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      values,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };
  const handleChangeTags = (values) => {
    setTagName(values);
    setPage(0);
    getExecutions(
        0,
        rowsPerPage,
        orderBy,
        order,
        processName,
        status,
        executionStartTime,
        executionEndTime,
        searchText,
        resourceName,
        triggers,
        values,
        selectedFleets
    );
  }
  const handleChangeDates = (from, to) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setExecutionStartTime(from);
    setExecutionEndTime(to);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      from,
      to,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };

  const handleChangeStatus = (values) => {
    setStatus(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      values,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };

  const handleChangeResource = (values) => {
    setResourceName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      values,
      triggers,
      tagName,
      selectedFleets
    );
  };

  const handleChangeTrigger = (values) => {
    setTriggers(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      values,
      tagName,
      selectedFleets
    );
  };

  const handleRequestSort = (property) => {
    setOrder(property.order);
    setOrderBy(property.id);
    getExecutions(
      page,
      rowsPerPage,
      property.id,
      property.order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getExecutions(
      newPage,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getExecutions(
      page + 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
    }
    const handlePrevious = (page) => {
      setPage(page - 1);
    getExecutions(
      page - 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
    }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getExecutions(
      0,
      event.target.value,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      resourceName,
      triggers,
      tagName,
      selectedFleets
    );
  };
  const handleChangeFleet = (values) => {
    setSelectedFleets(values)
    getExecutions(
        0,
        rowsPerPage,
        orderBy,
        order,
        [],
        status,
        executionStartTime,
        executionEndTime,
        searchText,
        resourceName,
        triggers,
        tagName,
        values,
    );
  };
  const getExecutions = (
    page,
    size,
    sortField,
    sortOrder,
    processesList = [],
    status = [],
    executionStartTime = null,
    executionEndTime = null,
    searchText = "",
    resources = [],
    triggers = [],
    tags = [],
    fleets = []
  ) => {
    setIsLoading(true);
    const processID = !processesList || processes?.length === processesList?.length ? [] : processesList.map((e) => e.id);
    const statusId = status ? status.map((e) => e.id) : [];
    const resourceId = resources ? resources.map((e) => e.id) : [];
    const tagId = tags ? tags.map((e) => e.id) : [];
    props
      .fetchExecutions(
        page,
        size,
        sortField,
        sortOrder,
        processID,
        statusId,
        executionStartTime,
        executionEndTime,
        searchText,
        resourceId,
        triggers,
        tagId,
        fleets
      )
      .then((result) => {
        setData(result?.data);
        setIsLoading(false);
      });
    props
        .fetchExecutionsCount(
            processID,
            statusId,
            executionStartTime,
            executionEndTime,
            searchText,
            resourceId,
            triggers,
            tagId,
            fleets
        )
        .then((result) => {
          setCount(result?.data);
        });
  };
  const exportFilteredExecutions = () => {
    localStorage.setItem(languageLSKey, i18n.language);
    setExportLoading(true)
    const processID = processName ? processName.map(({ id }) => id) : [];
    const statusId = status ? status.map(({ id }) => id) : [];
    const resourceId = resourceName ? resourceName.map(({ id }) => id) : [];
    const tagId = tagName ? tagName.map(({ id }) => id) : [];
    dispatch(
        exportFilteredExecutionsXLSX(
            orderBy,
            order,
            processID,
            statusId,
            executionStartTime,
            executionEndTime,
            searchText,
            resourceId,
            triggers,
            tagId,
            onSuccesExport,
            onErrorExport,
            count
        )
    )
  }
  const onSuccesExport = () => {
    setExportLoading(false);
    dispatch(fetchExportedFiles());
    toast.success(t("export.successful.notification"));
  }
  const onErrorExport = () => {
    setExportLoading(false);
    toast.error(t("something went wrong"));
  }

  const handleOpenOccurenceDetails = () => setOpenMsgDetail(true);

  const onClickRow = (executionInputs) => {
    handleOpenOccurenceDetails();
    if (!executionInputs) {
      setParsedInputs([]);
      return;
    }

    executionInputs = JSON.parse(executionInputs);

    const inputs = Object.keys(executionInputs).map((key) => ({
      name: key,
      value: executionInputs[key]
    }));

    setParsedInputs(inputs);
  };

  const closeDialog = () => setOpenMsgDetail(false);

  const handleChangeSearchText = (value) => {
    setSearchText(value);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      value,
      resources,
      triggers,
      tagName,
      selectedFleets
    );
  };
  const handleClearFilter = () => {
    getExecutions(
      0,
      rowsPerPage,
      columns[0].id,
      columns[0].order,
    );
  }
  const isExecutionStoppable = (row) => row?.statusCode === "RUNNING" && row?.process?.isStoppable;
  const handleStopExecution = (row) => {
    if (isExecutionStoppable(row)) {
      setSelectedExecutionId(row?.id);
      setExecutionStopMessageOpen(true);
    }
  };
  const handleStopExecutionCancel = () => {
    setExecutionStopMessageOpen(false);
    setSelectedExecutionId("");
  };
  const handlePopUpClose = () => {
    setExecutionStopMessageLoading(false);
    setExecutionStopMessageOpen(false);
    setSelectedExecutionId("");
  };
  const processExecutionStopOnSuccess = () => {
    handlePopUpClose();
    toast.success(t("Stop.Execution.success.notification"));
  };
  const processExecutionStopOnError = () => {
    handlePopUpClose();
    toast.error(t("Stop.Execution.error.notification"));
  };
  const handleProcessExecutionConfirm = () => {
    setExecutionStopMessageLoading(true);
    dispatch(requestProcessExecutionStop(selectedExecutionId, processExecutionStopOnSuccess, processExecutionStopOnError))
  };

  const areIframeDetailsEqual = () => resourceIframeDetails.height === iframeDetails.height && resourceIframeDetails.width === iframeDetails.width

  const setOrsDialogToMax = () => {
    if (areIframeDetailsEqual()) {
      const height = iframeDetails.height > 1080 ? iframeDetails.height + 90 : 1080;
      const width = iframeDetails.width > 1920 ? iframeDetails.width : 1920;
      setIframeDetails({ width, height, src: resourceIframeDetails.src });
    } else setIframeDetails(resourceIframeDetails);
  }

  return (
    <Grid container>
      <Filter
        processes={processes}
        status={statusRef}
        resources={resources}
        tags={tags}
        handleChangeProcess={handleChangeProcess}
        handleChangeStatus={handleChangeStatus}
        handleChangeResource={handleChangeResource}
        handleChangeDates={handleChangeDates}
        handleChangetags={handleChangeTags}
        handleRequestSort={handleRequestSort}
        handleChangeTrigger={handleChangeTrigger}
        handleChangeSearchText={handleChangeSearchText}
        handleClearFilter={handleClearFilter}
        columns={columns}
        showCustomDate={showCustomDate}
        setShowCustomDate={setShowCustomDate}
        exportFilteredExecutions={exportFilteredExecutions}
        exportLoading={exportLoading}
        showExportButton={data && data.length > 0}
        fleets={fleets}
        handleChangeFleet={handleChangeFleet}
        executionsCount={count}
      />
      <CustomTableContainer>
        <CustomTable>
          {data?.length > 0 && <CustomTableHeader labels={labels} />}
          {
        // eslint-disable-next-line no-nested-ternary
        isLoading || !data ? (
          <CircularLoader />
        ) : (data && data.length > 0
              ? data.map((row, index) => (
                <CustomTableRow key={index}>
                  <CustomTableCell>
                    <ProcessIconName
                        imgSrc={row?.process?.icon}
                        processName={row?.process?.processName}
                    />
                  </CustomTableCell>
                  <CustomTableCell text={row?.resource?.name || row?.startingResource?.name || EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell text={formatDateByLanguage(row?.executionStartTime) || EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell text={formatDateByLanguage(row?.executionEndTime) || EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell text={getDateDifference(row?.executionDuration)} />
                  <CustomTableCell text={row?.itemsProcessed || EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell>
                    {
                        (row.statusCode || row.srStatus)
                        && <StatusButton
                            status={row?.statusCode ? row.statusCode : row.srStatus}
                            label={row?.statusLabel ? row.statusLabel : row.srStatus}
                        />
                    }
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" justifyContent="flex-end">
                      {row?.orsOpened && (
                        <IconButton onClick={() => onClickLaunch(row?.id, row?.orsConnectionId)}>
                          <LaunchIcon />
                        </IconButton>
                        )}
                      {row?.executionInputs && (
                      <Tooltip title={t("Launched with input")}>
                        <InfoOutlinedIcon className={classes.infoIcon} onClick={() => onClickRow(row?.executionInputs)} />
                      </Tooltip>
                        )}
                      <AdditionalInfoTooltip data={additionalInfos(row)} />
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Grid container xs={12} direction="row" justify="flex-end" alignItems="center">
                      <Box
                      className={clsx(classes.stopIconContainer, !isExecutionStoppable(row) ? classes.stopIconContainerDisabled : "")}
                      disabled={!isExecutionStoppable(row)}
                      onClick={() => handleStopExecution(row)}
                      >
                        <Stop className={clsx(classes.stopIcon, !isExecutionStoppable(row) ? classes.stopIconDisabled : "")} disabled={!isExecutionStoppable(row)} />
                      </Box>
                    </Grid>
                  </CustomTableCell>
                </CustomTableRow>
              ))
              : (
                <DataNotFound
                      message={t("no.executions.message")}
                      icon={OccurrencesNotFound}
                  />
              )
        )
      }
        </CustomTable>
      </CustomTableContainer>

      <Grid container xs={12} direction="row" justify="flex-end">
        {!isLoading && data && data.length > 0 && (
        <CustomPagination
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onNext={handleNext}
                onPrevious={handlePrevious}
            />
        )}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          openStart={openMsgDetail}
          data={parsedInputs}
        />
      )}
      {executionStopMessageOpen && (
        <ConfirmMessage
          title={t("Stop.Execution.confirmation.title")}
          openStart={executionStopMessageOpen}
          onConfirm={handleProcessExecutionConfirm}
          onCancel={handleStopExecutionCancel}
          isLoading={executionStopMessageLoading}
          buttonConfirm={t("Continue")}
          buttonCancel={t("Cancel")}
        />
      )}
      <CustomDialog
          open={isOrsOpen}
          onClose={() => setIsOrsOpen(false)}
          className={classes.dialog}
          width={iframeDetails?.width || 1400}
          height={iframeDetails?.height || 600}
          title={
            <Box display="flex" justifyContent="space-between" width="100%">
              <IconButton onClick={setOrsDialogToMax}>
                {areIframeDetailsEqual() ? <Fullscreen /> : <FullscreenExit />}
              </IconButton>
              {
                !isOrsLoading
                 && (<CustomSwitch
                      label={t("resource.management.formControl.activateOrs")}
                      handleChange={updateOrsStatus} // TODO handle switch change
                      checked={isOrsEnabled} // TODO update value
                  />)
              }

            </Box>
          }
      >
        {isOrsLoading
        ? <CircularLoader />
        : <iframe
          src={iframeDetails?.src}
          width={iframeDetails?.width}
          height={iframeDetails?.height}
          title="Remote Session"
          className={classes.orsFrame}
        />}
      </CustomDialog>
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExecutions,
  fetchProcessesForFilters,
  fetchAllStatus,
  fetchTagsForFilters,
  fetchExecutionsResources,
  fetchFleetsForFilter,
  fetchExecutionsCount
};
export default connect(null, mapDispatchToProps)(ExecutionsPage);

const columns = [
  {
    id: "launchingDate",
    label: "Launching date (Descending)",
    order: "desc",
  },
  {
    id: "launchingDate",
    label: "Launching date (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];
