export const STATUS = {
    READY: "READY",
    DONE: "DONE",
    TAKEN: "TAKEN",
};
export const exceptionCurrentModule = {
    ITEMS: "itemsExceptions",
    PROCESSES: "processesExceptions",
};
export const exceptionCurrentModuleRoutes = {
    ITEMS: { name: "itemsExceptions", route: "exception" },
    PROCESSES: { name: "processesExceptions", route: "processesExceptions" },
}
export const childType = {
    EXCEPTIONS_TYPE: "EXCEPTIONS TYPE"
}
