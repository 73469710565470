import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../../../components/CustomDialog";
import useStyles from "../../../style.js";

function DeletePresetDialog({ presetId, onClose, onSubmit }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <CustomDialog
          title={t("analytics.preset.dialog.delete.label")}
          contentText={t("analytics.preset.dialog.delete.text")}
          actionElements={[
              {
                  label: t("Cancel"),
                  onClick: onClose,
                  color: "primary",
              },
              {
                  label: t("analytics.preset.dialog.delete.submit"),
                  onClick: () => onSubmit(presetId),
                  view: "primary",
              }
          ]}
          open={presetId !== -1}
          onClose={onClose}
          disableBackdropClick
          fullWidth
          className={classes.deleteDialog}
      />
    );
}

export default DeletePresetDialog;
