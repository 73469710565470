import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    boxShadow: theme.custom.shadow,
  },
  factorSimple: {
    backgroundColor: theme.custom.palette.factors.simple.bgColor,
    color: theme.custom.palette.factors.simple.textColor,
    minWidth: 50,
    padding: "6px",
    borderRadius: theme.spacing(1),
    fontWeight: 500,
    textAlign: "center",
  },
  factorMedium: {
    backgroundColor: theme.custom.palette.factors.medium.bgColor,
    color: theme.custom.palette.factors.medium.textColor,
    minWidth: 50,
    padding: "6px",
    borderRadius: theme.spacing(1),
    fontWeight: 500,
    textAlign: "center",
  },
  factorComplex: {
    backgroundColor: theme.custom.palette.factors.complex.bgColor,
    color: theme.custom.palette.factors.complex.textColor,
    minWidth: 50,
    padding: "6px",
    borderRadius: theme.spacing(1),
    fontWeight: 500,
    textAlign: "center",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.type === "light" ? "#f9fafb" : "#383838",
      $processName: {
        color: theme.palette.primary.main,
      },
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  statusAccepted: {
    color: theme.custom.palette.status.accepted,
  },
  statusRejected: {
    color: theme.custom.palette.status.rejected,
  },
  statusPending: {
    color: theme.custom.palette.status.pending,
  },
  statusDraft: {
    color: theme.custom.palette.status.draft,
  },
  statusNotSubmitted: {
    color: theme.custom.palette.status.notSubmitted,
  },
  statusSubmitted: {
    color: theme.custom.palette.status.submitted,
  },
  statusText: {
    fontWeight: 500,
  },
  statusIcon: {
    fontSize: "1.3rem",
  },
  roadmapIcon: {
    color: theme.custom.palette.status.submitted,
  },
  factorsContainer: {
    borderLeft: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
    }`,
    borderRight: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
    }`,
  },
  processName: {
    fontWeight: 500,
  },
  avatar: {
    marginRight: theme.spacing(1.5),
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "1rem",
  },
  noFactor: {},
  deleteIcon: {
    color: theme.palette.error.main,
  },
  editIcon: {
    color: theme.custom.palette.actions.edit,
  },
  rejectIcon: {
    color: theme.custom.palette.actions.reject,
  },
  otherIcons: {
    color: theme.palette.primary.main,
  },
  accept: {
    color: theme.custom.palette.actions.accept,
  },
  sortableField: {
    cursor: "pointer",
  },
  columnHeader: {
    maxWidth: 60,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  actionButtons: {
    marginLeft: "-15%",
  },
  hideXScroll: {
    overflowX: "unset"
  }
}));
