import
{
  FormHelperText,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useWatch } from "react-hook-form";
import { fetchOrchestratorsNamesAndIds, fetchResourceList } from "redux/actions/services";
import NoDataMenu from "../../../../../../components/NoData/NoDataMenu";
import { DISCONNECTED_ROBOT_WHEN } from "../../../../../../util/constants/alerts";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";

export default function DefaultTriggerRow({
  classes,
  errors,
  disableFields,
  menuItems,
  control,
  processMenuItem,
  register,
  trigger,
  selectedFleet
}) {
    const dispatch = useDispatch();
  const { t } = useTranslation();
  const watch = useWatch({ control });
  const [resources, setResources] = useState([]);
  const [orchestrators, setOrchestrators] = useState([]);
  const ofProcessArr = ["Schedule added"]
  const ofOrchestratorArr = ["A license is one month away from expiry", "A license is two months away from expiry"]
  useEffect(() => {
    if (watch.when === DISCONNECTED_ROBOT_WHEN)
    {
        dispatch(fetchResourceList({ fleets: [selectedFleet] })).then((res) => {
            setResources(res.data.content)
        })
    }
    if (ofOrchestratorArr.includes(watch.when)) {
      dispatch(fetchOrchestratorsNamesAndIds()).then((res) => {
        setOrchestrators(res.data)
      })
    }
  }, [watch.when, selectedFleet])
  return (
    <>
      <Grid item xs={6}>
        <Controller
          {...register("when")}
          name="when"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <CustomAutoComplete
              options={menuItems.whenMenuItem ?? []}
              label={t("alerts.management.when.placeholder")}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={field?.value}
              onChange={field?.onChange}
              disabled={disableFields}
              error={errors?.when}
              helperText={errors?.when && errors?.when?.message}
              inputRef={field?.ref}
              {...field}
            />
          )}
        />
      </Grid>
      {ofProcessArr.includes(watch.when) && (
        <Grid item xs={6}>
          <Controller
          name="processId"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <CustomAutoComplete
              options={processMenuItem ?? []}
              label={t("alerts.management.of.placeholder")}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              optionLabel={(option) => option?.processDescription?.processDisplayName}
              value={processMenuItem?.find((item) => item?.id === field?.value)}
              onChange={(value) => {
                field?.onChange(value?.id)
              }}
              disabled={disableFields}
              error={errors?.processId}
              helperText={errors?.processId && errors?.processId?.message}
              inputRef={field?.ref}
              noOptionsNode={<NoDataMenu message={t("no.process.message")} marginX={8} />}
            />
          )}
          />
        </Grid>
      )}
      {ofOrchestratorArr.includes(watch.when) && (
        <Grid item xs={6}>
          <Controller
          name="orchestratorId"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <CustomAutoComplete
              options={orchestrators ?? []}
              label={t("alerts.management.of.placeholder")}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              optionLabel={(option) => option?.name}
              value={orchestrators?.find((item) => item?.id === field?.value)}
              onChange={(value) => {
                field?.onChange(value?.id)
              }}
              disabled={disableFields}
              error={errors?.orchestratorId}
              helperText={errors?.orchestratorId && errors?.orchestratorId?.message}
              inputRef={field?.ref}
              noOptionsNode={<NoDataMenu message={t("no.orchestrator.message")} marginX={8} />}
            />
          )}
          />
        </Grid>
      )}

      {watch.when === DISCONNECTED_ROBOT_WHEN && (
      <>
        <Grid item xs={6}>
          <Controller
            name="resourcesIds"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <CustomAutoComplete
                multiple
                options={resources ?? []}
                label={`${t("resource.management.menu.title")} *`}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                optionLabel="resourceDisplayName"
                optionUuid="id"
                value={resources?.filter((item) => field?.value?.includes(item?.id))}
                onChange={(value) => {
                  field?.onChange(value?.map((item) => item?.id))
                }}
                disabled={disableFields}
                error={errors?.resourcesIds}
                helperText={errors?.resourcesIds && errors?.resourcesIds?.message}
                inputRef={field?.ref}
                noOptionsNode={<NoDataMenu message={t("no.resource.message")} marginX={8} />}
              />
            )}
            />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
              fullWidth
              name="disconnectionPeriod"
              label={t("disconnection.period")}
              placeholder={t("disconnection.period")}
              disableFields={disableFields}
              error={errors?.disconnectionPeriod}
              classes={classes}
              {...register(
                   "disconnectionPeriod",
                  {
                  required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                    },
                  validate: (value) => value >= 0 || t("restriction.form.minValue.error"),
                  }
              )}
              type="number"
              onBlur={() => trigger("disconnectionPeriod")}
          />
          <FormHelperText className={classes.validation_error}>
            {errors?.disconnectionPeriod?.message}
          </FormHelperText>
        </Grid>
      </>
      )}

    </>
  );
}
