/* eslint-disable no-nested-ternary */
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import { useDispatch, useSelector } from "react-redux";
import AlertsTable from "../AlertsTable";
import { HasPermission } from "../../../components/HasPermission";
import StatusBadge from "../../../components/StatusBadge";
import ConfirmMessage from "../../../components/ConfirmMessage";
import {
  changeDefaultAlertSubscriptionStatus,
  deleteDefaultAlert,
  enableOrDisableEmailForDefaultAlert,
  enableOrDisableSmsForDefaultAlert,
  fetchDefaultAlerts,
} from "../../../redux/actions/services";
import { FETCH_DEFAULT_ALERTS } from "../../../redux/constants";
import { useQuery } from "../../../components/Filter";
import { alertCurrentModule } from "util";
import { toast } from "react-toastify";
import { disableSmsFunctionality } from "util/constants/PermitedFeature";
import useStyles from "./style";
import CustomButton from "components/CustomButton";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import { get } from "lodash";
import DefaultAlertDialog from "./DefaultAlertDialog";

export default function DefaultAlerts() {
  const entityBaseUrl = "/alerts/defaultAlerts";
  const fetchEntities = fetchDefaultAlerts;
  const deleteEntity = deleteDefaultAlert;
  const entityQuery = FETCH_DEFAULT_ALERTS;
  const searchField = "searchCriteria";
  const classes = useStyles();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const alertFilter = useSelector(
    ({ filterListAlert }) => filterListAlert[alertCurrentModule.DEFAULT_ALERT],
  );
  const headerCells = [
    {
      id: "status",
      label: "",
    },
    {
      id: "alertName",
      label: t("alert.alertName"),
    },
    {
      id: "event",
      label: t("default.alert.event"),
    },

    {
      id: "type",
      label: t("alert.type"),
    },
    {
      id: "email",
      label: "",
    },
    {
      id: "sms",
      label: "",
    },
  ];

  const [active, setActive] = useState({
    index: null,
    value: null,
    field: null,
  });

  const statusList = [
    { code: "ACTIVE", label: "ACTIVE" },
    { code: "DISABLED", label: "DISABLED" },
  ];
  const typeList = [
    { code: "INFO", label: "info" },
    { code: "WARNING", label: "warning" },
    { code: "ERROR", label: "error" },
  ];

  const query = useQuery();
  const queryParam = query.get("q");

  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
  });

  const [defaultAlertDialogOpen, setDefaultAlertDialogOpen] = useState(false);
  const [defaultAlertDialogMode, setDefaultAlertDialogMode] = useState("add");
  const [defaultAlertDialogCurrentId, setDefaultAlertDialogCurrentId] = useState(null);

  const resetLoaders = () => setIsloading({ switch: false, deleteAction: false });

  const handleSwitchEmail = (e, index, value) => {
    e.stopPropagation();
    setOpenConfirmationPopup(true);
    setActive({ index, value, field: "email" });
  };

  const confirmSwitchEmail = () => {
    setIsloading({ ...isLoading, switch: true });
    dispatch(
      enableOrDisableEmailForDefaultAlert(active.index, active.value),
    ).then((err) => {
      if (err?.message?.data?.detail) {
        toast.error(t(err?.message?.data?.detail))
        setOpenConfirmationPopup(false);
      } else if (err?.action?.error?.response?.data?.message) {
        setOpenConfirmationPopup(false);
        resetLoaders();
        toast.info(t(err?.action?.error?.response?.data?.title, { processName: err?.action?.error?.response?.data?.detail }))
      } else {
        resetLoaders();
        dispatch(
          fetchEntities({ ...alertFilter, searchCriteria: queryParam }),
        );
        setOpenConfirmationPopup(false);
      }
    });
  };

  const handleSwitchSms = (e, index, value) => {
    e.stopPropagation();
    setOpenConfirmationPopup(true);
    setActive({ index, value, field: "sms" });
  };

  const confirmSwitchSms = () => {
    setIsloading({ ...isLoading, switch: true });
    dispatch(
      enableOrDisableSmsForDefaultAlert(active.index, active.value),
    ).then((err) => {
      if (err?.message?.data?.detail) {
        toast.error(t(err?.message?.data?.detail))
        setOpenConfirmationPopup(false);
      } else if (err?.action?.error?.response?.data?.message) {
        setOpenConfirmationPopup(false);
        resetLoaders();
        toast.info(t(err?.action?.error?.response?.data?.title, { processName: err?.action?.error?.response?.data?.detail }))
      } else {
        resetLoaders();
        dispatch(
          fetchEntities({ ...alertFilter, searchCriteria: queryParam }),
        );
        setOpenConfirmationPopup(false);
      }
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const getSubscriptionStatus = (alertSubscribersIds) => {
    const subscribersList = alertSubscribersIds?.split(",");
    return subscribersList?.includes(currentUser?.id);
  }

  const handleChangeSubscriptionStatus = (e, id, status) => {
    e.stopPropagation();
    dispatch(changeDefaultAlertSubscriptionStatus(id, status, onSubscriptionSuccess, onSubscriptionError));
  }

  const onSubscriptionSuccess = () => {
    resetLoaders();
    toast.success(t("alert.management.subscription.status.success"))
    dispatch(
      fetchEntities({ ...alertFilter, searchCriteria: queryParam }),
    );
  }

  const onSubscriptionError = () => {
    resetLoaders();
    toast.error(t("alert.management.subscription.status.error"))
  }

  const handleDefaultAlertDialogOpen = (id, mode) => {
    setDefaultAlertDialogOpen(true);
    setDefaultAlertDialogMode(mode);
    setDefaultAlertDialogCurrentId(id);
  };

  const handleDefaultAlertDialogClose = (reloadData = false) => {
    setDefaultAlertDialogOpen(false);
    setDefaultAlertDialogMode("add");
    setDefaultAlertDialogCurrentId(null);
    if (reloadData) {
      dispatch(
        fetchEntities({ ...alertFilter, searchCriteria: queryParam }),
      );
    }
  };

  const handleDefaultAlertEdit = () => {
    setDefaultAlertDialogMode("edit");
  };

  const labels = {
    title: "alerts.default.tab.title",
    addButton: "alerts.default.addButton",
    searchPlaceHolder: "Search",
    deleteEntity: "alert.management.delete.confirmMsg",
  };

  const displayFields = [
    {
      id: "status",
      width: 50,
      valuePath: "status",
      customContent: ({ value }) => <StatusBadge level={value} />,
    },
    {
      id: "alertName",
      valuePath: "alertName",
    },
    {
      id: "event",
      valuePath: "when",
      customContent: ({ value }) => t(value),
      contentTypeString: true
    },

    {
      id: "type",
      valuePath: "flag",
      customContent: ({ value }) => (value === "INFO" ? (
        <Typography variant="subtitle1" className={classes.infoColor}>
          <InfoOutlinedIcon className={classes.iconStyle} />
          {t("info")}
        </Typography>
          ) : value === "WARNING" ? (
            <Typography variant="subtitle1" className={classes.warningColor}>
              <WarningOutlinedIcon className={classes.iconStyle} />
              {t("warning")}
            </Typography>
          ) : value === "ERROR" ? (
            <Typography variant="subtitle1" className={classes.errorColor}>
              <ErrorOutlineOutlinedIcon className={classes.iconStyle} />
              {t("error")}
            </Typography>
          ) : (
            ""
          )),
      contentTypeString: true
    },
    {
      id: "email",
      valuePath: "emailStatus",
      customContent: ({ value, index, emailsLength }) => (
        <HasPermission name="Add default alert">
          <Grid container alignItems="center">
            <Tooltip
              placement="top"
              title={
                emailsLength < 1
                  ? t(
                    "please enter at least one email to enable email notification",
                  )
                  : value
                    ? t("tooltip.action.disable")
                    : t("tooltip.action.enable")
              }
            >
              <Grid container item alignItems="center">
                <IconButton
                  onClick={(e) => handleSwitchEmail(e, index, value)}
                  disabled={emailsLength < 1}
                  size="small"
                >
                  <EmailIcon
                    className={value ? classes.active : classes.inactive}
                    fontSize="small"
                  />
                </IconButton>
                <Grid item className={value ? classes.active : classes.inactive} onClick={(e) => handleSwitchEmail(e, index, value)}>
                  {value ? "ON" : "OFF"}
                </Grid>
              </Grid>
            </Tooltip>
          </Grid>
        </HasPermission>
      ),
      contentTypeString: true
    },
    {
      id: "sms",
      valuePath: "smsStatus",
      disableSmsFunctionality,
      customContent: ({ value, index, smsLength }) => (
        <HasPermission name="Add default alert">
          <Grid container alignItems="center">
            <Tooltip
              placement="top"
              title={
                smsLength < 1
                  ? t(
                    "please enter at least one phone number to activate the sms notification",
                  )
                  : value
                    ? t("tooltip.action.disable")
                    : t("tooltip.action.enable")
              }
            >
              <Grid item>
                <IconButton
                  onClick={(e) => handleSwitchSms(e, index, value)}
                  disabled={smsLength < 1}
                  size="small"
                >
                  <SmsIcon
                    className={value ? classes.active : classes.inactive}
                    fontSize="small"
                  />
                </IconButton>
              </Grid>
            </Tooltip>
            <Grid item className={value ? classes.active : classes.inactive}>
              {value ? "ON" : "OFF"}
            </Grid>
          </Grid>
        </HasPermission>
      ),
      contentTypeString: true
    },
    {
      id: "subscription",
      valuePath: "alertSubscribersIds",
      customContent: ({ value, index }) => (
        <HasPermission name="Add default alert">
          <Grid container item alignItems="center">
            <CustomButton
              type="submit"
              startIcon={getSubscriptionStatus(value) ? <Clear /> : <Add />}
              className={getSubscriptionStatus(value) ? classes.unsubscribe : classes.subscribe}
              onClick={(e) => handleChangeSubscriptionStatus(e, index, !getSubscriptionStatus(value))}
              >
              {t(getSubscriptionStatus(value) ? "UNSUBSCRIBE" : "SUBSCRIBE")}
            </CustomButton>
          </Grid>
        </HasPermission>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Grid container justify="center">
          <AlertsTable
            displayFields={displayFields}
            headerCells={headerCells}
            entityBaseUrl={entityBaseUrl}
            fetchEntities={fetchEntities}
            deleteEntity={deleteEntity}
            searchField={searchField}
            entityQuery={entityQuery}
            labels={labels}
            isDefaultAlertsPage
            statusList={statusList}
            typeList={typeList}
            handleAlertDialogOpen={handleDefaultAlertDialogOpen}
          />
        </Grid>
      </div>
      <ConfirmMessage
        message={
          !active.value && active.field === "email"
            ? t("alert.management.enable.email.notification")
            : active.value && active.field === "email"
              ? t("alert.management.disable.email.notification")
              : !active.value && active.field === "sms"
                ? t("alert.management.enable.sms.notification")
                : t("alert.management.disable.sms.notification")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={
          active.field === "email" ? confirmSwitchEmail : confirmSwitchSms
        }
        buttonConfirm={t("Confirm")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />
      <DefaultAlertDialog
        open={defaultAlertDialogOpen}
        mode={defaultAlertDialogMode}
        idAlert={defaultAlertDialogCurrentId}
        handleClose={handleDefaultAlertDialogClose}
        handleDefaultAlertEdit={handleDefaultAlertEdit}
      />
    </div>
  );
}
