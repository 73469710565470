import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropabbleTitle: {
    padding: theme.spacing(1),
    paddingLeft: 0
  },
  droppableContainer: {
      background: theme.palette.info.light,
      borderRadius: "4px",
      width: "100%",
      height: "300px",
      overflow: "auto",
      border: `8px ${theme.palette.info.light} solid`,
      paddingRight: "6px",
    },
  card: {
    width: "100%",
    maxHeight: "100%",
  },
  itemsContainer: {
    backgroundColor: theme.palette.info.light
  },
  selectedTitleContainer: {
    width: "100%",
    borderBottom: "solid 1px #bfbfbf",
  },
  selectedTitle: {
    padding: theme.spacing(1.5, 2.25),
    width: "fit-content",
    margin: "auto",
    fontSize: "1.1em",
  },
  alvailableTitle: {
    padding: theme.spacing(1.5, 1.75),
    paddingBottom: theme.spacing(2.5),
    fontSize: "1.1em",
    width: "100%",
  },
  assignedProcessContainer: {
    alignContent: "flex-start",
    height: "100%",
    overflow: "hidden",
    border: "solid 1px #bfbfbf",
    borderRadius: theme.spacing(1),
  },
  listContainer: {
    padding: theme.spacing(0.75, 0.75),
    background: "none",
    width: "100%",
    height: "calc(100% - 51px)",
    overflow: "auto",
  },
  listItem: {
    padding: "0px",
  },
  availableListItem: {
    padding: "4px 14px",
    textlign: "left",
  },
  availableButton: {
    textTransform: "none",
    justifyContent: "start",
    padding: "4px 14px",
    textlign: "left",
    width: "100%",
  },
  availableListItemText: {
    minHeight: "42px",
    margin: "0",
    alignItems: "center",
    display: "flex",
  },
  removeProcess: {
    "&:hover": {
      backgroundColor: "rgba(255, 0, 0, 0.04)",
      color: "red",
    },
  },
  availableProcessContainer: {
    width: "100%",
    height: "calc(100% - 61px)",
    overflow: "auto",
  },
  listButton: {
    textTransform: "none",
    justifyContent: "start",
    padding: "4px 14px",
    textlign: "left",
    width: "100%",
    cursor: "default",
  },
  removeProcessContainer: {
    height: "42px",
    display: "flex",
    alignItems: "center",
  },
  processContainer: {
    width: "48%",
  },
  processSeparator: {
    width: "4%",
  },
  droppableTitle: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    paddingBottom: 0
  },
  processAvatar: {
    width: 20,
    height: 20,
    fontSize: 10
  },
  checkBoxLabel: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: "0.9rem"
  },
  checkboxContainer: {
    marginLeft: theme.spacing(6.8)
  },
  assignBtn: {
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    borderRadius: "0.3125rem",
    marginTop: theme.spacing(3)
  },
  assigningBtns: {
    marginTop: theme.spacing(3)
  }
}));
