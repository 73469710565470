import { FETCH_ALL_EXECUTION_REQUESTS } from "redux/constants";
import { URL_EXECUTIONS_REQUESTS } from "redux/constants/endpoints";
import { formatDateTimeIsoParam } from "util/helpers/date.helpers";
/* eslint-disable import/prefer-default-export */
export const fetchExecutionRequestsList = (
  page,
  size,
  sortField,
  sortOrder,
  processes,
  fleetIds,
  fromDate,
  toDate,
  searchCriteria,
  tagsIds = [],
  archived = false
) => ({
  type: FETCH_ALL_EXECUTION_REQUESTS,
  request: {
    url: encodeURI(`${URL_EXECUTIONS_REQUESTS}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
    params: {
      page,
      processesIds: processes.join(),
      size,
      sort: `${sortField},${sortOrder}`,
      fleetIds: fleetIds?.join(),
      fromDate: formatDateTimeIsoParam(fromDate),
      toDate: formatDateTimeIsoParam(toDate),
      tagsIds: tagsIds?.join(),
      archived
    },
  },
});
