import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { ReactComponent as ExportIcon } from "../../assets/common/export.svg";
import theme from "../../style/orchestratorTheme";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Tooltip } from "@material-ui/core";

const ExportButton = ({
                          loading, withWarning = false, warningMessage, active = false, ...rest
                        }) => {
  const { t } = useTranslation();

  const iconToRender = () => {
      if (withWarning) {
          return (
            <Tooltip title={t(warningMessage)}>
              <ReportProblemOutlinedIcon fontSize="small" />
            </Tooltip>
          )
      }
      if (!withWarning) {
          return (
            <Tooltip title={t("export")}>
              <ExportIcon
                stroke={
                  (rest.disabled || active) ? theme.custom.color.dividerColor : "white"
                }
              />
            </Tooltip>
          )
      }
      return <CircularProgress color="inherit" size={20} />
  }
  return (
    <CustomButton
      {...rest}
      view="primary"
      iconBtn
    >
      {iconToRender()}
    </CustomButton>
  );
};

export default ExportButton;
