import React from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core";
import {
  updateUserPassword,
  validateToken,
} from "../../../../redux/actions/services";
import Spinner from "../Spinner";
import AlertMessage from "../AlertMessage";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "../../style";

export default function FormPasswordReset() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [passChangeSuccess, setPassChangeSuccess] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [validToken, setValidToken] = React.useState(false);
  const token = window.location.search.slice(7);
  const _handleModalClose = () => {
    setPassChangeSuccess(false);
  };

  React.useEffect(() => {
    dispatch(validateToken(token)).then((res) => setValidToken(res.data));
  }, [token]);

  const redirectToHome = () => history.push("/");

  const _renderModal = () => {
    const onClick = () => {
      setPassChangeSuccess(false);
      setTimeout(redirectToHome, 1000);
    };

    return (
      <AlertMessage
        isOpen={passChangeSuccess}
        onClose={_handleModalClose}
        handleSubmit={onClick}
        title={t(alertTitle)}
        text={t(alertMessage)}
        submitButtonText={t("profile.update-password.password-reset-done")}
      />
    );
  };

  const _handleSubmit = ({
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    dispatch(
      updateUserPassword(
        token,
        {
          newPassword: newPass,
          confirmation: confirmPass,
        },
        () => {
          setSubmitting(false);
          setAlertTitle("profile.update-password.password-reset");
          setAlertMessage("profile.update-password.password-reset-success");
          setPassChangeSuccess(true);
        },
        () => {
          setSubmitting(false);
          setAlertTitle("profile.update-password.password-reset");
          setAlertMessage("profile.update-password.password-reset-success");
          setPassChangeSuccess(true);
          resetForm();
        },
      ),
    );
  };

  if (!validToken) {
    return (
      <Alert severity="error">
        {t("profile.update-password.tokenError")}
      </Alert>
    );
  }
  return (
    <Grid className={classes.app}>
      <Formik
        initialValues={{
          newPass: "",
          confirmPass: "",
        }}
        validationSchema={object().shape({
          newPass: string().required(
            t("profile.update-password.new-password-required"),
          ),
          confirmPass: string()
            .oneOf(
              [ref("newPass")],
              t("profile.update-password.password-not-matched"),
            )
            .required(t("profile.update-password.password-required")),
        })}
        onSubmit={(
          { newPass, confirmPass },
          { setSubmitting, resetForm },
        ) => _handleSubmit({
          newPass,
          confirmPass,
          setSubmitting,
          resetForm,
        })}
        render={(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props;
          return isSubmitting ? (
            <Spinner />
          ) : (
            <Grid container item xs className={classes.container}>
              <Grid item xs={8}>
                <Paper className={classes.formWrapper}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Typography variant="h6" className={classes.title}>
                      {t("profile.update-password.update-password")}
                    </Typography>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.newPass && errors.newPass)}
                    >
                      <InputLabel
                        htmlFor="password-new"
                        error={Boolean(touched.newPass && errors.newPass)}
                      >
                        {t("profile.update-password.new-password")}
                      </InputLabel>
                      <Input
                        id="password-new"
                        name="newPass"
                        type="password"
                        value={values.newPass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.newPass && errors.newPass)}
                      />
                      <FormHelperText
                        error={Boolean(touched.newPass && errors.newPass)}
                      >
                        {touched.newPass && errors.newPass
                          ? errors.newPass
                          : ""}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(
                        touched.confirmPass && errors.confirmPass,
                      )}
                    >
                      <InputLabel
                        htmlFor="password-confirm"
                        error={Boolean(
                          touched.confirmPass && errors.confirmPass,
                        )}
                      >
                        {t("profile.update-password.confirm-password")}
                      </InputLabel>
                      <Input
                        id="password-confirm"
                        name="confirmPass"
                        type="password"
                        value={values.confirmPass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.confirmPass && errors.confirmPass,
                        )}
                      />
                      <FormHelperText
                        error={Boolean(
                          touched.confirmPass && errors.confirmPass,
                        )}
                      >
                        {touched.confirmPass && errors.confirmPass
                          ? errors.confirmPass
                          : ""}
                      </FormHelperText>
                    </FormControl>
                    <CustomButton
                      type="submit"
                      variant="raised"
                      color="primary"
                      disabled={Boolean(!isValid || isSubmitting)}
                      className={classes.resetButton}
                    >
                      {t("profile.update-password.reset-password")}
                    </CustomButton>
                  </form>
                  {_renderModal()}
                </Paper>
              </Grid>
            </Grid>
          );
        }}
      />
    </Grid>
  );
}
