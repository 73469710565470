import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Popper from "@material-ui/core/Popper";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import get from "lodash/get";
import { fade, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { kpiTypes, getLocale } from "util";
import { formatImagePath } from "util/helpers/file.helper";
import CustomTooltip from "components/CustomTooltip";
import useTooltipStyles from "components/AdditionalInfoTooltip/style"
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "./styles";

const isValued = (value, valued = false) => ((value || `${value}` === "0") && (isArray(value) ? !isEmpty(value) : true)) || valued

export const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline": {
        color: ({ customColor }) => (customColor ? get(theme, customColor) : "")
    },
    "& .MuiFormLabel-root": {
      color: ({ value, valued, disabled }) => {
        if (disabled) {
          return theme.custom.color.chartColor4;
        }
        if (isValued(value, valued)) {
          return fade(theme.palette.primary.main, 0.6);
        }
        return fade(theme.palette.primary.main, 0.6);
      },
      fontSize: 15,
      fontWeight: 600,
      lineHeight: 0.7
    },
    "& .MuiInput-underline:hover:not(.Mui-error):before": {
      borderBottomColor: ({ disabled }) => (disabled ? theme.custom.color.chartColor4 : theme.palette.info.main),
      borderWidth: 3,
    },
    "& .MuiInput-underline:not(.Mui-error):before": {
      borderBottomColor: ({ value, valued, disabled }) => {
        if (disabled) {
          return theme.custom.color.chartColor4;
        }
        if (isValued(value, valued)) {
          return theme.palette.info.main;
        }
        return theme.custom.color.chartColor1;
      },
      borderBottomStyle: "solid",
      borderWidth: 3,
    },
    "& .MuiInput-underline:not(.Mui-error):after": {
      borderBottomColor: ({ value }) => (isValued(value) ? theme.palette.info.main : theme.custom.color.chartColor1),
      borderBottomStyle: "solid",
      borderWidth: 3,
    },
    "& .Mui-error": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: ({ value, valued, disabled }) => {
        if (disabled) {
          return theme.custom.color.chartColor4;
        }
        if (isValued(value, valued)) {
          return theme.palette.info.main;
        }
        return theme.custom.color.chartColor1;
      },
    },
    "& .MuiInput-underline #info_icon": {
        display: "none",
    },
    "& .MuiInput-underline:hover #info_icon": {
        display: "block",
    }
  },
}))(TextField);

export default function CustomAutoComplete({
  options,
  optionLabel,
  label,
  noOptionsNode,
  onChange,
  multiple,
  value,
  handleDisplayedOptionLabel,
  inputRef,
  customRenderOption,
  getStatusColor,
  iconPath,
  optionUuid = "id",
  badgeLabel = "kpiType",
  badgeList = kpiTypes,
  disableCloseOnSelect = multiple,
  keepCaseLabel = false,
  translateLabel = true,
  showBadge = false,
  isStatusFilter = false,
  readOnly = false,
  concernedOptions = null,
  simpleChipStyle = false,
  ...rest
}) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();

  const getOptionLabel = (option) => {
    if (optionLabel) {
      if (typeof optionLabel === "function") {
        return getTranslatedLabel(optionLabel(option));
      }
      if (Array.isArray(optionLabel)) {
        // Concatenate multiple labels
        return optionLabel.map((label) => String(option[label])).join(" ");
      }
      // Check if optionLabel contains a nested property
      if (optionLabel.includes(".")) {
        const nestedProperties = optionLabel.split(".");
        // Access nested property using reduce and check for undefined values
        const nestedValue = nestedProperties.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : ""), option);
        return String(nestedValue);
      }
      return getTranslatedLabel(String(option[optionLabel]));
    }
    return t(String(option));
  };
  const autocompleteRef = useRef()
  const autocompleteContainerRef = useRef(null);
  const [limitTagsNumber, setLimitTagsNumber] = useState(undefined);
  const [newOptions, setNewOptions] = useState([]);
  const [isCloseTagHovered, setIsCloseTagHovered] = useState(false);
  const isAllSelected = multiple && !isEmpty(options) && !isEmpty(value) && value?.length === options?.length
  const [selectedAll, setSelectedAll] = useState(isAllSelected);
  const [openPopper, setOpenPopper] = useState(false);
  const isOptionLabelString = typeof optionLabel === "string";
  // eslint-disable-next-line no-nested-ternary
  const values = useMemo(() => (multiple ? (isOptionLabelString ? Array.isArray(options) && options?.filter((el) => Array.isArray(value) && value?.map((val) => val?.[optionUuid])?.includes(el?.[optionUuid])) : options?.filter((el) => Array.isArray(value) && value?.map((val) => val)?.includes(el))) : isOptionLabelString ? options?.find((el) => el?.[optionUuid] === value?.[optionUuid]) : options?.find((el) => el === value)), [value, options, multiple, optionLabel])
  React.useEffect(() => {
    if (options && multiple && options.length > 1) {
      const newOption = { [optionUuid]: -1, selectAllDisplayName: "Select All" };
      const optionsWithSelectAll = [newOption, ...options];
      setNewOptions(optionsWithSelectAll);
    } else {
      setNewOptions(options);
    }
  }, [options]);

  const getTranslatedLabel = (label) => {
    if (translateLabel) {
      return t(label);
    }
    return label;
  }

  const handleSelectAllChange = (check) => {
    setSelectedAll(check);
    const tempOptions = check ? options : []
    onChange(tempOptions);
  };

  const handleOptionDelete = (option) => {
    onChange(isOptionLabelString ? value?.filter((el) => el?.[optionUuid] !== option?.[optionUuid]) : value?.filter((el) => el !== option))
    setSelectedAll(false)
    handleAutocompleteWidth()
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (multiple && selectedOptions.find((option) => option?.selectAllDisplayName === "Select All")) {
        handleSelectAllChange(!selectedAll);
      } else {
        onChange(selectedOptions)
      }
    } else if (reason === "clear") {
      setSelectedAll(false);
      onChange([]);
    }
  }

  const countElementsToFitWidth = (totalWidth, elementWidths) => {
    if (elementWidths?.length === 0) return undefined
    let currentWidth = 0;
    let count = 0;

    for (let i = 0; i < elementWidths.length; i += 1) {
      currentWidth += elementWidths[i];
      if (currentWidth <= totalWidth) {
        count += 1;
      } else {
        break;
      }
    }

    return count;
  }

  const handleAutocompleteWidth = () => {
    if (multiple && autocompleteRef?.current?.children?.length) {
      setLimitTagsNumber((prevState) => prevState + 1)
      const childrenWidths = Array.from(autocompleteRef.current.children?.[label ? 1 : 0]?.children)?.filter(({ className }) => className?.includes("MuiChip-root"))?.map(({ clientWidth }) => clientWidth)
      const autocompleteWidth = autocompleteRef.current.clientWidth - 65
      setLimitTagsNumber(countElementsToFitWidth(autocompleteWidth, childrenWidths))
    }
  }

  const handleDisplayOption = (option) => {
    if (multiple && option?.selectAllDisplayName === "Select All") return t(option?.selectAllDisplayName)

      if (handleDisplayedOptionLabel) return handleDisplayedOptionLabel(getTranslatedLabel(getOptionLabel(option)), keepCaseLabel, translateLabel)
      return getTranslatedLabel(getOptionLabel(option))
  }

  const getStatusColorClass = (option) => {
    if (getStatusColor) {
      return classes[getStatusColor(option)];
    }
    return classes.UNKNOWN;
  }

  useEffect(() => {
    if (isEmpty(values)) setIsCloseTagHovered(false)
    setSelectedAll(isAllSelected)
  }, [values])

  useEffect(() => {
    handleAutocompleteWidth()
  }, [values, autocompleteRef?.current?.clientWidth, value])

  const renderMenuItem = (content, option) => {
    if (showBadge) { return (
      <Grid container xs={9} justify="space-between" alignItems="center">
        <Grid item xs={11} zeroMinWidth wrap="nowrap" className={classes.customRenderOptionText}>
          <Tooltip title={content} placement="top">
            <Typography className={classes.itemText}>
              {content}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid container item xs={1} spacing={1} justify="flex-end" alignItems="flex-end">
          {option?.[badgeLabel] && (
            <Tooltip title={t(option?.[badgeLabel])}>
              <IconButton size="small">
                {badgeList?.[option?.[badgeLabel]]?.icon}
              </IconButton>
            </Tooltip>
            )}
        </Grid>
      </Grid>
    ) }
    return (
      <Tooltip title={content}>
        <>
          {iconPath && (option?.[iconPath] ? (
            <img
                  src={formatImagePath(option?.[iconPath])}
                  align="left"
                  alt="Service"
                  className={classes.processAvatar}
              />
          ) : (
            <ProcessDefaultIcon className={classes.processAvatar} />
          ))}
          <Box component={Typography} className={classes.itemText} display="flex" alignItems="center" gridColumnGap={10}>
            {content}
          </Box>
        </>
      </Tooltip>
    )
  }

  const renderTextOption = (option, selected) => {
    if (customRenderOption) {
      if (multiple && option?.selectAllDisplayName === "Select All") {
        return (
          <Typography component="h3" className={classes.itemText}>
            {t(option?.selectAllDisplayName)}
          </Typography>
        )
      }
      return customRenderOption(option, { selected })
    }
      return (
        renderMenuItem(handleDisplayOption(option), option)
      )
  }
  const [popperWidth, setPopperWidth] = useState("auto");
  useEffect(() => {
    if (autocompleteContainerRef?.current) {
      setPopperWidth(autocompleteContainerRef?.current?.offsetWidth);
    }
  }, [autocompleteContainerRef]);

  const MenuPopper = useCallback((props) => (
    <Popper {...props} placement="bottom-start" style={{ width: "auto", minWidth: popperWidth, maxWidth: popperWidth * 1.5 }}>
      {props?.children}
    </Popper>
    ), [autocompleteContainerRef, popperWidth]);
  return (
    <Autocomplete
      ref={autocompleteContainerRef}
      multiple={multiple}
      limitTags={limitTagsNumber}
      options={newOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={handleChange}
      value={values}
      disableClearable
      key={`${!multiple ? values : ""}-${getLocale()?.code}`}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => {
        const optionId = typeof option === "object" ? option?.[optionUuid ?? "id"] : option;
        const valueId = typeof value === "object" ? value?.[optionUuid ?? "id"] : value;
        return optionId === valueId;
      }}
      noOptionsText={noOptionsNode || t("No options")}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          value={readOnly ? null : value}
          variant="standard"
          label={label}
          error={rest?.error}
          helperText={rest?.helperText}
          inputRef={inputRef}
          ref={autocompleteRef}
          InputProps={{
            ...params.InputProps,
            style: { height: "33px", ...params.InputProps.style },
            className: limitTagsNumber < values?.length && classes.selectedInput,
            readOnly
          }}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          inputProps={{
            ...params.inputProps,
            className: !multiple && classes.selectedMonoInput
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <Grid container alignItems="center" wrap="nowrap">
          {(multiple) && <Checkbox
              checked={multiple && option?.selectAllDisplayName === "Select All" ? selectedAll : selected}
          />}
          {(isStatusFilter && option?.selectAllDisplayName !== "Select All") && <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              variant="dot"
              className={classes.badge}
              classes={{ badge: getStatusColorClass(option), dot: classes.dot }}
            />}
          {option?.selectAllDisplayName !== "Select All" && (concernedOptions?.includes(option)) ? (
            <CustomTooltip
                placement="right-start"
                classes={{ tooltip: classes.tooltip }}
                title={
                  <Grid container direction="column" spacing={1}>
                    <Grid item className={tooltipClasses.moreInfoTooltipTitle}>
                      {getOptionLabel(option)}
                    </Grid>
                    <Typography variant="span" className={tooltipClasses.itemDataName}>
                      {t(option?.description)}
                    </Typography>
                  </Grid>
                }
                customBgColor
            >
              {renderTextOption(option, selected)}
            </CustomTooltip>) : (
                    renderTextOption(option, selected)
            )}
        </Grid>
      )}
      renderTags={(value, getTagProps) => (
        value.map((option, index) => (
          <Tooltip title={getOptionLabel(option)}>
            <ButtonBase
                  component={Chip}
                  key={getOptionLabel(option)}
                  label={(
                    <Typography component="div">
                      <Box className={clsx(classes.chipText, simpleChipStyle ? classes.chipTextSimple : "")}>
                        {getOptionLabel(option)}
                        {simpleChipStyle && multiple && index < value.length - 1 ? "," : "" }
                      </Box>
                    </Typography>
                  )}
                  {...getTagProps({ index })}
                  className={clsx(classes.chip, simpleChipStyle ? classes.chipSimple : "")}
                  classes={{
                    label: simpleChipStyle ? classes.chipLabelSimple : "",
                  }}
                  style={{
                    visibility: index < limitTagsNumber ? "visible" : "hidden",
                    position: index < limitTagsNumber ? "relative" : "absolute",
                  }}
                  onDelete={() => handleOptionDelete(option)}
                  deleteIcon={<CloseRoundedIcon className={clsx(readOnly ? classes.readOnly : "", classes.chipIcon, simpleChipStyle ? classes.chipIconSimple : "")} />}
              />
          </Tooltip>
        ))
      )}
      renderGroup={(params) => (
        <Box key={params.key}>
          <Typography className={classes.groupLabel}>
            {params.group}
          </Typography>
          {params.children}
        </Box>
      )}
      getLimitTagsText={(more) => (
        <Badge
          onMouseEnter={() => setIsCloseTagHovered(true)}
          onMouseLeave={() => setIsCloseTagHovered(false)}
          onClick={() => isCloseTagHovered && handleSelectAllChange(false)}
          badgeContent={isCloseTagHovered ? <CloseRoundedIcon className={classes.closeIcon} /> : `+${more}`}
          classes={{
            badge: clsx(classes.chipBadge, isCloseTagHovered ? classes.chipBadgeClose : "")
          }} />
      )}
      ChipProps={{
        size: "small",
      }}
      classes={{
        tag: classes.chipTag,
        option: classes.optionItem,
      }}
      PopperComponent={MenuPopper}
      open={openPopper}
      onOpen={() => !readOnly && setOpenPopper(true)}
      onClose={() => setOpenPopper(false)}
      onKeyPress={(e) => { readOnly && e.preventDefault(); }}
      className={readOnly && classes.caret}
      popupIcon={rest?.popupIcon ?? <ExpandMoreIcon />}
      openText={t("Open")}
      closeText={t("Close")}
      {...rest}
    />
  );
}
