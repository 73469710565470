import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  boxSection: {
    position: "relative",
    padding: theme.spacing(5, 5, 5, 5),
    marginTop: 20,
    border: `1px solid ${theme.palette.primary.light}`,
    width: "100%",
  },
  boxSectionTitle: {
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: -10,
    left: 25,
    color: theme.palette.primary.dark,
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0 10px"
  },
  fieldsGrid: {
    display: "flex",
    padding: theme.spacing(0.5, 0),
  },
  switchText: {
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: 600,
  },
  addCredentialButton: {
    marginTop: 15,
  },
}));
