import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import clsx from "clsx";
import { deleteUsers } from "../../../../../redux/actions/services";
import { isFleetAdministrator, displayDeletedItems, isOnlyCurrentUserSelected } from "util";
import { QUEUE_DELETED } from "util/constants";
import { updateQueueAction } from "../../../../../redux/slices/executionManagerSlice";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import useStyles from "../../../../../components/TableComponents/style";

export default function UserTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
  searchCriteria
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(
    ({ executionManager }) => executionManager,
  );
  const onlyCurrentUserSelected = isOnlyCurrentUserSelected(selected, currentUser);

  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);
  const deleteAll = (newSelected = selected) => {
    const selectedCount = newSelected?.length
    let deletedUsers = [];
    dispatch(
      deleteUsers(
          newSelected?.map((user) => user?.id),
        (res) => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage, searchCriteria })).then((res) => {
            deletedUsers = newSelected.filter((selectedItem) => !res?.data?.content.map((item) => item?.id).includes(selectedItem.id));
          }).then(() => {
            setSelected([]);
            handleCloseConfirmationPopup();
            const noDeletedCount = res?.data ?? 0;
            if (noDeletedCount < selectedCount) toast.success(displayDeletedItems(deletedUsers, "firstName", t("userSingle"), t("userPlural"), t))
            if (noDeletedCount > 0) toast.error(`${noDeletedCount} ${t("users.delete.has.exception")}`)
          })
        },
        (res) => {
          handleCloseConfirmationPopup();
          res?.response?.data?.title ? toast.error(t(res?.response?.data?.title)) : toast.error(t("something went wrong"));
        },
      ),
    );
  };
  useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected.filter((user) => user?.id !== Number(currentUser.id)));
  };
  const headerCells = [
      {
        id: "status",
        label: "",
      },
      {
        id: "logo",
        label: "",
      },
      {
        id: "name",
        label: t("administration.name"),
      },
      {
        id: "creationDate",
        label: t("administration.creationDate"),
      },
      {
        id: "email",
        label: t("administration.email"),
      },
      {
        id: "fleet",
        label: t("user.administration.fleet"),
      },
      {
        id: "lastLogin",
        label: t("user.administration.lastLogin"),
      },
      {
        label:
          isFleetAdministrator(currentUser) && data?.totalElements > 0 && (
            <Grid container item xs={12} justify="flex-end" alignItems="center">
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <CustomDeleteIcon
                      className={clsx(classes.rowActionButtons, classes.deleteIcon)}
                      aria-label="delete"
                      disabled={
                          isEmpty(selected)
                          || onlyCurrentUserSelected
                          || onlyCurrentUserInList
                      }
                      onClick={() => {
                        setOpenDeletePopup(true);
                      }}
                  />
                </Tooltip>
                <ConfirmMessage
                    message={t("user.management.deleteUsersActionMessage")}
                    openStart={openDeletePopup}
                    onCancel={handleCloseConfirmationPopup}
                    onConfirm={onClick}
                    buttonConfirm={t("Delete")}
                    buttonCancel={t("Cancel")}
                    isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <Checkbox
                    checked={checkedAll && !onlyCurrentUserInList}
                    className={classes.rowActionButtons}
                    indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                    onChange={(e) => {
                      handleSelectedAll(e.target.checked);
                    }}
                    disabled={onlyCurrentUserInList}
                />
              </Grid>
            </Grid>
          )
      }
      ];

  return (
    <CustomTableHeader labels={headerCells} />
  );
}
